<h1 mat-dialog-title class="text-danger text-center" [innerHTML]="data.title || 'Error'"></h1>
<hr />
<div mat-dialog-content class="font-bold">
  <p class="text-danger" [innerHTML]="data.header || ''"></p>
  <p class="text-danger" [innerHTML]="data.content || ''"></p>
</div>
<hr />
<div mat-dialog-actions class="float-end">
  <button mat-raised-button class="custom-modal-button" color="warn" (click)="close()">Close</button>
</div>
