import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ClaimSummaryResponse} from '../../../models/response/claim-summary-response';
import {ClaimDetailsResponse} from '../../../models/response/claim-details-response';
import {RecentClaimsResponse} from '../../../models/response/recent-claims-response';
import {RecentClaim} from '../../../models/recent-claim';
import {Claim} from '../../../models/claim';
import {ClaimDetail} from '../../../models/claim-details';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  private claimsUrl = `${environment.baseUrl}claims-management/pet-php-claims/v1/`;
  public policyClaims: any;
  public deductibleApplied = 0;
  public remainingDeductible = 0;
  // private recentClaimUrl = environment.baseUrl + 'claims/v1/recent/';
  // private historyClaimUrl = environment.baseUrl + 'claims/v1/history/';


  constructor(private http: HttpClient) {
  }

  // public getRecentClaims(): Observable<RecentClaimsResponse> {
  //   return this.http.get(this.recentClaimUrl).pipe(
  //     map(claims => {
  //       return claims as RecentClaimsResponse;
  //     })
  //   );
  // }

  public getNewRecentClaims(tokenPolicyList): Observable<any> {
    const responseList: any=[];
    tokenPolicyList.forEach(element => {      
      const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
      const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === element.policyNumber);    
      const activeMemberId = selectedPolicy.memberId;
      responseList.push(this.getClaimsList(element, activeMemberId));
    });
    return forkJoin(responseList);
  }


  public getClaimsList(element, activeMemberId) {
    element.renewal=element.renewalNumber;
    const httpOptions = {
      headers: new HttpHeaders({
        activeMemberId
      })
    };
    return this.http.get(`${this.claimsUrl}${element.policyNumber}/${element.renewal}`,httpOptions).pipe(
      map(claim => {
        const claimSummary: any = claim;
        if(claimSummary !== null && claimSummary.ClaimSummaryResponse!==null && 
        claimSummary.ClaimSummaryResponse.ClaimSummary!=null && 
        claimSummary.ClaimSummaryResponse.ClaimSummary!=undefined ){
          // eslint-disable-next-line @typescript-eslint/no-shadow
          claimSummary.ClaimSummaryResponse.ClaimSummary.forEach(claimSummary=>{claimSummary.petName = element.pet.name;
        //claimSummary.planName = element.planNames.length!=0?element.planNames[0]:null
        });
        return claimSummary.ClaimSummaryResponse.ClaimSummary ;
      }
      // else{
      //   return claimSummary.ClaimSummaryResponse;
      // }
      })
    );
  }

  public getAllClaimDetails(claimsList): Observable<any> {
    const recentClaimsArray: any=[];  
        if(claimsList!=null && claimsList!=undefined && claimsList.length){
          claimsList.forEach(claimObj => {
            const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
            const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === claimObj.policyNumber);    
            const activeMemberId = selectedPolicy.memberId;
            recentClaimsArray.push( this.getClaimsDetailsList(claimObj,activeMemberId));
          });
        }
    return forkJoin(recentClaimsArray);
  }

   getClaimsDetailsList(element,activeMemberId) {
     const httpOptions = {
      headers: new HttpHeaders({
        activeMemberId
      })
    };
    return this.http.get(this.claimsUrl + element.claimNumber,httpOptions).pipe(
      map(data => {
        const claimDetails: any = data;
        const recentClaim: any={};
        recentClaim.petName = element.petName;
        recentClaim.planName = element.planName;
        recentClaim.treatmentDate = new Date(element.treatmentDate);
        recentClaim.reportedDate = new Date(element.reportedDate);
        recentClaim.policyNumber = element.policyNumber;
        recentClaim.policyRenewal = element.policyRenewal;
        recentClaim.claimStatus = element.claimStatus;
        recentClaim.claimNumber = element.claimNumber;
        recentClaim.statusbar = element.statusbar;
        if(claimDetails.ClaimDetailsResponse.ClaimDetails!=null && claimDetails.ClaimDetailsResponse.ClaimDetails!=undefined){
        recentClaim.claimDetails = claimDetails.ClaimDetailsResponse.ClaimDetails;  
        recentClaim.claimDetails.forEach(claimDetail=>{
           if(claimDetail.diagnosisCode=='' && claimDetail.categoryType==''){
          claimDetail.categoryType = 600;
        }else if(claimDetail.diagnosisCode!='' && claimDetail.categoryType==''){
          claimDetail.categoryType = 0;
        }
         });
         recentClaim.claimDetails = recentClaim.claimDetails.filter(claimDetail=>(claimDetail.categoryType !== '600'));
        }else{
        recentClaim.claimDetails = null; }
        return recentClaim as RecentClaim;
      })
    );
  }  

  // public getHistoryClaims(): Observable<RecentClaimsResponse> {
  //   return this.http.get(this.historyClaimUrl).pipe(
  //     map(claims => {
  //       return claims as RecentClaimsResponse;
  //     })
  //   );
  // }

  public getClaimDetails(claimNumber: string, policyNumber: string, renewal: string): Observable<ClaimDetailsResponse> {
    return this.http.get(`${this.claimsUrl}${claimNumber}/${policyNumber}/${renewal}`).pipe(
      map(claimDetails => claimDetails as ClaimDetailsResponse)
    );
  }
  
  public getClaimDetailsByClaimNo(claimNumber: string): Observable<ClaimDetailsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        activeMemberId: sessionStorage.getItem('activeMemberId')
      })
    };
    return this.http.get(`${this.claimsUrl}${claimNumber}`,httpOptions).pipe(
      map(claimDetails => { 
        const claimDetailsResp: any =  claimDetails;
        if(claimDetailsResp.ClaimDetailsResponse.ClaimDetails){
        const claimsDtlList = claimDetailsResp.ClaimDetailsResponse.ClaimDetails;
        claimsDtlList.forEach(claimDetail=>{if(claimDetail.diagnosisCode==''){
          claimDetail.categoryType = 600;
        }else{
          claimDetail.categoryType = 1;
        }
      }); 
      claimDetails = claimDetailsResp;
        }
        return claimDetails as ClaimDetailsResponse;
      })
    );
  }

  public getClaimSummary(policyNumber: string, renewal: number): Observable<ClaimSummaryResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        activeMemberId: sessionStorage.getItem('activeMemberId')
      })
    };
    return this.http.get(`${this.claimsUrl}${policyNumber}/${renewal}`,httpOptions).pipe(
      map(claim => claim as ClaimSummaryResponse)
    );
  }

  getRemainingDeductible(term: any, annualDeductible) {
    this.remainingDeductible = this.policyClaims.deductible;
    if (this.policyClaims && this.policyClaims.ClaimSummaryResponse && this.policyClaims.ClaimSummaryResponse.ClaimSummary) {
      let termClaims = this.policyClaims.ClaimSummaryResponse.ClaimSummary.filter(cl => cl.eOBDate && cl.renewal === term);

      if (termClaims.length == 1) {
        this.remainingDeductible = termClaims[0].deductibleRemaining || 0;
      }
      else if (termClaims && termClaims.length > 1) {
        let claims = termClaims.sort((curr, acc) => {
          return (new Date(acc.eOBDate).getTime() - new Date(curr.eOBDate).getTime());
        });

        let remainingNew = [];
        for (let i = 0; i < claims.length - 1; i++) {
          if (claims[i].eOBDate === claims[i + 1].eOBDate) {
            remainingNew.push(claims[i]);
            remainingNew.push(claims[i + 1]);
          }
          else
            break;
        }

        if (remainingNew.length > 0) {
          let remain = remainingNew.sort((a, b) => a.deductibleRemaining - b.deductibleRemaining);
          this.remainingDeductible = remain[0].deductibleRemaining || 0;
        }
        else {
          this.remainingDeductible = claims[0].deductibleRemaining || 0;
        }
      }

    }
    if (annualDeductible) {
      this.deductibleApplied = Number((Number(annualDeductible) - Number(this.remainingDeductible)).toFixed(2));
    }
  }
}
