import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-pet-profile-picture',
  templateUrl: './pet-profile-picture.component.html',
  styleUrls: ['./pet-profile-picture.component.scss']
})
export class PetProfilePictureComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() imageId: string;
  @Input() imageAlt = '';


  constructor() {
  }

  ngOnInit() {
  }

}
