import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {ForgotIdRequest} from '../../../models/request/forgot-id-request';

@Injectable({
  providedIn: 'root'
})
export class ForgotIdService {
  private environment = environment;
  
  constructor(private http: HttpClient) {
  }
  
  public getLoginId(request: ForgotIdRequest): Observable<any> {
    return this.http.post(this.environment.baseUrl + 'members/v1/forgot-id', request)
      .pipe(map((response) => response));
  }
}
