import {Component, OnInit} from '@angular/core';
import {CONTENT} from '../../../../content-management/content';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../constants/routing-constants';

@Component({
  selector: 'app-email-notification',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.scss']
})
export class EmailNotificationComponent implements OnInit {

  public content = CONTENT;
  public route = ROUTINGCONSTANTS;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  navigateToLoginPage() {
    this.router.navigate([this.route.login]);
  }
}
