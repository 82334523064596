import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BillingPaymentService } from '../../../../shared/services/billing-payment.service';
import { PolicyDetails } from '../../../../models/policy-details';
import { PREFERENCES } from '../../../../shared/constants/preferences';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TransactionReceiptComponent } from '../transaction-receipt/transaction-receipt.component';
import moment from 'moment';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {

  public selectPetForm: UntypedFormGroup;
  public displayProgressSpinner = 0;
  public policyDetailslist: PolicyDetails[];
  public paymentTransactions: any;
  public policyNumber: string;
  public isPayHistoryReceived: boolean;
  public preferences = PREFERENCES;
  public selectedPetName: string;
  constructor(private billingPaymentService: BillingPaymentService,
    private fb: UntypedFormBuilder,private dialog: MatDialog) { }


  ngOnInit() {
    this.getInitialLoad();
  }

  getInitialLoad() {
    if (sessionStorage.getItem('policyList')) {
      this.policyDetailslist = JSON.parse(sessionStorage.getItem('policyList'));
      if (this.displayProgressSpinner > 0) {
        this.displayProgressSpinner = 0;
      }
      if (this.policyDetailslist) {
        this.policyDetailslist.filter(policyDetail => { policyDetail.petName = policyDetail.pet.name; });
        this.policyNumber = this.policyDetailslist[0].policyNumber;
        this.selectedPetName = this.policyDetailslist[0].petName;
        this.getTransactionsHistory(this.policyNumber);
        this.createForm();
      }
    }
  }

  getTransactionsHistory(policyNumber: string) {
    this.displayProgressSpinner = 0;
    this.isPayHistoryReceived = false;
    this.displayProgressSpinner++;
    this.billingPaymentService.getPaymentHistoy(policyNumber).subscribe({
      next: data => {
        if (data) {
          this.paymentTransactions = data;
          this.paymentTransactions.forEach(obj => {
            if (obj.accountNumber) {
              if (obj.accountNumber.length <= 4) {
                obj.accountNumber = 'XXXXXXXXXXXX' + obj.accountNumber;
              }
              else {
                obj.accountNumber = obj.accountNumber.replace(/\*/g, 'X');
              }
            }
            if (obj.amount) {
              if(obj.transactionType.toUpperCase() === 'PREMIUM PAYMENT') {
                obj.amount = obj.amount.toFixed(2);
                obj.refund = '';
              } else {
                obj.refund = obj.amount.toFixed(2);
                obj.amount = '';
              }
            }
            if (obj.transactionDate) {
              // let date = new Date(obj.transactionDate.replace("-", "/"));
              // obj.transactionDate = [
              //   date.getMonth() + 1,
              //   date.getDate(),
              //   date.getFullYear(),
              // ].join('/');
              obj.transactionDate = moment(obj.transactionDate).format('MM/DD/YYYY');
            }
            if (obj.paymentMethod) {
              if (obj.paymentMethod.toUpperCase() === 'ACH') {
                obj.paymentMethod = 'Bank Account';
              }
            }
          });
          this.isPayHistoryReceived = true;
        }
      },
      error: () => this.displayProgressSpinner--,
      complete: () => {
        this.displayProgressSpinner--;
      }
    });
  }

  createForm(): void {
    this.selectPetForm = this.fb.group({
      policyNumber: [this.policyNumber]
    });
    this.preferences = this.preferences.map(p => {
      p['hidden'] = true;
      return p;
    });
  }

  changePayment(e: MatSelectChange) {
    if (e.value) {
      this.policyNumber = e.value;
      const selectedPolicy = this.policyDetailslist.find(p => p.policyNumber === this.policyNumber);
      this.selectedPetName = selectedPolicy.petName;
      this.getTransactionsHistory(this.policyNumber);
    }
  }

  getReceipt(transaction: any) {
    const dialogRef = this.dialog.open(TransactionReceiptComponent, {
      width: '600px',
      data: {
        transaction,
        policyNumber: this.policyNumber,
        petName: this.selectedPetName
      }
    });
  }
}
