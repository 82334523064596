<div class="container text-center">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="navigateToLoginPage()" id='topBackButton' class=" pull-left d-inline pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{content.forgotIdPage.heading}}</h1>
  </div>
  <hr style="border: 1px solid grey">
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <p class="text-left"><b>No Problem.</b> {{content.forgotIdPage.info}} <a href="javascript:void(0);" class="custom_navigate_style" (click)="navigateToRegistration()"><b>{{content.forgotIdPage.startHere}}</b></a>
              </p>
            </div>
          </div>
          <form [formGroup]="forgotIdForm">
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>email</mat-icon>
                  <mat-label>Email</mat-label>
                  <input matInput id="email" type="text" formControlName="email" class="material-input-form" required>
                  <mat-error *ngIf="formDetails.email.errors && formDetails.email.errors.required">{{content.validations.forgotId.emailReqError}}</mat-error>
                  <mat-error *ngIf="formDetails.email.errors && formDetails.email.errors.pattern">{{content.validations.forgotId.emailPatternError}}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br>
            <mat-card-actions>
              <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10 col-sm-12">
                  <button mat-raised-button class="custom-email-mat-button float-end mobile-button" color="primary" id="forgot-id-submit-btn" [disabled]="!forgotIdForm.valid"
                          (click)="onSubmit()">{{content.buttons.submit}}</button>
                  <app-navigation-back (navigateBackEvent)="navigateToLoginPage()" id='bottomBackButton' class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
                </div>
              </div>
            </mat-card-actions>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
