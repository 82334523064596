import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export enum FeatureTogglesStatus {
  EnableNewClaimsForm = 'EnableNewClaimsForm'
}
@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  constructor() { }

  getFeatureToggles(): string[] {
    const content = JSON.parse(sessionStorage.getItem('portalContenful'));
    let featureToggles: string[] = [];
    switch (environment.env) {
      case 'stage':
        featureToggles = content?.featureToggles?.STAGING ?? [];
        break;
      case 'prod':
        featureToggles = content?.featureToggles?.PRODUCTION ?? [];
        break;
      default:
        featureToggles = content?.featureToggles?.TESTING ?? [];
        break;
    }
    return featureToggles;
  }

  isEnableNewClaimsForm():boolean {
    const featureToggles = this.getFeatureToggles();
    return featureToggles.includes(FeatureTogglesStatus.EnableNewClaimsForm);
  }
}
