import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-statesurcharge-dialog-model',
  templateUrl: './state-surcharge-dialog-model.component.html',
  styleUrls: ['./state-surcharge-dialog-model.component.css']
})
export class StateSurchargeDialogModelComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<StateSurchargeDialogModelComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }
  
  close(): void {
    this.dialogRef.close();
  }
}
