import {Injectable} from '@angular/core';

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GtmEventsService {

  constructor() {
  }

  public pushGTMTags(event: object) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
}
