<div class="col-lg-12">
  <form [formGroup]="electronicClaimsForm" *ngIf="electronicClaimsForm" (ngSubmit)="submit()">
    <div class="col-lg-10">
      <!-- email address -->
      <mat-form-field class="material-input-full-width pt-1" appearance="outline">
        <mat-icon matPrefix>email</mat-icon>
        <input matInput formControlName="emailAddress" type="email" [maxlength]="formValidation.lengthValidation.emailLength" [placeholder]="placeHolders.emailAddress" required />
        <mat-label>
          <b>{{ labels.emailAddress }}</b>
        </mat-label>
        <mat-error *ngIf="electronicClaimsForm.controls.emailAddress.errors && electronicClaimsForm.controls.emailAddress.errors.email">{{ errorMessages.emailAddress.pattern }}</mat-error>
        <mat-error *ngIf="electronicClaimsForm.controls.emailAddress.errors && electronicClaimsForm.controls.emailAddress.errors.required">{{ errorMessages.emailAddress.required }}</mat-error>
        <mat-error *ngIf="electronicClaimsForm.controls.emailAddress.errors && electronicClaimsForm.controls.emailAddress.errors.maxlength">{{ errorMessages.emailAddress.maxLength }}</mat-error>
      </mat-form-field>
      <!-- verify email address -->
      <mat-form-field class="material-input-full-width pt-1" appearance="outline">
        <mat-icon matPrefix>email</mat-icon>
        <input matInput formControlName="verifyEmailAddress" type="email" [maxlength]="formValidation.lengthValidation.emailLength" [placeholder]="placeHolders.emailAddress" required />
        <mat-label>
          <b> {{ labels.verifyEmailAddress }}</b>
        </mat-label>
        <mat-error *ngIf="electronicClaimsForm.controls.verifyEmailAddress.errors && electronicClaimsForm.controls.verifyEmailAddress.errors.email">{{ errorMessages.emailAddress.pattern }}</mat-error>
        <mat-error *ngIf="electronicClaimsForm.controls.verifyEmailAddress.errors && electronicClaimsForm.controls.verifyEmailAddress.errors.required">{{ errorMessages.emailAddress.required }}</mat-error>
        <mat-error *ngIf="electronicClaimsForm.controls.verifyEmailAddress.errors && electronicClaimsForm.controls.verifyEmailAddress.errors.maxlength">{{ errorMessages.emailAddress.maxLength }}</mat-error>
        <mat-error *ngIf="electronicClaimsForm.controls.verifyEmailAddress.errors && electronicClaimsForm.controls.verifyEmailAddress.errors.emailMismatch">{{ errorMessages.emailAddress.emailMismatch }}</mat-error>
      </mat-form-field>
    </div>
    <p class="required-text">*Required Field</p>
    <!-- ecp election checkbox -->
    <mat-checkbox formControlName="election" color="primary" (change)="preventClick($event)" (click)="openTermsOfService($event, false)">
       <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
      <p (ngModelChange)="preventClick($event)" (click)="preventClick($event)" class="no_bottom_margin electText">
        I elect to receive all claim payments and all Explanation of Benefits electronically. Click here to read the
         <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
        <span class="link" (click)="openTermsOfService($event, true)">terms of service</span>.
      </p>
    </mat-checkbox>
    <br />
    <div>
      <br />
      <button mat-raised-button type="submit" color="primary" class="custom-account-button" [disabled]="!canSubmit()">{{ content.buttons.submit }}</button>
    </div>
  </form>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>
