export const HYPERLINKSCONSTANTS = {
  sweepStakes: 'https://www.petinsurance.com/comfortzone',
  sweepStakesOfficialRulesPDF: 'https://assets.ctfassets.net/440y9b545yd9/273C1TsdN8jBvFB3D7n1BD/0f948a8dfca644d80140dd2641d79794/Comfort_Zone_Sweepstakes_Official_Rules_07-20-20.pdf',
  sweepStakesOfficialRulesReferralPDF: 'https://assets.ctfassets.net/440y9b545yd9/5ReTXnglr92WvM6Vli4SEx/0c7bf41275463cca431186d0e339e44a/Kick_Off_the_New___Year_Sweepstakes_Official_Rules.pdf',
  qecUrl: 'https://qec.petinsurance.com/?OM=PH0003',
  learnMorePetRx: 'https://www.petinsurance.com/PetRxExpress',
  petInsuranceSpecialist: {
    link: 'tel:888-341-0788',
    text: '(888) 341-0788'
  },
  contactInfo: 'https://vpiqa.petinsurance.com/contact-us.aspx',
  customerHelp: 'http://petinsurance.custhelp.com/',
  header: {
    nationwideLogo: 'https://vpiqa.petinsurance.com',
    underWriting: 'https://vpiqa.petinsurance.com/Underwriting.aspx',
    help: {
      email: 'http://petinsurance.custhelp.com/app/ask',
      faq: 'http://petinsurance.custhelp.com/app/answers/list',
      phone: 'tel:844-397-8938',
      phoneText: '844-397-8938'
    }
  },
  loginPage: {
    needHelp: 'http://petinsurance.custhelp.com/app/answers/detail/a_id/43'
  },
  electronicClaims: 'https://vpiqamy.petinsurance.com/ElectronicPaymentOptIn.aspx',
  goPaperless: 'https://ha2-stage.edelivery-view.com/Preferences/Index',
  coveragePlan: {
    majorMedicalPlan: 'https://www.petinsurance.com/images/VSSimages/media/pdf/Major_Medical_Plan_VPI_2008.pdf',
    petWellnessPlan: 'https://www.petinsurance.com/images/VSSimages/media/pdf/Pet_Wellness_Benefits_Backer.pdf',
    claimReviewForm: 'https://assets.ctfassets.net/440y9b545yd9/48ebT5H8tmIuwGAINGTbmn/4e1ad4a798f912517ecaab0180636b45/Review-Form-Interactive-2022.pdf',
    policyTransferForm: 'https://www.petinsurance.com/images/VSSimages/media/pdf/Transfer_of_Ownership7.pdf'
  },
  faqs: {
    faq1: 'http://petinsurance.custhelp.com/app/answers/detail/a_id/61/kw/How%20long%20will%20it%20take%20to%20review%20my%20claim%3F',
    faq2: 'http://petinsurance.custhelp.com/app/answers/detail/a_id/107/kw/Does%20Nationwide%20send%20notifications%20when%20they%20receive%20my%20claim%3F',
    faq3: 'http://petinsurance.custhelp.com/app/answers/detail/a_id/3/kw/What%20will%20I%20receive%20once%20my%20claim%20is%20complete%3F'
  },
  homePage: {
    officialRules: 'https://assets.ctfassets.net/440y9b545yd9/273C1TsdN8jBvFB3D7n1BD/0f948a8dfca644d80140dd2641d79794/Comfort_Zone_Sweepstakes_Official_Rules_07-20-20.pdf',
    vetHelpLine: 'https://www.petinsurance.com/vethelpline',
    walmartUrl: 'https://www.petinsurance.com/walmart-rx',
    referral: 'https://www.petinsurance.com/share/',
    newsLetter: 'https://www.petinsurance.com/newsletter/',
    articles: 'https://www.petinsurance.com/healthzone/'
  }
};
