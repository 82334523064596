import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';

import {AuthService} from '../services/auth.service';
import {ROUTINGCONSTANTS} from '../../shared/constants/routing-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  public routingConstants = ROUTINGCONSTANTS;

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const currentUser = this.authService.currentUserValue;
    if (currentUser ) {
      return true;
    } else {
      this.router.navigate([this.routingConstants.login]);
      return false;
    }
  }
}
