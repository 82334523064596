import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from './../../shared/services/common.service';
import { AccountService } from './../../shared/services/account.service';
import { Router } from '@angular/router';
import { OptInEmailRequest } from '../../models/request/opt-in-email-request';
import { BillingPaymentService } from '../../shared/services/billing-payment.service';
import { DatePipe } from '@angular/common';
import { ROUTINGCONSTANTS } from '../../shared/constants/routing-constants';

@Component({
  selector: 'app-thirdparty-partner',
  templateUrl: './thirdparty-partner.component.html',
  styleUrls: ['./thirdparty-partner.component.scss']
})
export class ThirdpartyPartnerComponent implements OnInit {

  routingConstants = ROUTINGCONSTANTS;
  conditionSelected = false;
  modalRef: BsModalRef;
  question1: boolean;
  question2: boolean;
  question3: boolean;
  question4: boolean;
  question5: boolean;
  question6: boolean;

  constructor(private modalService: BsModalService, private accountService: AccountService,
              private commonService: CommonService, private router: Router, private billingPaymentService: BillingPaymentService) { }

  ngOnInit() {
  }

  openConditionsModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg termsPopup' });
  }

  save() {
      this.accountService.updateOptIn({
        RxclaimOptin : 'Y'
        })
        .subscribe({
          next: data => {
            this.email();
            this.accountService.updateRxOptInClicked(true);
            this.router.navigate([this.routingConstants.home]);
          },
          error: () => {
            this.commonService.setDialogModal({
              title: 'Error',
              header: 'Error updating opt-in information.',
              content: ''
            });
          }
        });

  }

  convertDOB(dateofbirth) {
    const pipe = new DatePipe('en-US');
    const dob = dateofbirth ?  new Date(dateofbirth) : ''; 
    return dob ? pipe.transform(dob, 'M/d/yyyy') : '';
  }

  filterPolicies(policies) {
    const result = policies.filter(policy => (policy.Status !== 'Expired' && policy.Status !== 'Cancelled'));
    return result;
  }

  async email()  {
    try {
      const memberId = sessionStorage.getItem('activeMemberId');
      const account = await this.accountService.getAccountInfo(memberId).toPromise();
      const policyDetailsList = await this.billingPaymentService.getPolicyDetailsList().toPromise();
      const policies = policyDetailsList.policyDetailsList.map(policy => ({
          BIN: '023120',
          PCN: 'MED1',
          Group: 'NWIDE01',
          PolicyNumber: policy.policyNumber,
          Coverage: policy.coverage,
          Status: policy.policyStatus,
          ExpirationDate: policy.expirationDate.toString(),
          Pet: {
            DateOfBirth: this.convertDOB(policy.pet['dateofbirth']),
            name: policy.pet.name
          }
        }));

      if (policies !== undefined && policies.length > 0) {
        const filteredPolicies = this.filterPolicies(policies);

        if (filteredPolicies !== undefined && filteredPolicies.length > 0) {
          const request: OptInEmailRequest = {
            Insured: {
              Email: account.accountInfoResponse.email,
              InsuredFirstName: account.accountInfoResponse.firstName,
              InsuredLastName: account.accountInfoResponse.lastName,
              Relationship: '01'
            },
            Policies: filteredPolicies
          };
          await this.accountService.optInEmail(request).toPromise();
        }
      }
    } catch (_) {
  
    }
  }
}
