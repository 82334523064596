<div class="container pb-5">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="onNavigateBack()" id='topBackButton' class=" pull-left d-inline pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{content.settings.password.heading}}</h1>
  </div>
  <hr style="border: 1px solid grey">
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <p *ngIf="editError" class="red-text">{{getEditErrorText()}}</p>
        <p>{{confirmed ? content.settings.password.confirmation.body : content.settings.password.info}}</p>
        <mat-card-content class="pt-3 pb-3">
          <div *ngIf="isShowForm()">
            <form [formGroup]="editPasswordForm">
              <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10 col-sm-12">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <mat-icon matPrefix>vpn_key</mat-icon>
                    <input matInput id="currentPassword" [type]="hide ? 'password' : 'text'" formControlName="currentPassword" class="material-input-form"
                           [placeholder]="placeHolders.currentPassword" [maxLength]="lengthValidation.passwordMaxLength" required>
                    <mat-label><b>{{labels.currentPassword}}</b></mat-label>
                    <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    <mat-error *ngIf="formControl.currentPassword.errors && formControl.currentPassword.errors.required">{{errorMessages.currentPassword.required}}</mat-error>
                    <mat-error *ngIf="formControl.currentPassword.errors && formControl.currentPassword.errors.pattern">{{errorMessages.currentPassword.pattern}}</mat-error>
                    <mat-error *ngIf="formControl.currentPassword.errors && formControl.currentPassword.errors.maxlength">{{errorMessages.currentPassword.maxLength}}</mat-error>
                    <mat-error *ngIf="formControl.currentPassword.errors && formControl.currentPassword.errors.minlength">{{errorMessages.currentPassword.minLength}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <br>
              <div class="row justify-content-center">
                <div class="col-lg-5 col-md-5 col-sm-12">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <mat-icon matPrefix>vpn_key</mat-icon>
                    <input matInput id="newPassword" [type]="hide ? 'password' : 'text'" formControlName="newPassword" class="material-input-form" [placeholder]="placeHolders.newPassword"
                           [maxLength]="lengthValidation.passwordMaxLength" required>
                    <mat-label><b>{{labels.newPassword}}</b></mat-label>
                    <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    <mat-error *ngIf="formControl.newPassword.errors && formControl.newPassword.errors.required">{{errorMessages.newPassword.required}}</mat-error>
                    <mat-error *ngIf="formControl.newPassword.errors && formControl.newPassword.errors.pattern">{{errorMessages.newPassword.pattern}}</mat-error>
                    <mat-error *ngIf="formControl.newPassword.errors && formControl.newPassword.errors.maxlength">{{errorMessages.newPassword.maxLength}}</mat-error>
                    <mat-error *ngIf="formControl.newPassword.errors && formControl.newPassword.errors.minlength">{{errorMessages.newPassword.minLength}}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-12">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <mat-icon matPrefix>vpn_key</mat-icon>
                    <input matInput id="confirmNewPassword" [type]="hide ? 'password' : 'text'" formControlName="confirmNewPassword" class="material-input-form"
                           [placeholder]="placeHolders.confirmNewPassword" [maxLength]="lengthValidation.passwordMaxLength" required>
                    <mat-label><b>{{labels.confirmNewPassword}}</b></mat-label>
                    <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    <mat-error *ngIf="formControl.confirmNewPassword.errors && formControl.confirmNewPassword.errors.required">{{errorMessages.confirmNewPassword.required}}</mat-error>
                    <mat-error *ngIf="formControl.confirmNewPassword.errors && formControl.confirmNewPassword.errors.pattern">{{errorMessages.confirmNewPassword.pattern}}</mat-error>
                    <mat-error *ngIf="formControl.confirmNewPassword.errors && formControl.confirmNewPassword.errors.maxlength">{{errorMessages.confirmNewPassword.maxLength}}</mat-error>
                    <mat-error *ngIf="formControl.confirmNewPassword.errors && formControl.confirmNewPassword.errors.minlength">{{errorMessages.confirmNewPassword.minLength}}</mat-error>
                    <mat-error *ngIf="editPasswordForm.errors && editPasswordForm.errors.passwordMismatch">{{errorMessages.confirmNewPassword.passwordMismatch}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <br>
              <mat-card-actions>
                <div class="row justify-content-center">
                  <div class="col-lg-10 col-md-10 col-sm-12">
                    <button mat-raised-button class="custom-mat-button float-end" color="primary" (click)="onSubmit()" [disabled]="editPasswordForm.invalid">{{content.buttons.submit}}</button>
                    <app-navigation-back (navigateBackEvent)="onNavigateBack()" id='bottomBackButton' class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
                  </div>
                </div>
              </mat-card-actions>
            </form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
