export enum FILTERTYPES {
  POLICY_NUMBER = 'POLICY_NUMBER',
  TREATMENT_DATE = 'TREATMENT_DATE',
  CLAIM_STATUS = 'CLAIM_STATUS',
  REPORTED_DATE = 'REPORTED_DATE'
}

export enum ORDERS {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC'
}

export enum TYPES {
  HISTORY = 'history',
  RECENT = 'recent'
}

export enum STATUSES {
  RECEIVED = 'recieved',
  INPROCESS = 'in process',
  COMPLETE = 'complete'
}

export const filterOptionsRecent = [
  {value: FILTERTYPES.CLAIM_STATUS, label: 'Claim Status'},
  {value: FILTERTYPES.POLICY_NUMBER, label: 'Pet Name'},
  {value: FILTERTYPES.TREATMENT_DATE, label: 'Treatment Date'},
  {value: FILTERTYPES.REPORTED_DATE, label: 'Submitted Date'}
];
export const filterOptionsHistory = [
  {value: FILTERTYPES.POLICY_NUMBER, label: 'Pet Name'},
  {value: FILTERTYPES.TREATMENT_DATE, label: 'Treatment Date'},
];
export const allSortOptions = [
  {value: ORDERS.DESCENDING, label: 'Newest', filter: FILTERTYPES.TREATMENT_DATE},
  {value: ORDERS.ASCENDING, label: 'Oldest', filter: FILTERTYPES.TREATMENT_DATE},
  {value: ORDERS.DESCENDING, label: 'Newest', filter: FILTERTYPES.REPORTED_DATE},
  {value: ORDERS.ASCENDING, label: 'Oldest', filter: FILTERTYPES.REPORTED_DATE},
  {value: STATUSES.RECEIVED, label: 'Received', filter: FILTERTYPES.CLAIM_STATUS},
  {value: STATUSES.INPROCESS, label: 'In Process', filter: FILTERTYPES.CLAIM_STATUS},
  {value: STATUSES.COMPLETE, label: 'Completed', filter: FILTERTYPES.CLAIM_STATUS}
];
