import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-transaction-receipt',
  templateUrl: './transaction-receipt.component.html',
  styleUrls: ['./transaction-receipt.component.scss'],
  providers: [TitleCasePipe]
})
export class TransactionReceiptComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<TransactionReceiptComponent>, private titleCase: TitleCasePipe) {
    console.log(data);
  }

  ngOnInit() {
  }

  createPdf(): jsPDF {
    // preview here http://raw.githack.com/MrRio/jsPDF/master/   
    const doc = new jsPDF();
    doc.addImage('/assets/images/landing-page/nationwide-logo-white_bg.png', 'PNG', 20, 15, 50, 17);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(50);
    doc.setFontSize(10);
    doc.text('One Nationwide Plaza', 20, 40);
    doc.text('Columbus, OH 43215 USA', 20, 45);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(this.data.transaction.transactionDate, 20, 65);

    doc.setFont('helvetica', 'normal');
    doc.text('Thank you for your payment!', 20, 75);
    doc.setTextColor(150);

    doc.setLineWidth(0.1);
    doc.setDrawColor(150, 150, 150);
    doc.line(20, 85, 190, 85);

    doc.setTextColor(50);
    doc.setFont('helvetica', 'bold');

    doc.text('Pet name:', 20, 98);
    doc.text('Policy number:', 20, 106);

    doc.text('Payment account number:', 20, 121);
    doc.text('Payment type:', 20, 129);
    doc.text('Account type:', 20, 137);
    doc.text('Description:', 20, 145);
    if(this.data.transaction.transactionType.toUpperCase() === 'PREMIUM PAYMENT') {
      doc.text('Payment:', 20, 153);
    } else {
      doc.text('Reversal/Refund:', 20, 153);
    }

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text('Please contact us with inquiries at 800-540-2016 during', 55, 160);
    doc.text('normal business hours Monday through Saturday', 60, 165);

    doc.setFontSize(12);
    doc.text(this.data.petName, 41, 98);
    doc.text(this.data.policyNumber, 51, 106);

    doc.text(this.data.transaction.accountNumber, 74, 121);
    doc.text(this.titleCase.transform(this.data.transaction.paymentMethod), 50, 129);
    doc.text(this.data.transaction.paymentType, 49, 137);
    doc.text(this.titleCase.transform(this.data.transaction.transactionType), 46, 145);
    if(this.data.transaction.transactionType.toUpperCase() === 'PREMIUM PAYMENT') {
      doc.text(`$${this.data.transaction.amount}`, 40, 153);
    } else {
      doc.text(`$${this.data.transaction.refund}`, 56, 153);
    }

    return doc;
  }

  generateReceipt() {
    const doc = this.createPdf();
    doc.autoPrint();
    const blob = doc.output('bloburl');
    window.open(blob.toString());

    // this.createPdf().save('transaction_Receipt.pdf');
  }

  closeMe() {
    this.dialogRef.close();
  }
}
