import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../shared/services/contentful.service';
import CONTENTFULIDS from '../../shared/constants/contentful-ids';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.css']
})
export class LoadingPageComponent implements OnInit {

  public contentfulIds = CONTENTFULIDS;
  constructor(private contentfulService: ContentfulService,
    private httpClient: HttpClient) { }

  ngOnInit() {
    this.getPortalContenful();
    this.getPortalContenfulImage();
    this.getSpeciesIcons();
  }

  async getPortalContenful() {
    const res = await this.contentfulService.getAsset(environment.portalContentful);
    if (res) {
      this.httpClient.get(res.file.url).subscribe(data => {
        sessionStorage.setItem('portalContenful', JSON.stringify(data));
      },
        (error) => {
          console.error('Request failed with error')
          console.log(error);
        });
    }
  }

  async getPortalContenfulImage() {
    const res = await this.contentfulService.getEntry(this.contentfulIds.entries.imageIcons);
    if (res) {
      sessionStorage.setItem('portalImageContenful', JSON.stringify(res));
    }
  }

  async getSpeciesIcons() {
    const res = await this.contentfulService.getEntry(this.contentfulIds.entries.speciesIcons);
    if (res) {
      sessionStorage.setItem('portalSpeciesIcons', JSON.stringify(res));
    }
  }

}
