<div class="notifications-wrapper container">
  <div class="notifications-title">
    <app-navigation-back id="backbutton" (navigateBackEvent)="goToHome()" class="pull-left d-inline  pl-1 d-md-none">
    </app-navigation-back>
    <h3 *ngIf="!isFromPopover">My Alerts</h3>
  </div>
  <div *ngIf="!isFromPopover" class="alerts_list">
    <span *ngFor="let pet of newList; let i = index">
      <span *ngIf="!pet.statusFlag">
        <div class="alerts_item">
          <i class="material-icons">notifications</i>
          <div class="alert_content">
            <p>Your policy {{ pet.key?.split('/')[0] }} is due for renewal.</p>
            <div class="alert_actions">
              <button mat-raised-button type="button" color="primary" (click)="goToRenewal(pet.key?.split('/')[0])">Fix
                now</button>
            </div>
          </div>
        </div>
      </span>
      <span *ngIf="pet.statusFlag==='pastDue'">
        <div class="alerts_item">
          <i class="material-icons">notifications</i>
          <div class="alert_content">
            <p>Your policy {{ pet.key?.split('/')[0] }} is past due.</p>
            <div class="alert_actions">
              <button mat-raised-button type="button" color="primary"
                (click)="goToPayment(pet.key?.split('/')[0], pet.key?.split('/')[1])">Fix now</button>
            </div>
          </div>
        </div>
      </span>
      <span *ngIf="pet.statusFlag==='expired'">
        <div class="alerts_item">
          <i class="material-icons">notifications</i>
          <div class="alert_content">
            <p>Your policy {{ pet.key?.split('/')[0] }} is expired.</p>
            <div class="alert_actions">
              <button (click)="goToRenewal(pet.key?.split('/')[0])" color="primary" mat-raised-button type="button">
                Fix now
              </button>
            </div>
          </div>
        </div>
      </span>
    </span>
  </div>

  <div *ngIf="isFromPopover" class="alerts_list pet-slider">
    <mat-icon *ngIf="!hideIndicators" class="carousel_icon carousel_left" (click)="prev()">chevron_left</mat-icon>
    <mat-icon *ngIf="!hideIndicators" class="carousel_icon carousel_right" (click)="next()">chevron_right</mat-icon>
    <carousel [interval]="false" class="slide-animated" #mainCarousel id="mainCarousel">
      <span *ngFor="let pet of newList; let i = index; let even = even">
        <span *ngIf="even">
          <slide>
            <div class="slider_content">
              <div class="alerts_item" *ngIf="!pet.statusFlag">
                <i class="material-icons" *ngIf="!isFromPopover">notifications</i>
                <div class="alert_content">
                  <p>Your policy {{ pet.key?.split('/')[0] }} is due for renewal.</p>
                  <div class="alert_actions">
                    <button mat-raised-button type="button" color="primary"
                      (click)="goToRenewal(pet.key?.split('/')[0])">Fix now</button>
                  </div>
                </div>
              </div>
              <div *ngIf="pet.statusFlag==='pastDue'" class="alerts_item">
                <i class="material-icons" *ngIf="!isFromPopover">notifications</i>
                <div class="alert_content">
                  <p>Your policy {{ pet.key?.split('/')[0] }} is past due.</p>
                  <div class="alert_actions">
                    <button mat-raised-button type="button" color="primary"
                      (click)="goToPayment(pet.key?.split('/')[0], pet.key?.split('/')[1])">Fix now</button>
                  </div>
                </div>
              </div>
              <div *ngIf="pet.statusFlag==='expired'" class="alerts_item">
                <i *ngIf="!isFromPopover" class="material-icons">notifications</i>
                <div class="alert_content">
                  <p>Your policy {{ pet.key?.split('/')[0] }} is expired.</p>
                  <div class="alert_actions">
                    <button (click)="goToRenewal(pet.key?.split('/')[0])" color="primary" mat-raised-button
                      type="button">Fix now</button>
                  </div>
                </div>
              </div>
              <span *ngIf="newList[i + 1]">
                <div class="alerts_item" *ngIf="!newList[i + 1].statusFlag">
                  <i class="material-icons" *ngIf="!isFromPopover">notifications</i>
                  <div class="alert_content">
                    <p>Your policy {{ newList[i + 1].key?.split('/')[0] }} is due for renewal.</p>
                    <div class="alert_actions">
                      <button mat-raised-button type="button" color="primary"
                        (click)="goToRenewal(newList[i + 1].key?.split('/')[0])">Fix now</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="newList[i + 1].statusFlag ==='pastDue'" class="alerts_item">
                  <i class="material-icons" *ngIf="!isFromPopover">notifications</i>
                  <div class="alert_content">
                    <p>Your policy {{ newList[i + 1].key?.split('/')[0] }} is past due.</p>
                    <div class="alert_actions">
                      <button mat-raised-button type="button" color="primary"
                        (click)="goToPayment(newList[i + 1].key?.split('/')[0], pet.key?.split('/')[1])">
                        Fix now
                      </button>
                    </div>
                  </div>
                </div>
                <div *ngIf="newList[i + 1].statusFlag==='expired'" class="alerts_item">
                  <i *ngIf="!isFromPopover" class="material-icons">notifications</i>
                  <div class="alert_content">
                    <p>Your policy {{ newList[i + 1].key?.split('/')[0] }} is expired.</p>
                    <div class="alert_actions">
                      <button (click)="goToRenewal(newList[i + 1].key?.split('/')[0])" color="primary" mat-raised-button
                        type="button">
                        Fix now
                      </button>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </slide>
        </span>
      </span>
    </carousel>
  </div>

  <app-navigation-back id="backbutton" *ngIf="!isFromPopover" (navigateBackEvent)="goToHome()"
    class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
</div>
