
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare let newrelic: any;

newrelic.setCustomAttribute('userId', sessionStorage.getItem('userId'));
newrelic.setCustomAttribute('userName', sessionStorage.getItem('memberName'));
newrelic.setCustomAttribute('messageId', sessionStorage.getItem('XNWMessageId'));

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

let attempts = 1;

function changeOptOutLink() {
  setTimeout(() => {
    if (document.getElementById('truste-show-consent') != null) {
      (<HTMLAnchorElement>document.getElementById('truste-show-consent')).href = 'javascript:void(0);';
      attempts = 6;
    }
    if (attempts < 6) {
      attempts++;
      changeOptOutLink();
    }
  }, 1000);
}

changeOptOutLink();

const celebrusTagsScript = document.createElement('script');
celebrusTagsScript.src = environment.ensightenBootstrap; // Celebrus Tags
document.head.appendChild(celebrusTagsScript);

const trustarcScript = document.createElement('script');
trustarcScript.src = '//consent.trustarc.com/notice?domain=cm-nationwide.com&c=teconsent&js=nj&noticeType=bb&text=true&pcookie&gtm=1';
trustarcScript.setAttribute('crossorigin', 'anonymous');
document.head.appendChild(trustarcScript);
