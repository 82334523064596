import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorModalData } from '../../../models/error-modal-data';
import { CONTENT } from '../../../content-management/content';

@Component({
  selector: 'app-confirmation-dialog-modal',
  templateUrl: './enrollpet-dialog-modal.component.html',
  styleUrls: ['./enrollpet-dialog-modal.component.scss']
})
export class EnrollpetDialogModalComponent {
  public content = CONTENT;
  @Output() public notifyClosed = new EventEmitter<boolean>();

  constructor(public dialogRef: MatDialogRef<EnrollpetDialogModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ErrorModalData) {}

  close(): void {
    this.dialogRef.close();
    this.notifyClosed.emit(true);
  }
}
