import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { getCookieValue, UNUM_COOKIE_KEY } from '../../shared/helpers/helper-functions';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  public displayProgressSpinner = false;
  constructor(private router: Router, public oidcSecurityService: OidcSecurityService) { }

  ngOnInit() {
    const url = this.router.url;
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const hasCode = urlParams.has('code');
    const hasState = urlParams.has('state');
    const isUnumUser = getCookieValue(UNUM_COOKIE_KEY) ? JSON.parse(getCookieValue(UNUM_COOKIE_KEY)) : false;
    
    if (isUnumUser) {
      this.displayProgressSpinner = this.displayProgressSpinner ? false : true;
      this.router.navigate(['home']);
    } else {
      if((url.includes("?referrer=UNUM")) || (hasCode && hasState)){
        this.router.navigate(['loading-page'], { queryParamsHandling: "preserve" });
      } else{
        this.router.navigate(['login'], { queryParamsHandling: "preserve" });
      }
    }
  }
}
