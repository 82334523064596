import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorModalData } from '../../../models/error-modal-data';

@Component({
  selector: 'app-error-dialog-modal',
  templateUrl: './error-dialog-modal.component.html',
  styleUrls: ['./error-dialog-modal.component.scss']
})
export class ErrorDialogModalComponent {

  constructor(public dialogRef: MatDialogRef<ErrorDialogModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ErrorModalData) {}

  close(): void {
    this.dialogRef.close();
  }
}
