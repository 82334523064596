import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ChangeLoginIdRequest} from '../../models/request/change-loginId-request';
import {Observable} from 'rxjs';
import {ChangeLoginIdResponse} from '../../models/response/change-loginId-response';
import {map} from 'rxjs/operators';
import {ChangePasswordRequest} from '../../models/request/change-password-request';
import {ChangePasswordResponse} from '../../models/response/change-password-response';
import {ChangeSecurityQtnAndAnsRequest} from '../../models/request/change-security-QtnAndAns-request';
import {ChangeSecurityQtnAndAnsResponse} from '../../models/response/change-security-QtnAndAns-response';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public requests = {login: 'LoginId', password: 'Password', securityQA: 'SecurityQuestion'};
  public membersUrl = environment.baseUrl + 'members/v1/';
  
  constructor(private http: HttpClient) {
  }
  
  changeLoginID(request: ChangeLoginIdRequest): Observable<ChangeLoginIdResponse> {
    return this.http.post<ChangeLoginIdRequest>(this.membersUrl + 'change-logInId', request)
      .pipe(map((question) => question as ChangeLoginIdResponse));
  }
  
  changePassword(request: ChangePasswordRequest): Observable<ChangePasswordResponse> {
    return this.http.post<ChangePasswordRequest>(this.membersUrl + 'change-password', request)
      .pipe(map((password) => password as ChangePasswordResponse));
  }
  
  changeSecurityQA(request: ChangeSecurityQtnAndAnsRequest): Observable<ChangeSecurityQtnAndAnsResponse> {
    return this.http.post<ChangeSecurityQtnAndAnsRequest>(this.membersUrl + 'change-securityQtnAndAns', request)
      .pipe(map((response) => response as ChangeSecurityQtnAndAnsResponse));
  }
}
