<div class="row" >
  <div class="col-md-12">
    <div *ngFor="let items of detailsCoverage">
      <hr class="sectionDivider-top" />
      <div class="row text-center" *ngIf="items.riskCoverageCd" (click)="items.showMore = !items.showMore">
        <div class="text-left display-inline">
          <div class="float-start" class="padding-right-5">
            
            <img *ngIf="items.riskCoverageCd=='Accident'" class="width-25" src="{{imageConstants.svgLogos.accidentIcon}}"alt="" />
            <img *ngIf="items.riskCoverageCd!='Accident' && items.riskCoverageCd!='Wellness'" class="width-25" src="{{imageConstants.svgLogos.illnessIcon}}"alt="" />
            <img *ngIf="items.riskCoverageCd=='Wellness'" class="width-25" src="{{imageConstants.svgLogos.wellnessIcon}}"alt="" />

          </div>
            
          <span class="benefitUI-body-medium"><b class="benefitUI-body-large">{{items.riskCoverageCd | camelCaseSplit }}</b> coverage </span>

        </div>

        <div class="col-md-12" *ngIf="items.riskCoverageCd && items.riskCoverageCd!='Wellness'">

          <div class="mx-auto">
            <div class="d-flex justify-content-between recent-claims-status-code">
              
              <div>
                <p style="text-align: left;margin-bottom: auto;margin-top: revert;">
                  <b>{{items.reimbursedAmt | currency}}</b>
                </p>
                <p style="margin-bottom: auto;">Reimbursed</p>
              </div>

              <div>
                <p style="text-align: right;margin-bottom: auto;margin-top: revert;">
                  <b>{{items.riskCoverageLimit!="Unlimited" ? (items.riskCoverageLimit | currency) : items.riskCoverageLimit }}</b>
                </p>
                <p style="margin-bottom: auto;">Annual maximum</p>
              </div>
              
              
             

            </div>

            <div class="d-flex align-items-center">
              <mat-icon class="check-circle">check_circle</mat-icon>
              <mat-progress-bar class="my-color" mode="determinate" value="{{items.progressValue}}" [attr.aria-valuemax]="items.riskCoverageLimit=='Unlimited'? 25 : 100"></mat-progress-bar>
              <mat-icon class="check-circle-end" *ngIf="items.riskCoverageLimit!='Unlimited'">panorama_fish_eye</mat-icon>
              <mat-icon class="mat-arrow-align" *ngIf="items.riskCoverageLimit=='Unlimited'">forward_outline</mat-icon>
            </div>
          </div>

          <div *ngIf="items.riskCoverageCd && items.riskCoverageCd=='Illness'"  class="col-md-12 mx-auto">
            <div *ngFor="let itemDetail of items.groupBenefits">
              <hr class="sectionDivider-top"/>
              
              <div >
                <a class="text-color-black"><b>{{itemDetail.riskCoverageCd}}</b> coverage </a>
              </div>
            
              <div class="d-flex justify-content-between recent-claims-status-code">
                
                <span>
                  <p class="p_margin_align align-left">
                    <b>{{itemDetail.reimbursedAmt | currency}}</b>
                  </p>
                  <p class="align-right">Reimbursed</p>
                </span>

                <span>
                  <p class="p_margin_align align-left">
                    <b>{{itemDetail.riskCoverageLimit | currency}}</b>
                  </p>
                  <p class="align-right">Annual maximum</p>
                </span>
            </div>
            
            <div class="d-flex align-items-center">
              <mat-icon class="check-circle">check_circle</mat-icon>
              <mat-progress-bar class="my-color" mode="determinate" value="{{itemDetail.progressValue}}"></mat-progress-bar>
              <mat-icon class="check-circle" *ngIf="items.riskCoverageLimit!='Unlimited'">panorama_fish_eye</mat-icon> 
            </div>
          </div>
        </div>
      </div>
      
      <div *ngIf="items.riskCoverageCd && items.riskCoverageCd=='Wellness'">
        <div class="col-md-12 border">
          
          <table class="fixed">
            
            <tr>
              <th></th>
              <th>Benefit</th>
              <th>Reimbursed</th>
              <th class="border-right-remove">Remaining</th>
            </tr>

            <tr *ngFor="let itemDetail of items.groupBenefits">
              <td class="table-align-left">{{itemDetail.groupName}}</td>
              <td>{{itemDetail.groupInitialLimit | currency}}</td>
              <td>{{itemDetail.groupReimbursedAmt | currency}}</td>
              <td class="border-right-remove">{{itemDetail.groupRemainingBenefit | currency}}</td>
            </tr>

            <tr>
              <td class="table-align-left">Total</td>
              <td>{{totalBenefit | currency}}</td>
              <td>{{totalReimbursed | currency}}</td>
              <td class="border-right-remove">{{totalRemaining | currency}}</td>
            </tr>

          </table>
        </div>
        
        <div>
          <p class="p_margin_align_center" *ngIf="totalRemaining!=null && totalRemaining!='Unlimited'">
            <b>{{totalRemaining | currency}}</b> in benefits remaining.
          </p>
        </div>
      </div>

      <div class="col-md-12" >
        
        <p class="p_margin_align_center" *ngIf="items.remainingBenefit!=null && items.remainingBenefit!='Unlimited'">
          <b>{{items.remainingBenefit | currency}}</b> in benefits remaining.
        </p>
        
        <p class="p_margin_align_center" *ngIf="items.remainingBenefit=='Unlimited'">
          <b>{{items.remainingBenefit}}</b> benefits remaining.
        </p>
        
      </div>
    </div>
  </div>
 
</div>
