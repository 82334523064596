export class PolicyDetailsSession {
  policyNumber: string;
  renewalNumber: string;
  policyStatus?: string;
  pet: {
    name: string;
    sex: string;
    age: number;
  };
  memberId: string;
  renewalStatus: string;
  isPendingGroupRenewal: boolean;
  cancelEffectiveDate?: string;
  expirationDate?: string;
  effectiveDate?: string;
  groupRenewalExpiryDate?: string;
  groupRenewalEffectiveDate?: string;
  policyType?: string;
  planCode: string;
  accountId: string;
  planNames: any[];
  productNames: any[];
  premiumInstallmentAmount: number;
  freeLookAvailable:boolean;
  pendingClaims:boolean;
  paidClaims:boolean;
  billBreakDown: {
    categoryCd: string;
    amount: number;
  };
  legacyMemberId: string;
}
