import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../../shared/constants/routing-constants';
import {GtmEventsService} from '../../../../shared/services/gtm-events.service';
import {BillingPaymentService} from '../../../../shared/services/billing-payment.service';
import {PolicyDetails} from '../../../../models/policy-details';
import {HYPERLINKSCONSTANTS} from '../../../../shared/constants/hyperlinks-constants';
import {MyPetsService} from '../../my-pets.service';
import {EncrDecrService} from '../../../../core/services/encr-decr.service';

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancellationComponent implements OnInit {
  public policyNumber: any;
  public routingConstants = ROUTINGCONSTANTS;
  public renewalNumber: string;
  public policy: PolicyDetails;
  public isPendingRenewal = false;
  public hyperlinksConstants = HYPERLINKSCONSTANTS;
  public displayProgressSpinner = 0;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private myPetsService: MyPetsService,
              private encDcrService: EncrDecrService,
              private gtmService: GtmEventsService,
              private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.getPolicyNumberFromRoutes();
  }

  getPolicyNumberFromRoutes() {
    this.route.params.subscribe(params => {
      this.policyNumber = this.encDcrService.get(params['policyNumber']);
      this.renewalNumber = params['renewalNumber'];
      this.getPendingRenewal();
    });
  }

  getPendingRenewal() {
    this.addSpinner();
    this.myPetsService.getPendingRenewalStatus(this.policyNumber, this.renewalNumber).subscribe({
      next: response => {
        this.isPendingRenewal = response.isPendingRenewal;
      },
      complete: () => this.subtractSpinner()
    });
  }

  navigateToCancelForm() {
    this.router.navigate([this.routingConstants.cancelForm, this.encDcrService.set(this.policyNumber)]);
  }

  goToManagePolicy() {
    this.router.navigate([this.routingConstants.managePolicy, this.encDcrService.set(this.policyNumber)]);
  }

  addSpinner() {
    this.displayProgressSpinner++;
    this.cdr.markForCheck();
  }

  subtractSpinner() {
    this.displayProgressSpinner--;
    this.cdr.markForCheck();
  }
}
