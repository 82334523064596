import {environment} from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Subject} from 'rxjs';
import { NotificationServiceMessage, NOTIFICATIONTYPES } from '../../models/notification-service-message';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {

  private subject = new Subject<NotificationServiceMessage>();
  public notificationsUrl = environment.baseUrl + 'notifications/v1';

    triggerNotificationsUpdate() {
        this.subject.next( {type: NOTIFICATIONTYPES.UPDATENOTIFICATIONS} );
    }

    getNotificationsUpdate(): Observable<NotificationServiceMessage> {
       return this.subject.asObservable();
    }

    remindMeLater() {
      this.subject.next({type: NOTIFICATIONTYPES.REMINDMELATER});
    }

    // getNotifications() {
    //   return this.http.get(this.notificationsUrl);
    // }

  constructor(private http: HttpClient) { }
}
