import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorModalData } from '../../../models/error-modal-data';
import { ContentfulService } from '../../services/contentful.service';
import CONTENTFULIDS from '../../constants/contentful-ids';
import { HttpClient } from '@angular/common/http'; 
@Component({
  selector: 'app-address-validation-modal',
  templateUrl: './address.validation.modal.component.html',
  styleUrls: ['./address.validation.modal.component.scss']
})
export class AddressValidationModalComponent {
  public contentfulIds = CONTENTFULIDS; 
  // eslint-disable-next-line @typescript-eslint/ban-types
  validateMessage: String = '';

  constructor(public dialogRef: MatDialogRef<AddressValidationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ErrorModalData, private contentfulService: ContentfulService, private httpClient: HttpClient) 
  {
    this.getAddressValidationtext();

  }

  async getAddressValidationtext() {
    const content = JSON.parse(sessionStorage.getItem('portalContenful'));
    this.validateMessage = content.addressValidation.assets.message;
  }
  editClose(): void {
    this.dialogRef.close('Edit');
  }
  chooseClose(): void {
    this.dialogRef.close('Choose');
  }
  
}
