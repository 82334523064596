import {BillingPaymentService} from '../../shared/services/billing-payment.service';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DataService} from '../../core/services/data.service';
import {GtmEventsService} from '../../shared/services/gtm-events.service';
import {IMAGECONSTANTS} from '../../shared/constants/image-constants';
import {MyPets} from '../../models/my-pets';
import {MyPetsService} from './my-pets.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../shared/constants/routing-constants';
import {StandardMessage} from '../../models/standard-message';
import {CONTENT} from '../../content-management/content';
import {CommonService} from '../../shared/services/common.service';
import {HYPERLINKSCONSTANTS} from '../../shared/constants/hyperlinks-constants';
import * as _ from 'lodash';
import {CurrencyPipe} from '@angular/common';
import {EncrDecrService} from '../../core/services/encr-decr.service';
import {HomepageService} from '../homepage/homepage.service';
import {JwtService} from '../../core/services/jwt.service';
import {jsPDF} from 'jspdf';
import {PolicyDetailsSession} from '../../models/policy-details-session';
import { policySessionContants } from '../../shared/constants/policy-session-constants';
import { CoverageBenefitsResponse } from '../../models/response/coverage-benefits-response';
import {CoverageBenefits} from '../../models/coverage-benefits';
import { ContentfulService } from '../../shared/services/contentful.service';
import CONTENTFULIDS from '../../shared/constants/contentful-ids';
import {disbaleCoverageLinkConstants} from '../../shared/constants/disable-CoverageLink-constants'
import { FullstoryService } from '../../shared/services/fullstory.service';

@Component({
  selector: 'app-my-pets',
  templateUrl: './my-pets.component.html',
  styleUrls: ['./my-pets.component.scss']
})

export class MyPetsComponent implements OnInit 
{
  public routingConstants = ROUTINGCONSTANTS;
  public imageConstants = IMAGECONSTANTS;
  public hyperlinkConstants = HYPERLINKSCONSTANTS;
  public disbaleCoverageLinkConstants = disbaleCoverageLinkConstants;
  public userId: string;
  //public petSepcies: boolean;
  public petSpeciesUrl: string;
  public myPets: MyPets[] = [];
  public content = CONTENT;
  public displayProgressSpinner = 0;
  public hideIndicators = false;
  public standardMessage: StandardMessage;
  public payments: any[] = [];
  public defaultPolicyNumber = '';
  public currentSlideNumber = 0;
  public tokenPolicyList:any = [];
  public policyApiCount = 0;
  public activePolicyInfo: any;
  public policyClaims: any;
  public isClaimLoaded = false;
  public isMPIPolicy = false;
  public showMore = false;
  public disclosureTextHtml = '';
  public coverageDataArr:CoverageBenefits[]=[];
  public contentfulIds = CONTENTFULIDS;
  public iconObj: any;
  public doclink: any;
  public notCoverageCode:string[];

  constructor(private billingPaymentService: BillingPaymentService,
              private currencyPipe: CurrencyPipe,
              private dataService: DataService,
              private gtmService: GtmEventsService,
              private commonService: CommonService,
              private myPetsService: MyPetsService,
              private router: Router,
              private encDcrService: EncrDecrService,
              private homepageService: HomepageService,
              private jwtService: JwtService,
              private cdRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              private fullstoryService: FullstoryService,
              private contentfulService: ContentfulService) 
  {
  }

  ngOnInit() 
  {
    this.getPolicyNumberFromRoute();
    this.getUserId();
    this.getDisclaimerText();
    this.getSpeciesIcons();
  }

  async getDisclaimerText()
  {
    this.disclosureTextHtml = JSON.parse(sessionStorage.getItem('portalContenful'))?.benefitUI?.disclosureStatement;
  }

  getSpeciesIcons() {
    const portalSpeciesIcons = JSON.parse(sessionStorage.getItem('portalSpeciesIcons'));
    if(portalSpeciesIcons) {
      this.filterIcons(portalSpeciesIcons);
    }
  }

  filterIcons(iconList) {
    if (iconList) {
      const canineIcon = iconList?.canineIcon?.fields?.file?.url;
      const felineIcon = iconList?.felineIcon?.fields?.file?.url;
      const exoticIcon = iconList?.exoticIcon?.fields?.file?.url;
      const reptileIcon = iconList?.reptileIcon?.fields?.file?.url;
      const avianIcon = iconList?.avianIcon?.fields?.file?.url;

      this.iconObj = {
        canineIcon: canineIcon,
        felineIcon: felineIcon,
        reptileIcon: reptileIcon,
        avianIcon: avianIcon,
        exoticIcon: exoticIcon
      }
    }
    
  }

  getPolicyNumberFromRoute() 
  {
    this.route.params.subscribe(params => 
    {
      this.defaultPolicyNumber = this.encDcrService.get(params['policyNumber']);
      if(this.defaultPolicyNumber)
      {
        this.getMyPets();
      }
      else
      {
        this.getNewMyPets(0);
      }
    });
  }

  getUserId() 
  {
    this.userId = sessionStorage.getItem('userId');
  }

  getPaymentInfo(pet) 
  {
    if (pet && this.payments) 
    {
      return this.payments.reduce((acc, curr) => 
      {
        if (curr.policyNumber === pet.policyNumber) 
        {
          acc = curr;
        }
        return acc;
      }, null);
    }
    return null;
  }

  setGtmEvent(name) 
  {
    const gtmEvents = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Coverage Plan Pdf File',
      eventLabel: 'Link to Coverage Plan Pdf File Page',
      eventValue: name,
      userId: this.userId
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
  }

  goToClaimReviewForm() 
  {
    const gtmEvents = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Claim Review Form Pdf File',
      eventLabel: 'Link to Claim Review Form Pdf File Page',
      eventValue: 'Claim Review Form Pdf File',
      userId: this.userId
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
  }

  goToPolicyTransferForm() 
  {
    const gtmEvents = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Policy Transfer Form Pdf File',
      eventLabel: 'Link to Policy Transfer Form Pdf File Page',
      eventValue: 'Policy Transfer Form Pdf File',
      userId: this.userId
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
  }

  goToViewDocuments() 
  {
    this.myPetsService.getPolicyDocumentsSso().subscribe(url => 
    {
      if (url.ssoUrl) 
      {
        if (window.innerWidth <= 768) 
        {
          window.open(url.ssoUrl, '_parent');
        } 
        else 
        {
          window.open(url.ssoUrl, '_blank');
        }
      }
      const gtmEvents = 
      {
        event: 'Portal Data Layer',
        eventCategory: 'AnchorLink Click',
        eventAction: 'View Policy Documents Pdf File',
        eventLabel: 'Link to View Policy Documents Pdf File Page',
        eventValue: url.ssoUrl,
        userId: this.userId
      };
      this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
    });
  }

  isCoverageLinkEnable(planCode)
  {
    this.notCoverageCode = this.disbaleCoverageLinkConstants.disableCoverageLinkPlanCode;

    return !this.notCoverageCode.includes(planCode);
  }

  goToViewCoverage()
  {
    this.displayProgressSpinner++;
    this.myPetsService.getCoverageLink().subscribe({
      next: response => {
        
        if (response.docUrl) {
          this.doclink = response.docUrl.replace('&generic_field_4=null', '');
          if (window.innerWidth <= 768) {
            window.open(this.doclink, '_parent');
          } else {
            window.open(this.doclink, '_blank');
          }
        }
      },
      error: (err) => { console.log(err); this.displayProgressSpinner--; },
      complete: () => {
        this.displayProgressSpinner--;
      }
    });
  }

  goToManagePolicy(policyNumber) 
  {
    const gtmEvents = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Link to Manage Policy',
      eventLabel: 'Link to Manage Policy Page',
      eventValue: `Navigate to Manage Policy For ${policyNumber}`,
      userId: this.userId
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
    this.router.navigate([this.routingConstants.managePolicy, this.encDcrService.set(policyNumber)]);
  }

  goToMakeAPayment(policyNumber, renewalNumber) 
  {
    const gtmEvents = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Link to Make a Payment',
      eventLabel: 'Link to Make a Payment Page',
      eventValue: `Navigate to Make a Payment For ${policyNumber}`,
      userId: this.userId
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
    this.router.navigate([this.routingConstants.makePayment, this.encDcrService.set(policyNumber), renewalNumber]);
  }

  goToClaims(name, number) 
  {
    const gtmEvents = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Link to Claim History',
      eventLabel: 'Link to Past Claims Page',
      eventValue: `Navigate to Claim History For ${name} - ${number}`,
      userId: this.userId
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
    this.router.navigate([this.routingConstants.claims, {petName: name, policyNumber: this.encDcrService.set(number)}]);
  }

  getPayments() 
  {
    const activeAccountId = sessionStorage.getItem('activeAccountId');
    this.billingPaymentService.getAllPaymentInfo(activeAccountId).subscribe(data =>  {
      if(data) 
      {
        this.payments = data['payments'];
      }
    });
  }

  canEditPayment(pet) 
  {
    return pet && pet.policyStatus && pet.policyStatus.toUpperCase() !== 'CANCELLED' && pet.policyStatus.toUpperCase() !== 'EXPIRED';
  }

  setGTMForClaimForm(policyNumber) 
  {
    const gtmEvents = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Download Prefilled Claim Form',
      eventLabel: 'Link to Download Claim Form',
      eventValue: `Download Claim Form For ${policyNumber}`,
      userId: this.userId
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
  }

  getPreFilledForm(policyNumber, renewalNumber) 
  {
    this.setGTMForClaimForm(policyNumber);
    this.myPetsService.getPdfPreFilledForm(policyNumber, renewalNumber).subscribe(data => {
      var responseData:any = data; 
      if (responseData.pdf) 
      {
        const newBlob = new Blob([responseData.pdf]);
        const linkSource = `data:application/pdf;base64,${responseData.pdf}`;
        if (window.navigator.userAgent.includes('Firefox') === true) 
        {
          const data = linkSource;
          const link = document.createElement('a');
          link.href = data;
          link.className = 'hidden';
          link.download = 'Pet Insurance Claim Form.pdf';
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          link.onclick = () => 
          {
            requestAnimationFrame(() => 
            {
              URL.revokeObjectURL(data);
              setTimeout(() => 
              {
                link.remove();
              }, 300);
            });
          };
          link.click();
        } 
        else 
        {
          const pdfFileUrlData = linkSource;
          const link = document.createElement('a');
          link.download = 'Pet Insurance Claim Form.pdf';
          link.href = pdfFileUrlData;
          link.click();
          setTimeout(() => 
          {
            window.URL.revokeObjectURL(pdfFileUrlData);
          }, 100);
        }
      }
    });
  }

  setDeductible(deductible: string) 
  {
    const deductibleAmt = parseInt(deductible.replace(/\,/g, ''), 10);
    if(!isNaN(deductibleAmt)){
      return this.currencyPipe.transform(deductibleAmt);
    }
  }

  // setCanineImage(policyNumber, pet) 
  // {
  //   const canine =  _.startsWith(policyNumber, 'C');
  //   if(canine || pet.species.toUpperCase() === "CANINE")
  //   {
  //     this.petSepcies = true;
  //   }
  //   else
  //   {
  //     this.petSepcies = false;
  //   } 
  // }

  checkForPetType(pet) 
  {
    switch(pet?.species?.toUpperCase())
    {
      case "CANINE":
        this.petSpeciesUrl = this.iconObj?.canineIcon ? this.iconObj?.canineIcon : this.imageConstants.svgLogos.dogIcon;
        break; 
      case "FELINE":
        this.petSpeciesUrl = this.iconObj?.felineIcon ? this.iconObj?.felineIcon : this.imageConstants.svgLogos.catIcon;
        break;
      case "REPTILE":
        this.petSpeciesUrl = this.iconObj?.reptileIcon ? this.iconObj?.reptileIcon : this.imageConstants.svgLogos.reptileIcon;
        break;  
      case "AVIAN":
        this.petSpeciesUrl = this.iconObj?.avianIcon ? this.iconObj?.avianIcon : this.imageConstants.svgLogos.avianIcon;
        break; 
      case "EXOTIC":
        this.petSpeciesUrl = this.iconObj?.exoticIcon ? this.iconObj?.exoticIcon : this.imageConstants.svgLogos.exoticIcon;
        break; 
      default:
        this.petSpeciesUrl = this.iconObj?.canineIcon ? this.iconObj?.canineIcon : this.imageConstants.svgLogos.dogIcon;
        break;
    }
  }


  setDefaultPet(pets: MyPets[])
  {
    if (this.defaultPolicyNumber) 
    {
      const defaultIndex = pets.reduce((acc, curr, index) => 
      {
        if (curr.policyNumber === this.defaultPolicyNumber) 
        {
          return index;
        }
        return acc;
      }, 0);

      if (defaultIndex) 
      {
        const firstElements = pets.splice(0, defaultIndex);
        pets = pets.concat(firstElements);
      }
    }
    return pets;
  }

  onActiveSlideChanged(slide: number) 
  {
    this.isMPIPolicy = false;
    this.currentSlideNumber = slide;

    if(!this.defaultPolicyNumber && this.tokenPolicyList.length>1 && this.tokenPolicyList.length!=this.policyApiCount)
    {
      this.getNewMyPets(this.policyApiCount);
    }
    const policy = this.myPets[slide];
    sessionStorage.setItem("activePolicy", policy.policyNumber);
    sessionStorage.setItem("activeMemberId", policy.memberId);
    sessionStorage.setItem("activeAccountId", policy.accountId);
    //this.setCanineImage(policy.policyNumber,policy.pet);
    this.checkForPetType(policy.pet);
    this.activePolicyInfo = policy;
    this.isMPIPolicy = policy.planCode === "ModularPetInsurance";  
    this.getClaimSummary(policy);

    // if (this.isMPIPolicy) 
    // {
    //   this.getClaimSummary(policy);
    // }
    // else
    // {
    //   //this.isClaimLoaded = false;
    //   this.showMore = false;
    // }  
    
    if (!this.isMPIPolicy) 
    {
      this.showMore = false;
    }   
    this.getPayments();
  }

  getClaimSummary(policy) 
  {
    this.displayProgressSpinner++;
    this.isClaimLoaded = false;
    this.homepageService.getPetClaims(policy.policyNumber, Number(policy.renewalNumber)).subscribe({
      next: (recentClaim: any) => 
      {
        this.policyClaims = recentClaim;
      },
      error: (err) => 
      {
        this.displayProgressSpinner--;       
      },
      complete: () => 
      {
        this.displayProgressSpinner--;
        this.isClaimLoaded = true;
        const isGINPolicy = this.isGINPolicy(policy.policyNumber)
        if(isGINPolicy){
          this.getCoverageBenefits(policy)
        }
      }
    });
  }

  isGINPolicy(policyNumber){
    var currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt=this.jwtService.DecodeToken(currentUser);
    this.tokenPolicyList = decodeJwt["policyList"]
    const ginPolicyDataList = this.tokenPolicyList.find(p=>p.policyNumber === policyNumber);
    return (ginPolicyDataList && ginPolicyDataList.sourceSystem==="GIN");
  }

  getCoverageBenefits(policy)
  {
    this.displayProgressSpinner++;
    this.myPetsService.getCoverageBenefitsByPolicy(policy.policyNumber).subscribe({
      next: (data: any) => 
      {
        var coverageBenefitsResponse:CoverageBenefitsResponse=data;
        this.coverageDataArr=coverageBenefitsResponse.coverageBenefits;
        
        let index = this.coverageDataArr.findIndex(x => x.riskCoverageCd == "Wellness");
        if(index !== -1)
        {
          let subIndex=this.coverageDataArr[index].groupBenefits.findIndex(y => y.groupCd=="4599");
          if(subIndex !== -1)
          {
            this.coverageDataArr[index].groupBenefits.splice(subIndex,1);
          }
        }
      },
      error: (err) => 
      {
        this.displayProgressSpinner--; 
        this.isClaimLoaded = true;      
      },
      complete: () => 
      {
        this.displayProgressSpinner--;
        this.isClaimLoaded = true;
      }
    });
  }

  getNewMyPets(CurrentPolicy) 
  {
    var currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt=this.jwtService.DecodeToken(currentUser);
    this.tokenPolicyList = decodeJwt['policyList'];
    this.policyApiCount++;
    this.displayProgressSpinner++;
    // this.cdRef.detectChanges();
    this.billingPaymentService.getPolicyDetail(this.tokenPolicyList[CurrentPolicy].policyNumber).subscribe({
        next: data => 
        {
          if (data && data!==undefined)
          {
            let resultLenght = this.myPets.length;
            this.myPets.splice(resultLenght,0,data);
            if (this.myPets.length > 1) 
            {
              this.hideIndicators = true;
            }
          }
          else
          {
            if(this.tokenPolicyList.length>CurrentPolicy+1)
            {
              this.getNewMyPets(CurrentPolicy+1);
            }
          }
        },
        error: error => 
        {
          this.displayProgressSpinner--
        },
        complete: () => 
        {
          this.displayProgressSpinner--
        }
      });
  }

  public getMyPets() 
  {
    this.displayProgressSpinner++;
    var currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt=this.jwtService.DecodeToken(currentUser);
    this.tokenPolicyList = decodeJwt["policyList"]
    this.billingPaymentService.getPolicyDetailsNewList(this.tokenPolicyList).subscribe({
      next: data => 
      {
        if (data) 
        {
          const policyDataList = data.filter(x => x !== undefined);
          if (policyDataList && policyDataList.length >0)
          {
            this.myPets = policyDataList;
            this.myPets = this.setDefaultPet(this.myPets);
            if (this.myPets.length > 1) 
            {
              this.hideIndicators = true;
            }
          }
        }
      },
      complete: () => this.displayProgressSpinner--
    });
  }

}
