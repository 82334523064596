import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ClaimErrorModalData} from '../../../../models/claim-error-modal-data';
import {CONTENT} from '../../../../content-management/content';

@Component({
  selector: 'app-claim-error-modal',
  templateUrl: './claim-error-modal.component.html',
  styleUrls: ['./claim-error-modal.component.scss']
})
export class ClaimErrorModalComponent implements OnInit {
  public content = CONTENT;


  constructor(public dialogRef: MatDialogRef<ClaimErrorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ClaimErrorModalData) {
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }
}
