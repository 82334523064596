import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTINGCONSTANTS } from '../..../../../shared/constants/routing-constants';
import { MyPets } from '../../models/my-pets';
import { NotificationServiceService } from '../..../../../shared/services/notification-service.service';
import { NOTIFICATIONTYPES } from '../../models/notification-service-message';
import { EncrDecrService } from '../../core/services/encr-decr.service';
import {CarouselComponent} from 'ngx-bootstrap/carousel';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public routingConstants = ROUTINGCONSTANTS;
  public userId: string;
  public myPets: MyPets[];
  public displayProgressSpinner = false;
  public policyNumber: string;
  public hideIndicators = false;
  public pastDueList: any[] = [];
  public newList = [];
  public renewalStatusList: any[] = [];
  public expiredList: any[] = [];


  @Input() isFromPopover;
  @Output() hideCard = new EventEmitter();
  @Output() updatedCount = new EventEmitter();
  @ViewChild('mainCarousel') mainCarousel: CarouselComponent;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private encDcrService: EncrDecrService,
              private notificationService: NotificationServiceService) {
    this.getUserId();
    this.getPolicyNumberFromRoutes();

    notificationService.getNotificationsUpdate().subscribe(
      data => {
        if (data.type === NOTIFICATIONTYPES.UPDATENOTIFICATIONS) {
          setTimeout(() => {
            this.getUserId();
            this.getLists();
            this.getPolicyNumberFromRoutes();
          }, 2000);

        }

      }
    );
  }

  ngOnInit() {
    this.getUserId();
    this.getLists();

  }

  getLists() {
    this.getPastDueList();
    this.getRenewalList();
    this.getExpiredList();
    this.getNewList();
  }

  getPastDueList() {
    const pastDue = sessionStorage.getItem('pastDueList');
    if (pastDue) {
      this.pastDueList = JSON.parse(pastDue);
    } else {
      setTimeout(() => {
        this.getPastDueList();
      }, 100);
    }
  }

  getRenewalList() {
    const renewDue = sessionStorage.getItem('renewalStatusList');
    if (renewDue) {
      this.renewalStatusList = JSON.parse(renewDue);
    } else {
      setTimeout(() => {
        this.getRenewalList();
      }, 100);
    }
  }

  getExpiredList() {
    const expired = sessionStorage.getItem('expiredList');
    if (expired) {
      this.expiredList = JSON.parse(expired);
    } else {
      setTimeout(() => {
        this.getExpiredList();
      }, 100);
    }
  }

  getNewList() {
    const newList = sessionStorage.getItem('newList');
    if (newList) {
      this.newList = JSON.parse(newList);
    } else {
      setTimeout(() => {
        this.getNewList();
      }, 100);
    }
  }

  getUserId() {
    this.userId = sessionStorage.getItem('userId');
  }

  goToHome() {
    this.router.navigate([this.routingConstants.home]);
  }

  getPolicyNumberFromRoutes() {
    this.route.params.subscribe((params) => {
      this.policyNumber = this.encDcrService.get(params['id']);
    });
  }

  goToRenewal(policyNumber) {
    this.hideCard.emit(false);
    this.router.navigate([this.routingConstants.renewPolicy, this.encDcrService.set(policyNumber)]);
  }

  goToPayment(policyNumber, renewNumber) {
    this.hideCard.emit(false);
    this.router.navigate([this.routingConstants.makePayment, this.encDcrService.set(policyNumber), renewNumber]);
  }

  next() {
    this.mainCarousel.nextSlide();
  }

  prev() {
    this.mainCarousel.previousSlide();
  }

}
