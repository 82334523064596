import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/auth.service';
import { TokenInterceptorService } from './core/interceptors/token-interceptor.service';
import { ErrorInterceptorService } from './core/interceptors/error-interceptor.service';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/login/login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { ForgotIdComponent } from './shared/components/forgot-id/forgot-id.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AppMaterialModule } from './material.module';
import { HomepageFooterComponent } from './shared/components/footer/homepage-footer/homepage-footer.component';
import { NavigationBackComponent } from './shared/components/navigation-back/navigation-back.component';
import { AllClaimsComponent } from './modules/claims/all-claims/all-claims.component';
import { RecentClaimsComponent } from './modules/claims/all-claims/recent-claims/recent-claims.component';
import { ClaimsComponent } from './modules/claims/claims.component';
import { FileNewClaimComponent } from './shared/components/file-new-claim/file-new-claim.component';
import { MostRecentClaimComponent } from './modules/homepage/most-recent-claim/most-recent-claim.component';
import { PetInfoComponent } from './modules/login/pet-info/pet-info.component';
import { MyPetsComponent } from './modules/my-pets/my-pets.component';
import { CreatePortalAccountComponent } from './modules/create-portal-account/create-portal-account.component';
import { CreateLoginComponent } from './modules/create-portal-account/create-login/create-login.component';
import { RegisterAccountComponent } from './modules/create-portal-account/register-account/register-account.component';
import { ResetPasswordComponent } from './shared/components/forgot-password/reset-password/reset-password.component';
import { AccountComponent } from './modules/account/account.component';
import { PaymentComponent } from './modules/account/payment/payment.component';
import { MyAccountComponent } from './modules/account/my-account/my-account.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ManagePolicyComponent } from './modules/my-pets/manage-policy/manage-policy.component';
import { NotificationsComponent } from './modules/notifications/notifications.component';
import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { ClaimDetailsComponent } from './shared/components/claim-details/claim-details.component';
import { AppOverlayModule } from './shared/progress-spinner/overlay.module';
import { ProgressSpinnerModule } from './shared/progress-spinner/progress-spinner/progress-spinner.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SettingsComponent } from './modules/settings/settings.component';
import { EditLoginComponent } from './modules/settings/edit-login/edit-login.component';
import { EditPasswordComponent } from './modules/settings/edit-password/edit-password.component';
import { EditSecurityQuestionsComponent } from './modules/settings/edit-security-questions/edit-security-questions.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RenewalComponent } from './modules/my-pets/manage-policy/renewal/renewal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';
import { RecaptchaModule } from 'ng-recaptcha';

import { RenewalBillingComponent } from './modules/my-pets/manage-policy/renewal/renewal-billing/renewal-billing.component';
import { RenewalConfirmationComponent } from './modules/my-pets/manage-policy/renewal/renewal-confirmation/renewal-confirmation.component';
import { CancellationComponent } from './modules/my-pets/manage-policy/cancellation/cancellation.component';
import { CancellationFormComponent } from './modules/my-pets/manage-policy/cancellation/cancellation-form/cancellation-form.component';
import { CancellationResponseComponent } from './modules/my-pets/manage-policy/cancellation/cancellation-response/cancellation-response.component';
import { NewClaimButtonComponent } from './shared/components/new-claim-button/new-claim-button.component';
import { NavigationHeaderComponent } from './shared/components/navigation-header/navigation-header.component';
import { EditMyAccountComponent } from './modules/account/edit-my-account/edit-my-account.component';
import { EditPaymentComponent } from './modules/account/edit-payment/edit-payment.component';
import { ErrorDialogModalComponent } from './shared/components/error-dialog-modal/error-dialog-modal.component';
import { AchMockupModalComponent } from './shared/components/ach-mockup-modal/ach-mockup-modal.component';
import { AddressValidationModalComponent } from './shared/components/address-validation-modal/address.validation.modal.component';
import { MyPetPaymentComponent } from './modules/my-pets/my-pet-payment/my-pet-payment.component';
import { ElectronicClaimsPaymentsComponent } from './modules/electronic-claims-payments/electronic-claims-payments.component';
import { ElectronicClaimsPaymentsFormComponent } from './modules/electronic-claims-payments/electronic-claims-payments-form/electronic-claims-payments-form.component';
import { TermsOfServiceDialogComponent } from './modules/electronic-claims-payments/electronic-claims-payments-form/terms-of-service-dialog/terms-of-service-dialog.component';
import { ClaimsHistoryComponent } from './modules/claims/all-claims/claims-history/claims-history.component';
import { MppQuestionsComponent } from './modules/create-portal-account/mpp-questions/mpp-questions.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MakePaymentComponent } from './modules/my-pets/make-payment/make-payment.component';
import { LinkMissingPolicyComponent } from './modules/homepage/link-missing-policy/link-missing-policy.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ConfirmationDialogModalComponent } from './shared/components/confirmation-dialog-modal/confirmation-dialog-modal.component';
import { AchConfirmationDialogModalComponent } from './shared/components/ach-confirmation-dialog-modal/ach-confirmation-dialog-modal.component';
import { EnrollpetDialogModalComponent } from './shared/components/enrollpet-dialog-modal/enrollpet-dialog-modal.component';
import { EmailNotificationComponent } from './shared/components/forgot-password/email-notification/email-notification.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShowLast4Directive } from './shared/services/show-last4.directive';
import { DynamicTermsComponent } from './shared/components/file-new-claim/dynamic-terms/dynamic-terms.component';
import { PetProfilePictureComponent } from './shared/components/pet-profile-picture/pet-profile-picture.component';
import { TwoOptionModalComponent } from './shared/components/two-option-modal/two-option-modal.component';
import { ClaimErrorModalComponent } from './shared/components/file-new-claim/claim-error-modal/claim-error-modal.component';
import { ThirdpartyPartnerComponent } from './modules/thirdparty-partner/thirdparty-partner.component';
import { FilePreviewModalComponent } from './shared/components/file-preview-modal/file-preview-modal.component';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { InsuranceIdCardComponent } from './modules/homepage/insurance-id-card/insurance-id-card.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { JwtService } from './core/services/jwt.service';
import { ContentfulService } from './shared/services/contentful.service';
import { SessionoutDialogModelComponent } from './shared/components/sessionout-dialog-model/sessionout-dialog-model.component';
import { StateSurchargeDialogModelComponent } from './shared/components/state-surcharge-dialog-model/state-surcharge-dialog-model.component';
import { TermsDialogModelComponent } from './shared/components/terms-dialog-model/terms-dialog-model.component';
import { NewRelicErrorHandler } from './shared/helpers/new-relic-error-handler';
import { PaymentHistoryComponent } from './modules/account/payment/payment-history/payment-history.component';
import { TransactionReceiptComponent } from './modules/account/payment/transaction-receipt/transaction-receipt.component';
import { MatIconModule } from '@angular/material/icon';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import {MyPetCoverageComponent} from './modules/my-pets/my-pet-coverage/my-pet-coverage.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { BenefitsDeductibleGaugeComponent } from './shared/components/benefits-deductible-gauge/benefits-deductible-gauge.component'; 
import {CamelCaseSplitPipe} from './shared/custom-pipes/camel-case-split.pipe';
import { AuthModule,  LogLevel } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatRippleModule } from '@angular/material/core';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        CreateLoginComponent,
        RegisterAccountComponent,
        HomepageComponent,
        ForgotIdComponent,
        ConfirmationDialogModalComponent,
        AchConfirmationDialogModalComponent,
        EnrollpetDialogModalComponent,
        ForgotPasswordComponent,
        FooterComponent,
        HomepageFooterComponent,
        AllClaimsComponent,
        RecentClaimsComponent,
        NavigationBackComponent,
        ClaimsComponent,
        FileNewClaimComponent,
        AccountComponent,
        PaymentComponent,
        MyAccountComponent,
        MostRecentClaimComponent,
        PetInfoComponent,
        MyPetsComponent,
        CreatePortalAccountComponent,
        ManagePolicyComponent,
        CreatePortalAccountComponent,
        ResetPasswordComponent,
        NotificationsComponent,
        SettingsComponent,
        EditLoginComponent,
        EditPasswordComponent,
        EditSecurityQuestionsComponent,
        NotificationsComponent,
        RenewalBillingComponent,
        RenewalComponent,
        RenewalConfirmationComponent,
        CancellationComponent,
        CancellationFormComponent,
        CancellationResponseComponent,
        ClaimDetailsComponent,
        NotificationsComponent,
        EditPaymentComponent,
        EditMyAccountComponent,
        NewClaimButtonComponent,
        NavigationHeaderComponent,
        ErrorDialogModalComponent,
        MyPetPaymentComponent,
        AchMockupModalComponent,
        AddressValidationModalComponent,
        ClaimsHistoryComponent,
        ElectronicClaimsPaymentsComponent,
        ElectronicClaimsPaymentsFormComponent,
        TermsOfServiceDialogComponent,
        MppQuestionsComponent,
        MakePaymentComponent,
        LinkMissingPolicyComponent,
        EmailNotificationComponent,
        ResetPasswordComponent,
        ShowLast4Directive,
        DynamicTermsComponent,
        PetProfilePictureComponent,
        TwoOptionModalComponent,
        ClaimErrorModalComponent,
        ThirdpartyPartnerComponent,
        InsuranceIdCardComponent,
        SessionoutDialogModelComponent,
        StateSurchargeDialogModelComponent,
        PaymentHistoryComponent,
        TransactionReceiptComponent,
        TermsDialogModelComponent,
        LandingPageComponent,
        BenefitsDeductibleGaugeComponent,
        CamelCaseSplitPipe,
        MyPetCoverageComponent,
        FilePreviewModalComponent
    ],
    imports: [
        RecaptchaModule,
        MatRippleModule,
        MatTooltipModule,
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        AppMaterialModule,
        AppOverlayModule,
        ProgressSpinnerModule,
        CarouselModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        ModalModule.forRoot(),
        NgbModule,
        NgxPaginationModule,
        AngularSvgIconModule,
        GoogleMapsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        CollapseModule.forRoot(),
        FormsModule,
        RoundProgressModule,
        MatIconModule,
        AuthModule.forRoot({
            config: {
                authority:  environment.euaV3Configuration.authority,
                redirectUrl: environment.euaV3Configuration.redirectUrl,
                postLogoutRedirectUri: environment.euaV3Configuration.postLogoutRedirectUri,
                clientId: environment.euaV3Configuration.clientId,
                scope: environment.euaV3Configuration.scope,
                responseType: environment.euaV3Configuration.responseType,
                silentRenew: environment.euaV3Configuration.silentRenew,
                useRefreshToken: environment.euaV3Configuration.useRefreshToken,
                logLevel: LogLevel.Debug
            }
        })
    ],
    providers: [
        AuthGuard,
        AuthService,
        JwtService,
        DatePipe,
        TitleCasePipe,
        CurrencyPipe,
        ContentfulService,
        provideNgxMask(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: NewRelicErrorHandler
        },
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS,
            useValue: {disabled: true }
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

    constructor() {
    }
}
