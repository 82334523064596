import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EncrDecrService } from '../../../core/services/encr-decr.service';
import { ROUTINGCONSTANTS } from '../../constants/routing-constants';
import { GtmEventsService } from '../../services/gtm-events.service';
import { CONTENT } from '../../../content-management/content';
import moment from 'moment';
import { MyPetsService } from '../../../modules/my-pets/my-pets.service';
import { FullstoryService } from '../../services/fullstory.service';
import { ClaimsService } from '../../../modules/claims/all-claims/claims.service';

@Component({
  selector: 'app-benefits-deductible-gauge',
  templateUrl: './benefits-deductible-gauge.component.html',
  styleUrls: ['./benefits-deductible-gauge.component.scss']
})

export class BenefitsDeductibleGaugeComponent implements OnInit, OnChanges 
{
  public routingConstants = ROUTINGCONSTANTS;
  public annualDeductible = 0;
  public deductibleApplied = 0;
  public remainingDeductible = 0;
  @Input() public slideIndex: any;
  @Input() public policy: any;
  @Input() public isMyPetsPage: any;
  @Input() public policyClaims: any;
  @Input() public coverageData: any;

  public benefits: any = [];
  public benefitsForm: FormGroup;
  public content = CONTENT;
  public termPeriod: any;
  public totalReimbursed = 0;
  public selectedTerm: any;
  public isMPIPolicy = false;

  constructor(private router: Router,
    private encDcrService: EncrDecrService,
    private gtmService: GtmEventsService,
    private fullstoryService: FullstoryService,
    private formBuilder: FormBuilder,
    private myPetsService: MyPetsService,
    private claimService: ClaimsService
  ) { }

  ngOnInit() 
  {
  }

  ngOnChanges() 
  {
    this.setDeductibleGauge();
  }

  setDeductibleGauge() 
  {
    // if (this.policy && this.policy.planCode === "ModularPetInsurance")
    // {
    //   this.annualDeductible = this.policy.deductible;
    //   this.getRemainingDeductible(this.policy.renewalNumber);

    //   if (this.isMyPetsPage) 
    //   {
    //     this.setBenefitsUsedInfo();
    //   }
    // }
    if (this.policy)
    {
      this.annualDeductible = this.policy.deductible;
      this.claimService.getRemainingDeductible(this.policy.renewalNumber, this.annualDeductible);
      this.deductibleApplied = this.claimService.deductibleApplied;
      this.remainingDeductible = this.claimService.remainingDeductible;
      this.isMPIPolicy = this.policy.planCode === "ModularPetInsurance";
      
      if(this.isMyPetsPage)
      {
        this.setBenefitsUsedInfo();
      }                                               
    }
  }

  setBenefitsUsedInfo() 
  {
    if (this.policy) 
    {
      var effectiveDate = (new Date(this.policy.effectiveDate)).toLocaleDateString();
      var expDate = (new Date(this.policy.expirationDate)).toLocaleDateString();
      this.termPeriod = effectiveDate + ' to ' + expDate;
      if(this.coverageData && this.coverageData.length!=0)
      {
        this.totalReimbursed = this.coverageData.reduce((acc, curr) => +acc + +curr.reimbursedAmt, 0);
      }
    }
  }

  goToPets() 
  {
    const gtmEvent = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Clicking coverage details link to MyPets Page',
      eventLabel: 'Homepage Links',
      eventValue: 'Navigated To MyPets Page',
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(gtmEvent);
    this.router.navigate([this.routingConstants.pets, this.encDcrService.set(this.policy.policyNumber)]);
  }
}