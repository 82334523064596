import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../src/environments/environment';
import { ErrorModalData } from '../../../models/error-modal-data';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { getCookieValue, UNUM_COOKIE_KEY } from '../../helpers/helper-functions';


@Component({
  selector: 'app-sessionout-dialog-model',
  templateUrl: './sessionout-dialog-model.component.html',
  styleUrls: ['./sessionout-dialog-model.component.css']
})
export class SessionoutDialogModelComponent {
  public redirectUrl = environment.nationwiideRedirectUrl;
  public logoutUrl = environment.nationwideLogOut;
  constructor(public dialogRef: MatDialogRef<SessionoutDialogModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorModalData, public oidcSecurityService: OidcSecurityService) { }

  login() {
    // TODO: handle log in when this modal pops up
    const isUnumUser = getCookieValue(UNUM_COOKIE_KEY) ?  JSON.parse(getCookieValue(UNUM_COOKIE_KEY)) : false;
    if (isUnumUser) {
      this.oidcSecurityService.authorize();
    } else {
      window.open(this.redirectUrl, '_parent');
    }
  }

  logout() {
    const isUnumUser = getCookieValue(UNUM_COOKIE_KEY) ?  JSON.parse(getCookieValue(UNUM_COOKIE_KEY)) : false;
    this.clearSessionObjects();
    if (isUnumUser) {
      this.oidcSecurityService.logoff();
    } else {
      window.open(this.logoutUrl, '_parent');
    }
  }

  clearSessionObjects() {
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('dataService');
    sessionStorage.removeItem('pastDueList');
    sessionStorage.removeItem('renewalStatusList');
    sessionStorage.removeItem('newList');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('isPortalUser');
    sessionStorage.removeItem('policyList');
    sessionStorage.removeItem('rencurrentUser');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('XNWMessageId');
    sessionStorage.removeItem('refreshurl');
    sessionStorage.removeItem('auth service current user');
    sessionStorage.removeItem('memberName');
    sessionStorage.removeItem('activePolicy');
    sessionStorage.removeItem('activeMemberId');
    sessionStorage.removeItem('activeAccountId');
    sessionStorage.removeItem('expiredList');
  }
}
