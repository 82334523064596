import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CONTENT} from '../../../../content-management/content';
import {CommonService} from '../../../services/common.service';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../constants/routing-constants';
import {IMAGECONSTANTS} from '../../../constants/image-constants';
import {HomepageService} from '../../../../modules/homepage/homepage.service';
import {NewPetDetails} from '../../../../models/new-pet-details';
import {AccountService} from '../../../services/account.service';
import {environment} from '../../../../../environments/environment';
import {HYPERLINKSCONSTANTS} from '../../../constants/hyperlinks-constants';
import {GtmEventsService} from '../../../services/gtm-events.service';
import { ContentfulService } from '../../../services/contentful.service';
import CONTENTFULIDS from '../../../constants/contentful-ids';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { JwtService } from '../../../../core/services/jwt.service';
import { FullstoryService } from '../../../../shared/services/fullstory.service';

@Component({
  selector: 'app-homepage-footer',
  templateUrl: './homepage-footer.component.html',
  styleUrls: ['./homepage-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomepageFooterComponent implements OnInit {
  public content = CONTENT;
  public routingConstants = ROUTINGCONSTANTS;
  public imageConstants = IMAGECONSTANTS;
  public hyperLinkConstants = HYPERLINKSCONSTANTS;
  public newPetDetails: NewPetDetails;
  public userId: string;
  private addNewPetURL = environment.qecBaseUrl;
  private referralUrl = environment.referralUrl;
  @Input() rxclaimOptin?: any;
  public contentfulIds = CONTENTFULIDS;

  // public submitNewClaimURL = environment.vpiBaseUrl + 'submit-claim';
  public isECP = false;
  public officialRulesUrl: any;
  public officialRulesTitle: any;
  public bannerTextHtml = '';
  public policyList: any;
  public imageList: any[] = [];
  public iconObj: any;
  public dynamicContent: any;
  public shouldHideAddPetLink: boolean = false;
  baseDomain = window.location.origin;
  public helpInfoDetails: any;
  constructor(private router: Router,
              private homepageService: HomepageService,
              private gtmService: GtmEventsService,
              private accountService: AccountService,
              private commonService: CommonService,
              private contentfulService: ContentfulService,
              private fullstoryService: FullstoryService,
              private jwtService: JwtService
              ) {
    this.getUserId();
    this.getEnrollNewPetDetails();
    // this.getOfficialRulesUrl();
    this.getBannerText();
    this.getImageIcons();    
  }

  ngOnInit() {
    this.getAccount();
    this.dynamicContent = JSON.parse(sessionStorage.getItem('portalContenful'));
    this.helpInfoDetails = this.dynamicContent?.homePage?.homePageFooter?.helpInfoDetails?.infoContentList;
  }

  async getImageIcons() {
    const res = await this.contentfulService.getEntry(this.contentfulIds.entries.imageIcons);
    const petRxRes = await this.contentfulService.getAsset(this.contentfulIds.assets.petRxExpressStackedLogo);
    if(res && petRxRes) {
      this.filterIcons(res.itemList, petRxRes);
    }
  }

  filterIcons(iconList, petRxRes) {
    if(iconList) {
      const pawIcon = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.bluePawIcon).fields.icon.fields.file.url;
      const helpLine = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.customerServiceIcon).fields.icon.fields.file.url;
      const prescription = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.bluePrescriptionIcon).fields.icon.fields.file.url;
      const money = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.blueMoneyIcon).fields.icon.fields.file.url;
      const refer = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.peopleGroupIcon).fields.icon.fields.file.url;
      const lightBulb = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.blueLightBulbIcon).fields.icon.fields.file.url;
      const heart = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.blueHeartIcon).fields.icon.fields.file.url;
      const leaf = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.blueLeafIcon).fields.icon.fields.file.url;
      const petRxExpressLogo = petRxRes.file.url;
      const vethelpLineLogo = iconList.find(icon => icon.fields.contentName === this.contentfulIds.contentName.vethelplineLogo).fields.icon.fields.file.url;
            
      this.iconObj = {
        pawIcon,
        helpLine,
        prescription,
        money,
        refer,
        lightBulb,
        heart,
        leaf,
        petRxExpressLogo,
        vethelpLineLogo
      };
    }
    
  }

  async getBannerText() {
    const res = await this.contentfulService.getTextBlock(this.contentfulIds.entries.bannerText);
    this.bannerTextHtml = res.fields.content;
  }

  getAccount() {
    const accountId = sessionStorage.getItem('activeAccountId');
    if (accountId == null) {
      setTimeout(() => {
        this.getAccount();
      }, 250);
    } else {
      this.accountService.getECP(accountId).subscribe(data => {
        this.isECP = data.status;
      });
    }
  }

  getUserId() {
    if (!this.userId) {
      this.userId = sessionStorage.getItem('userId');
    }
    return this.userId;
  }

  isExternalLink(url: string): boolean {
    return !url.startsWith('/') && !url.startsWith(this.baseDomain);
  }

  navigateToHelpInfoLink(link: string, desc:string) {
    if(this.isExternalLink(link)){
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      this.router.navigate([link]);
      if(desc !== ''){
        this.setInternalNavGTM(desc);
      }
    }
  }

  navigateToFileNewClaim() {
    this.router.navigate([this.routingConstants.newClaimsSubmission]);
    this.setInternalNavGTM('File a New Claim');
  }

  navigateToWalmart() {
    this.router.navigate([this.routingConstants.walmartUrl]);
  }

  setInternalNavGTM(page) {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Homepage Helpful Links',
      eventLabel: 'Homepage Links',
      eventValue: page,
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
  }

  navigateToVetHelpLine() {
    this.setInternalNavGTM('Link to VetHelpLine');
  }

  navigateToFastCash() {
    this.setInternalNavGTM('Link to Fast Cash');
  }

  navigateToReferrals() {
    this.setInternalNavGTM('Link to Refer a Friend');
  }

  navigateToNewsLetter() {
    this.setInternalNavGTM('Link to News Letters');
  }

  navigateToArticles() {
    this.setInternalNavGTM('Link to Articles');
  }

  navigateToClaimStatus() {
    this.setInternalNavGTM('Status of a Claim');
    this.router.navigate([this.routingConstants.claims]);
  }

  navigateToCoverageDetails() {
    this.setInternalNavGTM('View Coverage Details');
    this.router.navigate([this.routingConstants.pets]);
  }

  navigateToBillingInfo() {
    this.setInternalNavGTM('Update Billing or Contact Information');
    this.router.navigate([this.routingConstants.account]);
  }

  getEnrollNewPetDetails() {
    const memberId = sessionStorage.getItem('activeMemberId');
    if (memberId == null) {
      setTimeout(() => {
        this.getEnrollNewPetDetails();
      }, 250);
    } else {
      this.homepageService.getNewPetDetails(memberId).subscribe((data) => {
        this.newPetDetails = data;
        const zip = String(data.zipCode);
        const code = zip && zip.split('-')[0];
        this.newPetDetails.zipCode = Number(code);
        this.shouldHideAddPetLink = this.dynamicContent?.homePage?.homePageFooter?.addPetForbiddenStates?.includes(this.newPetDetails?.state);
      }, (err) => {
        if (err['code'] !== 401) {
          this.updateContactErrorModal();
        }
      });
    }
  }

  updateContactErrorModal() {
    this.commonService.setDialogModal({
      title: 'Error',
      header: 'Error updating information.',
      content: ''
    });
  }

  async getOfficialRulesUrl() {
    const res = await this.contentfulService.getAsset(this.contentfulIds.assets.officialRulesUrl);
    if (res) {
      this.officialRulesUrl = res.file.url;
      this.officialRulesTitle = res.title;
    }
  }

  navigateToOfficialRules() {
    if (window.innerWidth <= 768) {
      window.open( this.officialRulesUrl, '_parent');
    } else {
      window.open( this.officialRulesUrl, '_blank');
    }
    this.setInternalNavGTM('Link to Official Rules');
  }

  navigateToQECSweepStakes() {
    if (window.innerWidth <= 768) {
      window.open(this.hyperLinkConstants.qecUrl, '_parent');
    } else {
      window.open(this.hyperLinkConstants.qecUrl, '_blank');
    }
    this.setInternalNavGTM('Link to Official Rules');
  }

  navigateToReferral() {
    this.setInternalNavGTM('Link to Navigate to Referral Page');
    const referralUrl = this.referralUrl;
    if (window.innerWidth <= 768) {
      window.open(referralUrl, '_parent');
    } else {
      window.open(referralUrl, '_blank');
    }
  }
  zeroPad = (num, places) => String(num).padStart(places, '0');
  navigateToQEC() {
    const currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt = this.jwtService.DecodeToken(currentUser);
    this.policyList = decodeJwt['policyList'];
    const renPolicyDataList = this.policyList.filter(policy => policy.sourceSystem === 'REN');
    if(currentUser && this.policyList.length === renPolicyDataList.length){
    this.setInternalNavGTM('Link to Enroll a New Pet');
    this.getEnrollNewPetDetails();
    const qecUrl = this.addNewPetURL +
      '?FirstName=' +
      this.newPetDetails.firstName +
      '&LastName=' +
      this.newPetDetails.lastName +
      '&Street=' +
      this.newPetDetails.streetAddress1 +
      this.newPetDetails.streetAddress2 +
      '&City=' +
      this.newPetDetails.city +
      '&State=' +
      this.newPetDetails.state +
      '&Zipcode=' +
      this.zeroPad(this.newPetDetails.zipCode,5) +
      '&Phone=' +
      (this.newPetDetails.homePhone || this.newPetDetails.mobilePhone || this.newPetDetails.workPhone) +
      '&Email=' +
      this.newPetDetails.email +
      '&OM=' +
      this.newPetDetails.om +
      '&IC=' +
      this.newPetDetails.ic +
      '&ACTION=' +
      this.newPetDetails.action;
    if (window.innerWidth <= 768) {
      window.open(qecUrl, '_parent');
    } else {
      window.open(qecUrl, '_blank');
    }
  }else{
    this.commonService.setNewPetValidationModal('To enroll a new pet, please call 866-880-4717.');
  }
  
  }
}
