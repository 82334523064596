import { Component, OnInit } from '@angular/core';
import { CONTENT } from '../../content-management/content';
import { HYPERLINKSCONSTANTS } from '../../shared/constants/hyperlinks-constants';
import { Router } from '@angular/router';
import { ROUTINGCONSTANTS } from '../../shared/constants/routing-constants';
import { Location } from '@angular/common';
import CONTENTFULIDS from '../../shared/constants/contentful-ids';

@Component({
  selector: 'app-electronic-claims-payments',
  templateUrl: './electronic-claims-payments.component.html',
  styleUrls: ['./electronic-claims-payments.component.scss']
})
export class ElectronicClaimsPaymentsComponent implements OnInit {
  public content = JSON.parse(sessionStorage.getItem('portalContenful'));
  public ecpContent = this.content?this.content.electronicClaimPage.assets:'';
  public links = HYPERLINKSCONSTANTS;
  public routes = ROUTINGCONSTANTS;
  public confirmed = false;
  public contentfulIds = CONTENTFULIDS;
  public blueArrowIcon;
  public blueCheckmark;
  public blueDocumentIcon;
  public bluePhoneIcon;
  public blueMoneyIcon;

  constructor(private router: Router, private location: Location) {}

  ngOnInit() {
    this.getContentfulImage();
  }

  goBack() {
    this.location.back();
  }

  async getContentfulImage() {
    const res = JSON.parse(sessionStorage.getItem('portalImageContenful'));
    if (res) {
      this.blueMoneyIcon = res.itemList[4].fields.icon.fields.file.url;
      this.blueArrowIcon = res.itemList[10].fields.icon.fields.file.url;
      this.blueCheckmark = res.itemList[11].fields.icon.fields.file.url;
      this.blueDocumentIcon = res.itemList[12].fields.icon.fields.file.url;
      this.bluePhoneIcon = res.itemList[13].fields.icon.fields.file.url;
    }

  }
}
