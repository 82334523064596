<div class="container">
  <div class="container-title">
    <app-navigation-back id="allClaims-backbutton" (navigateBackEvent)="goToHome()" class="pull-left d-inline  pl-1 d-md-none arrow_mobile"></app-navigation-back>
  </div>
  <div class="custom_tabs">
    <h3 class="claims-header">{{ content.claimsPage.allClaims.heading }}</h3>
    <div class="claims_filter" *ngIf="showFilter">
      <mat-form-field>
        <mat-label>Select Filter</mat-label>
        <mat-select (selectionChange)="filterChange($event.value)" disableOptionCentering [(value)]="selectedFilter">
          <mat-option *ngFor="let filter of filterOptions" [value]="filter.value">
            {{ filter.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="selectedFilter">
        <mat-label>{{ isSort() ? 'Sort ' : 'Filter ' }}By</mat-label>
        <mat-select (selectionChange)="sortChange($event.value)" disableOptionCentering [(value)]="selectedSorting">
          <mat-option *ngFor="let sort of sortOptions" [value]="sort.value">
            {{ sort.label | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-tab-group (selectedTabChange)="tabChange($event)" [(selectedIndex)]="selectedTabIndex"><!-- class="position-sticky">-->
      <mat-tab label="Recent">
        <section class="recent-claims--list">
          <app-recent-claims *ngIf="!displayProgressSpinner" [claims]="recentClaims"></app-recent-claims>
        </section>
      </mat-tab>
      <mat-tab label="History">
        <section class="claims--list">
          <app-claims-history *ngIf="!displayProgressSpinner" [claims]="claimsHistory"></app-claims-history>
        </section>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="row justify-content-center pt-3 pb-5">
    <div class="button-wrapper">
      <app-new-claim-button></app-new-claim-button>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>
