<div class="container pb-5">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="renewal-title">
        <app-navigation-back id="myPet-backbutton" (navigateBackEvent)="goToManagePolicy(renewPolicy.policyNumber)" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
        <h5 class="fw-bold">Renewal pet</h5>
      </div>
      <hr style="border: 1px solid grey"/>
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-12">
          <mat-card appearance="outlined">
            <strong>Is the below information correct?</strong>
            <mat-card-content>
              <br/>
              <p>Pet Name: <span class="float-end"><b>{{ renewPolicy?.pet?.name | titlecase }}</b></span></p>
              <p>Policy Number: <span class="float-end"><b>{{ renewPolicy?.policyNumber }}</b></span></p>
              <p>Effective Date: <span class="float-end"><b>{{ addYear(renewPolicy?.effectiveDate) }}</b></span></p>
              <p>Expiration Date: <span class="float-end"> <b>{{ addYear(renewPolicy?.expirationDate) }}</b></span></p>
              <p>Coverage: <span class="float-end"><b>{{ renewPolicy?.coverage | titlecase }}</b></span></p>
              <p>Total Annual Payment: <span class="float-end"><b>{{ paymentAmounts?.annualPayment | currency }}</b></span></p>
            </mat-card-content>
          </mat-card>
          <br/>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <mat-card appearance="outlined" style="height: 215px">
            <strong>Wrong Policy?</strong>
            <mat-card-content>
              <br/>
              <p>Return to <a href="javascript:void(0);" style="color: #1C57A5; cursor: pointer" (click)="routeToEditPayment()"><u>Account Overview</u></a> to select the policy you want to renew.</p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <strong>Want to change Your Pet's Coverage?</strong>
        <mat-card-content>
          <br/>
          <p>Please call toll-free <b>888-341-0788</b>. Our licensed pet insurance specialists are available Monday - Friday 5am-7pm and Saturday 7am-3:30pm (Pacific)</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 justify-content-start">
      <app-navigation-back id="backbutton" (navigateBackEvent)="goToManagePolicy(renewPolicy.policyNumber)" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-end">
      <button mat-raised-button type="button" color="primary" class="custom-renew-button" (click)="navigateToBilling(renewPolicy.policyNumber)">Next</button>
    </div>
  </div>
</div>
<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"> </app-progress-spinner>
