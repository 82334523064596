import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidationConstants} from '../../../../../shared/constants/form-validation-constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BillingPaymentService} from '../../../../../shared/services/billing-payment.service';
import {PREFERENCES} from '../../../../../shared/constants/preferences';
import {CONTENT} from '../../../../../content-management/content';
import {PaymentData, PersonalData, PolicyRenewalData} from '../../../../../models/policy-renewal-payments';
import {CARDPATTERNS} from '../../../../../shared/constants/card-patterns';
import {DataService} from '../../../../../core/services/data.service';
import {ROUTINGCONSTANTS} from '../../../../../shared/constants/routing-constants';
import {AchMockupModalComponent} from '../../../../../shared/components/ach-mockup-modal/ach-mockup-modal.component';

import {PaymentInfo} from '../../../../../models/payment-info';
import {PolicyDetails} from '../../../../../models/policy-details';
import {provideNgxMask} from 'ngx-mask';
import {AccountService} from '../../../../../shared/services/account.service';
import {AccountInfo} from '../../../../../models/account-info';
import {CommonService} from '../../../../../shared/services/common.service';
import * as _ from 'lodash';
import { EncrDecrService } from '../../../../../core/services/encr-decr.service';
import { JwtService } from '../../../../../core/services/jwt.service';

const cardTypeValidator = (control: AbstractControl) => {
  const visaPattern = new RegExp(CARDPATTERNS.visa);
  const amexPattern = new RegExp(CARDPATTERNS.americanExpress);
  const discoverPattern = new RegExp(CARDPATTERNS.discover);
  const masterCardPattern = new RegExp(CARDPATTERNS.masterCard);
  if (!control.value || control.value.match(visaPattern) || control.value.match(amexPattern) || control.value.match(discoverPattern) || control.value.match(masterCardPattern)) {
    return null;
  } else {
    return { invalidCardType: true };
  }
};

const setError = (formGroup: UntypedFormGroup, field: string, error: string) => {
  const newErrors = formGroup.get(field).errors || {};
  newErrors[error] = true;
  formGroup.get(field).setErrors(newErrors);
};

const removeError = (formGroup: UntypedFormGroup, field: string, error: string) => {
  const errors = formGroup.get(field).errors;
  if (errors) {
    delete errors[error];
    const newErrors = _.isEmpty(errors) ? null : errors;
    formGroup.get(field).setErrors(newErrors);
  }
};

const isExpired = (month: string, year: string): boolean => {
  const today = new Date();
  const dateString = month + '/01/' + year;
  const expDate = new Date(dateString);
  return expDate < today;
};

const creditValidation = (fg: UntypedFormGroup, errors: any) => {
  if (!fg.get('cardNumber').value) {
    setError(fg, 'cardNumber', 'required');
    errors['cardNumberRequired'] = true;
  }
  const expMonth = fg.get('expMonth').value;
  const expYear = fg.get('expYear').value;
  if (!expMonth) {
    setError(fg, 'expMonth', 'required');
    errors['expMonthRequired'] = true;
  }
  if (!expYear) {
    setError(fg, 'expYear', 'required');
    errors['expYearRequired'] = true;
  }
  if (expMonth && expYear && isExpired(fg.get('expMonth').value, fg.get('expYear').value)) {
    setError(fg, 'expMonth', 'expired');
    setError(fg, 'expYear', 'expired');
    errors['cardExpired'] = true;
  } else {
    removeError(fg, 'expMonth', 'expired');
    removeError(fg, 'expYear', 'expired');
  }
  return errors;
};

@Component({
  selector: 'app-renewal-billing',
  templateUrl: './renewal-billing.component.html',
  styleUrls: ['./renewal-billing.component.scss'],
  providers: [provideNgxMask()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenewalBillingComponent implements OnInit {
  public cardPatterns = CARDPATTERNS;
  public hideCC = true;
  public hideABA = true;
  public content = CONTENT;
  public labels = CONTENT.accountInfo.payment.labels;
  public errorMessages = CONTENT.accountInfo.payment.errorMessages;
  public errorMessages1 = CONTENT.accountInfo.myAccount.errorMessages;
  public paymentSelected: any;
  public billingAddressForm: UntypedFormGroup;
  public creditCardForm: UntypedFormGroup;
  public achForm: UntypedFormGroup;
  public formValidation = FormValidationConstants;
  public preferences = PREFERENCES;
  public editPaymentInfoForm: UntypedFormGroup;
  public expirationYears: number[];
  public months = [];
  public submitted = false;
  public paymentInfo: PaymentInfo;
  public policyNumber: any;
  public paymentData: PaymentData = {};
  public personalData: PersonalData = {};
  public paymentAmounts: any;
  public policyRenewalData: PolicyRenewalData = {};
  public routingConstants = ROUTINGCONSTANTS;
  public renewal: number;
  public policy: PolicyDetails;
  public displayProgressSpinner = 0;
  public accountInfo: AccountInfo;
  public ABANumberError = false;
  public termIdentifier: string;
  public opened = false;
  public addressRequest = { address1: '', address2: '', city: '', state: '', zipCode: '' };
  public addressResponse: any = { address1: '', address2: '', city: '', state: '', zipCode: '' };
  public isPendingRenewal_GIN = false;
  public isPendingRenewal_REN = false;
  public defaultMethod:Boolean = false;
  @ViewChild('focusAddr1') focusAddr1: ElementRef;

  @ViewChild('errorModal') errorModal: any;
  lastFour: string;

  constructor(private accountService: AccountService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private encDcrService: EncrDecrService,
    private billingPaymentService: BillingPaymentService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private commonService: CommonService,
    private jwtService: JwtService,
  ) {
    // this.getPolicyNumberFromRoute();
    // this.getAllMonths();
  }

  ngOnInit() {
    this.getInitialLoad();
  }

  getInitialLoad() {
    if (sessionStorage.getItem('policyList')) {
      if (this.displayProgressSpinner > 0) {
        this.displayProgressSpinner--;
      }
      this.defaultMethod = true;
      this.getPolicyNumberFromRoute();
      this.getAllMonths();
      this.getPolicy();
      this.getAccountInfo();
      this.CheckPendingRenewalPolicyType();
    } else {
      if (this.displayProgressSpinner == 0) {
        this.displayProgressSpinner++;
      }
      setTimeout(() => {
        this.getInitialLoad();
      }, 250);
    }
  }

  CheckPendingRenewalPolicyType() {
    const policyDetailsList = JSON.parse(sessionStorage.getItem("policyList"));
    var currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt = this.jwtService.DecodeToken(currentUser);
    const tokenPolicyList = decodeJwt["policyList"];
    policyDetailsList.forEach(element => {
      if (element.isPendingRenewal) {
        if(this.isPendingRenewal_REN == false)
        {
          this.isPendingRenewal_REN = tokenPolicyList.some(p => p.policyNumber === element.policyNumber && p.sourceSystem === "REN");
        }
        
        if(this.isPendingRenewal_GIN == false)
        {
          this.isPendingRenewal_GIN = tokenPolicyList.some(p => p.policyNumber === element.policyNumber && p.sourceSystem === "GIN");
        }
      }
    });
  }
  getPolicyNumberFromRoute() {
    this.route.params.subscribe(params => {
      this.policyNumber = this.encDcrService.get(params['policyNumber']);
    });
  }

  getPolicy() {
    this.addSpinner();
    this.billingPaymentService.getPolicyDetail(this.policyNumber).subscribe({
      next: response => {
        this.policy = response;
        this.getPaymentInfo(this.policyNumber);
        this.getPaymentAmounts(this.policyNumber);
      },
      complete: () => this.subtractSpinner()
    });
  }

  getPaymentAmounts(PolicyNumber: string) {
    this.addSpinner();
    this.billingPaymentService.renewalPayments(PolicyNumber, Number(this.policy.renewalNumber)).subscribe({
      next: response => {
        this.paymentAmounts = response;
      },
      complete: () => this.subtractSpinner()
    });
  }

  getPaymentInfo(editPolicyNumber: string) {
    this.addSpinner();
    this.billingPaymentService.getPaymentInfo(editPolicyNumber, Number(this.policy.renewalNumber)).subscribe({
      next: (paymentInfoData: { paymentInfo: PaymentInfo }) => {
        if (paymentInfoData && !_.isEmpty(paymentInfoData.paymentInfo)) {
          this.paymentInfo = paymentInfoData.paymentInfo;
          paymentInfoData.paymentInfo.zip = paymentInfoData.paymentInfo.zip.substring(0, 5);
          this.termIdentifier = paymentInfoData.paymentInfo.termIdentifier;
          this.applyPaymentInfo(paymentInfoData.paymentInfo);
        }
        this.createForm();
      },
      complete: () => this.subtractSpinner()
    });
  }

  getAccountInfo() {
    this.addSpinner();
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === this.policyNumber);    
    const memberId = selectedPolicy.memberId;
    this.accountService.getAccountInfo(memberId).subscribe({
      next: response => {
        this.accountInfo = response.accountInfoResponse;
      },
      complete: () => this.subtractSpinner()
    });
  }

  applyPaymentInfo(paymentInfo: PaymentInfo) {
    //const isCredit = !paymentInfo.abaNo;
    const isCredit = paymentInfo.payMethod.toUpperCase() === 'CC';
    
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (this.personalData.firstName = paymentInfo.firstName),
      (this.personalData.lastName = paymentInfo.lastName),
      (this.personalData.addressLine1 = paymentInfo.address1),
      (this.personalData.addressLine2 = paymentInfo.address2),
      (this.personalData.city = paymentInfo.city),
      (this.personalData.state = paymentInfo.state),
      (this.personalData.zipCode = paymentInfo.zip),
      (this.personalData.emailAddress = 'a@a.com'),
      // eslint-disable-next-line max-len
      (this.personalData.homePhone = paymentInfo.homeTel),
      // eslint-disable-next-line max-len
      (this.personalData.mobilePhone = (paymentInfo.bizTel && paymentInfo.bizTel.substring(0, 3) + '-' + paymentInfo.bizTel.substring(3, 6) + paymentInfo.bizTel.substring(6, paymentInfo.bizTel.length)) || ''),
      (this.paymentData.accountNumber = (!isCredit && paymentInfo.maskedAcctNo) || '');
    this.paymentData.accountType = paymentInfo.acctType;
    this.paymentData.cardNumber = (isCredit && paymentInfo.maskedAcctNo && paymentInfo.maskedAcctNo.toUpperCase()) || '';
    this.paymentData.expDate = (isCredit && paymentInfo.cardExpDate.substring(4, paymentInfo.cardExpDate.length) + '/' + paymentInfo.cardExpDate.substring(0, 4)) || '';
    this.paymentData.paymentMethod = paymentInfo.payMethod;
    //this.paymentData.paymentType = isCredit ? 'Credit card' : 'Debit/Checking account';
    if(this.defaultMethod)
    {
      this.paymentData.paymentType = 'Debit/Checking account';
      this.defaultMethod = false;
    }
    else
    {
      this.paymentData.paymentType = isCredit ? 'Credit card' : 'Debit/Checking account';
    }
    this.paymentData.routingNumber = paymentInfo.abaNo;
    this.paymentData.status = 'Current';
  }

  selectPaymentType(value) {
    this.paymentSelected = value;
  }

  setFormValidators(maxLength, pattern) {
    return [Validators.required, Validators.pattern(pattern), Validators.maxLength(maxLength)];
  }

  createForm(): void {
    const pattern = this.formValidation.patternValidation;
    const validation = this.formValidation.lengthValidation;
    this.billingAddressForm = this.fb.group({
      firstName: [this.personalData.firstName, this.setFormValidators(validation.firstNameLength, pattern.firstName)],
      lastName: [this.personalData.lastName, this.setFormValidators(validation.lastNameLength, pattern.lastName)],
      address1: [this.personalData.addressLine1, this.setFormValidators(validation.addressLine1Length, pattern.addressLine1)],
      address2: [this.personalData.addressLine2],
      city: [this.personalData.city, [Validators.required, Validators.pattern(pattern.city)]],
      state: [this.personalData.state, Validators.required],
      zip: [this.personalData.zipCode, [Validators.required, Validators.pattern(pattern.zipCode), Validators.maxLength(validation.zipCodeMaxLength), Validators.minLength(validation.zipCodeMinLength)]],
      homeTel: [this.personalData.homePhone, [Validators.required, Validators.maxLength(validation.phoneNumberLength), Validators.minLength(validation.phoneMinLength)]]
    });
    this.paymentSelected = this.paymentData.paymentType;
    this.editPaymentInfoForm = this.fb.group({
      paymentType: [this.paymentData.paymentType, Validators.required],
      paymentMode: ['N', Validators.required]
    });

    const today = new Date();
    this.creditCardForm = this.fb.group(
      {
        cardNumber: ['', [...this.setFormValidators(validation.ccMaxLength, pattern.creditCard), Validators.minLength(validation.ccMinLength), cardTypeValidator]],
        expMonth: [1],
        expYear: [String(today.getFullYear())]
      },
      { validator: this.creditValidators }
    );

    this.creditCardForm.valueChanges.subscribe(() => this.cdRef.markForCheck());

    this.achForm = this.fb.group({
      accountType: [this.paymentData.accountType ? this.paymentData.accountType : '', Validators.required],
      accountNumber: ['', [Validators.maxLength(this.formValidation.lengthValidation.accountMaxLength), Validators.pattern(this.formValidation.patternValidation.accountRouting)]],
      routingNumber: ['', [Validators.maxLength(this.formValidation.lengthValidation.routingLength), Validators.minLength(this.formValidation.lengthValidation.routingLength), Validators.pattern(this.formValidation.patternValidation.accountRouting)]]
    });

    this.preferences = this.preferences.map(p => {
      p['hidden'] = true;
      return p;
    });
    this.expirationYears = this.getNext10Years();
  }

  creditValidators(fg: UntypedFormGroup): any {
    let errors = {};
    errors = creditValidation(fg, errors);

    const empty = _.isEmpty(errors);
    return empty ? null : errors;
  }

  touchAllAccFields(reset): any {
    if (reset === 'accountNumber') {
      this.achForm.controls.accountNumber.reset();
    } else if (reset === 'routingNumber') {
      this.achForm.controls.routingNumber.reset();
    }
  }

  getNext10Years(): number[] {
    const today = new Date();
    const currYr = today.getFullYear();
    const next10Years = [];
    for (let i = 0; i <= 10; i++) {
      next10Years.push(currYr + i);
    }
    return next10Years;
  }

  getAllMonths() {
    this.months = [];
    for (let i = 1; i <= 12; i++) {
      this.months.push(i);
    }
  }

  resetCreditCard() {
    this.creditCardForm.controls.cardNumber.reset();
    this.creditCardForm.controls.cardNumber.setValue('');
  }

  isCredit(): boolean {
    return this.editPaymentInfoForm && this.editPaymentInfoForm && this.editPaymentInfoForm.value.paymentType === 'Credit card';
  }

  numbersOnly(event: KeyboardEvent) {
    return (event.key >= '0' && event.key <= '9') || event.key === ' ' || event.key === 'backspace';
  }

  checkForCardType() {
    let creditStatus = false;
    if (this.paymentSelected === 'Credit card') {
      const creditCardForm = this.creditCardForm.value;
      creditStatus = this.creditCardForm.valid || !this.creditCardForm.dirty ? true : creditCardForm.cardNumber === this.paymentData.cardNumber
        && creditCardForm.expMonth === this.paymentData.expDate.split('/')[0] && creditCardForm.expYear === this.paymentData.expDate.split('/')[1];
    } else if (this.paymentSelected === 'Debit/Checking account') {
      const achForm = this.achForm.value;
      creditStatus = this.achForm.valid || !this.achForm.dirty ? true : achForm.accountType === this.paymentData.accountType &&
        achForm.accountNumber === this.paymentData.accountNumber && achForm.routingNumber === this.paymentData.routingNumber;
      if (this.achForm.dirty) {
        this.validateABA(achForm.routingNumber);
        return;
      }
    }
    if (creditStatus) {
      this.cardProfile();
    }
  }

  validateABA(routingNumber: string) {
    this.dataService.policyRenewalData = this.populateBillingDocument();
    this.saveRenewalDataAndNavigate();
    // this.addSpinner();
    // this.billingPaymentService.validateABA(routingNumber).subscribe({
    //   next: () => {
    //     this.subtractSpinner();
    //     this.dataService.policyRenewalData = this.populateBillingDocument();
    //     this.saveRenewalDataAndNavigate();
    //   },
    //   error: () => {
    //     this.subtractSpinner();
    //     this.ABANumberError = true;
    //     // this.achForm.controls['routingNumber'].setErrors({pattern: true});
    //     document.getElementById('routingNumber').focus();
    //   }
    // });
  }

  setFormValues(formGroup: UntypedFormGroup, formControlName) {
    return formGroup.value[formControlName];
  }

  populateBillingDocument() {
    this.policyRenewalData.firstName = this.setFormValues(this.billingAddressForm, 'firstName');
    this.policyRenewalData.lastName = this.setFormValues(this.billingAddressForm, 'lastName');
    this.policyRenewalData.address1 = this.addressResponse.address1 ? this.addressResponse.address1 : this.setFormValues(this.billingAddressForm, 'address1');
    this.policyRenewalData.address2 = this.addressResponse.address2 ? this.addressResponse.address2 : this.setFormValues(this.billingAddressForm, 'addressLine2');
    this.policyRenewalData.city = this.addressResponse.city ? this.addressResponse.city : this.setFormValues(this.billingAddressForm, 'city');
    this.policyRenewalData.state = this.addressResponse.state ? this.addressResponse.state : this.setFormValues(this.billingAddressForm, 'state');
    this.policyRenewalData.zip = this.addressResponse.zipCode ? this.addressResponse.zipCode : this.setFormValues(this.billingAddressForm, 'zip');
    this.policyRenewalData.homeTel = this.setFormValues(this.billingAddressForm, 'homeTel');
    this.policyRenewalData.payMethod = this.setPaymentMethod();
    this.policyRenewalData.pmtPlan = this.setFormValues(this.editPaymentInfoForm, 'paymentMode');
    this.policyRenewalData.acctNo = this.setAccountNumber();
    this.policyRenewalData.acctType = this.getCardType(this.creditCardForm.value.cardNumber);
    this.policyRenewalData.cardExpDate = this.setCardExpDate();
    this.policyRenewalData.abaNo = this.setRoutingNumber();
    this.policyRenewalData.amount = this.setAmountValue();
    this.policyRenewalData.username = this.getUserName();
    this.policyRenewalData.policyNumber = this.policyNumber;
    this.policyRenewalData.renewal = Number(this.policy.renewalNumber);
    this.policyRenewalData.termIdentifier = this.termIdentifier;

    return this.policyRenewalData;
  }

  getCardType(cardNumber: string) {
    if (cardNumber) {
      const visaPattern = new RegExp(this.cardPatterns.visa);
      const amexPattern = new RegExp(this.cardPatterns.americanExpress);
      const discoverPattern = new RegExp(this.cardPatterns.discover);
      const masterCardPattern = new RegExp(this.cardPatterns.masterCard);

      let cardType = '';

      if (cardNumber.match(visaPattern)) {
        cardType = 'VI';
      } else if (cardNumber.match(amexPattern)) {
        cardType = 'AX';
      } else if (cardNumber.match(discoverPattern)) {
        cardType = 'DI';
      } else if (cardNumber.match(masterCardPattern)) {
        cardType = 'MC';
      }
      return cardType;
    } else {
      const achForm = this.achForm.value;
      return achForm.accountType;
    }
  }

  cardProfile() {
    this.dataService.policyRenewalData = this.populateBillingDocument();
    const paymentInfo = this.dataService.policyRenewalData;
    const ccAccountNumber = paymentInfo.acctNo;
    const cardLength = ccAccountNumber && ccAccountNumber.length;
    if (cardLength) {
      this.lastFour = ccAccountNumber.slice(cardLength - 4, cardLength);
    }
    this.addSpinner();
    this.billingPaymentService
      .cardProfiles({
        customerName: `${this.policyRenewalData.firstName} ${this.policyRenewalData.lastName}`,
        customerAddress1: this.policyRenewalData.address1,
        customerAddress2: this.policyRenewalData.address2,
        customerCity: this.policyRenewalData.city,
        customerZIP: this.policyRenewalData.zip,
        customerEmail: this.accountInfo.email,
        customerPhone: this.policyRenewalData.homeTel || this.policyRenewalData.bizTel,
        customerAccountType: 'CC',
        accountNumber: this.policyRenewalData.acctNo,
        expirationDate: this.policyRenewalData.cardExpDate
      })
      .subscribe({
        next: response => {
          if (response.status === '0') {
            this.dataService.policyRenewalData = {
              ...this.dataService.policyRenewalData,
              maskedAcctNo: String(response.accountNumber),
              acctNo: String(response.accountNumber),
              securityCode: String(response.customerRefNum)
            };
            this.saveRenewalDataAndNavigate();
          } else {
            this.commonService.setValidationErrorModal('Invalid Credit Card.');
          }
        },
        // error: () => this.subtractSpinner(),
        // complete: () => this.subtractSpinner()
        error:() => {
          this.subtractSpinner();
          this.commonService.setDialogModal({
            title: 'Error',
            header: 'An error occurred. Please try again.',
            content: '',
          });
        },
         complete: () => {this.subtractSpinner();} 
      });
  }

  setPaymentMethod() {
    return this.paymentSelected === 'Credit card' ? 'CC' : 'ACH';
  }

  setAccountNumber() {
    if (this.paymentSelected === 'Credit card') {
      return this.creditCardForm.controls['cardNumber'].dirty ? this.creditCardForm.value.cardNumber : this.paymentData.cardNumber;
    } else {
      return this.achForm.controls['accountNumber'].dirty ? this.achForm.value.accountNumber : this.paymentData.accountNumber;
    }
  }

  setRoutingNumber() {
    return this.achForm.controls['routingNumber'].dirty ? this.achForm.value.routingNumber : this.paymentData.routingNumber;
  }

  setAccountType() {
    return this.paymentSelected === 'Credit card' ? 'VI' : this.editPaymentInfoForm.value.accountType;
  }

  setCardExpDate() {
    return this.creditCardForm.value.expYear + (this.creditCardForm.value.expMonth < 10 ? `0${this.creditCardForm.value.expMonth}` : this.creditCardForm.value.expMonth);
  }

  setAmountValue() {
    return this.editPaymentInfoForm.value.paymentMode === 'N' ? this.paymentAmounts.annualPayment : this.paymentAmounts.firstPayment;
  }

  getUserName() {
    return sessionStorage.getItem('userId');
  }

  saveRenewalDataAndNavigate() {
    sessionStorage.setItem('dataService', JSON.stringify(this.dataService));
    this.router.navigate([this.routingConstants.renewalConfirmation, this.encDcrService.set(this.policyNumber)]);
  }

  canSubmit() {
    let isPaymentValid = true;
    if (this.paymentSelected === 'Credit card') {
      isPaymentValid = this.creditCardForm.valid;
    } else {
      isPaymentValid = this.achForm.valid;
    }
    return isPaymentValid && this.billingAddressForm.valid;
  }

  submit() {
    this.submitted = true;
    if (this.canSubmit()) {
      //this.checkForCardType();
      if (this.isPendingRenewal_REN && this.accountInfo.state !== this.billingAddressForm.value.state) {
        this.commonService.setDialogModal({header: this.errorMessages1.pendingRenewalState.modal});
        return;
      }
      if (this.isPendingRenewal_GIN && this.accountInfo.zipCode.substring(0,5) !== this.billingAddressForm.value.zip.substring(0,5)) {
        this.commonService.setDialogModal({header: this.errorMessages1.pendingRenewalState.modal});
        return;
      }
      this.verifyAddress();
    }
  }

  verifyAddress() {
    // build request from form values
    this.addressRequest.address1 =  this.billingAddressForm.get('address1').value;
    this.addressRequest.address2 =  this.billingAddressForm.get('address2').value;
    this.addressRequest.city =  this.billingAddressForm.get('city').value;
    this.addressRequest.state  = this.billingAddressForm.get('state').value;
    this.addressRequest.zipCode =  this.billingAddressForm.get('zip').value;
  
    this.commonService.verifyAddress(this.addressRequest).subscribe(
      (data) => {
        if(data) {
          this.addressResponse=data;
          this.addressResponse.address2=this.addressResponse.address2==null||this.addressResponse.address2=='null'||this.addressResponse.address2=='Null'?'':this.addressResponse.address2;
          this.addressResponse.zipCode=this.addressResponse.zipCode.toString();
          this.checkForCardType();
        } else {
          this.addressResponse = {};
          this.openAddressModal();
        }
      }, error => {
        this.addressResponse = {};
        this.openAddressModal();
      }
    );
  }

  openAddressModal() {
    this.commonService.setAddressValidationModal(this.addressRequest).subscribe(result => {
    if (result) {
      if (result === 'Edit') {
        setTimeout(() => {
          this.focusAddr1.nativeElement.focus();
        }, 0);
        window.scrollTo(0, 0);
      } else if (result === 'Choose') {
        this.checkForCardType();
      }
    }
    });
  }

  open() {
    const modalRef = this.modalService.open(AchMockupModalComponent);
  }

  goToRenewPolicy(policyNumber) {
    this.router.navigate([this.routingConstants.renewPolicy, this.encDcrService.set(policyNumber)]);
  }

  addSpinner() {
    this.displayProgressSpinner++;
    this.cdRef.markForCheck();
  }

  subtractSpinner() {
    this.displayProgressSpinner--;
    this.cdRef.markForCheck();
  }
}
