<div class="container text-center pb-5">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="navigateToForgotPasswordPage()" id="topBackButton" class=" pull-left d-inline pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{ content.forgotPasswordPage.heading }}</h1>
  </div>
  <hr style="border: 1px solid grey" />
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10 col-sm-12">
            <p class="text-left"><b>{{ content.forgotPasswordPage.securityInfo }}</b></p>
            <p class="text-left">
              If you do not remember the answer to your security question, please <a href="http://petinsurance.custhelp.com/app/ask" id="contact-online"><u>contact us online</u></a>
              or call our Member Care Department at 844-397-8938 Monday through Friday from 5:00 a.m. to 7:00 p.m. and Saturdays from 7:00 a.m. to 3:30 p.m. (Pacific)
            </p>
          </div>
        </div>
        <mat-card-content class="pt-3 pb-3">
          <form [formGroup]="resetPasswordForm">
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>security</mat-icon>
                  <mat-label><b>Your Personalized security question:</b></mat-label>
                  <input matInput id="securityQuestion" readonly value="{{ securityQuestion }}" type="text" class="material-input-form" />
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>security</mat-icon>
                  <mat-label><b>Enter the answer below:</b></mat-label>
                  <input matInput id="securityAnswer" type="text" formControlName="securityAnswer" class="material-input-form" placeholder="Enter Security Answer" required />
                  <mat-error *ngIf="formDetails.securityAnswer.errors && formDetails.securityAnswer.errors.required">Security Answer is Required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br />
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <h5 class="fw-bold">Change Your Password</h5>
                <br>
                <p class="text-left">Password must be between 8-65 characters in length and must include atleast one number and one letter or special character.</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>vpn_key</mat-icon>
                  <mat-label><b>New Password</b></mat-label>
                  <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                  <input matInput id="newPassword" type="text" [type]="hide ? 'password' : 'text'" formControlName="newPassword" class="material-input-form" placeholder="Enter new password" required />
                  <mat-error *ngIf="formDetails.newPassword.errors && formDetails.newPassword.errors.required">New Password is Required</mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>vpn_key</mat-icon>
                  <mat-label><b>Confirm New Password</b></mat-label>
                  <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                  <input matInput id="confirmNewPassword" type="text" formControlName="confirmNewPassword" [type]="hide ? 'password' : 'text'" class="material-input-form" placeholder="Confirm New Password" required />
                  <mat-error *ngIf="formDetails.confirmNewPassword.errors && formDetails.confirmNewPassword.errors.required">Confirm New Password is Required</mat-error>
                  <mat-error *ngIf="formDetails.confirmNewPassword.errors && formDetails.confirmNewPassword.errors.notMatch">Passwords don't match.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br />
            <mat-card-actions>
              <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10 col-sm-12">
                  <button mat-raised-button class="custom-password-mat-button float-end mobile-button" color="primary" id="forgot-id-submit-btn" (click)="onSubmit()" [disabled]="!resetPasswordForm.valid">{{ content.buttons.submit }}</button>
                  <app-navigation-back (navigateBackEvent)="navigateToForgotPasswordPage()" id="bottomBackButton" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
                </div>
              </div>
            </mat-card-actions>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"> </app-progress-spinner>
