import { AccountInfo } from '../../models/account-info';
import { AccountRequest } from '../../models/account-request';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { OptInRequest } from '../../models/opt-in-request';
import { OptInResponse } from '../../models/opt-in-response';
import { OptInStatus } from '../../models/opt-in-status';
import { AccountResponse } from '../../models/response/account-response';
import { ElectronicClaimPaymentResponse } from '../../models/response/electronic-claim-payment-response';
import { OptInEmailRequest } from '../../models/request/opt-in-email-request';
import { JwtService } from '../../core/services/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public accountsUrl = environment.baseUrl + 'account-management/pet-php-accounts/v1';
  public renAccountsUrl = environment.renbaseUrl + 'accounts/v1';
  public membersUrl = environment.baseUrl + 'members/v1';
  public environment = environment;
  public rxOptInClicked = false;

  constructor(private http: HttpClient,private jwtService: JwtService) {}

  getAccountInfo(memberId: string): Observable<AccountResponse> {
    const contentTypeHeader = new HttpHeaders({
      activeMemberId: memberId     
    });
    return this.http.get<AccountResponse>(this.accountsUrl, { headers: contentTypeHeader }).pipe(    
      map(data => data as AccountResponse)
    );
  }

  updateMemberDetails(updateType: string, accountRequest: AccountRequest): Observable<any> {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt = this.jwtService.DecodeToken(currentUser);
    const tokenPolicyList = decodeJwt['policyList'];
    const uniqueMemberIds: any[] = [];
    const uniqueAccountIds: any[] = [];
    accountRequest.updateType = updateType;

    if (updateType === 'PERSONAL') {
      const responseListPersonal: any = [];

      policyInfoList.forEach(element => {
        if (!uniqueMemberIds.some(x => x == element.memberId)) {
          uniqueMemberIds.push(element.memberId);
          const isGinpolicies = tokenPolicyList.some(p => p.policyNumber === element.policyNumber && p.sourceSystem === 'GIN');
          const acctRequest = JSON.parse(JSON.stringify(accountRequest));
          acctRequest.isGINSystem = isGinpolicies;          
          responseListPersonal.push(this.updateAccountInfo(acctRequest, element.memberId, element.accountId));
        }
      });
      return forkJoin(responseListPersonal);
    }
    else // 'ELECTRONICPAYMENTPREF'
    {
      const responseListEcp: any = [];

      policyInfoList.forEach(element => {
        if (!uniqueAccountIds.some(x => x == element.accountId)) {
          uniqueAccountIds.push(element.memberId);
          responseListEcp.push(this.updateAccountInfo(accountRequest, element.memberId, element.accountId));
        }
      });
      return forkJoin(responseListEcp);
    }
  }

  updateAccountInfo(accountRequest: AccountRequest, memberId: string, accountId: string) {
    const contentTypeHeader = new HttpHeaders({
      activeMemberId: memberId,
      activeAccountId: accountId
    });
    return this.http.post(this.accountsUrl, accountRequest, { headers: contentTypeHeader });
  }

  getOptIn(): Observable<OptInResponse> {
    return this.http.get<OptInResponse>(`${this.renAccountsUrl}/optin`).pipe(
      map(data => data as OptInResponse)
    );
  }

  getECP(accountId: string): Observable<ElectronicClaimPaymentResponse> {
    const contentTypeHeader = new HttpHeaders({
      activeAccountId: accountId
    });
    return this.http.get<ElectronicClaimPaymentResponse>(`${this.accountsUrl}/electronic-claim-payment`, { headers: contentTypeHeader }).pipe(
      map(data => data as ElectronicClaimPaymentResponse)
    );
  }

  updateOptIn(optInRequest: OptInRequest): Observable<OptInStatus> {
    return this.http.post<OptInStatus>(`${this.renAccountsUrl}/optin`, optInRequest).pipe(
      map(data => data as OptInStatus)
    );
  }

  optInEmail(optInEmailRequest: OptInEmailRequest): Observable<any> {
    return this.http.post(`${this.membersUrl}/rxoptin-email`, optInEmailRequest);
  }

  updateRxOptInClicked(clicked: boolean) {
    this.rxOptInClicked = clicked;
  }

  updatePaymentAuthorizationInfo(paymentAuthRequest: any,activeAccountId): Observable<any> {
    //var activeAccountId = sessionStorage.getItem('activeAccountId');
    const contentTypeHeader = new HttpHeaders({
      activeAccountId
    });
    return this.http.post(`${this.accountsUrl}/paymentauthorization`, paymentAuthRequest, { headers: contentTypeHeader });
  }

  updatePaymentAuthorization(paymentAuthInfoList): Observable<any> {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const uniqueAccountIds: any[] = [];
    const updatePaymentAuthInfoList: any = [];
    policyInfoList.forEach(element => {
        paymentAuthInfoList.forEach(paymentAuthInfo =>{ 
          if(paymentAuthInfo.policyNumber == element.policyNumber){
          updatePaymentAuthInfoList.push(this.updatePaymentAuthorizationInfo(paymentAuthInfo, element.accountId));
          }
        });
    });
    return forkJoin(updatePaymentAuthInfoList);
  }
}
