import {Component, OnInit} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {IMAGECONSTANTS} from '../../constants/image-constants';
import {ROUTINGCONSTANTS} from '../../constants/routing-constants';
import {LoginInfo} from '../../../models/login-info';
import {GtmEventsService} from '../../services/gtm-events.service';
import {ForgotPasswordService} from './forgot-password.service';
import {CommonService} from '../../services/common.service';
import {StandardMessage} from '../../../models/standard-message';
import {DataService} from '../../../core/services/data.service';
import { FullstoryService } from '../../services/fullstory.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public content = CONTENT;
  public imageConstants = IMAGECONSTANTS;
  public forgotPasswordForm: UntypedFormGroup;
  public isSubmitted = false;
  public routingConstants = ROUTINGCONSTANTS;
  public loginInfo: LoginInfo = {};
  public securityQuestion: string;

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private forgotPasswordService: ForgotPasswordService,
              private gtmService: GtmEventsService,
              private commonService: CommonService,
              private fullstoryService: FullstoryService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.forgotPasswordForm = this.fb.group({
      loginId: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      petName: ['', [Validators.required]]
    });
  }

  setForgotPassForm(forgotPasswordForm: UntypedFormGroup, formControlName) {
    return forgotPasswordForm.value[formControlName];
  }

  populateLoginInfo() {
    this.loginInfo.loginId = this.setForgotPassForm(this.forgotPasswordForm, 'loginId');
    this.loginInfo.zipCode = this.setForgotPassForm(this.forgotPasswordForm, 'zipCode');
    this.loginInfo.petName = this.setForgotPassForm(this.forgotPasswordForm, 'petName');
    this.dataService.forgotPasswordRequest = this.loginInfo;
    return this.loginInfo;
  }

  get formDetails() {
    return this.forgotPasswordForm.controls;
  }

  setGtmEvents(policy) {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Forgot Password Page 1 validation',
      eventLabel: 'Forgot Password Page 1',
      eventValue: policy['code'],
      userId: sessionStorage.getItem('userId'),
      error: {
        code: policy ? policy['code'] : '',
        message: policy ? policy['developerMessage'] : ''
      }
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  onSubmit() {
    this.isSubmitted = true;
    this.forgotPasswordService.validatePassword(this.populateLoginInfo()).subscribe((policy: StandardMessage) => {
      this.setGtmEvents(policy);
      if (policy['code'] === '0') {
        sessionStorage.setItem('userId', this.loginInfo.loginId);
        this.router.navigate([this.routingConstants.resetPassword]);
      } else {
        this.setFormValidationErrors(policy['code']);
      }
    });
  }

  setFormValidationErrors(code) {
    if (code === '93') {
      this.commonService.setValidationErrorModal(this.content.validations.forgotPassword.petNameOrZipError);
    } else if (code === '92') {
      this.commonService.setValidationErrorModal(this.content.validations.forgotPassword.loginIdError);
    }
  }

  navigateToLoginPage() {
    this.router.navigate([this.routingConstants.login]);
  }

  navigateToForgotId() {
    this.router.navigate([this.routingConstants.forgotId]);
  }
}

