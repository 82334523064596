import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

declare let newrelic: any;

@Injectable()
export class NewRelicErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        console.error(error);
        if (error instanceof HttpErrorResponse) {
            const errorText = new Error(`${error.status} - ${error.statusText}; ${error.message}`);
            newrelic.noticeError(errorText);
        } else {
            newrelic.noticeError(error);
        }
    }
}
