<div class="container pb-5" *ngIf="!showConfirmation">
  <div *ngIf="!(isCancelled || isExpired)">
    <div class="container-title">
      <app-navigation-back (navigateBackEvent)="goToAccount()" class="pull-left d-inline  pl-1 d-md-none"
        id="upperBackButton"></app-navigation-back>
      <h1 class="fw-bold" id="edit-payment-header">Edit Payment</h1>
    </div>
    
    <hr style="border: 1px solid grey" />
    
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <mat-card appearance="outlined">
          <mat-card-content class="pt-3 pb-3">
            <form (ngSubmit)="save()" *ngIf="editPaymentInfoForm" [formGroup]="editPaymentInfoForm">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-md-8 col-sm-12">
                  <mat-form-field appearance="outline" class="material-input-full-width">
                    <mat-label>{{ labels.selectPet }}</mat-label>
                    <mat-select [disabled]="isGroupPayroll && isLoadedWithGP" (selectionChange)="changePayment($event)"
                      formControlName="policyNumber" id="select-pet" multiple>
                      <mat-option *ngFor="let policy of policyDetailsList" [value]="policy.policyNumber">
                        {{ policy.pet.name | titlecase }}</mat-option>
                      <mat-option value="all">{{ content.accountInfo.payment.allPets }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="isGroupPayroll && isLoadedWithGP">
                <div class="row justify-content-center" *ngIf="!policyStatus && nonPayrollId">
                  <div class="col-lg-8 col-md-8 col-sm-12">
                    <div class="float-start">
                      Are you still employed by<b> {{this.empName}}</b> ?
                    </div>
                    <mat-radio-group formControlName="stillEmployed" aria-label="Select an option"
                      class="ms-lg-5 col-12 col-lg-4 d-block float-start margin_top">
                      <mat-radio-button [value]="true" class="me-5">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-lg-8 col-md-8 col-sm-12" *ngIf="renewalMidterm">
                    {{content.accountInfo.payment.labels.renewalText}}<br/>
                    {{content.accountInfo.payment.labels.needToCollect}} <b>${{editPaymentResponse?.pastdueamountcurr}}</b>
                    <mat-icon class="icon-display" matTooltipClass="custom-tooltip" [matTooltip]="editPaymentInfoForm.value.stillEmployed ? content.accountInfo.payment.tooltip.updatePaymentYes:content.accountInfo.payment.tooltip.updatePaymentNo">information</mat-icon>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-12" *ngIf="!renewalMidterm">
                    <div *ngIf="isPriorPastDue">
                      {{content.accountInfo.payment.labels.needToCollect}}
                      <br> Prior term balance: <b> ${{editPaymentResponse?.priorPastdueamount}}</b>
                      <mat-icon class="icon-display" matTooltipClass="custom-tooltip" [matTooltip]="editPaymentInfoForm.value.stillEmployed ? content.accountInfo.payment.tooltip.updatePaymentYes:content.accountInfo.payment.tooltip.updatePaymentNo">information</mat-icon>
                      <br> Current term balance: <b>${{editPaymentResponse?.pastdueamount}}</b> <br><br>
                    </div>

                    <div *ngIf="!isPriorPastDue">
                      {{content.accountInfo.payment.labels.needToCollect}} <b>${{editPaymentResponse?.pastdueamount}}</b>
                      <mat-icon class="icon-display" matTooltipClass="custom-tooltip" [matTooltip]="editPaymentInfoForm.value.stillEmployed ? content.accountInfo.payment.tooltip.updatePaymentYes:content.accountInfo.payment.tooltip.updatePaymentNo">information</mat-icon>                      
                    </div>
                    <br>
                  </div>
                </div>
                
                <div class="row justify-content-center">
                  <div class="col-lg-8 col-md-8 col-sm-12 mb-3">
                    {{content.accountInfo.payment.labels.frequency}}
                    <div class="mt-2">
                      <mat-radio-group formControlName="frequency" aria-label="Select an option">
                        <div class="row mb-3">
                          <mat-radio-button value="monthly"
                                            class="col-11 col-sm-5 border rounded ms-3 p-0 frequency-option"
                                            [ngClass]="{'frequency-option-selected border-primary':editPaymentInfoForm.value.frequency==='monthly'}" (change)="onChangePaymentPlan('Monthly')">
                            <div class="me-5 float-start">
                              <b>Monthly Payments</b>
                              
                              <p *ngIf="!renewalMidterm">Set automatic monthly payments of ${{editPaymentResponse?.monthlyinstallment}} beginning on <b>{{editPaymentResponse?.nextbillduedate | date: 'MM/dd/yyyy'}}</b> (includes a ${{installmentFee}} Installment fee)</p>
                              
                              <p *ngIf="renewalMidterm">Pay an initial installment of ${{editPaymentResponse?.downpaymentren}} today, and set subsequent automatic monthly payments of ${{editPaymentResponse?.monthlyinstallmentren}} beginning on <b>{{editPaymentResponse?.nextbillduedate | date: 'MM/dd/yyyy'}}</b> <br/>(Payments include a ${{installmentFee}} Installment fee)</p>
                            </div>
                          </mat-radio-button>
                        
                          <mat-radio-button value="full"
                            class="col-11 col-sm-5 border rounded ms-3 p-0 frequency-option"
                            [ngClass]="{'frequency-option-selected border-primary':editPaymentInfoForm.value.frequency==='full'}" (change)="onChangePaymentPlan('Annual')">
                            <div class="me-5 float-start">
                              <b>Pay in Full</b>
                            <!-- </div>
                            <div class="float-start "> --> 
                              <!-- <p *ngIf="!renewalMidterm">Pay the entire year's premium plus the current amount due for a total of ${{editPaymentResponse?.pifAmount}} today and stay covered until {{policyExpiryDate | date: 'MM/dd/yyyy'}}*.  (Payment includes the current balance due of ${{editPaymentResponse?.pastdueamount}})  <br/><br/> *This option does not automatically renew.</p> -->
                              <p *ngIf="!renewalMidterm">Pay the entire year's premium plus the current amount due for a total of ${{editPaymentResponse?.pifAmount}} today and stay covered until {{policyExpiryDate | date: 'MM/dd/yyyy'}}*.  <br/><br/> *This option does not automatically renew on {{policyExpiryDate | date: 'MM/dd/yyyy'}}.</p>
                              <p *ngIf="renewalMidterm">Schedule the entire year's premium of ${{editPaymentResponse?.pifAmountren}} billed today and stay covered until {{policyExpiryDate | date: 'MM/dd/yyyy'}}* <br/><br/> *Policy will not automatically renew on {{policyExpiryDate | date: 'MM/dd/yyyy'}}.</p>
                              
                            </div>
                          </mat-radio-button>
                        </div>
                      </mat-radio-group>
                    </div>
                      {{content.accountInfo.payment.labels.totalBalance}} <b>${{installmentAmount | number : '1.2-2'}}</b>
                    </div>
                  </div>
                </div>

                <div *ngIf="!isGroupPayroll || isGroupPayroll && editPaymentInfoForm?.value?.frequency"
                   class="row justify-content-center">
                  <div class="col-lg-8 col-md-8 col-sm-12">
                    <p>
                      <strong>{{ content.accountInfo.paymentMethod }}</strong>
                    </p>
                    <mat-radio-group [(ngModel)]="editPaymentInfoForm.value.paymentType" [disabled]="!isPolicySelected"
                                   formControlName="paymentType" id="paymentType">
                    <span *ngFor="let paymentType of paymentTypes" class="float-start me-3">
                      <mat-radio-button [value]="paymentType" (click)="paymentTypeChange(paymentType)" color="primary"><b>{{ paymentType }}</b>
                      </mat-radio-button>
                      <br/>
                    </span>
                  </mat-radio-group>
                </div>
              </div>
                <div *ngIf="isAch()  && (!isGroupPayroll || isGroupPayroll && editPaymentInfoForm?.value?.frequency)">
                  <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-5 col-sm-12">
                      <mat-form-field *ngIf="editPaymentInfoForm?.controls?.routingNumber" appearance="outline"
                                      class="material-input-full-width pt-1">
                        <mat-icon matPrefix>account_balance</mat-icon>
                        <input (blur)="hideABA = true" (click)="fieldClick('routingNumber')"
                              (keypress)="numbersOnly($event)" [formControl]="editPaymentInfoForm.controls.routingNumber"
                              [hiddenInput]="hideABA" [maxlength]="formValidation.lengthValidation.routingLength"
                              [placeholder]="placeholders.routingNumber"
                          [readonly]="!isPolicySelected" id="routingNumber" mask="XXXXX0000" matInput type="tel" />
                        <mat-label>
                        <b>{{ content.accountInfo.routingNumber }}</b>
                        </mat-label>
                        <mat-error
                          *ngIf="editPaymentInfoForm.controls.routingNumber.errors && editPaymentInfoForm.controls.routingNumber.errors.invalidABA">
                          {{ errorMessages.routingNumber.invalidABA }}</mat-error>
                        <mat-error
                          *ngIf="editPaymentInfoForm.controls.routingNumber.errors && editPaymentInfoForm.controls.routingNumber.errors.pattern">
                          {{ errorMessages.routingNumber.pattern }}</mat-error>
                        <mat-error
                          *ngIf="editPaymentInfoForm.controls.routingNumber.errors && editPaymentInfoForm.controls.routingNumber.errors.maxlength">
                          {{ errorMessages.routingNumber.maxLength }}</mat-error>
                        <mat-error
                          *ngIf="editPaymentInfoForm.controls.routingNumber.errors && editPaymentInfoForm.controls.routingNumber.errors.required">
                          {{ errorMessages.routingNumber.required }}</mat-error>
                      </mat-form-field>
                    </div>
                  
                    <div class="col-lg-5 col-md-5 col-sm-12">
                      <mat-form-field *ngIf="editPaymentInfoForm?.controls?.accountNumber" appearance="outline"
                      class="material-input-full-width pt-1">
                        <mat-icon matPrefix>account_balance</mat-icon>
                        <input (blur)="hideAcctNo = true" (click)="fieldClick('accountNumber')"
                        [formControl]="editPaymentInfoForm?.controls?.accountNumber"
                        [maxlength]="formValidation.lengthValidation.accountMaxLength"
                        [placeholder]="placeholders.accountNumber" [readonly]="!isPolicySelected" appShowLast4
                        class="material-input-form" id="accountNumber" matInput type="tel" />
                        <mat-label><b>{{ content.accountInfo.accountNumber }}</b></mat-label>
                        <mat-error
                          *ngIf="editPaymentInfoForm.controls.accountNumber.errors && editPaymentInfoForm.controls.accountNumber.errors.pattern">
                          {{ errorMessages.accountNumber.pattern }}</mat-error>
                        <mat-error
                          *ngIf="editPaymentInfoForm.controls.accountNumber.errors && editPaymentInfoForm.controls.accountNumber.errors.maxlength">
                          {{ errorMessages.accountNumber.maxLength }}</mat-error>
                        <mat-error
                          *ngIf="editPaymentInfoForm.controls.accountNumber.errors && editPaymentInfoForm.controls.accountNumber.errors.required">
                          {{ errorMessages.accountNumber.required }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <br />
                
                  <div class="row justify-content-center">
                  <div class="col-lg-5 col-md-5 col-sm-12">
                    <mat-form-field appearance="outline" class="material-input-full-width">
                      <mat-icon matPrefix>account_balance</mat-icon>
                      <mat-label><b>{{ content.accountInfo.accountType }}</b></mat-label>
                      <mat-select [disabled]="!isPolicySelected" formControlName="accountType" id="accountType"
                        required>
                        <mat-option value="">{{ content.accountInfo.accountType }}</mat-option>
                        <mat-option *ngFor="let accountType of accountTypes" [value]="accountType.value.toUpperCase()">
                          {{ accountType.display }}</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="editPaymentInfoForm.controls.accountType.errors && editPaymentInfoForm.controls.accountType.errors.required">
                        {{ errorMessages.accountType.required }}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="padding_top_5  row justify-content-center">
                  <div class="col-lg-5 col-md-5 col-sm-12 text-center">
                    <mat-checkbox #myCheckbox (change)="isTermsConditionChange($event)"  color="primary"></mat-checkbox>
                    <b  class="padding_left_5">Agree to the <a href="javascript:void(0);" style="cursor:pointer" (click)="getTermsAndConditionModel()">Terms and Conditions</a></b>
                  </div>
                </div>
              </div>

              <div *ngIf="isCredit() && (!isGroupPayroll || isGroupPayroll && editPaymentInfoForm?.value?.frequency)">
                <div class="row justify-content-center">
                  <div class="col-lg-7 col-md-7 col-sm-12">
                    <mat-form-field appearance="outline" class="material-input-full-width pt-1">
                      <mat-icon matPrefix>credit_card</mat-icon>
                      <input (blur)="hideCC = true" (click)="fieldClick('cardNumber')"
                             [formControl]="editPaymentInfoForm.controls['cardNumber']" [hiddenInput]="hideCC"
                             [maxlength]="formValidation.lengthValidation.ccMaxLength + 3"
                             [placeholder]="placeholders.cardNumber" [readonly]="!isPolicySelected"
                             class="material-input-form" id="cardNumber" mask="XXXX XXXX XXXX 0*" matInput type="tel"
                             [validation]="false"/>
                      <mat-label>
                        <b>{{ content.accountInfo.cardNumber }}</b>
                      </mat-label>
                      <mat-error *ngIf="editPaymentInfoForm?.controls?.cardNumber?.errors?.pattern">
                        {{ errorMessages.cardNumber.pattern }}</mat-error>
                      <mat-error *ngIf="editPaymentInfoForm?.controls?.cardNumber.errors?.minlength">
                        {{ errorMessages.cardNumber.minLength }}</mat-error>
                      <mat-error *ngIf="editPaymentInfoForm?.controls?.cardNumber?.errors?.required">
                        {{ errorMessages.cardNumber.required }}</mat-error>
                      <mat-error *ngIf="editPaymentInfoForm?.controls?.cardNumber?.errors?.invalidCardType">
                        {{ errorMessages.cardNumber.invalidCardType }}</mat-error>
                    </mat-form-field>
                    <div><span class=" form-group col-md-12 creditCardImg"></span></div>
                    <div class="padding_top_5">
                      <mat-checkbox #myCheckbox (change)="isTermsConditionChange($event)"  color="primary">
                  </mat-checkbox>
                    <b  class="padding_left_5">Agree to the <a href="javascript:void(0);" style="cursor:pointer" (click)="getTermsAndConditionModel()">Terms and Conditions</a></b>
                  </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12">
                    <mat-form-field appearance="outline" class="material-input-full-width">
                      <mat-label><b>Month</b></mat-label>
                      <mat-icon matPrefix>today</mat-icon>
                      <mat-select [disabled]="!isPolicySelected" formControlName="expMonth" id="expMonth" required>
                        <mat-option value="">Month</mat-option>
                        <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="editPaymentInfoForm.controls.expMonth.errors && editPaymentInfoForm.controls.expMonth.errors.required">
                        {{ errorMessages.expMonth.required }}</mat-error>
                    </mat-form-field>
                    <br />
                    <mat-form-field appearance="outline" class="material-input-full-width">
                      <mat-label><b>Year</b></mat-label>
                      <mat-icon matPrefix>today</mat-icon>
                      <mat-select [disabled]="!isPolicySelected" formControlName="expYear" id="expYear" required>
                        <mat-option value="">Year</mat-option>
                        <mat-option *ngFor="let year of expirationYears" [value]="year && year.toString()">{{ year }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="editPaymentInfoForm.controls.expYear.errors && editPaymentInfoForm.controls.expYear.errors.required">
                        {{ errorMessages.expYear.required }}</mat-error>
                      <mat-error
                        *ngIf="editPaymentInfoForm.controls.expYear.errors && editPaymentInfoForm.controls.expYear.errors.expired">
                        {{ errorMessages.expYear.expired }}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <br />
              <div class="row justify-content-center" *ngIf="!isGroupPayroll">
                <div class="col-lg-6 col-md-6 col-sm-12 text-center pt-2">
                  <button [disabled]="!canSubmit()" class="custom-account-button mobile-button" color="primary"
                    id="save" mat-raised-button type="submit">{{ content.buttons.save }}</button>
                  <!--<button mat-raised-button type="button" id="cancel" color="primary" class="custom-account-button mobile-button" (click)="goBack()">{{ content.buttons.cancel }}</button>-->
                  <br />
                </div>
              </div>
              <div class="row justify-content-center" *ngIf="!isGroupPayroll">
                <div class="col-lg-6 col-md-6 col-sm-12 text-center pt-4">
                  <a href="javascript:void(0);" (click)="goBack()" class="link">Cancel</a>
                </div>
              </div>
              <div class="row justify-content-center" *ngIf="isGroupPayroll">
                <div class="col-lg-6 col-md-6 col-sm-12 text-center pt-2">
                  <button [disabled]="!canSubmit()" class="custom-account-button mobile-button" color="primary"
                    id="save" mat-raised-button type="submit">Next</button>
                  <!--<button mat-raised-button type="button" id="cancel" color="primary" class="custom-account-button mobile-button" (click)="goBack()">{{ content.buttons.cancel }}</button>-->
                  <br />
                </div>
              </div>
              <div class="row justify-content-center" *ngIf="isGroupPayroll">
                <div class="col-lg-6 col-md-6 col-sm-12 text-center pt-4">
                  Questions? Contact a Member Care representative at <b>800-540-2016.</b>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div> 

  <div *ngIf="isCancelled || isExpired">
    <div class="container-title">
      <app-navigation-back (navigateBackEvent)="goToAccount()" class="pull-left d-inline  pl-1 d-md-none"
        id="upperBackButton"></app-navigation-back>
      <h1 class="fw-bold" id="edit-payment-header">
        Looks like there's been a faux paws.
      </h1>
    </div>
    <hr style="border: 1px solid grey" />
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <mat-card appearance="outlined">
          <mat-card-content class="pt-3 pb-3">
            <p>
              <strong>Our records indicate that the policy you are trying to update is currently
                {{ isCancelled ? 'cancelled' : 'expired' }}.</strong>
            </p>
            <p>
              {{ content.validations.registerAccount.errorMessage }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<div class="container pb-5" *ngIf="showConfirmation">
  <div class="container-title">
    <app-navigation-back id="upper-BackButton" (navigateBackEvent)="goToMyPets()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">Make a Payment</h1>
  </div>
  <hr style="border: 1px solid grey" />
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <!-- <p *ngIf="installmentPlan === 'Annual'">By authorizing this payment, you agree to make your pay in full payment using the payment
          information below. Pressing submit will complete the transaction and charge your account.</p>
        <p *ngIf="installmentPlan === 'Monthly'">By authorizing this payment, you agree to make all future monthly payments using the payment
          information below. Pressing submit will complete the transaction and charge your account.</p>  -->
        <p>By authorizing this payment, you agree to make your premium payment(s) as outlined on the previous page using the payment information below.  
          Pressing submit will complete the transaction and charge your account</p>
        <mat-card-content>
          <p><strong>Please confirm your payment information</strong></p>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <p>Total Payment Today: <span class="float-end"><strong>{{ installmentAmount | currency }}</strong></span>
              </p>
              <p>Payment Type: <span
                  class="float-end"><strong>{{ editPaymentInfo && setPaymentType(payMethodCode)}}</strong></span></p>
              <p>Card Type: <span class="float-end"><strong>{{editPaymentInfo &&  setPayMethod(editPaymentInfo?.acctType) }}</strong></span>
              </p>
              <div *ngIf="!editPaymentInfo?.ABANo">
                <p>Card Number: <span class="float-end"><strong>{{ acctNo}}</strong></span></p>
                <p>Expiration Date: <span
                    class="float-end"><strong>{{ editPaymentInfo && setExpirationDate(editPaymentInfo?.cardExpDate) }}</strong></span></p>
              </div>
              <div *ngIf="editPaymentInfo?.ABANo">
                <p>Account Type: <span
                    class="float-end"><strong>{{setPayMethod(editPaymentInfo?.acctType) | titlecase}}</strong></span></p>
                <p>Account Number: <span class="float-end"><strong>{{acctNo}}</strong></span></p>
                <p>ABA Number: <span class="float-end"><strong>{{ abaNo }}</strong></span></p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12" style="border-left: 1px solid black">
              <p>Name: <span class="float-end"><strong>{{ editPaymentInfo?.firstName | titlecase }}
                    {{ editPaymentInfo?.lastName | titlecase }}</strong></span></p>
              <p>Address: <span class="float-end"><strong>{{ editPaymentInfo?.address1 | titlecase }}</strong></span></p>
              <p *ngIf="editPaymentInfo?.Address2">Address 1: <span
                  class="float-end"><strong>{{editPaymentInfo?.address2 | titlecase}}</strong></span></p>
              <p>City: <span class="float-end"><strong>{{ editPaymentInfo?.city | titlecase}}</strong></span></p>
              <p>State: <span class="float-end"><strong>{{ editPaymentInfo?.state }}</strong></span></p>
              <p>Zip Code: <span class="float-end"><strong>{{ editPaymentInfo?.zip }}</strong></span></p>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p class="no_bottom_margin"><strong>Importance of validation</strong></p>
              <p>Submitting inaccurate payment information may result in delays, coverage gaps or unforseen billing errors.</p>
              <br />
              <p class="no_bottom_margin"><strong>Need to update your payment information?</strong></p>
              <p>You can edit your payment information here<a class="custom_link" href="javascript:void(0);" (click)="navigateToEditPayment(editPaymentInfo.PolicyNumber)"> edit your payment information</a>
              </p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <br />
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-navigation-back id="Back-Button" (navigateBackEvent)="goBack()"
            class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
          <button mat-raised-button type="submit" color="primary"
            class="custom-login-button float-end mobile-button" (click)="submitPayrolltoDirect()">Submit</button>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 text-center pt-4">
          Questions? Contact a Member Care representative at <b>800-540-2016.</b>
        </div>
      </div>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"> </app-progress-spinner>