import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-two-option-modal',
  templateUrl: './two-option-modal.component.html',
  styleUrls: ['./two-option-modal.component.scss']
})
export class TwoOptionModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
