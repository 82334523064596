import {Component, OnInit} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidationConstants} from '../../../shared/constants/form-validation-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {SettingsService} from '../../../shared/services/settings.service';
import {AuthService} from '../../../core/services/auth.service';
import * as _ from 'lodash';
import {ChangePasswordRequest} from '../../../models/request/change-password-request';
import {first} from 'rxjs/operators';
import {CommonService} from '../../../shared/services/common.service';
import {LoginRequest} from '../../../models/request/login-request';
import {LoginResponse} from '../../../models/response/login-response';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import { FullstoryService } from '../../../shared/services/fullstory.service';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {
  public content = CONTENT;
  public errorMessages = CONTENT.settings.password.errorMessages;
  public labels = CONTENT.settings.password.labels;
  public placeHolders = CONTENT.settings.password.placeHolders;
  public lengthValidation = FormValidationConstants.lengthValidation;
  public patternValidation = FormValidationConstants.patternValidation;
  public routes = ROUTINGCONSTANTS;
  public editPasswordForm: UntypedFormGroup;
  public changePasswordRequest: ChangePasswordRequest = {};
  public confirmed = false;
  public userId: string;
  public loginId: string;
  public editError = '';
  public forgotPasswordRoute: string;
  public hide = true;
  public loginRequest: LoginRequest = {};

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private gtmService: GtmEventsService,
              private settingsService: SettingsService,
              private commonService: CommonService,
              private fullstoryService: FullstoryService,
              private authService: AuthService) {
    this.getRouteFromParams();
  }

  getRouteFromParams() {
    this.route.params.subscribe((params) => {
      this.forgotPasswordRoute = params['route'];
      this.loginId = params['loginId'];
    });
  }

  get formControl() {
    return this.editPasswordForm.controls;
  }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    this.createForm();
  }

  canSubmit() {
    return this.editPasswordForm.valid;
  }

  setFormValidators() {
    return [Validators.required, Validators.maxLength(this.lengthValidation.passwordMaxLength), Validators.minLength(this.lengthValidation.passwordMinLength)];
  }

  createForm(): void {
    this.editPasswordForm = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        newPassword: ['', this.setFormValidators()],
        confirmNewPassword: ['', this.setFormValidators()]
      },
      {validator: this.customValidators}
    );
  }

  customValidators(fg: UntypedFormGroup): any {
    let errors = {};
    const confirmPasswordErrors = fg.get('confirmNewPassword').errors || {};

    const newPassword = fg.get('newPassword').value;
    const confirmPassword = fg.get('confirmNewPassword').value;

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      errors['passwordMismatch'] = true;
      confirmPasswordErrors['passwordMismatch'] = true;
    } else {
      errors = null;
      delete confirmPasswordErrors['passwordMismatch'];
    }
    const confirmEmpty = _.isEmpty(confirmPasswordErrors);
    fg.get('confirmNewPassword').setErrors(confirmEmpty ? null : confirmPasswordErrors);
    fg.get('confirmNewPassword').markAsDirty();
    return errors;
  }

  onNavigateBack() {
    this.router.navigateByUrl(this.routes.settings);
  }

  populateDocument() {
    if (!this.userId) {
      this.userId = this.loginId;
    }
    this.changePasswordRequest.oldPwd = this.editPasswordForm.value['currentPassword'];
    this.changePasswordRequest.newPwd = this.editPasswordForm.value['newPassword'];
    this.changePasswordRequest.loginId = this.userId;
    return this.changePasswordRequest;
  }

  onSubmit() {
    if (this.canSubmit()) {
      this.settingsService.changePassword(this.populateDocument())
        .pipe(first())
        .subscribe((data) => {
            if (data['code'] === null) {
              this.setChangePassword();
            } else {
              this.setValidationError(data['code']);
            }
            this.setGTMEvent(data);
          }
        );
    }
  }

  setGTMEvent(data) {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Form Submit Button',
      eventLabel: 'Change Password Submit Button',
      eventValue: `New Password For: ${this.userId}`,
      userId: sessionStorage.getItem('userId'),
      error: {
        code: data ? data['code'] : '',
        message: data ? data['developerMessage'] : ''
      }
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  setValidationError(code) {
    if (code === '88') {
      this.commonService.setValidationErrorModal(this.content.validations.settings.changePassword.invalidOldPwd);
    } else {
      this.commonService.setValidationErrorModal(this.content.validations.maintenanceError);
    }
  }

  setLoginDetails() {
    this.loginRequest.loginId = this.userId;
    this.loginRequest.password = this.editPasswordForm.value['newPassword'];
    return this.loginRequest;
  }


  setChangePassword() {
    this.setLoginDetails();
    this.editError = '';
    if (this.forgotPasswordRoute === 'loginPage') {
      this.authService.authenticate(this.setLoginDetails())
        .pipe(first())
        .subscribe((response: LoginResponse) => {
          if (response.jwt) {
            this.router.navigate([this.routes.home]);
          }
        });
    } else {
      this.authService.authenticate(this.setLoginDetails());
      this.commonService.setConfirmationModal({header: this.content.settings.password.confirmation.body}).subscribe({
        next: () => {
          this.router.navigate([this.routes.settings]);
        }
      });
    }
  }

  getEditErrorText() {
    let text = this.content.settings.password.editError.generic;
    if (this.editError && this.editError.includes('Invalid old password')) {
      text = this.content.settings.password.editError.currentPassword;
    }
    return text;
  }

  isShowForm() {
    return !this.confirmed;
  }
}
