export const FormValidationConstants = {
  patternValidation: {
    addressLine1: '^[a-zA-Z0-9.-][a-zA-Z0-9. ]*[a-zA-Z0-9. ]*$',
    addressLine2: '^[a-zA-Z0-9.-][a-zA-Z0-9. ]*[a-zA-Z0-9. ]*$',
    city: '^([a-zA-Z]+|[a-zA-Z]+\\s[a-zA-Z]+)*$', //([a-zA-Z]+|[a-zA-Z]+\\s[a-zA-Z]+)',    
    email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
    firstName: '^[a-zA-Z0-9]+([ -][a-zA-Z0-9]+)*$',
    name: '^[a-zA-Z0-9]+([ -][a-zA-Z0-9]+)*$',
    lastName: '^[a-zA-Z0-9]+([ -][a-zA-Z0-9]+)*$',
    phoneNumber: '^[0-9-0-9-0-9]{12}$',
    zipCode: '^[0-9]{5}(?:-[0-9]{4})?$',
    accountRouting: '^[0-9]*$',
    creditCard: '^[0-9]*$',
    loginID: '^[A-Za-z0-9@_.]+$',
    password: '^[A-Za-z0-9@_.]+$',
    securityQA: '^[A-Za-z0-9 ]+$',
    loginIdRegex: '[0-9a-zA-Z.@_]{6,64}',
    passwordRegex: '[0-9a-zA-Z~!@#$:%^&*-_/|?,.]{8,65}',
    specialCharacters: '^([a-zA-Z0-9\\s]{6,80})$',
    emailPattern: '[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+'
  },
  lengthValidation: {
    addressLine1Length: 30,
    addressLine2Length: 30,
    emailLength: 50,
    firstNameLength: 60,
    lastNameLength: 60,
    nameLength: 60,
    phoneMinLength: 10,
    phoneMaxLength: 10,
    phoneNumberLength: 12,
    policyNumLength: 24,
    zipCodeMinLength: 5,
    zipCodeMaxLength: 5,
    accountMaxLength: 50,
    accountMinLength: 8,
    routingLength: 9,
    ccMinLength: 15,
    ccMaxLength: 16,
    loginIDMaxLength: 64,
    loginIDMinLength: 6,
    secQueMinLength: 6,
    passwordMinLength: 6,
    passwordMaxLength: 65,
    securityQuestionMinLength: 6,
    securityQuestionMaxLength: 80,
    securityAnswerMaxLength: 80
  }
};