import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {FormValidationConstants} from '../../constants/form-validation-constants';
import {CONTENT} from '../../../content-management/content';
import {IMAGECONSTANTS} from '../../constants/image-constants';
import {ForgotIdService} from './forgot-id.service';
import {first} from 'rxjs/operators';
import {ROUTINGCONSTANTS} from '../../constants/routing-constants';
import {GtmEventsService} from '../../services/gtm-events.service';
import {CommonService} from '../../services/common.service';
import {ForgotIdRequest} from '../../../models/request/forgot-id-request';
import { FullstoryService } from '../../services/fullstory.service';

@Component({
  selector: 'app-forgot-id',
  templateUrl: './forgot-id.component.html',
  styleUrls: ['./forgot-id.component.scss']
})
export class ForgotIdComponent implements OnInit {
  public forgotIdForm: UntypedFormGroup;
  public isSubmitted = false;
  private formValidation = FormValidationConstants;
  public content = CONTENT;
  public imageConstants = IMAGECONSTANTS;
  public routingConstants = ROUTINGCONSTANTS;
  public forgotIdRequest: ForgotIdRequest = {};

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private forgotIdService: ForgotIdService,
              private gtmService: GtmEventsService,
              private fullstoryService: FullstoryService,
              private commonService: CommonService) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.forgotIdForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.formValidation.patternValidation.email), Validators.maxLength(this.formValidation.lengthValidation.emailLength)]]
    });
  }

  get formDetails() {
    return this.forgotIdForm.controls;
  }

  populateDocument() {
    this.forgotIdRequest.email = this.forgotIdForm.value['email'];
    return this.forgotIdRequest;
  }

  setGTMEvent() {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Forgot ID Submit Button Click',
      eventLabel: 'Forgot ID Submit Button',
      eventValue: `Submitted Email ID: ${this.forgotIdRequest.email}`,
      userId: sessionStorage.getItem('userId'),
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  onSubmit() {
    const content = this.content.validations;
    this.isSubmitted = true;
    this.forgotIdService
      .getLoginId(this.populateDocument())
      .pipe(first())
      .subscribe({
        next: () => {
          this.setGTMEvent();
          this.commonService.setConfirmationModal({
              header: 'Thank you. If you previously created an online account associated with this email address, you will receive your' +
                ' login ID. Please adjust your spam filter settings to ensure you can receive emails from vpiportal@petinsurance.com.',
              content: `If you do not receive an email from us, you may need to create an account. For further assistance,
               please contact Member Care at 844 - 397 - 8938. Our Member Care team is available Monday through Friday
               from 5 a.m.to 7 p.m. and Saturday from 7 a.m.to 3: 30 p.m. (Pacific)`
            })
            .subscribe({
              next: () => this.router.navigate([this.routingConstants.login])
            });
        },
        error: () => {
          this.commonService.setValidationErrorModal(content.maintenanceError);
        }
      });
  }

  navigateToLoginPage() {
    this.router.navigate([this.routingConstants.login]);
  }

  navigateToRegistration() {
    this.router.navigate([this.routingConstants.registration]);
  }
}
