import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  public cardProfileUrl = environment.cardProfileUrl;

  public renBaseUrl = environment.renbaseUrl;

  constructor(private authService: AuthService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue;
    const accessToken = sessionStorage.getItem('access_token');
    const XNWMessageId = sessionStorage.getItem('XNWMessageId');
    const isLoggedIn = this.authService.isLoggedIn();
    const cardProfileUrlCondition = req.url.indexOf(this.cardProfileUrl) === 0; 
    const renBaseUrlCondition = req.url.indexOf(this.renBaseUrl) === 0;
    const rencurrentUser = 'Bearer '+sessionStorage.getItem('rencurrentUser');    
    const validateToken = req.url.toLowerCase().indexOf('validateenterprisejwt') !== -1;
    const isRenAccountsUrl = req.url.indexOf(environment.accountsUrl) === 0; 
    const isSplunkLogging = req.url.toLowerCase().indexOf('client-logs-cloud') !== -1;
    const isOTEDocumentUrl = req.url.indexOf('documents')!== -1; 
    const isPivotEobDocumentUrl = req.url.indexOf('pet-php-policies/v1/documents')!== -1; 
    const isApplicationUrl = req.url.indexOf('pet-application/v1/address')!== -1; 
    const isEUAUrl = req.url.indexOf('/enterprise-user-auth')!== -1; 
    if (!validateToken && !isSplunkLogging) {
      if ((renBaseUrlCondition || isRenAccountsUrl) && isLoggedIn) {
        req = req.clone({
          setHeaders: {
            Authorization: `${rencurrentUser}`,
          }
        });
      } else if (!isApplicationUrl && !cardProfileUrlCondition && !isOTEDocumentUrl && !isEUAUrl && currentUser && isLoggedIn) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,            
            jwt: `${currentUser}`,
            'X-NW-Message-Id': XNWMessageId,
            client_id: environment.client_id     
          }
        });
      } else if(isOTEDocumentUrl) {
        if(isPivotEobDocumentUrl){
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
              'X-NW-Message-Id': XNWMessageId,
              client_id: environment.client_id,
              jwt: `${currentUser}` 
            }
          }); 
        }else{
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
              'X-NW-Message-Id': XNWMessageId,
              client_id: environment.client_id 
            }
          }); 
        }     
      }
    }

    return next.handle(req);
  }
}
