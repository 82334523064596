<div class="container pb-5">
  <div class="container-title">
    <app-navigation-back id="seeDetails-backbutton" (navigateBackEvent)="goBack()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{ content.claimsPage.allClaims.heading1 }}</h1>
  </div>
  <hr style="border: 1px solid grey"/>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-md-5 col-lg-5 col-sm-12">
          <div class="media">
            <div class="media-left media-middle pr-3">
              <!-- <app-pet-profile-picture *ngIf="setCanineImage(claim?.policyNumber)" imageAlt="dogInfo" imageId="img-blue-dog"
                                       [imageUrl]="iconObj.canineIcon"></app-pet-profile-picture>
              <app-pet-profile-picture *ngIf="!setCanineImage(claim?.policyNumber)" imageAlt="dogInfo" imageId="img-blue-cat"
                                       [imageUrl]="iconObj.felineIcon"></app-pet-profile-picture> -->
              <app-pet-profile-picture imageAlt="dogInfo" imageId="img-blue-cat"
                                       [imageUrl]="petSpeciesUrl"></app-pet-profile-picture>
            </div>
            <div class="media-body" style="margin: auto">
              <h4 class="pet-name">{{ name | titlecase }}</h4>
              <p>Policy#: <b>{{ claim?.policyNumber | titlecase }}</b></p>
            </div>
          </div>
          <br/>
        </div>
        <div class="col-md-7 col-lg-7 col-sm-12">
          <div class="claim-details-right">
            <p>{{ content.claimsPage.allClaims.diagnosis }}:<span class="float-end">
              <strong *ngIf="isInProcess()">{{ content.claimsPage.pending }}</strong>

              <div *ngIf="!isInProcess()">
              <strong >{{claimDetails[0]?.diagnosisDescription | titlecase}}</strong>
              <!-- <strong *ngIf="!isRENPolicy()">{{ !isInProcess() && claim?.diagnosisDesc ? (claim?.diagnosisDesc | titlecase) : content.claimsPage.pending }}</strong> -->
                <a href="javascript:void(0);"  (click)="openDialog()" *ngIf="claimDetails[0]?.diagnosisDescription" class="custom-modal d-none d-md-inline ms-2" id="more-diagnosis"><u>view more</u></a>
               </div>
              </span>
            </p>
            <p>{{ content.claimsPage.allClaims.treatmentDate }}:<span
              class="float-end"><strong>{{ claim?.treatmentDate ? (claim?.treatmentDate | date) : content.claimsPage.pending }}</strong></span></p>
            <p>
              {{ content.claimsPage.allClaims.submittedDate }}:<span class="float-end"><strong>{{ claim?.reportedDate ? (claim?.reportedDate | date) : content.claimsPage.pending }}</strong></span>
            </p>
            <p>
              {{ content.claimsPage.allClaims.plan }}:<span class="float-end"><strong>{{ (plan | titlecase) || content.claimsPage.pending }}</strong></span>
            </p>
            <p>
              {{ content.claimsPage.allClaims.claimNumber }}:<span class="float-end"><strong>{{ claim?.claimNumber ? claim?.claimNumber : content.claimsPage.pending }}</strong></span>
            </p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <br/>
  <div class="row">
    <div class="col-md-6">
      <mat-card appearance="outlined" class="custom_mat_card">
        <h5 *ngIf="claim?.status !== 'Complete'" class="fw-bold">{{ content.claimsPage.recentClaims.whatHappened }}</h5>
        <h5 *ngIf="claim?.status === 'Complete'" class="fw-bold">{{ content.claimsPage.recentClaims.recap }}</h5>
        <mat-card-content class="pt-3 pb-3">
          <p>{{ content.claimsPage.allClaims.claimStatus}}<span class="float-end"><b>{{ checkClaimStatus(claim?.status) }}</b></span>
          </p>
          <p>{{ content.claimsPage.allClaims.submittedAmount}}<span
            class="float-end"><b>{{ checkForAmount(claim?.status, claim?.totalClaimAmount) }}</b></span>
          </p>
          <p>{{ content.claimsPage.allClaims.deductibleApplied}}
            <span class="float-end"><b>{{ getDeductibleApplied() && !isInProcess() ? (getDeductibleApplied() | currency) : content.claimsPage.pending }}</b></span>
            <!-- <span *ngIf="!isRENPolicy() && claimDetails" class="float-end"><b>{{ (claimDetails[0]?.deductibleAmount | currency) }}</b></span> -->
          </p>
          <span *ngIf="!isInProcess() && claimDetails?.length">
            <p>As of <b>{{ claim?.eOBDate | date }}</b></p>
            <p>
              {{ content.claimsPage.allClaims.paid}}<span class="float-end"><b>{{ checkForAmount(claim?.status, claim?.totalPaidAmount) }}</b></span>
            </p>
          </span>
        </mat-card-content>
      </mat-card>
      <br/>
    </div>
    <div *ngIf="claim?.status !== 'Complete'" class="col-md-6">
      <mat-card appearance="outlined" class="custom_mat_card">
        <h5 class="fw-bold">{{ content.claimsPage.recentClaims.faqs }}</h5>
        <br/>
        <mat-card-content>
          <ol>
            <li>{{ content.claimsPage.recentClaims.faq1 }}</li>
            <span class="pb-1"><a (click)="navigateToFAQs(content.claimsPage.recentClaims.faq1, hyperlinkConstants.faqs.faq1)"
                                  href="{{ hyperlinkConstants.faqs.faq1 }}" target="_blank">{{ content.claimsPage.recentClaims.seeAnswer }}</a></span>
            <li>{{ content.claimsPage.recentClaims.faq2 }}</li>
            <span><a (click)="navigateToFAQs(content.claimsPage.recentClaims.faq2, hyperlinkConstants.faqs.faq2)" href="{{ hyperlinkConstants.faqs.faq2 }}"
                     target="_blank">{{ content.claimsPage.recentClaims.seeAnswer }}</a></span>
            <li>{{ content.claimsPage.recentClaims.faq3 }}</li>
            <span><a (click)="navigateToFAQs(content.claimsPage.recentClaims.faq3, hyperlinkConstants.faqs.faq3)" href="{{ hyperlinkConstants.faqs.faq3 }}"
                     target="_blank">{{ content.claimsPage.recentClaims.seeAnswer }}</a></span>
          </ol>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="claim?.status === 'Complete'" class="col-md-6">
      <mat-card appearance="outlined" class="custom_mat_card">
        <h5 class="fw-bold">{{ content.claimsPage.recentClaims.details }}</h5>
        <mat-card-content class="pt-3 pb-3">
          <p *ngIf="claim?.paymentType === 'Check'">We completed your claim and mailed you a check on <b>{{ claim?.eOBDate | date }}</b>. Thanks for taking such great care of your pet!</p>
          <p *ngIf="claim?.paymentType === 'Electronic'">We completed your claim and sent payment electronically on <b>{{ claim?.eOBDate | date }}</b>. Please allow up to two business days for
            notification
            from Chase. Thanks for taking such great care of your pet!</p>
          <p *ngIf="getPaidAmount() === 0">We completed your claim. Please refer to your Explanation of Benefits (EOB) for details regarding how your claim was processed.</p>
          <br/>
          <a *ngIf="claim?.status === 'Complete'" (click)="getEobDocURL()" class="custom_cursor" id="pivotURL">{{ content.claimsPage.recentClaims.benefits }} <span class="float-right"><mat-icon>insert_drive_file</mat-icon></span></a>
          <br/><div *ngIf="claim?.status === 'Complete'" class="p-1" style="margin-top: 20px;"><p><b><u>Please note:</u></b>{{ content.claimsPage.recentClaims.completeClaim }}</p></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <app-navigation-back id="backbutton" (navigateBackEvent)="goBack()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>

<ng-template #diagnosisModal>
  <mat-dialog-content>
    <table id="custom-table">
      <thead>
      <tr>
        <th class="explanation">Diagnosis Code</th>
        <th class="left">Description</th>
        <th>Submitted Expenses</th>
        <th>Eligible Amount</th>
        <th>Deductible</th>
        <th>Reimbursement Amount</th>
        <th class="d-none d-md-table-cell">Reason Code</th>
        <th class="left explanation">Explanation</th>
      </tr>
      </thead>
      <tbody *ngFor="let claimDetail of claimDetails">
      <tr>
        <td class="explanation">{{ claimDetail.diagnosisCode }}</td>
        <td class="left">{{ claimDetail.diagnosisDescription }}</td>
        <td class="text-right">{{ claimDetail.submittedExpense | currency }}</td>
        <td class="text-right">{{ claimDetail.eligibleAmount | currency }}</td>
        <td class="text-right">{{ claimDetail.deductibleAmount | currency }}</td>
        <td class="text-right">{{ claimDetail.reimbursementAmount | currency }}</td>
        <td class="d-none d-md-table-cell">{{ claimDetail.reasonCode }}</td>
        <td class="left explanation">{{ claimDetail.reasonDescription }}</td>
      </tr>
      </tbody>
    </table>
  </mat-dialog-content>
</ng-template>
