<div class="container">
  <div *ngIf="isPendingRenewal">
    <div class="container-title">
      <app-navigation-back id="upperBackButton" (navigateBackEvent)="goToMyPets()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
      <h1 class="fw-bold">Looks like there's been a faux paws</h1>
    </div>
    <hr style="border: 1px solid grey" />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <mat-card appearance="outlined">
          <mat-card-content>
            <p><strong>Our records indicate that your pet's policy has a pending Policy Renewal in process.</strong></p>
            <br>
            <p>Please contact our Member Care Department at <b>844-397-8938</b> to complete your request. Our Member
              Care team is available Monday-Friday 5am-7pm and Saturday 7am-3:30pm (Pacific).
            </p>
          </mat-card-content>
          <mat-card-actions>
            <div class="row justify-content-center">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <app-navigation-back id="back-button" (navigateBackEvent)="goToMyPets()"
                  class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
              </div>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

  <div *ngIf="!showConfirmation && !isPendingRenewal" class="container pb-5">
    <div class="container-title">
      <app-navigation-back id="upperBackButton" (navigateBackEvent)="goToMyPets()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
      <h1 class="fw-bold">Make a Payment</h1>
    </div>
    <hr style="border: 1px solid grey" />
    <form [formGroup]="makePaymentForm">
      <div *ngIf="makePaymentForm.controls['paymentMode'].value === 'entire'" class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <mat-card appearance="outlined">
            <mat-card-content>
              <p><strong>Your payment plan will change from monthly to annual and will no longer automatically
                  renew.</strong></p>
              <p><b>Note:</b> By selecting pay entire balance, you are opting to pay the remaining balance due for the
                annual policy term.</p>
            </mat-card-content>
          </mat-card>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <mat-card appearance="outlined" class="custom_mat_card">
            <p class="no_bottom_margin fw-bold">Please select a payment amount:</p>
            <span><small>Note: Please allow up to 48 hours for your payment to be processed.</small></span>
            <mat-card-content class="pb-3 pt-3">
              <div class="payment-type">
                <mat-radio-group formControlName="paymentMode" name="paymentMode">
                   <!-- eslint-disable-next-line @angular-eslint/template/accessibility-label-for, @angular-eslint/template/accessibility-label-has-associated-control -->
                  <label class="payment_item" *ngIf="petInfo?.pastDue">
                    <mat-radio-button value="pastDue"></mat-radio-button>
                    <div class="payment_info">
                      <p class="no_bottom_margin"><strong>Pay past due amount</strong></p>
                      <span>Payment amount: {{ pastDueAmount?.policyDuesResponse?.current | currency }}</span>
                    </div>
                  </label>
                   <!-- eslint-disable-next-line @angular-eslint/template/accessibility-label-for, @angular-eslint/template/accessibility-label-has-associated-control -->
                  <label class="payment_item">
                    <mat-radio-button value="entire"></mat-radio-button>
                    <div class="payment_info">
                      <p class="no_bottom_margin"><strong>Pay entire amount</strong></p>
                      <span>Payment amount: {{ pastDueAmount?.policyDuesResponse?.balance | currency }}</span>
                    </div>
                  </label>
                </mat-radio-group>
              </div>
            </mat-card-content>
          </mat-card>
          <br />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <mat-card appearance="outlined" class="custom_mat_card">
            <p class="fw-bold">Please confirm your policy information</p>
            <mat-card-content class="pb-3 pt-3">
              <p>Pet Name: <span class="float-end"><strong>{{ petInfo?.pet.name | titlecase }}</strong></span></p>
              <p>Policy Number: <span class="float-end"><strong>{{petInfo?.policyNumber}}</strong></span></p>
              <p>Effective Date: <span class="float-end"><strong>{{ petInfo?.effectiveDate | date}}</strong></span>
              </p>
              <p>Expiration Date: <span class="float-end"><strong>{{ petInfo?.expirationDate | date}}</strong></span>
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <br />
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <br />
          <mat-card appearance="outlined">
            <mat-card-content>
              <p class="no_bottom_margin"><strong>Wrong Policy?</strong></p>
              <span>Return to <a href="javascript:void(0);" class="custom_link" (click)="routeToEditPayment()"><u>account overview</u></a> to
                select the correct policy.</span>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-navigation-back id="back-button" (navigateBackEvent)="goToMyPets()"
            class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
          <button mat-raised-button type="submit" color="primary" class="custom-login-button float-end mobile-button"
            (click)="goToPaymentConfirmation()" [disabled]="!makePaymentForm.valid">Next</button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="showConfirmation && !isPendingRenewal" class="container pb-5">
    <div class="container-title">
      <app-navigation-back id="upper-BackButton" (navigateBackEvent)="goToMyPets()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
      <h1 class="fw-bold">Make a Payment</h1>
    </div>
    <hr style="border: 1px solid grey" />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <mat-card appearance="outlined">
          <p>By authorizing this payment, you agree to have all future monthly payments deducted using the payment
            information below. Pressing submit will charge your account.</p>
          <mat-card-content>
            <p><strong>Please confirm your payment information</strong></p>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <p>Payment Amount: <span class="float-end"><strong>{{ selectedPayment | currency }}</strong></span>
                </p>
                <p>Payment Type: <span
                    class="float-end"><strong>{{ setPaymentType(paymentInfo.payMethod)}}</strong></span></p>
                <p>Card Type: <span class="float-end"><strong>{{setPayMethod(paymentInfo.acctType) }}</strong></span>
                </p>
                <div *ngIf="!paymentInfo?.abaNo">
                  <p>Card Number: <span class="float-end"><strong>{{ paymentInfo.maskedAcctNo}}</strong></span></p>
                  <p>Expiration Date: <span
                      class="float-end"><strong>{{ setExpirationDate(paymentInfo.cardExpDate) }}</strong></span></p>
                </div>
                <div *ngIf="paymentInfo?.abaNo">
                  <p>Account Type: <span
                      class="float-end"><strong>{{setPayMethod(paymentInfo.acctType) | titlecase}}</strong></span></p>
                  <p>Account Number: <span class="float-end"><strong>{{ paymentInfo.maskedAcctNo}}</strong></span></p>
                  <p>ABA Number: <span class="float-end"><strong>{{ paymentInfo?.abaNo }}</strong></span></p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12" style="border-left: 1px solid black">
                <p>Name: <span class="float-end"><strong>{{ paymentInfo.firstName | titlecase }}
                      {{ paymentInfo.lastName | titlecase }}</strong></span></p>
                <p>Address: <span class="float-end"><strong>{{ paymentInfo.address1 | titlecase }}</strong></span></p>
                <p *ngIf="paymentInfo?.address2">Address 1: <span
                    class="float-end"><strong>{{paymentInfo.address2 | titlecase}}</strong></span></p>
                <p>City: <span class="float-end"><strong>{{ paymentInfo.city | titlecase}}</strong></span></p>
                <p>State: <span class="float-end"><strong>{{ paymentInfo.state }}</strong></span></p>
                <p>Zip Code: <span class="float-end"><strong>{{ paymentInfo.zip }}</strong></span></p>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <p class="no_bottom_margin"><strong>Importance of validation</strong></p>
                <p>Any inaccurate information that is submitted may cause delays or unforseen billing errors.</p>
                <br />
                <p class="no_bottom_margin"><strong>Wrong payment information?</strong></p>
                <p>You can <a href="javascript:void(0);" class="custom_link" (click)="navigateToEditPayment()">edit your payment information</a>
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <br />
        <div *ngIf="petInfo.authInd!=='Y'" class="padding_top_5 justify-content-center">
                      
          <mat-checkbox #myCheckbox (change)="isTermsConditionChange($event)"  color="primary">
      </mat-checkbox>
        <b  class="padding_left_5">Agree to the <a href="javascript:void(0);" style="cursor:pointer" (click)="getTermsAndConditionModel()">Terms and Conditions</a></b>
      </div>
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <app-navigation-back id="Back-Button" (navigateBackEvent)="goBack()"
              class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
            <button mat-raised-button [disabled]="!canSubmit()"type="submit" color="primary"
              class="custom-login-button float-end mobile-button" (click)="submitValidate()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>
