<!-- eslint-disable @angular-eslint/template/accessibility-label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/accessibility-label-for -->
<!-- eslint-disable @angular-eslint/template/mouse-events-have-key-events -->
<div class="container pb-5">
  <div class="container-title">
    <h1 class="fw-bold">Please complete your pet profile before proceeding</h1>
  </div>
  <hr style="border: 1px solid grey">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <mat-card-content>
          <div *ngIf="currentPet">
            <p>Looks like we're missing a few details about your pet. Please give us a little more info on {{currentPet.petName}} so we can keep our records up to date.</p>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="petinfoForm">
                  <mat-error *ngIf="isSubmitted && formValidator.age.errors && formValidator.age.errors.required">This field is required</mat-error>
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <mat-select formControlName="age" class="material-input-form">
                      <mat-option value="">Please select one</mat-option>
                      <mat-option *ngFor="let age of ages" [value]="age">{{age}}</mat-option>
                    </mat-select>
                    <mat-label><b>How old is {{currentPet.petName}}?</b></mat-label>
                  </mat-form-field>
                  <br>
                  <mat-error *ngIf="isSubmitted && formValidator.dogType.errors && formValidator.dogType.errors.required">This field is required</mat-error>
                  <mat-radio-group *ngIf="petType === 'Canine'" formControlName="dogType" name="dogType" aria-label="Is your pet purebred or mixed?">
                    <mat-label class="checkbox_label"><b>Is  {{currentPet.petName}} purebred or mixed?</b>
                      <span class="pl-3">
                      <mat-radio-button (change)="radioChange($event)" value="pure">Purebred &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button (change)="radioChange($event)" value="mixed">Mixed</mat-radio-button>
                    </span>
                    </mat-label>
                  </mat-radio-group>
                  <br>
                  <mat-error *ngIf="isSubmitted && formValidator.catType.errors && formValidator.catType.errors.required">This field is required</mat-error>
                  <mat-radio-group *ngIf="petType === 'Feline'" formControlName="catType" name="catType" aria-label="Is your pet purebred or mixed?">
                    <mat-label class="checkbox_label"><b>Is  {{currentPet.petName}} purebred or mixed?</b>
                      <span class="pl-3">
                      <mat-radio-button (change)="radioChange($event)" value="pure">Purebred &nbsp;&nbsp;</mat-radio-button>
                      <mat-radio-button (change)="radioChange($event)" value="mixed">Mixed</mat-radio-button>
                    </span>
                    </mat-label>
                  </mat-radio-group>
                  <mat-error *ngIf="isSubmitted && breedType === 'mixed' && petType === 'Canine' &&  formValidator.dogSize.errors && formValidator.dogSize.errors.required">This field is required
                  </mat-error>
                  <fieldset *ngIf="petType === 'Canine' && breedType !== '' && breedType === 'mixed'" class="form-group mt-3 secondTier"
                            id="dogSizeQuestion">
                    <legend for="dogSize"><strong>How big is your dog?</strong><span class="text-muted"> (When your dog is fully grown.)</span>
                    </legend>
                    <div class="form-row text-center">
                      <div class="col-lg-2 col align-self-end my-3">
                        <svg-icon src="/assets/images/mpp/toy-dog.svg" id="icon-toy-dog" [applyCss]="true" class="petIcon" role="image"
                                  alt="Toy Dog Icon"></svg-icon>
                        <br>
                        <label for="Toy" class="petIconToy my-1 label-autosize"><strong>Toy</strong></label><br>
                        <span class="text-muted" id="smDogWt">10 lbs. or less</span><br>
                        <label class="radio-btn-container radio-label-vertical">
                          <input type="radio" formControlName="dogSize" value="Toy"
                                 class="radioCircle " id="toy" name="dogSize">
                          <span class="checkmark"></span>
                          <label class="sr-only">Toy Dog Radio Button</label>
                        </label>
                      </div>
                      <div class="col-lg-2 col align-self-end my-3">
                        <svg-icon src="/assets/images/mpp/small-dog.svg" id="icon-small-dog" [applyCss]="true" class="petIcon" role="image" alt="Small Dog Icon"></svg-icon>
                        <br>
                        <label for="Small" class="petIconSmall my-1 label-autosize"><strong>Small</strong></label><br>
                        <span class="text-muted">11 - 30 lbs.</span><br>
                        <label class="radio-btn-container radio-label-vertical">
                          <input type="radio" formControlName="dogSize" value="Small"
                                 class="radioCircle " id="small" name="dogSize">
                          <span class="checkmark"></span>
                          <label class="sr-only">Small Dog Radio Button</label>
                        </label>
                      </div>
                      <div class="col-lg-2 col align-self-end my-3">
                        <svg-icon src="/assets/images/mpp/medium-dog.svg" [applyCss]="true" id="icon-medium-dog" class="petIcon" role="image" alt="Medium Dog Icon"></svg-icon>
                        <br>
                        <label for="Medium" class="petIconMedium my-1 label-autosize"><strong>Medium</strong></label><br>
                        <span class="text-muted">31 - 50 lbs.</span><br>
                        <label class="radio-btn-container radio-label-vertical">
                          <input type="radio" formControlName="dogSize" value="Medium"
                                 class="radioCircle " id="medium" name="dogSize">
                          <span class="checkmark"></span>
                          <label class="sr-only">Medium Dog Radio Button</label>
                        </label>
                      </div>
                      <div class="col-lg-2 col align-self-end my-3">
                        <svg-icon src="/assets/images/mpp/large-dog.svg" [applyCss]="true" id="icon-large-dog" class="petIcon" role="image" alt="Large Dog Icon"></svg-icon>
                        <br>
                        <label for="Large" class="petIconLarge my-1 label-autosize"><strong>Large</strong></label><br>
                        <span class="text-muted">51 - 110 lbs.</span><br>
                        <label class="radio-btn-container radio-label-vertical">
                          <input type="radio" formControlName="dogSize" value="Large"
                                 class="radioCircle " id="large" name="dogSize">
                          <span class="checkmark"></span>
                          <label class="sr-only">Large Dog Radio Button</label>
                        </label>
                      </div>
                      <div class="col-lg-2 col align-self-end my-3">
                        <svg-icon src="/assets/images/mpp/very-large-dog.svg" [applyCss]="true" class="petIcon" id="icon-very-large-dog" role="image" alt="Very Large Dog Icon"></svg-icon>
                        <br>
                        <label for="VeryLarge" class="petIconVeryLarge my-1 label-autosize" id="vLgDogLabel"><strong>Very Large</strong></label><br>
                        <span class="text-muted" id="vLgDogWt">111 lbs. or greater</span><br>
                        <label class="radio-btn-container radio-label-vertical">
                          <input type="radio" formControlName="dogSize" value="Very Large" class="radioCircle " id="veryLarge" name="dogSize">
                          <span class="checkmark"></span>
                          <label class="sr-only">Very Large Dog Radio Button</label>
                        </label>
                      </div>
                    </div>
                    <!-- </div> -->
                  </fieldset>

                  <mat-error *ngIf="isSubmitted && breedType === 'mixed' && petType === 'Feline' &&  formValidator.catSize.errors && formValidator.catSize.errors.required">This field is required
                  </mat-error>
                  <fieldset *ngIf="petType === 'Feline' && breedType !== '' && breedType === 'mixed'" class="form-group mt-3 secondTier"
                            id="catSizeQuestion">
                    <legend for="catSize"><strong>Which best describes your cat?</strong></legend>
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-3 col align-self-end my-3">
                          <svg-icon src="/assets/images/mpp/short-hair-cat.svg" [applyCss]="true" class="petIcon" id="icon-short-hair-cat" role="image" alt="Short Hair Cat Icon"></svg-icon>
                          <br>
                          <label for="catShort" id="shortHairLabel" class="label-autosize my-2"><strong>Short
                            Hair</strong></label><br>
                          <label class="radio-btn-container radio-label-vertical">
                            <input type="radio" value="Short Hair" id="catShort" formControlName="catSize" name="catSize">
                            <span class="checkmark"></span>
                            <label class="sr-only">Short Hair Cat Radio Button</label>
                          </label>
                        </div>
                        <div class="col-lg-3 col align-self-end my-3">
                          <svg-icon src="/assets/images/mpp/medium-hair-cat.svg" [applyCss]="true" class="petIcon" id="icon-medium-hair-cat" role="image" alt="Medium Hair Cat Icon"></svg-icon>
                          <br>
                          <label for="catMedium" id="medHairLabel" class="label-autosize my-2"><strong>Medium
                            Hair</strong></label><br>
                          <label class="radio-btn-container radio-label-vertical">
                            <input type="radio" class="radioCircle " value="Medium Hair" formControlName="catSize" id="catMedium" name="catSize">
                            <span class="checkmark"></span>
                            <label class="sr-only">Medium Hair Cat Radio Button</label>
                          </label>
                        </div>
                        <div class="col-lg-3 col align-self-end my-3">
                          <svg-icon src="/assets/images/mpp/long-hair-cat.svg" [applyCss]="true" class="petIcon" id="icon-long-hair-cat" role="image" alt="Long Hair Cat Icon"></svg-icon>
                          <br>
                          <label for="catLarge" id="longHairLabel" class="label-autosize my-2"><strong>Long Hair</strong></label><br>
                          <label class="radio-btn-container radio-label-vertical">
                            <input type="radio" class="radioCircle " value="Long Hair" id="catLarge" name="catSize" formControlName="catSize">
                            <span class="checkmark"></span>
                            <label class="sr-only">Long Hair Cat Radio Button</label>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <mat-error *ngIf="isSubmitted && breedType === 'pure' && petType === 'Canine' &&  formValidator.dogBreed.errors && formValidator.dogBreed.errors.required">This field is required
                  </mat-error>
                  <mat-form-field *ngIf="breedType === 'pure' && petType === 'Canine'" class="material-input-full-width pt-1" appearance="outline">
                    <mat-select formControlName="dogBreed" class="material-input-form">
                      <mat-option value="">Please select one</mat-option>
                      <mat-option *ngFor="let breed of breedList" [value]="breed.code">{{breed.name  | titlecase}}</mat-option>
                    </mat-select>
                    <mat-label><b>What breed?</b></mat-label>
                  </mat-form-field>

                  <mat-error *ngIf="isSubmitted && breedType === 'pure' && petType === 'Feline' &&  formValidator.catBreed.errors && formValidator.catBreed.errors.required">This field is required
                  </mat-error>
                  <mat-form-field *ngIf="breedType === 'pure' && petType === 'Feline'" class="material-input-full-width pt-1" appearance="outline">
                    <mat-select formControlName="catBreed" class="material-input-form">
                      <mat-option value="">Please select one</mat-option>
                      <mat-option *ngFor="let breed of breedList" [value]="breed.code">{{breed.name | titlecase}}</mat-option>
                    </mat-select>
                    <mat-label><b>What breed?</b></mat-label>
                  </mat-form-field>
                  <br>
                  <mat-error *ngIf="isSubmitted &&  formValidator.petGender.errors && formValidator.petGender.errors.required">This field is required</mat-error>
                  <mat-radio-group formControlName="petGender" name="petGender" aria-label="Is your pet purebred or mixed?">
                    <mat-label class="checkbox_label"><b>What gender is  {{currentPet.petName}}?</b>
                      <span class="pl-3">
                      <mat-radio-button value="true">Male &nbsp;&nbsp;</mat-radio-button>
                      <mat-radio-button value="false">Female</mat-radio-button>
                    </span>
                    </mat-label>
                  </mat-radio-group>
                  <br>
                  <mat-error *ngIf="isSubmitted && formValidator.petColor.errors && formValidator.petColor.errors.required">This field is required</mat-error>
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <mat-select formControlName="petColor" class="material-input-form">
                      <mat-option value="">Please select one</mat-option>
                      <mat-option *ngFor="let color of colorsList" [value]="color.code">{{color.colorName | titlecase}}</mat-option>
                    </mat-select>
                    <mat-label><b>What color is  {{currentPet.petName}}?</b></mat-label>
                  </mat-form-field>

                  <mat-error *ngIf="isSubmitted && formValidator.petNature.errors && formValidator.petNature.errors.required">This field is required</mat-error>
                  <mat-radio-group formControlName="petNature" name="petNature" aria-label="Is your pet purebred or mixed?">
                    <mat-label class="checkbox_label"><b>Is {{currentPet.petName}} spayed/neutered?</b>
                      <span class="pl-3">
                       <mat-radio-button value="true">Yes &nbsp;&nbsp;</mat-radio-button>
                       <mat-radio-button value="false">No</mat-radio-button>
                    </span>
                    </mat-label>
                  </mat-radio-group>
                  <br>

                  <div class="row">
                    <div class="col-md-6">
                      <mat-error *ngIf="isSubmitted && formValidator.petRegHospital.errors && formValidator.petRegHospital.errors.required">This field is required</mat-error>
                      <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                        <mat-select formControlName="petRegHospital" (selectionChange)="selectHospital($event);" class="material-input-form">
                          <mat-option value="" disabled selected>(Please Select One)</mat-option>
                          <mat-option value="Never Visited">Never Visited</mat-option>
                          <mat-option value="Not one of These">Not One of These</mat-option>
                          <mat-option value="My Hospital is not listed" hidden>My Hospital is not listed</mat-option>
                          <mat-option *ngFor="let item of providersList" value="{{item.providerCode}}">
                            {{item.name | titlecase}} - {{ item.distance | number: '1.1-1' }}m
                          </mat-option>
                        </mat-select>
                        <mat-label><b>Which veterinary hospital do you usually use?</b></mat-label>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <a href="javascript:void(0);" class="maplink" (click)="toggleMap()">map veterinary hospital list</a>
                    </div>
                  </div>
                </form>
                <div class="hosp_sub_questions">
                  <div class="subquestions" *ngIf="hospitalselected === 'Not one of These'">
                    <p>To view more options, please enter additional information below</p>
                    <form [formGroup]="searchForm">
                      <div>
                        <label for="hospital" class="sr-only">Hospital</label>
                        <input placeholder="Hospital Name" type="text" id="hospital" class="form-control mb-3" aria-labelledby="hospital" formControlName="hospital">
                        <label for="City" class="sr-only">City</label>
                        <input placeholder="City" type="text" id="city" class="form-control mb-3" aria-labelledby="city" formControlName="city">
                        <label for="state" class="sr-only">State</label>
                        <select class="form-control my-3 col-md-4" id="state" aria-labelledby="state" formControlName="state">
                          <option value="">Select State</option>
                          <option *ngFor="let item of statesList" value="{{item.code}}">{{item.name}}
                          </option>
                        </select>
                      </div>
                      <div>
                        <button (click)="getProviderList(searchForm.value.state, searchForm.value.city,'2')" class="btn btn-primary default-btn">Search</button>
                      </div>
                    </form>
                  </div>
                  <div class="locations_data" [hidden]="!mapstatus">
                    <div class="locations_des">
                       <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
                      <div class="locations_close" (click)="setMapStatus()">Close</div>
                      <p>Hospitals are located within 7 miles from the center of the zip code you have entered.<br/>Although you may
                        use any licensed veterinarian of your choice, please select the hospital where you
                        currently take {{currentPet.name}}. You are not limited to this particular hospital in the future.</p>
                      <p class="text-center">Click on the pin within the map to see additional information about the
                        hospitals.</p>
                    </div>
                    <div class="row">
                      <div class="maps-widget col-sm-8 p-1">
                        <!-- <agm-map [zoom]="11"
                                 [latitude]="lat"
                                 [longitude]="lng"
                                 [fullscreenControl]="true"
                                 [mapTypeControl]="true"
                                 (mapClick)="mapClick()">

                          <agm-marker *ngFor="let m of providersList; let i = index"
                                      [latitude]="m.latitude"
                                      [longitude]="m.longitude"
                                      [label]="{color: 'black', text: (i+1).toString()}"
                                      (markerClick)="markerClick(iw)">
                            <agm-info-window #iw
                                             [isOpen]="isInfoWindowOpen(i+1)">
                              <p class="map-info">{{m.name}}
                                <br/>{{m.street1}}, {{m.city}}, {{m.stateCode}}, {{m.zipCode}}
                                <br/>{{m.phone }}
                              </p>
                              <button (click)="selectmapLocation(m.providerCode)">Select this location</button>
                            </agm-info-window>
                          </agm-marker>
                        </agm-map> -->
                        <google-map [zoom]="11" [latitude]="lat" [longitude]="lng" [fullscreenControl]="true" [mapTypeControl]="true"
                          (mapClick)="mapClick()">
                          <map-marker *ngFor="let m of providersList; let i = index" [latitude]="m.latitude" [longitude]="m.longitude"
                            [label]="{color: 'black', text: (i+1).toString()}" (markerClick)="markerClick(iw)">
                            <map-info-window #iw [isOpen]="isInfoWindowOpen(i+1)">
                              <p class="map-info">{{m.name}}
                                <br />{{m.street1}}, {{m.city}}, {{m.stateCode}}, {{m.zipCode}}
                                <br />{{m.phone }}
                              </p>
                              <button (click)="selectmapLocation(m.providerCode)">Select this location</button>
                            </map-info-window>
                          </map-marker>
                        </google-map>
                      </div>
                      <div class="col-sm-4 p-1">
                        <div class="locations_list container">
                          <ol class="list-inline pb-0">
                            <li *ngIf="!checkForSelectedHospital()" class="pr-3"><a href="javascript:void(0);" (click)="selectmapLocation('Never Visited')">- Never Visited </a></li>
                            <li *ngIf="!checkForSelectedHospital()" class="pr-3"><a href="javascript:void(0);" (click)="selectmapLocation('Not one of These')">- Not One of These </a></li>
                            <li *ngIf="checkForSelectedHospital()" class="pr-3"><a href="javascript:void(0);" (click)="selectmapLocation('My Hospital is not listed')">- My Hospital is not listed </a></li>
                            <ol class="p-3 mt-0">
                               <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
                              <li (click)="selectmapLocation(item.providerCode)" (mouseover)="openWindow(i+1)" (mouseleave)="closeWindow(i)" *ngFor="let item of providersList; let i = index;">
                                <a>{{item.name}}
                                  ({{ item.distance | number: '1.1-1' }}m)</a>
                              </li>
                            </ol>
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class="mapDesc">Maps/Directions are information only. User assumes all risk of use. Google,
                        Nationwide and their suppliers make no representations or warranties about content, road conditions,
                        route usability, or speed.. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <b>Submitted responses are final, so please double-check your answers before proceeding. </b>
                <button mat-raised-button type="button" color="primary" (click)="onSubmit()" class="custom-cancel-button float-end">Submit</button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
