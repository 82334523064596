import { AccountInfo } from '../../models/account-info';
import { AccountService } from '../../shared/services/account.service';
import { BillingPaymentService } from '../../shared/services/billing-payment.service';
import { Component, OnInit } from '@angular/core';
import { CONTENT } from '../../content-management/content';
import { GtmEventsService } from '../../shared/services/gtm-events.service';
import { PREFERENCES } from '../../shared/constants/preferences';
import { PaymentFormData } from '../../models/payment-form-data';
import { PolicyDetails } from '../../models/policy-details';
import { Router } from '@angular/router';
import { ROUTINGCONSTANTS } from '../../shared/constants/routing-constants';
import {HYPERLINKSCONSTANTS} from '../../shared/constants/hyperlinks-constants';
import { MyPetsService } from '../my-pets/my-pets.service';
import { AccountResponse } from '../../models/response/account-response';
import { CommonService } from '../../shared/services/common.service';
import { provideNgxMask } from 'ngx-mask';
import { MatDialog } from '@angular/material/dialog';
import { TwoOptionModalComponent } from '../../shared/components/two-option-modal/two-option-modal.component';
import { AccountRequest } from '../../models/account-request';
import {ContentfulService} from '../../shared/services/contentful.service';
import CONTENTFULIDS from '../../shared/constants/contentful-ids';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  providers: [provideNgxMask()]
})
export class AccountComponent implements OnInit {
  public content = CONTENT;
  public routingConstants = ROUTINGCONSTANTS;
  public hyperlinkConstants = HYPERLINKSCONSTANTS;
  public preferences = PREFERENCES;
  public ecpElected = false;
  public paymentData: PaymentFormData;
  public personalData: AccountInfo;
  public policyDetailsList: PolicyDetails[] = [];
  public userId: string;
  public isValidPayment = true;
  public displayProgressSpinner = 0;
  public isECPCheckboxDisplayed = false;
  public rxclaimOptin: string;
  public isPreferenceDisabled = true;
  public blueMoneyImage;
  public blueLeafImage;
  public contentfulIds = CONTENTFULIDS;
  public preferencesChanged = false;
  public cancelPreferences;
  public saveFlag = false;

  constructor(private accountService: AccountService,
              private billingPaymentService: BillingPaymentService,
              private gtmService: GtmEventsService,
              private router: Router,
              private commonService: CommonService,
              private myPetsService: MyPetsService,
              private contentfulService: ContentfulService,
              private dialog: MatDialog) {
    //this.getPaymentInfo();
  }

  ngOnInit(): void {
    this.getAccountInfo();
    this.getOptInInfo();
    this.getECP();
    this.getInitialLoad();
    this.getContentfulImage();
  }
  getInitialLoad(){
  if(sessionStorage.getItem('policyList')){
    if(this.displayProgressSpinner !== 0){
      this.subtractSpinner();
    }
    this.getPaymentInfo();
  }else{
    if(this.displayProgressSpinner == 0){
      this.addSpinner();
    }
    setTimeout(() => { 
    this.getInitialLoad();
    },250);
  }
}

  displayECPCheckbox() {
    this.isECPCheckboxDisplayed = true;
  }

  confirmCancelECP(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(TwoOptionModalComponent, {
      disableClose: true,
      data: {
        header: 'Are you sure you want to cancel receiving electronic claim payments?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelECP();
      }
    });
  }

  cancelECP() {
    const accountRequest: AccountRequest = {
      accountInfo: {
        ...this.personalData,
        streetAddress1: this.personalData.address1,
        streetAddress2: this.personalData.address2
      },
      updateType: 'ELECTRONICPAYMENTPREF'
    };

    this.addSpinner();
    // this.accountService.updateAccountInfo(accountRequest, memberId, accountId).subscribe({
    this.accountService.updateMemberDetails(accountRequest.updateType, accountRequest).subscribe({
      next: response => {
        if (!response.some(x => x['messageId'] !== 0)) {
          this.commonService.setConfirmationModal({
            header: 'You will no longer receive electronic claim payments.'
          });
          this.ecpElected = false;
          this.isECPCheckboxDisplayed = false;
        } else {
          this.commonService.setValidationErrorModal(response.some(x => x['code'] === 400 || x['code'] === 402) ? 'Our records indicate that your pet’s policy has one or' +
            ' more changes still pending.' : 'Error updating contact information.');
        }
      },
      error: () => {
        this.commonService.setDialogModal({
          header: 'Error updating contact information.'
        });
      },
      complete: () => this.subtractSpinner()
    });
  }

  getECP() {
    this.addSpinner();
    const accountId = sessionStorage.getItem('activeAccountId'); 
    this.accountService.getECP(accountId).subscribe({
      next: response => {
        this.ecpElected = response.status;
      },
      complete: () => this.subtractSpinner()
    });
  }

  managePreferencesEdit() {
    this.isPreferenceDisabled = !this.isPreferenceDisabled;
  }

  goToSettings() {
    this.router.navigate([this.routingConstants.settings]);
  }

  navigateToPivot() {
    this.myPetsService.getPolicyDocumentsSso().subscribe(url => {
      if (url.ssoUrl) {
        if (window.innerWidth <= 768) {
          window.open(url.ssoUrl, '_parent');
        } else {
          window.open(url.ssoUrl, '_blank');
        }
      }
    });
  }

  getAccountInfo() {
    this.addSpinner();
    const memberId = sessionStorage.getItem('activeMemberId');
    this.accountService.getAccountInfo(memberId).subscribe({next:(data: AccountResponse) => {
      if (!data.standardMessage) {
        this.personalData = data.accountInfoResponse;
        this.personalData.homePhone = this.personalData.homePhone || '';
        this.personalData.homePhone = this.personalData.homePhone.replace(/[^a-zA-Z0-9]/g, '');
        this.personalData.workPhone = this.personalData.workPhone || '';
        this.personalData.workPhone = this.personalData.workPhone.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        if (data.standardMessage.code === '99') {
          this.commonService.setValidationErrorModal(this.content.validations.AccountInfoError.AccountError);
        }
      }
      setTimeout(() => { 
        this.addSpinner()
        },1000);
    },
     error: error => {
        this.subtractSpinner();
      },
      complete:()=> this.subtractSpinner()
  });
  }

  getOptInInfo() {
    this.accountService.getOptIn().subscribe(data => {
      if (!data.standardMessage) {
        this.preferences[0].response = data.affiliateOptin !== 'N';
        this.preferences[1].response = data.vpiOptin !== 'N';
        this.rxclaimOptin = data.rxclaimOptin;
        this.cancelPreferences = data;
      }       
      // else {
      //   if (data.standardMessage.code === '99') {
      //     this.commonService.setValidationErrorModal(this.content.validations.AccountInfoError.OptInError);
      //   }
      // }
    });
  }

  getPaymentInfo() {
    this.policyDetailsList = JSON.parse(sessionStorage.getItem('policyList'));
  }

  goToHome() {
    this.router.navigate([this.routingConstants.home]);
  }
  
  cancel() {
    if(this.cancelPreferences && !this.cancelPreferences.standardMessage)
    {
      this.preferences[0].response = this.cancelPreferences.affiliateOptin !== 'N';
      this.preferences[1].response = this.cancelPreferences.vpiOptin !== 'N';
    }
    else
    {
      this.preferences[0].response = false;
      this.preferences[1].response = false;
    }
    this.isPreferenceDisabled = !this.isPreferenceDisabled;
  }

  onValidPayment(e) {
    this.isValidPayment = e;
  }

  getContentfulImage() {
    const res = JSON.parse(sessionStorage.getItem('portalImageContenful'));
    if (res) {
      this.blueMoneyImage = res.itemList[4].fields.icon.fields.file.url;
      this.blueLeafImage = res.itemList[0].fields.icon.fields.file.url;
    }
  }

  setPreference(prefText: string): void {
    this.preferencesChanged = true;
    this.preferences = this.preferences.map(pref => {
      if (pref.question === prefText) {
        pref.response = !pref.response;
      }
      return pref;
    });
  }
  
  updateOptIn()
  {  
    if (this.preferencesChanged) 
    {
      this.addSpinner();
      this.saveFlag = false;
      this.accountService.updateOptIn
      ({
        InfoOptIn: this.preferences[0].response ? 'Y' : 'N',
        PromoOptIn: this.preferences[1].response ? 'Y' : 'N'
      })
      .subscribe({
        next: data => {
          this.subtractSpinner();
            if (data.standardMessage && data.standardMessage.code === '99') 
            {
              this.commonService.setDialogModal({
                title: 'Error',
                header: data.standardMessage.developerMessage,
                content: ''
              });
            }
            else
            {
              this.commonService.setConfirmationModal({header: 'Preferences updated successfully.'});
              this.saveFlag = true;
            }
            this.getOptInInfo();
          },
          error: () => {
            this.subtractSpinner();
            this.commonService.setDialogModal({
              title: 'Error',
              header: 'Error updating contact information.',
              content: ''
            });
          }
        });

        if(!this.saveFlag)
        {
          if(this.cancelPreferences && !this.cancelPreferences.standardMessage)
          {
            this.preferences[0].response = this.cancelPreferences.affiliateOptin !== 'N';
            this.preferences[1].response = this.cancelPreferences.vpiOptin !== 'N';
          }
          else
          {
            this.preferences[0].response = false;
            this.preferences[1].response = false;
          }
        }

        this.isPreferenceDisabled = !this.isPreferenceDisabled;
        this.saveFlag = false;
    }   
  }

  canSubmit(): boolean {
    return this.preferencesChanged;
  }
  addSpinner() {
    this.displayProgressSpinner++;
  }

  subtractSpinner() {
    this.displayProgressSpinner--;
  }
}
