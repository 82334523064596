<div class="container text-center">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="navigateToLoginPage()" id='topBackButton' class="d-block d-md-none float-start"></app-navigation-back>
    <h1 class="fw-bold">{{content.registerAccountPage.heading}}</h1>
  </div>
  <hr style="border: 1px solid grey">
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10 col-sm-12"><p class="text-left"><small>{{content.registerAccountPage.info}}</small></p></div>
        </div>
        <mat-card-content>
          <form [formGroup]="registerForm">
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>assignment_ind</mat-icon>
                  <mat-label><b>Policy Number</b></mat-label>
                  <mat-icon matSuffix [matTooltip]="content.validations.registerAccount.tooltip.policyNumber">help</mat-icon>
                  <input matInput id="input-policyNumber" type="text" formControlName="policyNumber" class="material-input-form" [dropSpecialCharacters]="false" [specialCharacters]="[ '-' ]"
                         mask="S-0*" placeholder="Enter Policy Number" required>
                  <mat-error id="policyNumber-error" *ngIf="formValidator.policyNumber.errors && formValidator.policyNumber.errors.required">
                    {{content.validations.registerAccount.policyNumReqError}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput id="input-firstName" type="text" formControlName="firstName" class="material-input-form" placeholder="Enter First Name" required>
                  <mat-label><b>First Name</b></mat-label>
                  <mat-icon matSuffix [matTooltip]="content.validations.registerAccount.tooltip.firstName">help</mat-icon>
                  <mat-error id="firstName-error" *ngIf="formValidator.firstName.errors && formValidator.firstName.errors.required">{{content.validations.registerAccount.firstNameReq}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput id="input-lastName" type="text" formControlName="lastName" class="material-input-form" placeholder="Enter Last Name" required>
                  <mat-label><b>Last Name</b></mat-label>
                  <mat-error id="lastName-error" *ngIf="formValidator.lastName.errors && formValidator.lastName.errors.required">{{content.validations.registerAccount.lastNameReq}}</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>local_shipping</mat-icon>
                  <input matInput id="input-zipCode" type="text" formControlName="zipCode" class="material-input-form" (keypress)="numbersOnly($event)" placeholder="Enter Zip Code" maxlength="5"
                         required>
                  <mat-label><b>Zip Code</b></mat-label>
                  <mat-hint align="end">{{formValidator.zipCode.value?.length || 0}}/5</mat-hint>
                  <mat-error id="zipCode-error">{{setZipCodeError('zipCode')}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>call</mat-icon>
                  <input matInput id="input-phoneNumber" type="text" formControlName="phoneNumber" class="material-input-form" mask="000-000-0000" maxlength="12" placeholder="Enter Phone number"
                         required>
                  <mat-label><b>Phone Number</b></mat-label>
                  <mat-icon matSuffix [matTooltip]="content.validations.registerAccount.tooltip.phoneNumber">help</mat-icon>
                  <mat-error id="phoneNUmber-error">{{setPhoneNumberError('phoneNumber')}}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <div class="row justify-content-center pb-2">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <button mat-raised-button class="custom-mat-button float-end mobile-button" color="primary" type="submit" id="button-next" (click)="onSubmit()" [disabled]="!registerForm.valid">
                {{content.buttons.next}}
              </button>
              <app-navigation-back (navigateBackEvent)="navigateToLoginPage()" id='bottomBackButton' class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
