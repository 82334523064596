import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../../shared/constants/routing-constants';
import * as _ from 'lodash';
import moment from 'moment';
import {MyPetsService} from '../../my-pets.service';
import {CONTENT} from '../../../../content-management/content';
import {MyPets} from '../../../../models/my-pets';
import {GtmEventsService} from '../../../../shared/services/gtm-events.service';
import {BillingPaymentService} from '../../../../shared/services/billing-payment.service';
import {EncrDecrService} from '../../../../core/services/encr-decr.service';

@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.scss']
})
export class RenewalComponent implements OnInit {
  public routingConstants = ROUTINGCONSTANTS;
  public content = CONTENT;
  public policyNumber: any;
  public renewPolicy: MyPets;
  public displayProgressSpinner = 0;
  public paymentAmounts: any;

  constructor(private router: Router,
              private myPetService: MyPetsService,
              private route: ActivatedRoute,
              private encDcrService: EncrDecrService,
              private gtmService: GtmEventsService,
              private billingPaymentService: BillingPaymentService) {
    this.getPolicyNumberFromRoutes();
  }

  ngOnInit() {
  }

  addYear(dateString) {
    if(dateString){
      return moment(dateString)
      .add(1, 'years')
      .format('LL');
    }
    else{
      return '';
    }
  }

  getPolicyNumberFromRoutes() {
    this.route.params.subscribe(params => {
      if (this.policyNumber !== params['policyNumber']) {
        this.policyNumber = this.encDcrService.get( params['policyNumber']);
        this.getPolicyInfo();
      }
    });
  }

  getPolicyInfo() {
    this.displayProgressSpinner++;
    this.billingPaymentService.getPolicyDetail(this.policyNumber).subscribe({
      next: myPet => {
        this.renewPolicy = myPet;
        this.getPaymentAmounts(this.policyNumber);
      },
      complete: () => this.displayProgressSpinner--
    });
  }


  getPaymentAmounts(PolicyNumber: string) {
    this.displayProgressSpinner++;
    this.billingPaymentService.renewalPayments(PolicyNumber, Number(this.renewPolicy.renewalNumber)).subscribe({
      next: response => {
        this.paymentAmounts = response;
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  navigateToBilling(policyNumber) {
    this.router.navigate([this.routingConstants.renewalBilling, this.encDcrService.set(policyNumber)]);
  }

  routeToEditPayment() {
    this.router.navigate([this.routingConstants.account]);
  }

  goToManagePolicy(policyNumber) {
    this.router.navigate([this.routingConstants.managePolicy, this.encDcrService.set(policyNumber)]);
  }
}
