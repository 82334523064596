<div>
  <div class="row text-center">
    <div class="col-6 text-start">
      <h3 class="fw-bold">
        <b>{{ content.accountInfo.payment.header }}</b>
      </h3>
    </div>
    <div *ngIf="isPaymentInfo() && validPayment && paymentData || isGroupPayroll()" class="col-6 text-end">
      <a href="javascript:void(0);" (click)="editDetails()" class="my-auto edit-text" id="edit-payment-icon">
        <b style="font-size: .8rem;">{{ content.accountInfo.edit }} </b>&nbsp; <i class="far fa-edit fa-lg"> </i>
      </a>
    </div>
  </div>
  <hr style="border: 1px solid grey" />
  <div *ngIf="policyDetailsList?.length">
    <form [formGroup]="selectPetForm" *ngIf="selectPetForm && policyNumber">
      <div class="row">
        <div class="col-12 mx-auto">
          <mat-form-field appearance="outline" class="material-input-full-width">
            <mat-label>Pet</mat-label>
            <mat-select (selectionChange)="changePayment($event)" formControlName="policyNumber" id="policyNumber">
              <mat-option *ngFor="let policy of policyDetailsList" [value]="policy.policyNumber">
                {{ policy.pet.name | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
    <br />
    <div *ngIf="paymentData && (paymentData.paymentMethod|uppercase)!=='PAYROLL'">
      <div class=" row mb-2">
        <div class="col-10 text-start">
          <p class="no_bottom_margin">{{ content.accountInfo.paymentMethod }}:</p>
          <span><strong>{{ 'Direct bill' + ' - ' + paymentData.paymentType }}</strong></span>
        </div>
      </div>
      <br />
      <div *ngIf="!isCredit()" class="row mb-2">
        <div class="col-6 text-start text-wrap">
          <div>
            <p class="no_bottom_margin">{{ content.accountInfo.routingNumber }}:</p>
            <span style="text-overflow: ellipsis;"><strong
                class="cc-ellipses">{{ handleMask(paymentData.routingNumber) }}</strong></span>
          </div>
        </div>

        <div class="col-6 text-start text-wrap">
          <p class="no_bottom_margin">{{ content.accountInfo.accountNumber }}:</p>
          <span style="text-overflow: ellipsis;"><strong
              class="cc-ellipses">{{ handleMask(paymentData.accountNumber) }}</strong></span>
        </div>
      </div>
      <br />
      <div class="row mb-2">
        <div class="col-6 text-start">
          <div *ngIf="isCredit() && !isCheck()">
            <p class="no_bottom_margin">{{ content.accountInfo.cardNumber }}:</p>
            <span class="cc-ellipses"><strong>{{ paymentData.cardNumber }}</strong></span>
          </div>
          <div *ngIf="!isCredit()">
            <p class="no_bottom_margin">{{ content.accountInfo.accountType }}:</p>
            <span class="cc-ellipses"
              style="text-overflow: ellipsis;"><strong>{{ normalizeAccountType(paymentData.accountType) | titlecase }}</strong></span>
          </div>
        </div>
        <div class="col-6 text-start">
          <div *ngIf="isCredit() && !isCheck()">
            <span>
              <p class="no_bottom_margin">{{ content.accountInfo.expiration }}:</p>
              <div style="text-overflow: ellipsis;white-space: nowrap;">
                <strong>{{ paymentData.expDate }}</strong>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(!paymentData || (paymentData && (paymentData.paymentMethod|uppercase)==='PAYROLL') )&& isBillingInfoRetreived">
      <div class=" row mb-2">
        <div class="col-10 text-start">
          <p class="no_bottom_margin">
            Payment Method:
            <span class="float-end">
              <strong>Payroll Deduction</strong>
            </span>
          </p>
        </div>
      </div>
      <div class=" row mb-2">
        <div class="col-10 text-start">
          <p class="no_bottom_margin">
            Annual Premium:
            <span class="float-end">
              <strong>{{ policy?.premiumInstallmentAmount | currency }}</strong>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <br/>
  <div  *ngIf="!isGroupPayroll() && isBillingInfoRetreived" class="row col-md-12">
    <div class="pt-2 col-md-6 text-center">
      <button (click)="goToMakeAPayment(policyNumber)" color="primary" mat-raised-button type="button">
        Make a payment
         <!-- for {{selectedPetName}} -->
      </button>
    </div>
    <div class="pt-2 col-md-6 text-center">
      <button (click)="goToPaymentHistory()" color="primary" mat-raised-button type="button">
        Payment History
      </button>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"> </app-progress-spinner>