<div class="container">
  <div class="container-title">
    <!--    <app-navigation-back (navigateBackEvent)="navigateToHome()" class="pull-left d-inline  pl-1 d-md-none arrow_mobile">-->
    <!--    </app-navigation-back>-->
    <h1 class="fw-bold">{{content.fileNewClaim.heading}}</h1>
  </div>

  <hr style="border: 1px solid grey"/>
  <form *ngIf="startNewClaimForm" [formGroup]="startNewClaimForm" name="startNewClaimForm">
    <div class="row">
      <div class="col-11 col-md mx-auto mr-md-3">
        <div class="field-wrapper">
          <h5 class="font-placeholder"><b>Who is this Claim for?</b></h5>
          <br>
          <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
            <mat-label><b>Select Pet</b></mat-label>
            <mat-select formControlName="petName" id="select-pet" [(value)]="selectedPolicy" required>
              <mat-option *ngFor="let policy of policyDetailsList" [value]="policy.policyNumber">
                {{policy.pet.name | titlecase}}
              </mat-option>
            </mat-select>
            <mat-error>{{content.fileNewClaim.errorMessages.selectThePet}}</mat-error>
          </mat-form-field>
        </div>
        <section class="recent-claims-list">
          <hr>
          <div>
            <h5 class="font-placeholder"><b>{{content.fileNewClaim.tellUsWhatHappened}}:</b></h5>
          </div>
          <hr>
          <div class="field-wrapper">
            <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
              <input matInput formControlName="hospitalName" id="hospital-Name"
                     placeholder="Please enter your Clinic/Pharmacy" required>
              <mat-label>Clinic/Pharmacy</mat-label>
              <mat-error>{{content.fileNewClaim.errorMessages.hospitalName}}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
              <mat-label>Reason for Visit</mat-label>
              <mat-select id="reason-For-Visit" (selectionChange)="hideDiagnosisField()" multiple formControlName="reasonForVisit" required>
                <mat-option *ngFor="let reason of reasons" [value]="reason">{{reason}}</mat-option>
              </mat-select>
              <mat-error>{{content.fileNewClaim.errorMessages.reasonForVisit}}</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="hideTextArea" class="field-wrapper">
            <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
              <textarea matInput id="diagnosis-Details" formControlName="diagnosisDetails"
                        placeholder={{content.fileNewClaim.question}} required></textarea>
              <mat-label>Diagnosis Details</mat-label>
              <mat-error>{{content.fileNewClaim.errorMessages.diagnosisDetails}}</mat-error>
            </mat-form-field>
          </div>
        </section>
      </div>
      <div class="col-11 col-md mx-auto mr-md-3">
        <section>
          <div class="field-wrapper">
            <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
              <input matInput maxlength="10" [max]="maxDate" id="treatmentDate-From" formControlName="treatmentDateFrom" [matDatepicker]="picker2"
                     placeholder="mm/dd/yyyy" onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')" required>
              <mat-label>Treatment Date from</mat-label>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <mat-error>{{content.fileNewClaim.errorMessages.treatmentDateFrom}}</mat-error>
            </mat-form-field>
          </div>
          <div class="field-wrapper">
            <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
              <input matInput maxlength="10" [min]="startNewClaimForm.controls.treatmentDateFrom.value" [max]="maxDate" id="treatmentDate-To" formControlName="treatmentDateTo"
                     [matDatepicker]="picker1" onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                     placeholder="mm/dd/yyyy" required>
              <mat-label>Treatment Date to</mat-label>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error>{{content.fileNewClaim.errorMessages.treatmentDateTo}}</mat-error>
            </mat-form-field>
          </div>
          <div>

            <div class="field-wrapper">
              <h5 class="font-placeholder"><b>{{content.fileNewClaim.invoicesTotal}}</b></h5>
              <p class="font-placeholder sub-text-label">{{content.fileNewClaim.invoiceDescription}}</p>
              <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
                <mat-icon matPrefix>$</mat-icon>
                <input matInput typemode="number" pattern="[0-9]+(\.[0-9][0-9]?)?" mask="separator.2" thousandSeparator="," formControlName="itemizedInvoices" id="itemized-Invoices"
                       placeholder="000.00"  required>
                <mat-label>Enter the Total Amount</mat-label>
                <mat-error>{{content.fileNewClaim.errorMessages.invoicesTotal}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </section>

        <h5 class="font-placeholder"><b>{{content.fileNewClaim.uploadInvoice}}<span style=color:red;>*</span></b></h5>
        <mat-hint>{{content.fileNewClaim.imageRestriction}}</mat-hint>
        <hr>
        <div class="container">
          <div class="upload-container d-none d-md-block">
            <label for="file-upload" id="file-explorer" class="custom-file-upload">
              <i class="fa fa-cloud-upload" type="file"></i> Upload Invoice(s)
            </label>
            <input type="file" id="file-upload" multiple tabindex="0" (change)="fileUploads($event)"
              accept=".jpeg, .jpg, .png, .pdf, .tiff, .tif" required>
          </div>

          <div class="upload-container d-md-none">
            <label for="file-upload-mobile" id="take-photo" class="custom-file-upload">
              <i class="fa fa-camera" type="file"></i> Take Photo
            </label>
            <input type="file" id="file-upload-mobile" multiple (change)="fileUploads($event)" required>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div *ngIf="uploadedFiles.length > 0">
      <h5>Uploaded Files</h5>
      <table class="table table-bordered">
        <thead class="thead-light">
        <th>File name</th>
        <th>Delete</th>
        </thead>
        <tbody>
        <tr *ngFor="let image of images | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }">
          <td>{{image.name}}</td>
          <td><a href="javascript:void(0)" (click)="deleteImage(image)"><i class="fa fa-trash"></i></a></td>
        </tr>
        </tbody>
      </table>
      <br>
      <div class="row">
        <div class="justify-content-center d-flex">
          <pagination-controls (pageChange)="currentPage = $event" [(page)]="currentPage" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"></pagination-controls>
        </div>
      </div>
    </div>
    <br>
    <div>
      <div class="text-center">
        <mat-checkbox formControlName="termsAndConditions" id="checkForTerms" (click)="openDialog($event,false)"  color='primary' class="font-placeholder">
          <p (ngModelChange)="preventClick($event)" (click)="openDialog($event,true)" class="no_bottom_margin electText">
          View and Accept Terms and Conditions</p>
      </mat-checkbox>
      <p>{{content.fileNewClaim.viewTerms}}</p>
      </div>
    </div>
    <br>
    <div class="row justify-content-center pb-5">
      <div class="col-lg-12 col-md-12 col-sm-12 text-center">
        <button mat-raised-button class="custom-claim-button mobile-button" color="primary" type="submit" id="button-next" (click)="onSubmit()" [disabled]="(!startNewClaimForm.valid) || (images.length <= 0)">
          {{content.fileNewClaim.submitClaim}}
        </button>
      </div>
    </div>
  </form>
</div>


<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>


