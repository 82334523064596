<div *ngIf="ecpContent && ecpContent.body && ecpContent.headers" class="container pb-5">
  <div class="container-title">
    <app-navigation-back id="upperBackButton" (navigateBackEvent)="goBack()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{ ecpContent.headers.main }}</h1>
  </div>
  <hr style="border: 1px solid grey"/>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <p class="mb-0" [innerHTML]="ecpContent.body.main"></p>
        <div>
          <mat-list>
            <mat-list-item *ngFor="let item of ecpContent.body.list">
              <img id="helipline" src="{{blueCheckmark}}" width="25px" style="padding-right: 3px;" alt="blueCheckmark" />
              {{ item }}
            </mat-list-item>
          </mat-list>
          <div class="application-progress"></div>
          <div id="main-container">
            <div class="row justify-content-center col-lg-2 col-md-12 col-sm-12 img-content-center"> <img  src="{{blueDocumentIcon}}" alt="progress bar"/><p class="text-content">Submit your claim</p></div>
            <div class="row justify-content-center col-lg-2 col-md-12 col-sm-12 img-content-center"> <img  class="arrow-right" src="{{blueArrowIcon}}" alt="progress bar"/></div>
            <div class="row justify-content-center col-lg-2 col-md-12 col-sm-12 img-content-center"> <img  class="text-center" src="{{bluePhoneIcon}}" alt="progress bar"/><p class="text-content">Get a text or email notification</p></div>
            <div class="row justify-content-center col-lg-2 col-md-12 col-sm-12 img-content-center"> <img  class="arrow-right" src="{{blueArrowIcon}}" alt="progress bar"/></div>
            <div class="row justify-content-center col-lg-3 col-md-12 col-sm-12 img-content-center"> <img  src="{{blueMoneyIcon}}" alt="progress bar"/><p class="text-content">Click to get your reimbursement</p></div>
          </div>
          <div class="mb-3">
            <app-electronic-claims-payments-form></app-electronic-claims-payments-form>
          </div>
        </div>
        <h6>{{ ecpContent.headers.faq }}</h6>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let faq of ecpContent.body.faqs">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ faq.title }}</mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="faq.body"></div>
          </mat-expansion-panel>
        </mat-accordion>
        <br>
        <p class="more-info" [innerHTML]="ecpContent.body.moreInfo"></p>
      </mat-card>
    </div>
  </div>
  <app-navigation-back id="lowerBackButton" (navigateBackEvent)="goBack()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
</div>
