<div class="my-pet-wrapper container">
  <div class="pets-title">
    <app-navigation-back id='manage-policy-backbutton' (navigateBackEvent)="goToPets()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
    <h5 class="fw-bold">{{content.pets.managePolicy.heading}}</h5>
  </div>
  <hr style="border: 1px solid grey">
  <mat-card appearance="outlined">
    <div class="slider_content container">
      <div class="row">
        <div class="col-md-6">
          <div class="left--section">
            <img *ngIf="checkForPetType(managePolicy?.policyNumber)" alt="dogInfo"  id="img-blue-dog" src="{{ petSpeciesUrl }}" height="250px" width="250px"/>
            <!-- <img *ngIf="!setCanineImage(managePolicy?.policyNumber)" alt="dogInfo" id="img-blue-cat" src="{{ iconObj.felineIcon }}" height="250px" width="250px"/> -->
            <div>
              <h4>{{managePolicy.pet.name | titlecase}}</h4>
              <p>{{content.pets.managePolicy.policy}} {{managePolicy.policyNumber}}</p>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-5">
          <div class="coverage_section">
            <p>{{content.pets.managePolicy.effective}} <span class="float-end"><b>{{managePolicy.effectiveDate | date}}</b></span></p>
            <p>{{content.pets.managePolicy.expiry}} <span class="float-end"><b>{{managePolicy.expirationDate | date}}</b></span></p>
            <p>{{content.pets.managePolicy.deductible}} <span class="float-end"><b>{{managePolicy.deductible | currency}}</b></span></p>
          </div>
        </div>
        <div class="col-md-7">
          <div class="anchor-links">
            <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
            <p *ngIf="managePolicy.renewalStatus === 'RNW'" (click)="goToRenewal(managePolicy.policyNumber)">{{content.pets.managePolicy.renewPolicy}} </p>
            <hr *ngIf="managePolicy.renewalStatus === 'RNW'">
            <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
            <p *ngIf="showCancelPolicy" (click)="goToCancel(managePolicy.policyNumber, managePolicy.renewalNumber)">{{content.pets.managePolicy.cancelPolicy}} </p>
            <hr *ngIf="showCancelPolicy">
            <p><a href="javascript:void(0);" class="view-manage-policy" (click)="navigateToViewPolicy()">{{content.pets.managePolicy.viewPolicies}}</a></p>
          </div>
        </div>
      </div>
      <br>
      <div class="button-wrapper">
        <app-new-claim-button [policyNumber]="managePolicy.policyNumber" [renewalNumber]="managePolicy.renewalNumber"></app-new-claim-button>
      </div>
    </div>
    <br/>
    <div class="row justify-content-center pb-2">
      <div class="col-lg-12 col-md-12 col-sm-12 text-center">
        <app-navigation-back id='backbutton' (navigateBackEvent)="goToPets()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
      </div>
    </div>
  </mat-card>
</div>
