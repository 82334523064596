import {AccountInfo} from '../../../models/account-info';
import {AccountRequest} from '../../../models/account-request';
import {AccountService} from '../../../shared/services/account.service';
import {CommonService} from '../../../shared/services/common.service';
import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidationConstants} from '../../../shared/constants/form-validation-constants';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {TermsOfServiceDialogComponent} from './terms-of-service-dialog/terms-of-service-dialog.component';
import * as _ from 'lodash';
import {AccountResponse} from '../../../models/response/account-response';
import {Location} from '@angular/common';
import { JwtService } from '../../../core/services/jwt.service';

@Component({
  selector: 'app-electronic-claims-payments-form',
  templateUrl: './electronic-claims-payments-form.component.html',
  styleUrls: ['./electronic-claims-payments-form.component.scss']
})
export class ElectronicClaimsPaymentsFormComponent implements OnInit {
  @Output() confirmed = new EventEmitter();

  public confirmedContent = CONTENT.accountInfo.myAccount.confirmed;
  public content = JSON.parse(sessionStorage.getItem('portalContenful'));
  public defaults = CONTENT.accountInfo.myAccount.defaults;
  public ecpFormText = this.content?this.content.electronicClaimPage.assets.form:'';
  public errorMessages = CONTENT.accountInfo.myAccount.errorMessages;
  public formValidation = FormValidationConstants;
  public labels = CONTENT.accountInfo.myAccount.labels;
  public placeHolders = CONTENT.accountInfo.myAccount.placeHolders;
  public routes = ROUTINGCONSTANTS;

  public accountInfo: AccountInfo;
  public electronicClaimsForm: UntypedFormGroup;
  public internalRenId: string;
  public loginId: string;
  public displayProgressSpinner = 0;
  public isConfirmChecked:boolean =false;

  constructor(private location: Location, 
    private accountService: AccountService, 
    private commonService: CommonService, 
    private dialog: MatDialog, 
    private fb: UntypedFormBuilder, 
    private router: Router,  
    private jwtService: JwtService) {}

  ngOnInit() {
    this.getAccountInfo();
    this.isConfirmChecked = this.electronicClaimsForm.value.election;
  }

  applyAccountInfo(accountInfo: AccountInfo) {
    if (accountInfo.electronicPaymentPrefStatus) {
      this.router.navigateByUrl(this.routes.account);
    }
    const newAccountInfo: AccountInfo = JSON.parse(JSON.stringify(accountInfo));
    newAccountInfo.homePhone = newAccountInfo.homePhone || '';
    newAccountInfo.homePhone = newAccountInfo.homePhone.replace(/[^a-zA-Z0-9]/g, '');
    newAccountInfo.workPhone = (accountInfo.workPhone && `${accountInfo.workPhone.substring(0, 3)}-${accountInfo.workPhone.substring(3, 6)}-${accountInfo.workPhone.substring(6, accountInfo.workPhone.length)}`) || '';
    newAccountInfo.workPhone = newAccountInfo.workPhone.replace(/[^a-zA-Z0-9]/g, '');  
    this.accountInfo = newAccountInfo;
  }

  getAccountInfo() {
    this.addSpinner();
    const memberId = sessionStorage.getItem('activeMemberId');
    this.accountService.getAccountInfo(memberId).subscribe({
      next: (data: AccountResponse) => {
        this.applyAccountInfo(data.accountInfoResponse);
        this.createForm();
      },
      error: () => {
        this.commonService.setDialogModal({
          title: 'Error',
          header: 'Error retrieving contact information.',
          content: ''
        });
      },
      complete: () => this.subtractSpinner()
    });
  }

  createForm(): void {
    this.electronicClaimsForm = this.fb.group(
      {
        // primaryPhone: [this.accountInfo.homePhone, [Validators.required, Validators.maxLength(12)]],
        emailAddress: [this.accountInfo.email, [Validators.required, Validators.email, Validators.maxLength(this.formValidation.lengthValidation.emailLength)]],
        verifyEmailAddress: [this.accountInfo.email, [Validators.required, Validators.email, Validators.maxLength(this.formValidation.lengthValidation.emailLength)]],
        election: [false, [Validators.required, Validators.requiredTrue]]
      },
      {validator: this.customValidators}
    );
  }

  customValidators(fg: UntypedFormGroup): any {
    const confirmEmailErrors = fg.get('verifyEmailAddress').errors || {};

    const emailAddress = fg.get('emailAddress').value;
    const confirmEmailAddress = fg.get('verifyEmailAddress').value;

    if (emailAddress && confirmEmailAddress && emailAddress !== confirmEmailAddress) {
      confirmEmailErrors['emailMismatch'] = true;
    } else {
      delete confirmEmailErrors['emailMismatch'];
    }
    const confirmEmpty = _.isEmpty(confirmEmailErrors);
    fg.get('verifyEmailAddress').setErrors(confirmEmpty ? null : confirmEmailErrors);
    fg.get('verifyEmailAddress').markAsDirty();
  }

  contactInfoDirty() {
    return Object.keys(this.electronicClaimsForm.controls).reduce((acc, key) => {
      if (key !== 'election' && this.electronicClaimsForm.controls[key].dirty) {
        acc = true;
      }
      return acc;
    }, false);
  }

  preventClick(e: Event) {
    e.stopPropagation;
    e.preventDefault;
    return;
  }

  openTermsOfService(e: Event, isLink) {
    if (this.electronicClaimsForm.value.election || isLink) {
      e.stopPropagation;
      e.preventDefault;
      const dialogRef = this.dialog.open(TermsOfServiceDialogComponent, {
        disableClose: true,
        data: this.isConfirmChecked
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isConfirmChecked = true;
          this.electronicClaimsForm.patchValue({election: result});
          this.electronicClaimsForm.get('election').markAsDirty();
        } else {
          this.electronicClaimsForm.patchValue({election: false});
          this.isConfirmChecked = false;
        }
      });
    }else{
      if(!this.electronicClaimsForm.value.election){
        this.isConfirmChecked = this.electronicClaimsForm.value.election;
      }
    }
  }

  numbersOnly(event: KeyboardEvent) {
    return (event.key >= '0' && event.key <= '9') || event.key === ' ' || event.key === 'backspace';
  }

  canSubmit() {
    return this.electronicClaimsForm.valid;
  }

  applyFormData(): AccountRequest {
    const accountRequest: AccountRequest = {
      accountInfo: {
        ...this.accountInfo,
        streetAddress1: this.accountInfo.address1,
        streetAddress2: this.accountInfo.address2,
        email: this.electronicClaimsForm.value.emailAddress,
        // workPhone: this.electronicClaimsForm.value.primaryPhone.replace(/\D/g, ''),
        electronicPaymentPrefStatus: true
      },
      updateType: null
    };
    return accountRequest;
  }

  updateAccount(updateType: string, accountRequest: AccountRequest) {
    accountRequest.updateType = updateType;
    this.addSpinner();   
    //this.accountService.updateAccountInfo(accountRequest, memberId, accountId).subscribe({
      // let content = JSON.parse(sessionStorage.getItem('portalContenful'));
      this.accountService.updateMemberDetails(updateType,accountRequest).subscribe({
      next: () => {
        if (updateType === 'PERSONAL') {
          this.updateAccount('ELECTRONICPAYMENTPREF', accountRequest);
        } else {
          this.commonService
            .setConfirmationModal({
              header: this.content.electronicClaimPage.assets.headers.confirmed,
              content: this.content.electronicClaimPage.assets.body.confirmed
            })
            .subscribe({
              next: () => {
                this.router.navigateByUrl(this.routes.account);
              }
            });
        }
      },
      error: () => {
        this.updateContactErrorModal();
        this.subtractSpinner();
      },
      complete: () => this.subtractSpinner()
    });
  }

  submit() {
    if (sessionStorage.getItem('policyList')) {
      const appliedFormData = this.applyFormData();
      if (this.contactInfoDirty()) {
        this.updateAccount('PERSONAL', appliedFormData);
      } else {
        this.updateAccount('ELECTRONICPAYMENTPREF', appliedFormData);
      }
    }
    else {
      setTimeout(() => {
        this.submit();
      }, 250);
    }
  }

  goToAccount() {
    this.router.navigateByUrl(this.routes.account);
  }

  updateContactErrorModal() {
    this.commonService.setDialogModal({
      title: 'Error',
      header: 'Error updating contact information.',
      content: ''
    });
  }

  goBack() {
    this.location.back();
  }

  addSpinner() {
    this.displayProgressSpinner++;
  }
  subtractSpinner() {
    this.displayProgressSpinner--;
  }
}
