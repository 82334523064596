<div class="container text-center">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="onNavigateBack()" id="topBackButton" class=" pull-left d-inline pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{ content.settings.login.heading }}</h1>
  </div>
  <hr style="border: 1px solid grey" />
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <p *ngIf="editError">
          {{ content.settings.login.editError.text }}<br /><b>{{ getErrorField() }}</b>
        </p>
        <mat-card-content class="pt-3 pb-3">
          <form [formGroup]="editLoginForm">
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput id="currentLoginID" type="text" formControlName="currentLoginID" class="material-input-form" [placeholder]="placeholders.currentLogin" [maxLength]="lengthValidation.loginIDMaxLength" required />
                  <mat-label
                    ><b>{{ labels.currentID }}</b></mat-label
                  >
                  <mat-hint align="end">{{ formControl.currentLoginID.value?.length || 0 }}/{{ lengthValidation.loginIDMaxLength }} </mat-hint>
                  <mat-error *ngIf="formControl.currentLoginID.errors && formControl.currentLoginID.errors.required">{{ errorMessages.currentLogin.required }}</mat-error>
                  <mat-error *ngIf="formControl.currentLoginID.errors && formControl.currentLoginID.errors.pattern">{{ errorMessages.currentLogin.pattern }}</mat-error>
                  <mat-error *ngIf="formControl.currentLoginID.errors && formControl.currentLoginID.errors.maxlength">{{ errorMessages.currentLogin.maxLength }}</mat-error>
                  <mat-error *ngIf="formControl.currentLoginID.errors && formControl.currentLoginID.errors.minlength">{{ errorMessages.currentLogin.minLength }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br />
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput id="newLoginID" type="text" formControlName="newLoginID" class="material-input-form" [placeholder]="placeholders.newLogin" [maxLength]="lengthValidation.loginIDMaxLength" required />
                  <mat-label
                    ><b>{{ labels.newID }}</b></mat-label
                  >
                  <mat-hint align="end">{{ formControl.newLoginID.value?.length || 0 }}/{{ lengthValidation.loginIDMaxLength }}</mat-hint>
                  <mat-error *ngIf="formControl.newLoginID.errors && formControl.newLoginID.errors.required">{{ errorMessages.newLogin.required }}</mat-error>
                  <mat-error *ngIf="formControl.newLoginID.errors && formControl.newLoginID.errors.pattern">{{ errorMessages.newLogin.pattern }}</mat-error>
                  <mat-error *ngIf="formControl.newLoginID.errors && formControl.newLoginID.errors.maxlength">{{ errorMessages.newLogin.maxLength }}</mat-error>
                  <mat-error *ngIf="formControl.newLoginID.errors && formControl.newLoginID.errors.minlength">{{ errorMessages.newLogin.minLength }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput id="confirmNewLoginID" type="text" formControlName="confirmNewLoginID" class="material-input-form" [placeholder]="placeholders.confirmLogin" [maxLength]="lengthValidation.loginIDMaxLength" required />
                  <mat-label
                    ><b>{{ labels.confirmNewID }}</b></mat-label
                  >
                  <mat-hint align="end">{{ formControl.confirmNewLoginID.value?.length || 0 }}/{{ lengthValidation.loginIDMaxLength }}</mat-hint>
                  <mat-error *ngIf="formControl.confirmNewLoginID.errors && formControl.confirmNewLoginID.errors.required">{{ errorMessages.confirmLogin.required }}</mat-error>
                  <mat-error *ngIf="formControl.confirmNewLoginID.errors && formControl.confirmNewLoginID.errors.pattern">{{ errorMessages.confirmLogin.pattern }}</mat-error>
                  <mat-error *ngIf="formControl.confirmNewLoginID.errors && formControl.confirmNewLoginID.errors.maxlength">{{ errorMessages.confirmLogin.maxLength }}</mat-error>
                  <mat-error *ngIf="formControl.confirmNewLoginID.errors && formControl.confirmNewLoginID.errors.minlength">{{ errorMessages.confirmLogin.minLength }}</mat-error>
                  <mat-error *ngIf="editLoginForm.errors && editLoginForm.errors.loginIDMismatch">{{ errorMessages.confirmLogin.mismatch }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br />
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>vpn_key</mat-icon>
                  <input matInput id="password" [type]="hide ? 'password' : 'text'" formControlName="password" class="material-input-form" required placeholder="Enter password" />
                  <mat-label><b>Password</b></mat-label>
                  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                  </button>
                  <mat-error *ngIf="formControl.password.errors && formControl.password.errors.required">{{ errorMessages.password.required }}</mat-error>
                  <mat-error *ngIf="formControl.password.errors && formControl.password.errors.pattern">{{ errorMessages.password.pattern }}</mat-error>
                  <mat-error *ngIf="formControl.password.errors && formControl.password.errors.maxlength">{{ errorMessages.password.maxLength }}</mat-error>
                  <mat-error *ngIf="formControl.password.errors && formControl.password.errors.minlength">{{ errorMessages.password.minLength }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br />
            <mat-card-actions>
              <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10 col-sm-12">
                  <button mat-raised-button class="custom-mat-button float-end" color="primary" (click)="onSubmit()" [disabled]="editLoginForm.invalid">{{ content.buttons.submit }}</button>
                  <app-navigation-back (navigateBackEvent)="onNavigateBack()" id="bottomBackButton" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
                </div>
              </div>
            </mat-card-actions>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
