export class SplunkLogEvent {
    app?: string;
    level?: string;
    env?: string;
    message?: string;
    userMessage?: string;
    developerMessage?: string;    
    messageId?: string;
    code?: string;
    url?: string;
    payLoad?: string;
    requestTimeStamp?: string;
}
