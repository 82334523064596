<div class="container pb-5">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="goToRenewPolicy(policyNumber)" class="pull-left d-inline  pl-1 d-md-none" id="renewal-billing-backbutton"></app-navigation-back>
    <h1 class="fw-bold">Policy Renewal</h1>
  </div>
  <hr style="border: 1px solid grey" />
  <div *ngIf="editPaymentInfoForm && billingAddressForm && creditCardForm && achForm">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <form [formGroup]="editPaymentInfoForm">
          <mat-card appearance="outlined">
            <strong>A. Choose a Payment Schedule</strong>
            <mat-card-content>
              <br />
              <div class="payment-type">
                <mat-radio-group formControlName="paymentMode" name="paymentMode">
                  <!-- eslint-disable-next-line @angular-eslint/template/accessibility-label-for, @angular-eslint/template/accessibility-label-has-associated-control -->
                  <label class="payment_item">
                    <mat-radio-button value="Y"></mat-radio-button>
                    <div class="payment_info">
                      <p><b>Monthly Payments*</b></p>
                      <p>Initial charge of <b>{{ paymentAmounts?.firstPayment | currency }}</b> (two months' premium) <br />Subsequent monthly payments of <b>{{ paymentAmounts?.secondPayment | currency }}</b></p>
                    </div>
                  </label>
                  <!-- eslint-disable-next-line @angular-eslint/template/accessibility-label-for, @angular-eslint/template/accessibility-label-has-associated-control -->
                  <label class="payment_item">
                    <mat-radio-button value="N"></mat-radio-button>
                    <div class="payment_info">
                      <p><b>Annual Payment</b></p>
                      <p>Pay entire year’s premium of <b>{{ paymentAmounts?.annualPayment | currency }}</b> <br />This option does not automatically renew.</p>
                    </div>
                  </label>
                  <mat-error *ngIf="editPaymentInfoForm.controls.paymentMode.errors && editPaymentInfoForm.controls.paymentMode.errors.required">
                    Please select payment mode.
                  </mat-error>
                </mat-radio-group>
              </div>
            </mat-card-content>
          </mat-card>
        </form>
        <br />
        <mat-card appearance="outlined">
          <strong>B. Choose a Payment Method</strong>
          <mat-card-content>
            <br />
            <mat-radio-group name="paymentType" aria-label="Select an option">
              <mat-radio-button (click)="selectPaymentType('Credit card')" [checked]="paymentSelected === 'Credit card'" value="Credit card">Credit or Debit Card &nbsp;&nbsp; </mat-radio-button>
              <mat-radio-button (click)="selectPaymentType('Debit/Checking account')" [checked]="paymentSelected === 'Debit/Checking account'" value="Debit/Checking account">
                Checking or Savings Account
              </mat-radio-button>
              <mat-error *ngIf="editPaymentInfoForm.controls.paymentType?.errors && editPaymentInfoForm.controls.paymentType?.errors.required">
                Please select payment type.
              </mat-error>
            </mat-radio-group>
          </mat-card-content>
          <br />
          <div *ngIf="paymentSelected === 'Credit card'">
            <form [formGroup]="creditCardForm">
              <div class="row">
                <div class="col">
                  <mat-form-field class="material-input-full-width mat-input-error pt-1" appearance="outline">
                    <input
                      matInput
                      id="cardNumber"
                      type="tel"
                      class="material-input-form"
                      mask="XXXX XXXX XXXX 0*"
                      (keypress)="numbersOnly($event)"
                      [maxlength]="formValidation.lengthValidation.ccMaxLength + 3"
                      [required]="isCredit()"
                      formControlName="cardNumber"
                      placeholder="Enter Credit Card Number"
                      (blur)="hideCC = true"
                      (click)="resetCreditCard()"
                      [hiddenInput]="hideCC"
                      [validation]="false"
                    />
                    <mat-label><b>Credit Card Number</b></mat-label>
                    <mat-error *ngIf="creditCardForm?.controls?.cardNumber?.errors && creditCardForm?.controls?.cardNumber?.errors.pattern"> {{ errorMessages?.cardNumber?.pattern }}</mat-error>
                    <mat-error *ngIf="creditCardForm?.controls?.cardNumber?.errors && creditCardForm?.controls?.cardNumber?.errors.minlength"> {{ errorMessages.cardNumber?.minLength }}</mat-error>
                    <mat-error *ngIf="creditCardForm?.controls?.cardNumber?.errors && creditCardForm?.controls?.cardNumber?.errors.required"> {{ errorMessages.cardNumber?.required }}</mat-error>
                    <mat-error *ngIf="creditCardForm?.controls?.cardNumber?.errors?.invalidCardType">
                      {{ errorMessages.cardNumber?.invalidCardType }}
                    </mat-error>
                  </mat-form-field>
                  <div>
                    <span class=" form-group col-md-12 creditCardImg"></span>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                        <mat-label><b>Month</b></mat-label>
                        <mat-select formControlName="expMonth" required>
                          <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="creditCardForm.controls.expMonth.errors && creditCardForm.controls.expMonth.errors.required"> {{ errorMessages.expMonth.required }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                        <mat-label><b>Year</b></mat-label>
                        <mat-select formControlName="expYear" required>
                          <mat-option value="">Year</mat-option>
                          <mat-option *ngFor="let year of expirationYears" [value]="year && year.toString()">{{ year }} </mat-option>
                        </mat-select>
                        <mat-error *ngIf="creditCardForm.controls.expYear.errors && creditCardForm.controls.expYear.errors.required"> {{ errorMessages.expYear.required }}</mat-error>
                        <mat-error *ngIf="creditCardForm.controls.expYear.errors && creditCardForm.controls.expYear.errors.expired"> {{ errorMessages.expYear.expired }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="paymentSelected === 'Debit/Checking account'">
            <mat-card appearance="outlined">
              <strong>Need Help?</strong>
              <mat-card-content>
                <br />
                <p>
                  See an <a href="javascript:void(0);" (click)="open()" style="cursor: pointer; color: #1C57A5"><u>example check</u></a> to see where to find the ABA and account Numbers.
                </p>
              </mat-card-content>
            </mat-card>
            <br />
            <form [formGroup]="achForm">
              <div class="row">
                <div class="col">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input
                      matInput
                      id="routingNumber"
                      class="material-input-form"
                      formControlName="routingNumber"
                      placeholder="Enter ABA Number"
                      [(ngModel)]="achForm.value.routingNumber"
                      type="tel"
                      (keypress)="numbersOnly($event)"
                      [maxlength]="formValidation.lengthValidation.routingLength"
                      required
                      (click)="touchAllAccFields('routingNumber')"
                      (blur)="hideABA = true"
                      [hiddenInput]="hideABA"
                      mask="XXXXX0000"
                    />
                    <mat-label><b>ABA Number</b></mat-label>
                    <mat-error *ngIf="ABANumberError">ABA Number is Invalid</mat-error>
                    <mat-error *ngIf="achForm.controls.routingNumber.errors && achForm.controls.routingNumber.errors.pattern"> {{ errorMessages.routingNumber.pattern }}</mat-error>
                    <mat-error *ngIf="achForm.controls.routingNumber.errors && achForm.controls.routingNumber.errors.maxlength"> {{ errorMessages.routingNumber.maxLength }}</mat-error>
                    <mat-error *ngIf="achForm.controls.routingNumber.errors && achForm.controls.routingNumber.errors.minLength"> {{ errorMessages.routingNumber.minLength }}</mat-error>
                    <mat-error *ngIf="achForm.controls.routingNumber.errors && achForm.controls.routingNumber.errors.required"> {{ errorMessages.routingNumber.required }}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input
                      matInput
                      id="accountNumber"
                      type="tel"
                      (keypress)="numbersOnly($event)"
                      [maxlength]="formValidation.lengthValidation.accountMaxLength"
                      class="material-input-form"
                      formControlName="accountNumber"
                      placeholder="Enter Account Number"
                      required
                      (click)="touchAllAccFields('accountNumber')"
                      appShowLast4
                    />
                    <mat-label><b>Account Number</b></mat-label>
                    <mat-error *ngIf="achForm.controls.accountNumber.errors && achForm.controls.accountNumber.errors.pattern"> {{ errorMessages.accountNumber.pattern }}</mat-error>
                    <mat-error *ngIf="achForm.controls.accountNumber.errors && achForm.controls.accountNumber.errors.maxlength"> {{ errorMessages.accountNumber.maxLength }}</mat-error>
                    <mat-error *ngIf="achForm.controls.accountNumber.errors && achForm.controls.accountNumber.errors.minlength"> {{ errorMessages.accountNumber.minLength }}</mat-error>
                    <mat-error *ngIf="achForm.controls.accountNumber.errors && achForm.controls.accountNumber.errors.required"> {{ errorMessages.accountNumber.required }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="material-input-full-width pt-1">
                    <mat-label><b>Account Type</b></mat-label>
                    <mat-select formControlName="accountType" required>
                      <mat-option id="select" disabled>Select Account Type</mat-option>
                      <mat-option id="checking" value="CHECKING">Checking</mat-option>
                      <mat-option id="saving" value="SAVING">Savings</mat-option>
                    </mat-select>
                    <mat-error *ngIf="achForm.controls.accountType.errors && achForm.controls.accountType.errors.required"> {{ errorMessages.accountType.required }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>
        </mat-card>
      </div>
      <br />
      <div class="col-lg-6 col-md-6 col-sm-12">
        <mat-card appearance="outlined">
          <strong>C. Enter Your Billing Address</strong>
          <form [formGroup]="billingAddressForm">
            <mat-card-content>
              <br />
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input class="material-input-form" formControlName="firstName" id="firstName" matInput placeholder="Enter First Name" required type="text" />
                    <mat-label><b>First Name</b></mat-label>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input class="material-input-form" formControlName="lastName" id="lastName" matInput placeholder="Enter Last Name" required type="text" />
                    <mat-label><b>Last Name</b></mat-label>
                  </mat-form-field>
                </div>
              </div>
              <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                <input class="material-input-form" #focusAddr1 formControlName="address1" id="address1" matInput placeholder="Enter Address 1" required type="text" />
                <mat-label><b>Address 1</b></mat-label>
                <mat-error *ngIf="billingAddressForm.controls.address1.errors && billingAddressForm.controls.address1.errors.required">
                  {{ errorMessages1.addressLine1.required }}
                </mat-error>
                <mat-error *ngIf="billingAddressForm.controls.address1.errors && billingAddressForm.controls.address1.errors.pattern">
                  {{ errorMessages1.addressLine1.pattern }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                <input class="material-input-form" formControlName="address2" id="address2" matInput placeholder="Enter Address 2" type="text" />
                <mat-label><b>Address 2</b></mat-label>
              </mat-form-field>

              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input class="material-input-form" formControlName="city" id="city" matInput placeholder="Enter City" required type="text" />
                    <mat-label><b>City</b></mat-label>
                    <mat-error *ngIf="billingAddressForm.controls.city.errors && billingAddressForm.controls.city.errors.required">
                      {{ errorMessages1.city.required }}
                    </mat-error>
                    <mat-error *ngIf="billingAddressForm.controls.city.errors && billingAddressForm.controls.city.errors.pattern">
                      {{ errorMessages1.city.pattern }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input class="material-input-form" formControlName="state" id="state" matInput placeholder="Enter State" required type="text" />
                    <mat-label><b>State</b></mat-label>
                    <mat-error *ngIf="billingAddressForm.controls.state.errors && billingAddressForm.controls.state.errors.pattern">
                      {{ errorMessages1.state.pattern }}
                    </mat-error>
                    <mat-error *ngIf="billingAddressForm.controls.state.errors && billingAddressForm.controls.state.errors.required">
                      {{ errorMessages1.state.required }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input matInput id="zipCode" type="text" formControlName="zip" class="material-input-form" placeholder="Enter Zip Code" (keypress)="numbersOnly($event)" mask="00000" required />
                    <mat-label><b>Zip Code</b></mat-label>
                    <mat-error *ngIf="billingAddressForm.controls.zip.errors && billingAddressForm.controls.zip.errors.minlength">
                      {{ errorMessages1.zipCode.pattern }}
                    </mat-error>
                    <mat-error *ngIf="billingAddressForm.controls.zip.errors && billingAddressForm.controls.zip.errors.required">
                      {{ errorMessages1.zipCode.required }}
                    </mat-error>
                    <mat-error *ngIf="billingAddressForm.controls.zip.errors && billingAddressForm.controls.zip.errors.maxlength">
                      {{ errorMessages1.zipCode.maxLength }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input matInput id="phone" type="tel" mask="000-000-0000" formControlName="homeTel" class="material-input-form" placeholder="Enter Phone Number" required />
                    <mat-label><b>Phone</b></mat-label>
                    <mat-error *ngIf="billingAddressForm.controls.homeTel.errors && billingAddressForm.controls.homeTel.errors.minlength">{{ errorMessages1.phone.pattern }}</mat-error>
                    <mat-error *ngIf="billingAddressForm.controls.homeTel.errors && billingAddressForm.controls.homeTel.errors.required">{{ errorMessages1.phone.required }}</mat-error>
                    <mat-error *ngIf="billingAddressForm.controls.homeTel.errors && billingAddressForm.controls.homeTel.errors.maxlength">{{ errorMessages1.phone.maxLength }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </form>
        </mat-card>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 justify-content-start">
        <app-navigation-back (navigateBackEvent)="goToRenewPolicy(policyNumber)" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline" id="backbutton"></app-navigation-back>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 text-end">
        <button (click)="submit()" [disabled]="!canSubmit()" class="custom-renew-button" color="primary" mat-raised-button type="button">Next</button>
      </div>
    </div>
  </div>
</div>

<ng-template #addressErrorModal id="addressErrorModal" let-modal>
  <div class="modal-header" class="p-1">
    <ngb-alert [dismissible]="false" type="primary" class="col-12 p-1">A match for your address could not be found in
      the USPS database. Please choose one of the options below to proceed.
    </ngb-alert>
  </div>
  <div class="modal-body">
    <p class="text-center"><strong>You entered:</strong></p>
    <p class="text-center">
      {{addressRequest.address1}}{{addressRequest.address2 ? ', ' + addressRequest.address2 : ''}}
    </p>
    <p class="text-center">
      {{addressRequest.city}}, {{addressRequest.state}}, {{addressRequest.zipCode}}
    </p>
  </div>
  <div class="modal-footer d-block text-center">
    <div class="mx-auto">
      <button type="button" class="btn-primary" (click)="modal.close('Edit')" id="editAddress"><span
          class="addressButton">Edit this address</span></button>
      <button type="button" class="btn-primary ml-1 ml-sm-3 adrsBtn" (click)="modal.close('Choose')" id="chooseAddress"><span
          class="addressButton">Choose this address</span></button>
    </div>

  </div>
</ng-template>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>
