import {Component, OnInit} from '@angular/core';
import {GtmEventsService} from '../../shared/services/gtm-events.service';

@Component({
  selector: 'app-create-portal-account',
  templateUrl: './create-portal-account.component.html',
  styleUrls: ['./create-portal-account.component.scss']
})
export class CreatePortalAccountComponent implements OnInit {

  constructor(private gtmService: GtmEventsService) {
  }

  ngOnInit() {
    // this.gtmService.pageLoadEvent('Create an Account');
  }

}
