import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TitleCasePipe} from '@angular/common';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BillingPaymentService} from '../../../../../shared/services/billing-payment.service';
import {NotificationServiceService} from '../../../../../shared/services/notification-service.service';
import {PolicyDetailResponse} from '../../../../../models/response/policy-detail-response';
import { CommonService } from '../../../../../shared/services/common.service';
import { StateSurchargeDialogModelComponent } from '../../../../../shared/components/state-surcharge-dialog-model/state-surcharge-dialog-model.component';
import {ACHCONSTANTS} from '../../../../../shared/constants/ach-constants';
import moment from 'moment';
import {OTEDocumentService} from '../../../../../shared/services/ote-document.service';
import {AccountService} from '../../../../../shared/services/account.service';
import * as xml2js from 'xml2js';
import {CARDPATTERNS} from '../../../../../shared/constants/card-patterns';
import { HttpClient } from '@angular/common/http'; 
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-renewal-confirmation',
  templateUrl: './renewal-confirmation.component.html',
  styleUrls: ['./renewal-confirmation.component.scss']
})
export class RenewalConfirmationComponent implements OnInit, OnDestroy {
  public policyNumber: any;
  public policyRenewalData: any;
  public conditionTerms: UntypedFormGroup;
  public renewalSuccessful = false;
  public submitted = false;
  public dataService: any;
  public petName = '';
  public policyDetailResponse: PolicyDetailResponse;
  public displayProgressSpinner = 0;
  public surchargeTitle = '';
  public surchargeHeader = '';
  public surchargeBody = '';
  public surchargeBody1 = '';
  public surchargeUrl = environment.surchargeUrl;
  public achConstants = ACHCONSTANTS;
  public cardPatterns = CARDPATTERNS;
  public policyDetailsNewList = [];
  modalContent: any;
  
  // @ViewChild('stateSurchargeModel') stateSurchargeModelTemplate1: ElementRef<HTMLElement>;

  constructor(private router: Router,
              private fb: UntypedFormBuilder,
              private accountService: AccountService,
              private billingPaymentService: BillingPaymentService,
              private titleCasePipe: TitleCasePipe,
              private notificationService: NotificationServiceService,
              public oteDocumentService: OTEDocumentService,
              private commonService: CommonService,
              private httpClient: HttpClient,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.createForm();
    this.dataService = sessionStorage.getItem('dataService') ? JSON.parse(sessionStorage.getItem('dataService')) : {};
    this.policyNumber = this.dataService.policyRenewalData ? this.dataService.policyRenewalData.policyNumber : '';
    this.petName = this.getPetName(this.policyNumber);
    this.policyRenewalData = this.dataService.policyRenewalData ? this.dataService.policyRenewalData : undefined;
    this.getStateSurchargeContent();
    if (this.policyRenewalData) {
      //this.getPolicy(this.policyRenewalData.policyNumber, this.policyRenewalData.renewal);
    }
  }

  getPolicy(policyNumber: string, renewalNumber: string) {
    this.billingPaymentService.getPolicyDetails(policyNumber, renewalNumber).subscribe({
      next: response => {
        this.policyDetailResponse = response;
      }
    });
  }

  getPetName(policyNumber: string): string {
    return (
      (this.dataService &&
        this.dataService.myPets &&
        this.titleCasePipe.transform(
          this.dataService.myPets.reduce((acc, curr) => curr.policyNumber === policyNumber ? curr.pet.name : acc, '')
        )) ||
      ''
    );
  }


  ngOnDestroy() {
    delete this.dataService.policyRenewalData;
    sessionStorage.setItem('dataService', JSON.stringify(this.dataService));
  }

  createForm() {
    this.conditionTerms = this.fb.group({
      authorize: ['', Validators.requiredTrue],
      terms: ['', Validators.requiredTrue]
    });
  }

  updateErrorModal() {
    this.commonService.setDialogModal({
      title: 'Error',
      header: this.modalContent ? this.modalContent : 'Error updating payment information.',
      content: '',
    });
  }

  updatePaymentAuthorization() {
      this.accountService.updatePaymentAuthorization(this.applyPaymentAuthRequest(this.policyRenewalData)).subscribe({
        next: (paymentAuthdata: any) => {
          if (paymentAuthdata.some(x=>x.standardMessage)) {
            if (paymentAuthdata.some(x=>x.standardMessage.code != 200)) {
              this.modalContent = paymentAuthdata.standardMessage.developerMessage;
              this.updateErrorModal();
            }else{
              this.renewalSuccessful = true;
              }
            }
    }});
    
  }
  submit() {
    this.submitted = true;
    if (this.conditionTerms.valid) {
      this.displayProgressSpinner++;
      this.createOteXmlPayload(this.policyRenewalData);
      this.billingPaymentService.renewalPolicy(this.policyNumber, this.policyRenewalData).subscribe(
        (data: any) => {
          // this.router.navigate(['/renewal-confirmation']);
          if (data && data.standardMessage && data.standardMessage.code === 201) {
            //this.updatePaymentAuthorization();
            this.renewalSuccessful = true;
            this.notificationService.triggerNotificationsUpdate();
          } else {
            if (data.standardMessage.code === 'NC400'|| data.standardMessage.code === '200' || data.standardMessage.code === '422') {
              this.modalContent = data.standardMessage.developerMessage;
            }  
            this.renewalSuccessful = false;
            this.updateErrorModal();
          }   
          this.displayProgressSpinner--;  
          // if (data && data.standardMessage && (data.standardMessage.code === 'NC400' || data.standardMessage.code === '200' || data.standardMessage.code === 200)) {
          //   this.modalContent = data.standardMessage.developerMessage;
          //   this.renewalSuccessful = false;
          //   this.updateErrorModal();
          // } else {
          //   this.notificationService.triggerNotificationsUpdate();
          //   this.renewalSuccessful = true;
          // }   
          // this.displayProgressSpinner--;       
        },
        error => {
          // if(error.standardMessage["code"] === 422)
          // {
          //   this.modalContent = 'Renewal payment has been already completed for this policy';
          // }
          this.renewalSuccessful = false;
          this.updateErrorModal();
          this.displayProgressSpinner--;
        },
      );
    }
  }

  goToHomePage() {
    this.router.navigate(['/home']);
  }

  getStateSurchargeContent(){
    let stateSurcharge: any;
    this.httpClient.get(this.surchargeUrl).subscribe(data =>{
      if(data){
        const responseData: any=data;
        for(let loop=0;loop<responseData.length;loop++){
          if(responseData[loop].state=='FL'){
            stateSurcharge = responseData[loop];
            this.surchargeTitle=stateSurcharge.title; 
            this.surchargeTitle=this.surchargeTitle.replace('state surcharge','<a>state surcharge</a>');
            this.surchargeHeader='Florida state surcharge';
            this.surchargeBody=stateSurcharge.content[0];
            this.surchargeBody=this.surchargeBody.replace('a 1.3%','an');
            this.surchargeBody1=stateSurcharge.content[1];
            setTimeout(() => { 
            if(document.getElementById('surchangeTitle_id')){
            const surchangeTitleEle: any = document.getElementById('surchangeTitle_id').getElementsByTagName('a');
            surchangeTitleEle.item(0).onclick=(()=>this.openDialog());
            const attr = document.createAttribute('style');
            attr.value = 'cursor:pointer;text-decoration:underline';
            surchangeTitleEle.item(0).setAttributeNode(attr);
            }
          },250);
            
          }
        }
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(StateSurchargeDialogModelComponent, {     
      width: '400px',
      data: {
        title: this.surchargeHeader,
        header: this.surchargeBody,
        content: this.surchargeBody1
      }
    });
  }
  
  getTermsAndConditionModel() {
    setTimeout(() => { 
        if(this.conditionTerms.value.terms=='false' || this.conditionTerms.value.terms==false){
          document.getElementById('termsAndCondition_id').click();
        }
    },100);
    let achContent=this.achConstants.termsAndCondition.renewalContent;
    const today = moment(new Date()).format('LL');
    let isCreditcard = '';
    if(this.policyRenewalData.payMethod=='CC'){
      if(this.policyRenewalData.pmtPlan=='Y'){
        achContent=this.achConstants.termsAndCondition.recurringBankCardContent;
      }else{
        achContent=this.achConstants.termsAndCondition.oneTimeBankCardContent;
      }
      isCreditcard='credit card';
    }else{
      if(this.policyRenewalData.pmtPlan=='Y'){
        achContent=this.achConstants.termsAndCondition.recurringACHContent;
      }else{
        achContent=this.achConstants.termsAndCondition.oneTimeACHContent;
      }
      isCreditcard='debit card';
    }
    achContent=achContent.replace('TODAY',today).replace('CARDTYPE',isCreditcard);
    this.commonService.setTermsDialogModal({
      header: this.achConstants.termsAndCondition.header,
      content: achContent
    });
  }

  setACHPayMethod(type) {
    let res = '';
    switch (type) {
      case 'AX':
        res = 'AMEX';
        break;
      case 'DI':
        res = 'DISC';
        break;
      case 'MC':
        res = 'Master';
        break;
      case 'VI':
        res = 'VISA';
        break;
      case 'CHECKING':
        res = 'CHECKING';
        break;
      case 'SAVING':
        res = 'SAVINGS';
        break;
    }
    return res;
  }

  getCardType(cardNumber: string) {
    const visaPattern = new RegExp(this.cardPatterns.visa);
    const amexPattern = new RegExp(this.cardPatterns.americanExpress);
    const discoverPattern = new RegExp(this.cardPatterns.discover);
    const masterCardPattern = new RegExp(this.cardPatterns.masterCard);

    let cardType = '';

    if (cardNumber.match(visaPattern)) {
      cardType = 'VI';
    } else if (cardNumber.match(amexPattern)) {
      cardType = 'AX';
    } else if (cardNumber.match(discoverPattern)) {
      cardType = 'DI';
    } else if (cardNumber.match(masterCardPattern)) {
      cardType = 'MC';
    }
    return cardType;
  }

  setPayMethod(type) {
    let res = '';
    switch (type) {
      case 'AX':
        res = 'American Express';
        break;
      case 'DI':
        res = 'Discover';
        break;
      case 'MC':
        res = 'Mastercard';
        break;
      case 'VI':
        res = 'Visa';
        break;
      case 'CHECKING':
        res = 'Checking';
        break;
      case 'SAVING':
        res = 'Savings';
        break;
    }
    return res;
  }

  applyPaymentAuthRequest(appliedFormData){
    const paymentAuthList: any=[];
    this.createOteXmlPayload(appliedFormData);
    let paymentType = '';
    let accountNo = '';
    let ccNo = '';
    if(appliedFormData.payMethod!='CC'){
      paymentType=this.setACHPayMethod(appliedFormData.acctType);
      accountNo = appliedFormData.acctNo;
    }else{
      paymentType=this.setACHPayMethod(appliedFormData.acctType);
      ccNo = appliedFormData.maskedAcctNo;
    }
    const paymentAuthObj: any =  {
    'policyNumber': appliedFormData.policyNumber,
    'termNumber': appliedFormData.renewal.toString(),
    'lastFourDigitsCCNo': ccNo,
    'achAccount': accountNo,
    paymentType,
    'authInd': 'Y',
    'sourceSystem': 'Portal'};
    paymentAuthList.push(paymentAuthObj);

     return paymentAuthList;
  }

  createOteXmlPayload(appliedFormData){
    this.policyDetailsNewList = JSON.parse(sessionStorage.getItem('policyList'));
    const Recipient: any = {};
    const ach: any = {};
    let documentContentXml: any = '';
    this.oteDocumentService.policyNumberList=[];
    const sessionPolicyList = JSON.parse(sessionStorage.getItem('dataService'));
    const renewalData = sessionPolicyList.policyRenewalData;
    let documentName = '';
      if(appliedFormData.payMethod=='CC'){
        if(renewalData.pmtPlan=='Y'){
          documentName='POTRBC';
        }else{
          documentName='POTBC';
        }
      }else{
        if(renewalData.pmtPlan=='Y'){
          documentName='POTRACH';
        }else{
          documentName='POTACH';
        }
      }

      const Document =[
        {'$' : {
            Name: documentName
        }}
      ];
      const PrintData: any = {};
      const currentPolicy: any = this.policyDetailsNewList.filter(policyDetail => policyDetail.policyNumber == appliedFormData.policyNumber);
      const effectiveDate = moment(currentPolicy[0].effectiveDate).add(1, 'days').format('LL');
      const policyEffectiveDate = moment(currentPolicy[0].effectiveDate).add(30, 'days').format('LL');
    PrintData.Document=Document;
    PrintData.PolicyNumber=appliedFormData.policyNumber;
    Recipient.Name=appliedFormData.firstName+ ' ' +appliedFormData.lastName;
    PrintData.Recipient=Recipient;
    ach.IssueDate=effectiveDate?effectiveDate:'';
    ach.ACHPolicyEffectiveDate=policyEffectiveDate?policyEffectiveDate:'';
    ach.PortalURL=this.achConstants.xmlFormat.portalURL;
    ach.Authdays=this.achConstants.xmlFormat.authdays;
    ach.CallNumber=this.achConstants.xmlFormat.callNumber;
    ach.ValidTime=this.achConstants.xmlFormat.validTime;
    if(appliedFormData.payMethod=='CC'){
      ach.CardType=this.setPayMethod(appliedFormData.acctType);
      ach.LastFour=appliedFormData.maskedAcctNo;
    }else{
      ach.BankRoutingNumber='*****'+this.getLast4(appliedFormData.abaNo);
      ach.BankAccountNumber='******'+this.getLast4(appliedFormData.acctNo);
      ach.BankAccountType=this.setPayMethod(appliedFormData.acctType);
    }
    this.oteDocumentService.policyNumberList.push(appliedFormData.policyNumber);
    PrintData.ACH=ach;
    const builder = new xml2js.Builder({headless: true , explicitRoot : false , rootName :'PrintData'});
    documentContentXml = builder.buildObject(PrintData);
  
    const base64EncodedXml = btoa(documentContentXml);
  const oteRequest = {
    'pubName':this.achConstants.oteRequest.publishName,
    'fileName': this.achConstants.oteRequest.fileName,
    'referenceArea': this.achConstants.oteRequest.referenceArea,
    'driverFile': base64EncodedXml
  };
  this.oteDocumentService.requestJsonObject = oteRequest;
  
  }

  downloadAgreement(){

    this.oteDocumentService.getOteDocument().subscribe(data => {
      const responseData: any = data; 
      const today = moment(new Date()).format('YYYYMMDD');
      const policyNumber = this.oteDocumentService.policyNumberList[0];
      if (responseData.fileOutput) {
        const newBlob = new Blob([responseData.fileOutput]);
        const linkSource = `data:application/pdf;base64,${responseData.fileOutput}`;
        if (window.navigator.userAgent.includes('Firefox') === true) {
          const data1 = linkSource;//window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = data1;
          link.className = 'hidden';
          link.download = 'PaymentAuthorization_'+today+'.pdf';
          
          link.click();
        } else {
          const pdfFileUrlData = linkSource;//URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.download = 'PaymentAuthorization_'+today+'.pdf';
          link.href = pdfFileUrlData;
          link.click();
          
        }
      }
    });


  }

  getLast4(s: string) {
    let last4 = '';
    if (s) {
      if (s.length > 4) {
        last4 = s.slice(s.length - 4);
      } else {
        last4 = s;
      }
    }
    return last4;
  }
}
