import {AccountInfo} from '../../../models/account-info';
import {Component, Input, OnInit} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {FormValidationConstants} from '../../../shared/constants/form-validation-constants';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {provideNgxMask} from 'ngx-mask';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import { FullstoryService } from '../../../shared/services/fullstory.service';

@Component({
  selector: 'app-myaccount',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  providers: [provideNgxMask()]
})
export class MyAccountComponent implements OnInit {

  public content = CONTENT;
  public FormValidationConstants = FormValidationConstants;
  public routes = ROUTINGCONSTANTS;
  public submitted: boolean;
  @Input() accountInfo: AccountInfo;

  constructor(private router: Router,
              private fullstoryService: FullstoryService,
              private gtmService: GtmEventsService) {
  }

  ngOnInit(): void {

  }


  setPhoneNumber(phoneNumber) {
    let phone = '';
    if (phoneNumber) {
      phone = phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, phoneNumber.length) || '';
    }
    return phone;
  }

  editDetails(): void {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Edit Personal Information',
      eventLabel: 'Edit Personal Information Button',
      eventValue: 'Accounts: Edit Personal Information',
      userId: sessionStorage.getItem('userId'),
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
    this.router.navigate([this.routes.editMyAccount]);
  }
}
