<div class="container text-center" xmlns="http://www.w3.org/1999/html">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="onNavigateBack()" id="topBackButton" class=" pull-left d-inline pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{ isUserLogin ? content.settings.securityQuestions.headingCreate : content.settings.securityQuestions.heading }}</h1>
  </div>
  <hr style="border: 1px solid grey" />
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <p *ngIf="editError" class="red-text">{{ content.settings.securityQuestions.editError.body }}</p>
        <p>{{ content.settings.securityQuestions.info }}</p>
        <mat-card-content>
          <div>
            <form [formGroup]="editSecurityQuestionForm">
              <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10 col-sm-12">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input matInput id="question" type="text" formControlName="question" class="material-input-form" required [placeholder]="placeholders.question" [maxLength]="lengthValidation.securityQuestionMaxLength" />
                    <mat-label><b>{{ labels.question }}</b></mat-label>
                    <mat-icon matSuffix [matTooltip]="content.settings.securityQuestions.hints.question">help</mat-icon>
                    <mat-hint align="end">{{ formControl.question.value?.length || 0 }}/{{ lengthValidation.securityQuestionMaxLength }} </mat-hint>
                    <mat-error *ngIf="formControl.question.errors && formControl.question.errors.required"> {{ errorMessages.question.required }}</mat-error>
                    <mat-error *ngIf="formControl.question.errors && formControl.question.errors.pattern"> {{ errorMessages.question.pattern }}</mat-error>
                    <mat-error *ngIf="formControl.question.errors && formControl.question.errors.maxlength"> {{ errorMessages.question.maxLength }}</mat-error>
                    <mat-error *ngIf="formControl.question.errors && formControl.question.errors.minlength"> {{ errorMessages.question.minLength }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10 col-sm-12">
                  <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <input matInput id="answer" type="text" formControlName="answer" class="material-input-form" required [placeholder]="placeholders.answer" [maxLength]="lengthValidation.securityAnswerMaxLength" />
                    <mat-label
                      ><b>{{ labels.answer }}</b></mat-label
                    >
                    <mat-hint align="end">{{ formControl.answer.value?.length || 0 }}/{{ lengthValidation.securityAnswerMaxLength }} </mat-hint>
                    <mat-icon matSuffix [matTooltip]="content.settings.securityQuestions.hints.answer">help</mat-icon>
                    <mat-error *ngIf="formControl.answer.errors && formControl.answer.errors.required"> {{ errorMessages.answer.required }}</mat-error>
                    <mat-error *ngIf="formControl.answer.errors && formControl.answer.errors.pattern"> {{ errorMessages.answer.pattern }}</mat-error>
                    <mat-error *ngIf="formControl.answer.errors && formControl.answer.errors.maxlength"> {{ errorMessages.answer.maxLength }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>
        </mat-card-content>

        <br />
        <mat-card-actions>
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <button mat-raised-button class="custom-mat-button float-end" color="primary" (click)="onSubmit()" [disabled]="editSecurityQuestionForm.invalid">{{ content.buttons.submit }}</button>
              <app-navigation-back (navigateBackEvent)="onNavigateBack()" id="bottomBackButton" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
