import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MppQuestionsService {
  public mppBreedURL = environment.orchBaseUrl + 'Breed?specieCode=';
  public mppColorURl = environment.orchBaseUrl + 'color?specieCode=';
  public mppLocationURL = environment.orchBaseUrl + 'Locations?zipcode=';
  public mppProvidersURL = environment.orchBaseUrl + 'provider';
  public mppUpdateEmptyPetsURL = environment.baseUrl + 'policies/v1/';
  
  constructor(private http: HttpClient) {
  }
  
  getEmptyPetsList(): Observable<any> {
    return this.http.get<{ data: any }>(this.mppUpdateEmptyPetsURL + 'petinfo').pipe(map(
      data => data
    ));
  }
  
  updateEmptyPetsList(petInfo): Observable<any> {
    return this.http.post(this.mppUpdateEmptyPetsURL + 'petinfo', petInfo);
  }
  
  getBreedsList(petType: string): Observable<any> {
    return this.http.get<{ data: any }>(this.mppBreedURL + petType).pipe(map(
      data => data
    ));
  }
  
  getColorsList(petType: string): Observable<any> {
    return this.http.get<{ data: any }>(this.mppColorURl + petType).pipe(map(
      data => data
    ));
  }
  
  getLocationList(Zipcode: string): Observable<any> {
    return this.http.get<{ data: any }>(this.mppLocationURL + Zipcode).pipe(map(
      data => data
    ));
  }
  
  getProvidersList(city: string, range: number, state: string, name: string): Observable<any> {
    return this.http.get<{ data: any }>(this.mppProvidersURL + '?city=' + city + '&range=' + range + '&state=' + state + '&name=' + name).pipe(map(
      data => data
    ));
  }
  
}

