import { Component, DoCheck, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { OverlayService } from '../overlay.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit, DoCheck, OnDestroy {
  @Input() diameter = 100;
  @Input() strokeWidth?: number=10;
  @Input() displayProgressSpinner: boolean;

  @ViewChild('progressSpinnerRef',{static:true})
  private progressSpinnerRef: TemplateRef<any>;
  private progressSpinnerOverlayConfig: OverlayConfig;
  private overlayRef: OverlayRef;
  public color = 'primary';
  public mode = 'indeterminate';
  public value = 50;
  public backdropEnabled = true;
  public positionGloballyCenter = true;

  constructor(private vcRef: ViewContainerRef, private overlayService: OverlayService) {}

  ngOnInit() {
    this.progressSpinnerOverlayConfig = {
      hasBackdrop: this.backdropEnabled
    };
    if (this.positionGloballyCenter) {
      this.progressSpinnerOverlayConfig['positionStrategy'] = this.overlayService.positionGloballyCenter();
    }
    this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
  }

  ngOnDestroy(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  ngDoCheck() {
    if (this.displayProgressSpinner && !this.overlayRef.hasAttached()) {
      this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
    } else if (!this.displayProgressSpinner && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
