<div class="container text-center">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="navigateToRegistration()" id='topBackButton' class=" pull-left d-inline pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{content.createLogInPage.heading}}</h1>
  </div>
  <hr style="border: 1px solid grey">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined" class="pb-5">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10 col-sm-12">
            <p class="text-left"><small>{{content.createLogInPage.infoStep1}}</small></p>
          </div>
        </div>
        <mat-card-content>
          <form [formGroup]="createLoginForm">
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput id="input-createLogin" type="text" maxlength="64" minlength="6" formControlName="loginId" class="material-input-form" placeholder="Enter Login ID" required>
                  <mat-label><b>Login ID</b></mat-label>
                  <mat-icon matSuffix [matTooltip]="content.validations.loginPage.toolTip.loginId">help</mat-icon>
                  <mat-hint align="end">{{formControl.loginId.value?.length || 0}}/64</mat-hint>
                  <mat-error id="loginId-error">{{setLoginIdError('loginId')}}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>vpn_key</mat-icon>
                  <input matInput id="input-password" type="password" formControlName="password" maxlength="65" class="material-input-form" required>
                  <mat-label><b>Password</b></mat-label>
                  <mat-icon matSuffix [matTooltip]="content.validations.loginPage.toolTip.password">help</mat-icon>
                  <mat-hint align="end">{{formControl.password.value?.length || 0}}/65</mat-hint>
                  <mat-error id="password-error">{{setPasswordError('password')}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>vpn_key</mat-icon>
                  <mat-label><b>Confirm Password</b></mat-label>
                  <mat-icon matSuffix [matTooltip]="content.validations.loginPage.toolTip.confirmPassword">help</mat-icon>
                  <input matInput id="input-confirmPassword" type="password" formControlName="confirmPassword" maxlength="65" class="material-input-form" required>
                  <mat-hint align="end">{{formControl.confirmPassword.value?.length || 0}}/65</mat-hint>
                  <mat-error id="confirmPassword-error">{{setConfirmPasswordError('confirmPassword')}}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>email</mat-icon>
                  <mat-label><b>Email</b></mat-label>
                  <input matInput id="input-email" type="text" formControlName="email" class="material-input-form" required>
                  <mat-error *ngIf="formControl.email.errors && formControl.email.errors.required">{{content.validations.forgotId.emailReqError}}</mat-error>
                  <mat-error *ngIf="formControl.email.errors && formControl.email.errors.pattern">{{content.validations.forgotId.emailPatternError}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>email</mat-icon>
                  <mat-label><b>Confirm Email</b></mat-label>
                  <input matInput id="input-confirmEmail" type="text" formControlName="confirmEmail" class="material-input-form" required>
                  <mat-error id="confirmEmail-error" *ngIf="formControl.confirmEmail.errors && formControl.confirmEmail.errors.required">{{content.validations.forgotId.emailReqError}}</mat-error>
                  <mat-error *ngIf="formControl.confirmEmail.errors && formControl.confirmEmail.errors.notMatch">{{content.validations.forgotId.confirmEmail}}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br>
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <p class="text-left"><small>{{content.createLogInPage.infoStep2}}</small></p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <input matInput id="input-securityQuestion" maxlength="80" type="text" formControlName="securityQuestion" class="material-input-form" placeholder="Please type in your question" required>
                  <mat-label><b>{{content.createLogInPage.dropDwnPlaceHolder}}</b></mat-label>
                  <mat-icon matSuffix [matTooltip]="content.validations.loginPage.toolTip.securityQuestion">help</mat-icon>
                  <mat-error id="secQtn-required-error" *ngIf="formControl.securityQuestion.errors && formControl.securityQuestion.errors.required">{{content.validations.registerAccount.securityQuesReq}}</mat-error>
                  <mat-error id="secQtn-minlength-error" *ngIf="formControl.securityQuestion.errors && formControl.securityQuestion.errors.minlength">{{content.validations.registerAccount.securityQueMinLength}}</mat-error>
                  <mat-error id="secQtn-pattern-error" *ngIf="formControl.securityQuestion.errors && formControl.securityQuestion.errors.pattern">{{content.validations.registerAccount.securityQuePattern}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <input matInput id="input-answer" type="text" maxlength="80" formControlName="answer" class="material-input-form" placeholder="Please type in your answer" required>
                  <mat-label><b>Please type in your answer</b></mat-label>
                  <mat-icon matSuffix [matTooltip]="content.validations.loginPage.toolTip.securityAnswer">help</mat-icon>
                  <mat-hint align="end">{{formControl.answer.value?.length || 0}}/80</mat-hint>
                  <mat-error id="securityAnswer-error" *ngIf="formControl.answer.errors && formControl.answer.errors.required">{{content.validations.registerAccount.securityAnsReq}}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <div class="row justify-content-center pb-2">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <button mat-raised-button class="custom-mat-button float-end mobile-button" color="primary" type="submit" id="button-submit"
                      (click)="onSubmit()" [disabled]="!createLoginForm.valid">{{content.buttons.submit}}</button>
              <app-navigation-back (navigateBackEvent)="navigateToRegistration()" id='bottomBackButton' class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
