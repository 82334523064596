import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './modules/login/login.component';
import {HomepageComponent} from './modules/homepage/homepage.component';
import {ForgotIdComponent} from './shared/components/forgot-id/forgot-id.component';
import {ForgotPasswordComponent} from './shared/components/forgot-password/forgot-password.component';
import {FileNewClaimComponent} from './shared/components/file-new-claim/file-new-claim.component';
import {ClaimsComponent} from './modules/claims/claims.component';
import {MyPetsComponent} from './modules/my-pets/my-pets.component';
import {CreateLoginComponent} from './modules/create-portal-account/create-login/create-login.component';
import {CreatePortalAccountComponent} from './modules/create-portal-account/create-portal-account.component';
import {ResetPasswordComponent} from './shared/components/forgot-password/reset-password/reset-password.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ManagePolicyComponent} from './modules/my-pets/manage-policy/manage-policy.component';
import {NotificationsComponent} from './modules/notifications/notifications.component';

import {AccountComponent} from './modules/account/account.component';
import {RenewalComponent} from './modules/my-pets/manage-policy/renewal/renewal.component';
import {RenewalBillingComponent} from './modules/my-pets/manage-policy/renewal/renewal-billing/renewal-billing.component';
import {RenewalConfirmationComponent} from './modules/my-pets/manage-policy/renewal/renewal-confirmation/renewal-confirmation.component';
import {CancellationComponent} from './modules/my-pets/manage-policy/cancellation/cancellation.component';
import {CancellationFormComponent} from './modules/my-pets/manage-policy/cancellation/cancellation-form/cancellation-form.component';
import {CancellationResponseComponent} from './modules/my-pets/manage-policy/cancellation/cancellation-response/cancellation-response.component';
import {ClaimDetailsComponent} from './shared/components/claim-details/claim-details.component';
import {SettingsComponent} from './modules/settings/settings.component';
import {EditLoginComponent} from './modules/settings/edit-login/edit-login.component';
import {EditSecurityQuestionsComponent} from './modules/settings/edit-security-questions/edit-security-questions.component';
import {EditPasswordComponent} from './modules/settings/edit-password/edit-password.component';
import {EditPaymentComponent} from './modules/account/edit-payment/edit-payment.component';
import {EditMyAccountComponent} from './modules/account/edit-my-account/edit-my-account.component';
import {MakePaymentComponent} from './modules/my-pets/make-payment/make-payment.component';
import {ElectronicClaimsPaymentsComponent} from './modules/electronic-claims-payments/electronic-claims-payments.component';
import {MppQuestionsComponent} from './modules/create-portal-account/mpp-questions/mpp-questions.component';
import {LinkMissingPolicyComponent} from './modules/homepage/link-missing-policy/link-missing-policy.component';
import {EmailNotificationComponent} from './shared/components/forgot-password/email-notification/email-notification.component';
import {NewClaimButtonComponent} from './shared/components/new-claim-button/new-claim-button.component';
import { ThirdpartyPartnerComponent } from './modules/thirdparty-partner/thirdparty-partner.component';
import { PaymentHistoryComponent } from './modules/account/payment/payment-history/payment-history.component';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import { LoadingPageComponent } from './modules/loading-page/loading-page.component';

const routes: Routes = [
  //{path: '', pathMatch: 'full', redirectTo: 'login'},
  {path: '', pathMatch: 'full', component: LandingPageComponent},
  {path: 'loading-page', component: LoadingPageComponent},
  {path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
  {path: 'cancellation-form/:policyNumber', component: CancellationFormComponent, canActivate: [AuthGuard]},
  {path: 'cancellation-response', component: CancellationResponseComponent, canActivate: [AuthGuard]},
  {path: 'cancellation/:policyNumber/:renewalNumber', component: CancellationComponent, canActivate: [AuthGuard]},
  {path: 'claim-details/:claimNumber/:policyNumber/:renewal', component: ClaimDetailsComponent, canActivate: [AuthGuard]},
  {path: 'claims', component: ClaimsComponent, canActivate: [AuthGuard]},
  {path: 'create-account/:insuredCode', component: CreateLoginComponent},
  {path: 'edit-login', component: EditLoginComponent, canActivate: [AuthGuard]},
  {path: 'edit-my-account', component: EditMyAccountComponent, canActivate: [AuthGuard]},
  {path: 'edit-password', component: EditPasswordComponent},
  {path: 'edit-payment', component: EditPaymentComponent, canActivate: [AuthGuard]},
  {path: 'edit-payment/:policyNumber', component: EditPaymentComponent, canActivate: [AuthGuard]},
  {path: 'edit-security-questions', component: EditSecurityQuestionsComponent},
  {path: 'electronic-payment-opt-in', component: ElectronicClaimsPaymentsComponent, canActivate: [AuthGuard]},
  {path: 'file-new-claim', component: FileNewClaimComponent, canActivate: [AuthGuard]},
  {path: 'file-new-claim/:policyNumber/:renewalNumber', component: FileNewClaimComponent, canActivate: [AuthGuard]},
  {path: 'new-claim-button', component: NewClaimButtonComponent, canActivate: [AuthGuard]},
  {path: 'forgot-id', component: ForgotIdComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'home', component: HomepageComponent, canActivate: [AuthGuard]},
  {path: 'id', pathMatch: 'full', redirectTo: ''},
  {path: 'login', component: LoginComponent},
  {path: 'make-payment/:policyNumber/:renewalNumber', component: MakePaymentComponent, canActivate: [AuthGuard]},
  {path: 'manage-policy/:id', component: ManagePolicyComponent, canActivate: [AuthGuard]},
  {path: 'mpp-questions', component: MppQuestionsComponent},
  {path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard]},
  {path: 'pets', component: MyPetsComponent, canActivate: [AuthGuard]},
  {path: 'pets/:policyNumber', component: MyPetsComponent, canActivate: [AuthGuard]},
  {path: 'registration', component: CreatePortalAccountComponent},
  {path: 'renewal-billing/:policyNumber', component: RenewalBillingComponent, canActivate: [AuthGuard]},
  {path: 'renewal-confirmation/:policyNumber', component: RenewalConfirmationComponent, canActivate: [AuthGuard]},
  {path: 'renewal/:policyNumber', component: RenewalComponent, canActivate: [AuthGuard]},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'email-sent', component: EmailNotificationComponent},
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  {path: 'link-missing-policy', component: LinkMissingPolicyComponent, canActivate: [AuthGuard]},
  {path: 'walmart-rx', component: ThirdpartyPartnerComponent, canActivate: [AuthGuard]},
  {path: 'payment-history', component: PaymentHistoryComponent, canActivate: [AuthGuard]},
  { path: 'new-claims-submission', loadChildren: () => import('./modules/new-claims-submission/new-claims-submission.module').then(m => m.NewClaimsSubmissionModule),canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
