export const PREFERENCES = [
  {
    question: 'Send me promotional information and alerts related to Nationwide.',
    extraInfo: 'I\'d like to receive information from Nationwide about pet insurance products and/ or services including event' +
      ' notifications and Pet HealthZone alerts.I understand that if I choose not to receive these additional notifications, ' +
      'I will still receive important information about my Nationwide policy.',
    response: false,
  },
  {
    question: 'Send me information from Nationwide\'s partnership companies.',
    extraInfo: 'I\'d like to receive information from Nationwide\'s third party affiliates about special offers on pet ' +
      'products and/or services exclusively for pet insurance members.',
    response: false,
  },
];
