import {Component, OnInit} from '@angular/core';
import {MppQuestionsService} from '../../../shared/services/mpp-questions.service';
import {UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup} from '@angular/forms';
// import {AgmInfoWindow, AgmMap} from '@agm/core';
import {MapInfoWindow} from '@angular/google-maps';
import {STATESLIST} from '../../../content-management/states';
import {PetInfo} from '../../../models/pet-info';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {CommonService} from '../../../shared/services/common.service';

@Component({
  selector: 'app-mpp-questions',
  templateUrl: './mpp-questions.component.html',
  styleUrls: ['./mpp-questions.component.scss']
})
export class MppQuestionsComponent implements OnInit {
  public currentPet: any;
  public petsList;
  public petInfo: PetInfo[] = [];
  public petAnswersList = [];
  public petType: string;
  public petTypeCode: string;
  public petPolicyNumber: string;
  public routingConstants = ROUTINGCONSTANTS;
  public breedType = '';
  public hospitalselected = '';
  public displayProgressSpinner = 0;
  public ages: any = ['0 - 11 months', '1 year', '2 years', '3 years', '4 years', '5 years', '6 years', '7 years', '8 years', '9 years', '10 years', '11 years', '12 years', '13 years', '14 years', '15 years', '16 years', '17 years', '18 years', '19 years', '20 years'];
  public breedList: any = [];
  public colorsList: any = [];
  public statesList: any = STATESLIST;
  public providersList: any = [];
  public cities: any = [];
  public lat: any;
  public lng: any;
  public searchForm: UntypedFormGroup;
  public petinfoForm: UntypedFormGroup;
  public mapstatus = false;
  public searchSubmitted = false;
  public mapChanged = false;
  public profile: any;
  public currentIW: MapInfoWindow;
  public previousIW: MapInfoWindow;
  public openedWindow = 0;
  public isSubmitted = false;
  public zipcode: any;

  constructor(private mppQuestionsService: MppQuestionsService, private formBuilder: UntypedFormBuilder, private router: Router, private commonService: CommonService) {}

  ngOnInit() {
    this.createForm();
    this.getEmptyPetsList(0);
  }

  createForm() {
    this.searchForm = this.formBuilder.group({
      hospital: new UntypedFormControl(''),
      city: new UntypedFormControl('', Validators.required),
      state: new UntypedFormControl('', Validators.required)
    });
    this.petinfoForm = this.formBuilder.group({
      age: ['', [Validators.required]],
      petType: ['', [Validators.required]],
      dogType: [''],
      dogBreed: [''],
      dogSize: [''],
      catType: [''],
      catBreed: [''],
      catSize: [''],
      petGender: ['', [Validators.required]],
      petColor: ['', [Validators.required]],
      petNature: ['', [Validators.required]],
      petRegHospital: ['', [Validators.required]]
    });
  }

  getEmptyPetsList(index) {
    this.displayProgressSpinner++;
    this.mppQuestionsService.getEmptyPetsList().subscribe({
      next: data => {
        this.petsList = data.pets;
        this.currentPet = {
          petName: this.petsList[index].name,
          petType: this.petsList[index].policyNumber.charAt(0) === 'C' ? 'Canine' : 'Feline'
        };
        this.petType = this.currentPet.petType;
        this.petTypeCode = this.petsList[index].policyNumber.charAt(0);
        this.petPolicyNumber = this.petsList[index].policyNumber;
        this.zipcode = this.petsList[index].zipCode;
        this.petinfoForm.controls['petType'].setValue(this.petType);
        this.getBreedsList();
        this.getColorsList();
        this.getLocation();
        this.setValidations();
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  updateEmptyPetsList() {
    this.displayProgressSpinner++;
    this.mppQuestionsService.updateEmptyPetsList({mppPetList: [...this.petInfo]}).subscribe({
      next: data => {
        if (data.standardMessage.code === '99') {
          this.commonService.setDialogModal({
            title: 'Error',
            content: 'There was an issue updating pet information.'
          });
        } else {
          const confirmationMessage = data.standardMessage.developerMessage;
          this.commonService.setConfirmationModal({header: confirmationMessage}).subscribe({
            next: () => this.router.navigate([this.routingConstants.home])
          });
        }
      },
      error: error => {
        const errorMessage = error.standardMessage.developerMessage;
        this.commonService.setDialogModal({
          title: 'Error',
          content: errorMessage
        });
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  goToHome() {
    this.router.navigate([this.routingConstants.home]);
  }

  normalizePetRegHospital(providerCode: string): string {
    const stateCode = this.profile && this.profile.stateCode;
    if (providerCode === 'Never Visited' || providerCode === 'Not one of These') {
      return stateCode + '0001';
    }
    return providerCode;
  }

  populatePetInfo(petAnswersList) {
    this.petAnswersList.forEach((item, i) => {
      const str = item.age;
      const result = str ? str.split(/-| /)[0] : 0;
      const objPetInfo: PetInfo = {};
      objPetInfo.age = JSON.parse(result);
      objPetInfo.policyNumber = this.petsList[i].policyNumber;
      objPetInfo.zipCode = this.zipcode;
      objPetInfo.name = this.petsList[i].name;
      objPetInfo.gender = JSON.parse(item.petGender);
      objPetInfo.colorCode = item.petColor;
      objPetInfo.breedCode = this.getBreedcode(item);
      objPetInfo.spayNeuteredFlag = JSON.parse(item.petNature);
      objPetInfo.providerCode = this.normalizePetRegHospital(item.petRegHospital);

      this.petInfo.push(objPetInfo);
    });

    return this.petInfo;
  }

  getBreedcode(item) {
    if (item.petType === 'Canine') {
      if (item.dogType === 'mixed') {
        return parseInt(this.petSizeImageCode(item.dogSize), 10);
      } else {
        return item.dogBreed;
      }
    } else {
      if (item.catType === 'mixed') {
        return parseInt(this.petSizeImageCode(item.catSize), 10);
      } else {
        return item.catBreed;
      }
    }
  }

  get formValidator() {
    return this.petinfoForm.controls;
  }

  setPetInfoFormValidators(data) {}

  setValidations() {
    if (this.petType === 'Canine') {
      this.setPetInfoFormValidators('dogType');
    } else {
      this.setPetInfoFormValidators('catType');
    }
  }

  setDogTypeValidations(type) {
    if (type === 'pure') {
      this.setPetInfoFormValidators('dogBreed');
      // this.clearPetInfoFormValidators('dogSize');
    } else {
      // this.clearPetInfoFormValidators('dogBreed');
      this.setPetInfoFormValidators('dogSize');
    }
  }

  setCatTypeValidations(type) {
    if (type === 'pure') {
      this.setPetInfoFormValidators('catBreed');
    } else {
      this.setPetInfoFormValidators('catSize');
    }
  }

  radioChange(event) {
    this.breedType = event.value;
    if (this.petType === 'Canine') {
      this.setDogTypeValidations(this.breedType);
    } else {
      this.setCatTypeValidations(this.breedType);
    }
  }

  selectHospital(event) {
    this.hospitalselected = event.value;
  }

  public getBreedsList() {
    this.displayProgressSpinner++;
    this.mppQuestionsService.getBreedsList(this.petTypeCode).subscribe({
      next: data => {
        data.breeds.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.breedList = data.breeds;
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  public getColorsList() {
    this.displayProgressSpinner++;
    this.mppQuestionsService.getColorsList(this.petTypeCode).subscribe({
      next: data => {
        data.colors.sort((a, b) => (a.colorName > b.colorName ? 1 : -1));
        this.colorsList = data.colors;
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  getLocation() {
    this.displayProgressSpinner++;
    this.mppQuestionsService.getLocationList(this.zipcode).subscribe({
      next: data => {
        if (data) {
          if (data.locations[0]) {
            this.cities = data.locations[0].cities;
          }
          this.profile = {
            stateCode: data.locations[0].stateCode,
            state: data.locations[0].stateDescription,
            zipCode: this.zipcode
          };
          this.getProviderList(data.locations[0].stateCode.toLowerCase(), data.locations[0].cities[0], '1');
        }
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  petSizeImageCode(petSize) {
    switch (petSize) {
      case 'Toy':
        return '1589';
      case 'Small':
        return '1590';
      case 'Medium':
        return '1591';
      case 'Large':
        return '1592';
      case 'Very Large':
        return '2000';
      case 'Short Hair':
        return '2001';
      case 'Medium Hair':
        return '2002';
      case 'Long Hair':
        return '2003';
    }
  }

  setMapStatus() {
    this.mapstatus = false;
    if (this.previousIW) {
      this.previousIW.close();
    }
  }

  markerClick(infoWindow) {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
  }

  mapClick() {
    if (this.previousIW) {
      this.previousIW.close();
    }
  }

  openWindow(id) {
    if (this.previousIW) {
      this.previousIW.close();
    }
    this.openedWindow = id; // alternative: push to array of numbers
  }

  closeWindow(id) {
    this.openedWindow = null;
  }

  isInfoWindowOpen(id) {
    return this.openedWindow === id; // alternative: check if id is in array
  }

  selectmapLocation(value) {
    this.mapstatus = false;
    this.hospitalselected = value;
  }

  checkForSelectedHospital() {
    return this.hospitalselected === 'Not one of These';
  }

  toggleMap() {
    if (this.mapChanged) {
      this.getLocation();
    }
    if (this.mapstatus) {
      this.mapstatus = false;
    } else {
      this.hospitalselected = '';
      this.mapstatus = true;
    }
  }

  getProviderList(state, city, status) {
    if (status === '2' && this.searchForm.invalid) {
      this.searchSubmitted = true;
    }

    this.mapChanged = status === '2';
    const range = 7;
    const name = '';
    this.providersList = [];
    if (this.hospitalselected === 'Not one of These') {
      this.getList(this.searchForm.value.city, range, this.searchForm.value.state, this.searchForm.value.hospital, status, 0);
    } else {
      this.getList(city, range, state, name, status, 0);
    }
  }

  getList(city, range, state, name, status, i) {
    this.displayProgressSpinner++;
    this.mppQuestionsService.getProvidersList(city, range, state, name).subscribe({
      next: response => {
        if (response.latitude && response.longitude) {
          this.lat = response.latitude;
          this.lng = response.longitude;
        }
        if (response.providers.length > 0) {
          this.searchSubmitted = false;
          this.providersList = response.providers;
          if (status === '2') {
            this.mapstatus = true;
          }
        }
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.petinfoForm.valid) {
      this.petAnswersList.push(this.petinfoForm.value);
      if (this.petAnswersList.length < this.petsList.length) {
        this.petinfoForm.reset();
        this.hospitalselected = '';
        this.getEmptyPetsList(this.petAnswersList.length);
        this.isSubmitted = false;
      } else {
        this.populatePetInfo(this.petAnswersList);
        this.updateEmptyPetsList();
      }
    }
  }
}
