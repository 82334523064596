import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorModalData } from '../../../models/error-modal-data';
import { CONTENT } from '../../../content-management/content';
import moment from 'moment';
import {OTEDocumentService} from '../../../shared/services/ote-document.service';

@Component({
  selector: 'app-confirmation-dialog-modal',
  templateUrl: './ach-confirmation-dialog-modal.component.html',
  styleUrls: ['./ach-confirmation-dialog-modal.component.scss']
})
export class AchConfirmationDialogModalComponent {
  public content = CONTENT;
  @Output() public notifyClosed = new EventEmitter<boolean>();

  constructor(public dialogRef: MatDialogRef<AchConfirmationDialogModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ErrorModalData, public oteDocumentService: OTEDocumentService) {}

  close(): void {
    this.dialogRef.close();
    this.notifyClosed.emit(true);
  }

  downloadAgreement(){

    this.oteDocumentService.getOteDocument().subscribe(data => {
      const responseData: any = data; 
      const today = moment(new Date()).format('YYYYMMDD');
      const policyNumber = this.oteDocumentService.policyNumberList[0];
      if (responseData.fileOutput) {
        const newBlob = new Blob([responseData.fileOutput]);
        const linkSource = `data:application/pdf;base64,${responseData.fileOutput}`;
        if (window.navigator.userAgent.includes('Firefox') === true) {
          const data1 = linkSource;//window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = data1;
          link.className = 'hidden';
          link.download = 'PaymentAuthorization_'+today+'.pdf';
          
          link.click();
        } else {
          const pdfFileUrlData = linkSource;//URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.download = 'PaymentAuthorization_'+today+'.pdf';
          link.href = pdfFileUrlData;
          link.click();
          
        }
      }
    });


  }
}
