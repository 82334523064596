import {Component, OnInit} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidationConstants} from '../../../shared/constants/form-validation-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {SettingsService} from '../../../shared/services/settings.service';
import {AuthService} from '../../../core/services/auth.service';
import {CommonService} from '../../../shared/services/common.service';
import {ChangeSecurityQtnAndAnsRequest} from '../../../models/request/change-security-QtnAndAns-request';
import {first} from 'rxjs/operators';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import { FullstoryService } from '../../../shared/services/fullstory.service';

@Component({
  selector: 'app-edit-security-questions',
  templateUrl: './edit-security-questions.component.html',
  styleUrls: ['./edit-security-questions.component.scss']
})
export class EditSecurityQuestionsComponent implements OnInit {
  public routes = ROUTINGCONSTANTS;
  public content = CONTENT;
  public placeholders = CONTENT.settings.securityQuestions.placeHolders;
  public errorMessages = CONTENT.settings.securityQuestions.errorMessages;
  public labels = CONTENT.settings.securityQuestions.labels;
  public lengthValidation = FormValidationConstants.lengthValidation;
  public patternValidation = FormValidationConstants.patternValidation;
  public editSecurityQuestionForm: UntypedFormGroup;
  public changeSecurityQtnAndAnsRequest: ChangeSecurityQtnAndAnsRequest = {};
  public userId: string;
  public editError = '';
  public isUserLogin = false;
  public securityQuestionRoute: string;

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private gtmService: GtmEventsService,
              private settingsService: SettingsService,
              private fullstoryService: FullstoryService,
              private authService: AuthService,
              private commonService: CommonService) {
    this.getSecurityQtnRoute();
  }

  get formControl() {
    return this.editSecurityQuestionForm.controls;
  }

  getSecurityQtnRoute() {
    this.route.params.subscribe(params => {
      this.securityQuestionRoute = params['route'];
    });
  }

  ngOnInit() {
    this.isUserLogin = !!(this.securityQuestionRoute === 'forgotPassword' || this.securityQuestionRoute === 'homePage');
    this.createForm();
  }

  createForm(): void {
    this.editSecurityQuestionForm = this.fb.group({
      question: ['', [Validators.required, Validators.maxLength(this.lengthValidation.securityQuestionMaxLength),
        Validators.minLength(this.lengthValidation.securityQuestionMinLength), Validators.pattern(this.patternValidation.securityQA)]],
      answer: ['', [Validators.required, Validators.maxLength(this.lengthValidation.securityAnswerMaxLength),
        Validators.pattern(this.patternValidation.securityQA)]]
    });
  }

  onNavigateBack() {
    this.router.navigateByUrl(this.routes.settings);
  }

  populateDocument() {
    this.changeSecurityQtnAndAnsRequest.securityQuestion = this.editSecurityQuestionForm.value['question'];
    this.changeSecurityQtnAndAnsRequest.securityAnswer = this.editSecurityQuestionForm.value['answer'];
    return this.changeSecurityQtnAndAnsRequest;
  }

  onSubmit() {
    if (this.editSecurityQuestionForm.valid) {
      this.changeSecurityQuestion();
    }
  }

  changeSecurityQuestion() {
    this.settingsService
      .changeSecurityQA(this.populateDocument())
      .pipe(first())
      .subscribe(
        data => {
          if (data['code'] === '0') {
            this.commonService
              .setConfirmationModal({
                header: this.content.settings.securityQuestions.confirmation.body
              })
              .subscribe({
                next: () => {
                  if (this.securityQuestionRoute === 'forgotPassword') {
                    this.router.navigate([this.routes.resetPassword]);
                  } else if (this.securityQuestionRoute === 'homePage') {
                    this.router.navigate([this.routes.home]);
                  } else {
                    this.router.navigate([this.routes.settings]);
                  }
                }
              });
          } else {
            this.setErrorValidation(data['code']);
          }
          this.setGTMEvent(data);
        }
      );
  }

  setGTMEvent(data) {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Form Submit Button',
      eventLabel: 'Update Security Question Submit Button',
      eventValue: `Update Security Question For: ${this.userId}`,
      userId: sessionStorage.getItem('userId'),
      error: {
        code: data ? data['code'] : '',
        message: data ? data['developerMessage'] : ''
      }
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  setErrorValidation(code) {
    if (code === '99') {
      this.commonService.setValidationErrorModal(this.content.validations.settings.changeLoginIdError);
    } else {
      this.router.navigate([this.routes.login]);
      this.commonService.setValidationErrorModal(this.content.validations.maintenanceError);
    }
  }
}
