<div>
  <div class="row text-center">
    <div class="col-6 text-start">
      <h3 class="fw-bold">{{content.accountInfo.personal}}</h3>
    </div>
    <div class="col-6 text-end">
      <a href="javascript:void(0);" (click)="editDetails()" class="my-auto edit-text" id="edit-icon"><b style="font-size: .8rem;">{{content.accountInfo.edit}}
        </b>&nbsp; <i class="far fa-edit fa-lg"></i></a>
    </div>
  </div>
  <hr style="border: 1px solid grey">

  <div *ngIf="accountInfo">
    <div class="row">
      <div class="col-6 text-start">
        <p class="mb-0">{{content.accountInfo.firstName}}:</p>
        <span><strong>{{accountInfo.firstName | titlecase}}</strong></span>
      </div>
      <div class="col-6 text-start">
        <p class="mb-0">{{content.accountInfo.lastName}}:</p>
        <span><strong>{{accountInfo.lastName | titlecase}}</strong></span>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col">
        <p class="mb-0">{{content.accountInfo.streetAddress}}:</p>
        <span><strong>{{accountInfo.address1 | titlecase}}</strong></span>
      </div>
    </div>
    <br *ngIf="accountInfo.address2">
    <div class="row" *ngIf="accountInfo.address2">
      <div class="col">
        <p class="mb-0">{{content.accountInfo.line2}}:</p>
        <span><strong>{{accountInfo.address2 | titlecase}}</strong></span>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-4 col-lg-5 text-start">
        <p class="mb-0">{{content.accountInfo.city}}:</p>
        <span><strong>{{accountInfo.city | titlecase}}</strong></span>
      </div>
      <div class="col-4">
        <p class="mb-0">{{content.accountInfo.state}}:</p>
        <span><strong>{{accountInfo.state}}</strong></span>
      </div>
      <div class="col-4 col-lg-3">
        <p class="mb-0">{{content.accountInfo.zip}}:</p>
        <span><strong>{{accountInfo.zipCode | mask: '00000'}}</strong></span>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col text-start">
        <p class="mb-0">{{content.accountInfo.emailAddress}}:</p>
        <span><strong>{{accountInfo.email | lowercase}}</strong></span>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-6 text-start">
        <p class="mb-0">{{content.accountInfo.homePhone}}:</p>
        <span style="text-overflow: ellipsis;white-space: nowrap;"><strong>{{setPhoneNumber(accountInfo.homePhone)}}</strong></span>
      </div>
<!--      <div class="col-6 text-end" *ngIf="accountInfo.workPhone">-->
<!--        <p class="mb-0">{{content.accountInfo.mobilePhone}}:</p>-->
<!--        <span style="text-overflow: ellipsis;white-space: nowrap;"><strong>{{setPhoneNumber(accountInfo.workPhone)}}</strong></span>-->
<!--      </div>-->
    </div>
  </div>
</div>
