import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {CommonService} from '../../shared/services/common.service';
import {CONTENT} from '../../content-management/content';
import { SplunkLogRequest } from '../../models/splunk-log-request';
import { SplunkLogLog } from '../../models/splunk-log-log';
import { SplunkLogEvent } from '../../models/splunk-log-event';
import { environment } from '../../../environments/environment';

declare let newrelic: any;

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
  private content = CONTENT;
  private isSessionOutWindowOpened = false;

  constructor(private commonService: CommonService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isSplunkLogging = request.url.toLowerCase().indexOf('client-logs-cloud') !== -1;
    const cardProfile = request.url.toLowerCase().indexOf('cardprofiles') !== -1;
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (!(isSplunkLogging || cardProfile)) {
        if (error.status === 500 || error.status === 401 || error.status === 403 ||
          error.status === 413 || error.status === 408 || error.status === 429) {
          if (error.status === 401 || error.status === 403 || error.error['code'] === 401 || error.error['code'] === 403 || (error.error.standardMessage && (error.error.standardMessage.code === 401 ||error.error.standardMessage.code === 403))) {
            if (!this.isSessionOutWindowOpened) {
              this.isSessionOutWindowOpened = true;
              this.commonService.setSessionOutErrorModal(this.content.validations.sessionoutError);
            }
          } else if (error.status === 413 || error.status === 408 || error.error['code'] === 413 || error.error['code'] === 408) {
            this.commonService.setValidationErrorModal(this.content.validations.maintenanceError);
          } else {
            this.commonService.setValidationErrorModal(this.content.validations.maintenanceError);
          }
        }

        this.logError(error);
      }

      this.noticeNewRelicError(error);

      // return throwError(error.error);
      return throwError(error);
    }));
  }

  noticeNewRelicError(error) {
    if (typeof newrelic !== 'undefined') {
      newrelic.noticeError(
        error.message,
        {
          'messageId': error.error.messageID,
          'developerMessage': error.error.developerMessage,
          'memberId': sessionStorage.getItem('userId'),
          'memberName': sessionStorage.getItem('memberName')
        }
      );
    }
  }

  logError(error: HttpErrorResponse) {
    const event = new SplunkLogEvent();
    event.app = 'pet_nmp';
    event.level = 'error';
    event.env = environment.env;
    event.code = error.status.toString();
    event.message = error.statusText;
    event.url = error.url;

    if (error.error) {
      event.userMessage = error.error['userMessage'];
      event.developerMessage = error.error['developerMessage'];
      event.messageId = error.error['messageID'];
    }

    const log = new SplunkLogLog();
    log.event = JSON.stringify(event);
    log.fields = '{}';
    log.sourceType = 'nmp_json';

    const payLoad = new SplunkLogRequest();
    payLoad.splunkToken = environment.splunkToken;
    payLoad.logs = [];
    payLoad.logs.push(log);

    this.commonService.logErrorToSplunk(payLoad).subscribe(x => { });
  }
}
