import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ValidatePolicyRequest} from '../../models/request/validate-policy-request';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ValidatePolicyResponse} from '../../models/response/validate-policy-response';

@Injectable({
  providedIn: 'root'
})
export class CreatePortalAccountService {
  private environment = environment;

  constructor(private http: HttpClient) {
  }

  public validatePolicy(validatePolicyRequest: ValidatePolicyRequest): Observable<ValidatePolicyResponse> {
    return this.http.post<ValidatePolicyRequest>(this.environment.baseUrl + 'members/v1/validation', validatePolicyRequest)
      .pipe(map((policy) => policy as ValidatePolicyResponse));
  }
}
