<div class="modal-header" class="p-2">
  <div class="col-12 p-3">
  <div class="alert alert-primary" role="alert">{{validateMessage}}</div>
</div>
  <!-- <ngb-alert [dismissible]="false" type="primary" class="col-12 p-1">{{validateMessage}}</ngb-alert> -->
</div>
<div *ngIf="data" class="modal-body">
  <p class="text-center"><strong>You entered:</strong></p>
  <p class="text-center">
    {{data.address1}}{{data.address2 ? ', ' + data.address2 : ''}}
  </p>
  <p class="text-center">
    {{data.city}}, {{data.state}}, {{data.zipCode}}
  </p>
</div>
<div class="modal-footer d-block text-center p-3">
  <div class="mx-auto">
    <button type="button" class="btn-primary" (click)="editClose()" id="editAddress"><span
        class="addressButton">Edit this address</span></button>
    <button type="button" class="btn-primary ms-1 ms-sm-3 adrsBtn" (click)="chooseClose()" id="chooseAddress"><span
        class="addressButton">Choose this address</span></button>
  </div>
</div>