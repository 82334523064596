<div>
  <div class="container pb-5">
    <div class="container-title">
      <!-- <app-navigation-back id="upperBackButton" (navigateBackEvent)="goToHome()"
      class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back> -->
      <h1 class="fw-bold">Payment History</h1>
    </div>
    <hr style="border: 1px solid grey" />
    <div class="matselect-dropdown">
      <form [formGroup]="selectPetForm" *ngIf="selectPetForm && policyNumber">
        <div class="col-12 mx-auto">
          <mat-form-field appearance="outline" class="material-input-full-width">
            <mat-label>Pet</mat-label>
            <mat-select (selectionChange)="changePayment($event)" formControlName="policyNumber" id="policyNumber">
              <mat-option *ngFor="let policy of policyDetailslist" [value]="policy.policyNumber">
                {{ policy.pet.name | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <br />
    <!-- <div class="d-none d-md-block">
  <div class="row table-border">
    <div class="col-2"><b>Transaction Date</b></div>
    <div class="col-2"><b>Account Type</b></div>
    <div class="col-2"><b>Account Number</b></div>
    <div class="col-2"><b>Amount</b></div>
    <div class="col-2"><b>Status</b></div>
    <div class="col-2"><b>Receipt</b></div>
  </div>
  <div class="row table-border" *ngFor = "let trans of paymentTransactions">
    <div class="col-2">{{trans.transactionDate}}</div>
    <div class="col-2">{{trans.paymentType}}</div>
    <div class="col-2">{{handleMask(trans.accountNumber)}}</div>
    <div class="col-2">${{trans.amount}}</div>
    <div class="col-2">{{trans.status | titlecase}}</div>
    <div class="col-2"><a (click)="getPreFilledForm(pet.policyNumber, pet.renewalNumber)" href="javascript:void(0);">View Receipt</a></div>
  </div>
</div> -->
    <!-- <div class="d-block d-md-none"> -->
    <div class="wrapper">

      <div class="table" *ngIf="isPayHistoryReceived">

        <div class="row header">
          <div class="cell">
            Transaction Date
          </div>
          <div class="cell">
            Description
          </div>
          <div class="cell">
            Payments
          </div>
          <div class="cell">
            Reversals/Refunds
          </div>
          <div class="cell">
            Payment Type
          </div>
          <div class="cell">
            Account Type
          </div>
          <div class="cell">
            Account Number
          </div>
          <!-- <div class="cell">
            Status
          </div> -->
          <div class="cell">
            Receipt
          </div>
        </div>

        <div class="row" *ngFor="let trans of paymentTransactions">
          <div class="cell" data-title="Transaction Date">
            {{trans.transactionDate}}
          </div>
          <div class="cell" data-title="Description">
            {{trans.transactionType | titlecase}}
          </div>     
          <div class="cell" data-title="Payments">
            {{trans.amount | currency}}
          </div>
          <div class="cell" data-title="Reversals/Refunds">
            {{trans.refund | currency}}
          </div>
          <div class="cell" data-title="Payment Type">
            {{trans.paymentMethod | titlecase}}
          </div>
          <div class="cell" data-title="Account Type">
            {{trans.paymentType}}
          </div>
          <div class="cell" data-title="Account Number">
            <!-- {{handleMask(trans.accountNumber)}} -->
            {{trans.accountNumber}}
          </div>
          <!-- <div class="cell" data-title="Status">
            {{trans.status | titlecase}}
          </div> -->
          <div class="cell" data-title="Receipt">
            <a (click)="getReceipt(trans)" href="javascript:void(0);">View Receipt</a>
          </div>
        </div>


      </div>

    </div>
    <!-- </div> -->
  </div>
</div>
<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>