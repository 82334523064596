<div class="container">
  <div class="row text-center pb-4">
    <div class="col">
      <!--<div class="col-xs-12 covid-19">
        <div class="row">
          <div class="d-none d-sm-block"><img alt="information_icon" id="information-icon" src="{{imageConstants.svgLogos.infoIcon}}"></div>
          <div class="col-sm-11 col-xs-12 ">
            <h3>Coronavirus resources</h3>
            <p><a href="https://www.petinsurance.com/covid-19" target="_blank">Learn what steps Nationwide is taking and how you can care for your pet (and others)</a></p>
          </div>
        </div>
      </div>-->
      <img *ngIf="bannerDesktopUrl" class="mb-2" id="deskBanner" alt="{{bannerDesktopTitle}}" src="{{ bannerDesktopUrl }}" />
      <img *ngIf="bannerMobileUrl" class="mb-2" id="mobileBanner" alt="{{bannerMobileTitle}}" src="{{ bannerMobileUrl }}" style="width: 100%;" />
      <h5 class="fw-bold">{{ content.loginPage.heading }}</h5>
    </div>
  </div>

  <div class="row">
    <div class="col pt-1">
      <form [formGroup]="loginForm">

        <div class="row pb-5 pl-lg-5">
          <div class="col-lg-7 col-md-7 col-sm-7 leftDiv">

            <div class="loginDiv">
              <h5><strong>Log in to your account</strong></h5>
              <button mat-button id="button-login" type="submit" color="primary" class="custom-login-button btn-block" (click)="navigateToNationwideLoginPage('login')">{{ content.loginPage.alreadyUpdated}}</button>
            </div>

            <div class="registerDiv">
              <h5 id="createAccountH1"><strong>Register a new account</strong></h5>
              <button mat-button id="button-createAccount" type="submit" color="primary" class="custom-account-button  btn-block" (click)="navigateToNationwideAccountsScreen('Sign up for account access', createAccountUrl)">{{ content.loginPage.createAccountShort }}</button>
            </div>
            
          </div>
          <div class="col-lg-5 col-md-5 col-sm-5 dividers rightDiv">
            <div class="right-content">
              <h5><strong>Log in to your account to:</strong></h5>
              <ul class="list">
                <li class="list-item">Submit claims</li>
                <li class="list-item">Check claim status</li>
                <li class="list-item">Update your account info</li>
                <li class="list-item">Manage preferences</li>
                <li class="list-item">And more</li>
              </ul>
            </div>
          </div>

        </div>

        <!-- <div class="row justify-content-center pb-5 pl-lg-5">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <p class="custom-p-tag">Log in with your updated nationwide.com account information</p>
            <button mat-raised-button id="button-login" type="submit" color="primary" class="custom-login-button btn-block" (click)="navigateToNationwideAccountsScreen('login', loginUrl)">{{ content.loginPage.login }}</button>
          </div>
        </div>

        <div class="row justify-content-center pb-5 pl-lg-5">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <p class="custom-p-tag">Sign up for new account access</p>
            <button mat-raised-button id="button-createAccount" type="submit" color="primary" class="custom-login-button btn-block" (click)="navigateToNationwideAccountsScreen('Sign up for account access', createAccountUrl)">{{ content.loginPage.createAccount }}</button>
          </div>
        </div> -->
<!--
        <button mat-raised-button id="button-login" type="submit" color="primary" class="custom-login-button btn-block" (click)="navigateToNationwideLoginPage()">{{ content.loginPage.login }}</button>
-->
      </form>
    </div>
  </div>

  <!--<div class="row">
    <div class="col-lg-5 col-md-12">
     &lt;!&ndash; <form [formGroup]="loginForm" (ngSubmit)="reCaptchaEnabled ? reCaptcha.execute() : logIn()">&ndash;&gt;
      <form [formGroup]="loginForm">

        <div class="row justify-content-center pb-5 pl-5">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <button mat-raised-button id="button-login" type="submit" color="primary" class="custom-login-button btn-block" (click)="navigateToNationwideLoginPage('login')">{{ content.loginPage.login }}</button>
          </div>
        </div>

        <div class="row justify-content-center pb-5">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <button mat-raised-button id="button-fup" type="submit" color="primary" class="custom-login-button btn-block" (click)="navigateToNationwideLoginPage('Forgot username or password')">{{ content.loginPage.forgotIdAndPassword }}</button>
          </div>
        </div>

        <div class="row justify-content-center pb-5">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <button mat-raised-button id="button-createAccount" type="submit" color="primary" class="custom-login-button btn-block" (click)="navigateToNationwideLoginPage('Sign up for account access')">{{ content.loginPage.createAccount }}</button>
          </div>
        </div>
        &lt;!&ndash;<div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <mat-icon matPrefix>account_circle</mat-icon>
                    <input matInput id="input-loginId" type="text" maxlength="64" formControlName="loginId" class="material-input-form" placeholder="Enter Login ID" required />
                    <mat-label><b>Login ID</b></mat-label>
                    <mat-error id="loginId-error">{{ showLoginIdErrors('loginId') }}</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                    <mat-icon matPrefix>vpn_key</mat-icon>
                    <input matInput id="input-password" maxlength="65" formControlName="password" class="material-input-form" [type]="hide ? 'password' : 'text'" required />
                    <mat-label><b>Password</b></mat-label>
                    <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    <mat-error id="password-error">{{ showPasswordErrors('password') }}</mat-error>
                    <br />
                </mat-form-field>
            </div>
        </div>
        <div class="row pt-3">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label data-toggle="collapse" data-target="#rememberMe">
      <mat-checkbox id="checkbox-rememberMe" color="primary" class="custom_rememberMe" (change)="isRememberMeChecked($event)" [disabled]="!loginForm.valid">{{ content.loginPage.rememberMe }}</mat-checkbox>
    </label>
            </div>
        </div>
        <div id="rememberMe" class="collapse">
            <p><strong>Note:</strong> {{ content.loginPage.rememberMeInfo }}</p>
        </div>&ndash;&gt;
&lt;!&ndash;
        <re-captcha id="reCaptcha" #reCaptcha size="invisible" (resolved)="$event && validateReCaptcha($event)" [siteKey]="reCaptchaSiteKey" *ngIf="reCaptchaEnabled"></re-captcha>
&ndash;&gt;

        &lt;!&ndash;<button mat-raised-button id="button-forgotidAndPassword" type="submit" color="primary" class="custom-login-button btn-block" (click)="navigateToForgotIdAndPassword()">{{ content.loginPage.forgotIdAndPassword }}</button>

        <button mat-raised-button id="button-createAccount" (click)="goToRegister()" color="primary"
                class="custom-login-button custom-account-button btn-block">{{ content.loginPage.createAccount }}</button>&ndash;&gt;

       &lt;!&ndash; <div>
          <br/>
          <p class="no_bottom_margin">
            <a href="javascript:void(0);" id="anchorLink-forgotId" (click)="navigateToForgotId()">
              <u>{{ content.loginPage.forgotId }}</u>
            </a>
          </p>
          <p class="no_bottom_margin">
            <a href="javascript:void(0);" id="anchorLink-forgotPassword" (click)="navigateToForgotPassword()">
              <u>{{ content.loginPage.forgotPassword }}</u>
            </a>
          </p>
          <p class="no_bottom_margin">
            <a href="{{ hyperlinkConstants.loginPage.needHelp }}" target="_blank" id="anchorLink-needHelp" (click)="navigateToLoginHelp()">
              <u>{{ content.loginPage.needHelp }}</u>
            </a>
          </p>
        </div>&ndash;&gt;
        <br/>
        &lt;!&ndash;
                        <button mat-raised-button id="button-createAccount" (click)="goToRegister()" color="primary" class="custom-login-button custom-account-button btn-block">{{ content.loginPage.createAccount }}</button>
        &ndash;&gt;
      </form>
    </div>
&lt;!&ndash;    <div class="col-lg-4 d-none d-lg-block divider pt-3 pb-3 homepage_pet_info">
      <div *ngFor="let info of petInfo">
        <app-pet-info [petInformation]="info"></app-pet-info>
      </div>
      <div class="pb-5"></div>
      <div class="font-weight-light text-xs-left">
        <p class="terms_fontsize">{{ content.loginPage.info.termsAndConditions }}</p>
      </div>
    </div>
    <div class="col-lg-4 d-none d-lg-block pt-3 pb-3">
        <img alt="{{loginImageTitle}}" id="dog_image" src="{{ loginImageUrl }}" />
    </div>&ndash;&gt;
  </div>-->
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>