<h1 mat-dialog-title class="text-danger text-center" [innerHTML]="data.title || 'Error'"></h1>
<hr/>
<div mat-dialog-content class="font-bold">
  <p class="text-danger" [innerHTML]="data.header || ''"></p>
  <ol>
    <li class="text-danger" *ngFor="let file of data.files"><b>{{file}}</b></li>
  </ol>
  <p class="text-danger" [innerHTML]="data.headers1 || ''"></p>
  <p [innerHTML]="data.content || ''"></p>
 
</div>
<hr/>
<div mat-dialog-actions class="float-end">
  <button mat-raised-button class="custom-modal-button" color="primary" (click)="close()">Ok</button>
</div>
