<h1 mat-dialog-title class="text-center text-success" [innerHTML]="data.title || content.validations.confirmationModal.heading"></h1>
<hr />
<div mat-dialog-content class="font-bold">
  <p [innerHTML]="data.header || ''"></p>
  <p [innerHTML]="data.content || ''"></p>
</div>
<hr />
<div mat-dialog-actions class="float-end">
  <button mat-raised-button class="custom-modal-button" color="primary" (click)="close()">Ok</button>
</div>
