<div class="container text-center pb-5">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="navigateToLoginPage()" id='topBackButton' class=" pull-left d-inline pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{content.forgotPasswordPage.heading}}</h1>
  </div>
  <hr style="border: 1px solid grey">
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10 col-sm-12">
            <p class="text-left"><b>No Problem.</b> {{content.forgotPasswordPage.info}}</p>
          </div>
        </div>
        <mat-card-content class="pt-2 pb-3">
          <form [formGroup]="forgotPasswordForm">
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>account_box</mat-icon>
                  <mat-label><b>Login ID</b></mat-label>
                  <input matInput id="loginId" type="text" formControlName="loginId" class="material-input-form" placeholder="Enter Login ID" required>
                  <mat-error *ngIf="formDetails.loginId.errors && formDetails.loginId.errors.required">Login ID is Required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>local_shipping</mat-icon>
                  <mat-label><b>Zip Code</b></mat-label>
                  <input matInput id="zipCode" maxlength="5" type="text" formControlName="zipCode" class="material-input-form" placeholder="Enter Zip Code" required>
                  <mat-error *ngIf="formDetails.zipCode.errors && formDetails.zipCode.errors.required">Zip code is required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>pets</mat-icon>
                  <mat-label><b>Pet Name</b></mat-label>
                  <input matInput id="petName" type="text" formControlName="petName" class="material-input-form" placeholder="Enter Pet Name" required>
                  <mat-error *ngIf="formDetails.petName.errors && formDetails.petName.errors.required">Pet Name is Required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row custom-forgot-id">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <p><a href="javascript:void(0);" class="float-start" id="forgot-id" (click)="navigateToForgotId()"><u>{{content.forgotPasswordPage.forgotId}}</u></a></p>
              </div>
            </div>
            <br>
            <mat-card-actions>
              <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10 col-sm-12">
                  <button mat-raised-button class="custom-password-mat-button float-end mobile-button" color="primary" id="forgot-id-submit-btn"
                          (click)="onSubmit()" [disabled]="!forgotPasswordForm.valid">{{content.buttons.next}}</button>
                  <app-navigation-back (navigateBackEvent)="navigateToLoginPage()" id='bottomBackButton' class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
                </div>
              </div>
            </mat-card-actions>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
