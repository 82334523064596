<div class="container">
  <div class="container-title">
    <app-navigation-back id='topBackButton' class="pull-left d-inline  pl-1 d-md-none" (navigateBackEvent)="onNavigateBack()"></app-navigation-back>
    <h1 class="fw-bold">{{content.settings.heading}}</h1>
  </div>
  <hr style="border: 1px solid grey">
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <h5 class="fw-bold">{{content.settings.subHeading}}</h5>
        <p>{{content.settings.info}}</p>
        <br>
        <mat-card-content class="pt-3 pb-3">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <h5><b>{{content.settings.login.heading}}</b> <a class="float-end cursor_pointer" href="javascript:void();" (click)="navChangeLoginID()"><b>{{content.buttons.edit}}</b> &nbsp;<i class="far fa-edit fa-lg"></i></a></h5>
              <hr style="border: 1px solid grey">
            </div>
          </div>
          <br>
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <h5><b>{{content.settings.password.heading}}</b> <a class="float-end cursor_pointer" href="javascript:void();" (click)="navChangePassword()"><b>{{content.buttons.edit}}</b> &nbsp;<i class="far fa-edit fa-lg"></i></a>
              </h5>
              <hr style="border: 1px solid grey">
            </div>
          </div>
         <!-- <br>
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <h5><b>{{content.settings.securityQuestions.heading}}</b> <a class="float-end cursor_pointer" href="javascript:void();" (click)="navChangeSecQs()"><b>{{content.buttons.edit}}</b> &nbsp;<i class="far fa-edit fa-lg"></i></a></h5>
            </div>
          </div>-->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
