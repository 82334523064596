<footer class="d-block d-md-none mobile_footer_legal">
  <div class="container">
    <div class="row">
    <div *ngIf="showSideNav" class="col-12">
      <p style="margin-bottom: 10px;  ">
        Products underwritten by Veterinary Pet Insurance Company (CA), Columbus, OH; National Casualty Company (all other states), Columbus, OH. Agency of Record: DVM Insurance Agency. All are subsidiaries of Nationwide Mutual Insurance Company. Subject to underwriting guidelines, review and approval. Products and discounts not available to all persons in all states. Insurance terms, definitions and explanations are intended for informational purposes only and do not in any way replace or modify the definitions and information contained in individual insurance contracts, policies or declaration pages, which are controlling. Nationwide, the Nationwide N and Eagle, Nationwide is on your side, and vethelpline are service marks of Nationwide Mutual Insurance Company. ©2021 Nationwide.
      </p>
    </div>
    <div *ngIf="!showSideNav" class="col-12">
      <p>
        Products underwritten by Veterinary Pet Insurance Company (CA), Columbus, OH; National Casualty Company (all other states), Columbus, OH. Agency of Record: DVM Insurance Agency. All are subsidiaries of Nationwide Mutual Insurance Company. Nationwide, the Nationwide N and Eagle, and Nationwide is on your side are service marks of Nationwide Mutual Insurance Company. ©2022 Nationwide.
      </p>
    </div>
  </div>
</div>
</footer>
<footer *ngIf="showSideNav" class="d-block d-md-none mobile_footer">
  <div class="container">
    <div class="row custom_icon-style">
      <div class="col-3"><a href="javascript:void(0);" [ngClass]="{'active': currentURL === routingConstants.home}"  (click)="goToHome()">
        <img class="bordered" id="icon-home" alt="home_icon" src="{{imageConstants.svgLogos.footerHomeIcon}}">
        <img class="filled" id="icon-filled-home" alt="home_icon" src="{{imageConstants.svgLogos.footerFilledHomeIcon}}">
        Home</a>
      </div>
      <div class="col-3"><a href="javascript:void(0);" [ngClass]="{'active': currentURL === routingConstants.claims}"  (click)="goToClaims()">
        <img class="bordered" alt="claim_icon" id="icon-claim" src="{{imageConstants.svgLogos.footerClaimIcon}}">
        <img class="filled" alt="home_icon" id="icon-filled-claim" src="{{imageConstants.svgLogos.footerFilledClaimIcon}}">
        Claims
      </a></div>
      <div class="col-3"><a href="javascript:void(0);" [ngClass]="{'active': currentURL === routingConstants.pets}"  (click)="goToPets()">
        <img class="bordered" alt="paw_icon" id="icon-paw" src="{{imageConstants.svgLogos.footerPawIcon}}">
        <img class="filled" alt="home_icon" id="icon-filled-paw" src="{{imageConstants.svgLogos.footerFilledPawIcon}}">Pets</a></div>
      <div class="col-3"><a href="javascript:void(0);" [ngClass]="{'active': currentURL === routingConstants.account}"  (click)="goToAccounts()">
        <img class="bordered" alt="user_icon" id="icon-user" src="{{imageConstants.svgLogos.footerUserIcon}}">
        <img class="filled" alt="home_icon" id="icon-filled-user" src="{{imageConstants.svgLogos.footerFilledUserIcon}}">Accounts</a>
      </div>
    </div>
  </div>
</footer>
<footer class="align-self-end desktop_footer">
  <div class="container">
    <div class="row">
    <div *ngIf="showSideNav" class="col-10">
      <p [innerHTML]="footerTxtAfterLogin"></p> 
    </div> 
    <div *ngIf="!showSideNav" class="col-10">
      <p [innerHTML]="footerTxtBeforeLogin"></p>
      <div  class="col-sm-12 footer_a_link text-center" id="teconsent"></div>
    </div>
    <div class="col-2">
      <img src="./../../../../assets/images/Nationwide-N-and-Eagle.png" id="logo-nationwide" alt="Nationwide logo">
    </div>
   </div>
  </div>
</footer>

