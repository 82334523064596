import {AccountInfo} from '../../../../../models/account-info';
import {AccountRequest} from '../../../../../models/account-request';
import {AccountService} from '../../../../../shared/services/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from '../../../../../shared/services/common.service';
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {CONTENT} from '../../../../../content-management/content';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidationConstants} from '../../../../../shared/constants/form-validation-constants';
import {ROUTINGCONSTANTS} from '../../../../../shared/constants/routing-constants';
import {MyPetsService} from '../../../my-pets.service';
import STATECODES from '../../../../../shared/constants/state-codes';
import {CancelReason} from '../../../../../models/cancel-reason';
import * as _ from 'lodash';
import {PolicyDetails} from '../../../../../models/policy-details';
import {Claim} from '../../../../../models/claim';
import {ClaimsService} from '../../../../claims/all-claims/claims.service';
import {BillingPaymentService} from '../../../../../shared/services/billing-payment.service';
import {DataService} from '../../../../../core/services/data.service';
import {CancelDateParams} from '../../../../../models/cancel-date-params';
import {TitleCasePipe} from '@angular/common';
import {EncrDecrService} from '../../../../../core/services/encr-decr.service';
import { JwtService } from '../../../../../core/services/jwt.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cancellation-form',
  templateUrl: './cancellation-form.component.html',
  styleUrls: ['./cancellation-form.component.scss']
})
export class CancellationFormComponent implements OnInit {
  public accountInfo: AccountInfo;
  public addressForm: UntypedFormGroup;
  public content = CONTENT;
  public defaults = CONTENT.accountInfo.myAccount.defaults;
  public error: string;
  public errorMessages = CONTENT.accountInfo.myAccount.errorMessages;
  public formValidation = FormValidationConstants;
  public insuredCode: string;
  public labels = CONTENT.accountInfo.myAccount.labels;
  public petName: string;
  public placeHolders = CONTENT.accountInfo.myAccount.placeHolders;
  public policy: PolicyDetails;
  public policyNumber: string;
  public reasons: CancelReason[] = [];
  public reasonSelected = false;
  public stateCodes = STATECODES;
  public claims: Claim[] = [];
  public closedClaimsList: Claim[] = [];
  public pendingClaimsList: Claim[] = [];
  public gracePeriod = 10;
  public routingConstants = ROUTINGCONSTANTS;
  public displayProgressSpinner = 0;
  public policyDetailsList = [];
  public opened = false;
  public addressRequest = {address1: '', address2: '', city: '', state: '', zipCode: ''};
  public addressResponse:any = {address1: '', address2: '', city: '', state: '', zipCode: ''};
  public isCAPolicy:boolean;
  public flatRateFlag: boolean = false;
  public isDenyClaimOption:boolean=true;
  public inputCancelationDate: string = '';
  @ViewChild('focusAddr1') focusAddr1: ElementRef;

  constructor(private accountService: AccountService,
              private billingPaymentService: BillingPaymentService,
              private claimsService: ClaimsService,
              private modalService: NgbModal,
              private commonService: CommonService,
              private dataService: DataService,
              private encDcrService: EncrDecrService,
              private fb: UntypedFormBuilder,
              private titleCasePipe: TitleCasePipe,
              private myPetsService: MyPetsService,
              private route: ActivatedRoute,
              private router: Router,
              private jwtService: JwtService) {
    
  }

  ngOnInit() {
    this.getInitialLoad();
  }

getInitialLoad(){
  if(sessionStorage.getItem('policyList')){
    if(this.displayProgressSpinner>0){
      this.displayProgressSpinner--;
    }
    this.getPolicyNumberFromRoutes();
    this.getPolicy(this.policyNumber);
    this.getAccountInfo();
    this.getCancelReasons();
  }else{
    if(this.displayProgressSpinner==0){
      this.displayProgressSpinner++;
    }
    setTimeout(() => { 
    this.getInitialLoad();
    },250);
  }

}

  getRenewal(): number {
    return Number(this.policy.renewalNumber);
  }

  getCancelReasonText(cancelCode: string) {
    return this.reasons.reduce((acc, curr) => curr.code === cancelCode ? this.titleCasePipe.transform(curr.reason) : acc, '');
  }

  getClaims() {
    this.displayProgressSpinner++;
    this.claimsService.getClaimSummary(this.policyNumber, this.getRenewal()).subscribe({
      next: claims => {
       // this.claims = claims.claims;
       const claimSummary: any= claims;
       if(claimSummary && claimSummary.ClaimSummaryResponse){          
          this.claims = claimSummary.ClaimSummaryResponse.ClaimSummary;
          this.closedClaimsList = this.getClosedClaimsList();
          this.pendingClaimsList = this.getPendingClaimsList();
       }
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  setGracePeriod(state) {
    // this.gracePeriod = state === 'CA' ? 30 : 10;
    this.gracePeriod = 10;
    if(state === 'ME') {
      this.gracePeriod = 15;
    }
    if(state === 'CA') {
      this.gracePeriod = 30;
    }
  }

  getPendingClaimsList() {
    return this.claims.filter(claim => claim.status && claim.status.toUpperCase() !== 'COMPLETE');
  }

  getClosedClaimsList() {
    return this.claims.filter(claim => claim.status && claim.status.toUpperCase() === 'COMPLETE');
  }

  isLostPet() {
    return (this.addressForm && (this.addressForm.value.reason === '12' || this.addressForm.value.reason === 'L'));
  }

  isPetPassed() {
    return (this.addressForm && (this.addressForm.value.reason === '11' || this.addressForm.value.reason === 'D'));
  }

  alphabetsOnly(event: KeyboardEvent): boolean {
    return (event.key >= 'A' && event.key <= 'Z') || (event.key >= 'a' && event.key <= 'z') || event.key === ' ' || event.key === ' Backspace';
  }

  applyAccountInfo(accountInfo: AccountInfo) {
    const newAccountInfo: AccountInfo = JSON.parse(JSON.stringify(accountInfo));
    newAccountInfo.homePhone = (accountInfo.homePhone && accountInfo.homePhone.substring(0, 3) + '-' + accountInfo.homePhone.substring(3, 6) + '-' + accountInfo.homePhone.substring(6, accountInfo.homePhone.length)) || '';
    newAccountInfo.workPhone = (accountInfo.workPhone && accountInfo.workPhone.substring(0, 3) + '-' + accountInfo.workPhone.substring(3, 6) + accountInfo.workPhone.substring(6, accountInfo.workPhone.length)) || '';
    newAccountInfo.homePhone = newAccountInfo.homePhone.replace(/[^a-zA-Z0-9]/g, '');
    newAccountInfo.workPhone = newAccountInfo.workPhone.replace(/[^a-zA-Z0-9]/g, '');
    this.accountInfo = newAccountInfo;
  }

  applyFormData(): AccountRequest {
    const accountRequest: AccountRequest = {
      accountInfo: {
        ...this.accountInfo,
        city: this.addressResponse.city?this.addressResponse.city:this.addressForm.value.city,
        homePhone: this.addressForm.value.phone,
        state: this.addressResponse.state?this.addressResponse.state:this.addressForm.value.state,
        streetAddress1: this.addressResponse.address1?this.addressResponse.address1:this.addressForm.value.address,
        streetAddress2: this.addressResponse.address2?this.addressResponse.address2:this.addressForm.value.address1,
        zipCode: this.addressResponse.zipCode?this.addressResponse.zipCode:this.addressForm.value.zipCode
      },

      updateType: 'PERSONAL'
    };
    return accountRequest;
  }

  cancelPolicy(policyNumber: string, renewalNumber) {
    const cancelRequest = {
      type: 'I',
      rate: this.getRate(this.policy.effectiveDate, this.policy.cancelEffectiveDate),
      reason: this.getCancelReasonText(this.addressForm.value.reason),
      code: this.addressForm.value.reason,
      effectiveDate: this.getNewCancellationDate(false).replace(/[^ -~]/g, ''),
      policyEffectiveDate: this.policy.effectiveDate
    };
    this.displayProgressSpinner++;
    this.myPetsService.cancelPolicy(policyNumber, cancelRequest, renewalNumber).subscribe({
      next: (data) => {
        this.checkForCancellationErrors(data);
      },
      error: () => {
        this.displayProgressSpinner--;
        this.commonService.setDialogModal({
          title: 'Error',
          header: 'Error cancelling policy.',
          content: ''
        });
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  checkForCancellationErrors(data) {
    if (data && data.standardMessage && data.standardMessage.code === '73') {
      this.commonService.setDialogModal({
        title: 'Looks like there\'s been a faux paws',
        header: 'Our records indicate that your pet’s policy has a pending Policy Renewal in process.',
        content: 'Please contact our Customer Care department at 844-397-8938 to complete your request. Our Customer Care team is available Monday-Friday 5am-7pm and Saturday 7am-3:30pm (Pacific).'
      });
    } else if (data && data.standardMessage && data.standardMessage.code === '71') {
      this.commonService.setDialogModal({
        title: 'Looks like there\'s been a faux paws',
        header: 'Our records indicate that your pet’s policy has a already been Cancelled.',
        content: 'Please contact our Customer Care department at 844-397-8938 to complete your request. Our Customer Care team is available Monday-Friday 5am-7pm and Saturday 7am-3:30pm (Pacific).'
      }); 
    }else if (data && data.standardMessage && data.standardMessage.code === '99') {
      this.commonService.setDialogModal({
        title: 'Error',
        header: 'Error cancelling policy.',
        content: ''
      });
    }else {
      this.updateSessionStoragePolicy(this.policyNumber, this.policy.renewalNumber || null);
      this.dataService.cancelResponseView = this.getCancelResponseObject();
      this.router.navigate(['/cancellation-response']);
    }
  }

  lostPassedVerb() {
    return this.isLostPet() ? 'was lost or given away' : 'passed away';
  }

  canSubmit(): boolean {
    return this.addressForm && this.addressForm.dirty && this.addressForm.valid && this.addressForm.value.pendingPolicyOptions !== '3';
  }

  showStaticCancelDateField() {
    if(this.reasonSelected && this.isStaticField()){
      this.inputCancelationDate = this.getNewCancellationDate(true);
      return true;
    } 
    return false;
  }

  isStaticField() {
    return this.pendingClaimsList.length || (!this.isLostPet() && !this.isPetPassed() && this.closedClaimsList.length) || (!this.closedClaimsList.length && !this.pendingClaimsList.length && (this.isWithinGracePeriod() || (!this.isLostPet() && !this.isPetPassed())));
  }

  showDatePicker() {
    return this.reasonSelected && !this.isStaticField();
  }

  earliestCancelDate() {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    return this.closedClaimsList.reduce((acc, curr) => {
      const treatmentDate = new Date(curr.treatmentDate);
      return treatmentDate > acc ? treatmentDate : acc;
    }, thirtyDaysAgo);
  }

  customValidators(fg: UntypedFormGroup): any {
    const effectiveDateErrors = fg.get('effectiveDate').errors || {};
    const cancelDate = fg.get('effectiveDate').value;
    const today = new Date();
    if (cancelDate && (cancelDate < this.earliestCancelDate() || cancelDate > today)) {
      effectiveDateErrors['invalidDate'] = true;
    } else {
      delete effectiveDateErrors['invalidDate'];
    }
    const confirmEmpty = _.isEmpty(effectiveDateErrors);
    fg.get('effectiveDate').setErrors(confirmEmpty ? null : effectiveDateErrors);
    fg.get('effectiveDate').markAsDirty();
  }

  setDateError(flag: boolean) {
    const effectiveDateErrors = this.addressForm.get('effectiveDate').errors || {};
    if (flag) {
      effectiveDateErrors['invalidDate'] = true;
    } else {
      delete effectiveDateErrors['invalidDate'];
    }
    const confirmEmpty = _.isEmpty(effectiveDateErrors);
    this.addressForm.get('effectiveDate').setErrors(confirmEmpty ? null : effectiveDateErrors);
    this.addressForm.get('effectiveDate').markAsDirty();
  }

  createForm() {
    this.addressForm = this.fb.group(
      {
        address: [this.titleCasePipe.transform(this.accountInfo.address1), [Validators.required, Validators.pattern(this.formValidation.patternValidation.addressLine1), Validators.maxLength(this.formValidation.lengthValidation.addressLine1Length)]],
        address1: [this.titleCasePipe.transform(this.accountInfo.address2)],
        city: [this.titleCasePipe.transform(this.accountInfo.city), [Validators.required, Validators.pattern(this.formValidation.patternValidation.city)]],
        effectiveDate: [new Date(), Validators.required],
        phone: [this.accountInfo.homePhone, [Validators.maxLength(this.formValidation.lengthValidation.phoneNumberLength), Validators.minLength(this.formValidation.lengthValidation.phoneMinLength)]],
        reason: ['', Validators.required],
        agree: [false, Validators.requiredTrue],
        pendingPolicyOptions: ['1'],
        state: [this.accountInfo.state, Validators.required],
        zipCode: [this.accountInfo.zipCode, [Validators.required, Validators.pattern(this.formValidation.patternValidation.zipCode), Validators.maxLength(this.formValidation.lengthValidation.zipCodeMaxLength)]]
      },
      {validator: this.customValidators.bind(this)}
    );
  }

  numbersOnly(event: KeyboardEvent) {
    return (event.key >= '0' && event.key <= '9') || event.key === ' ' || event.key === 'backspace';
  }

  getAccountInfo() {
    this.displayProgressSpinner++;
    const memberId = sessionStorage.getItem('activeMemberId');
    this.accountService.getAccountInfo(memberId).subscribe({
      next: data => {
        this.isCA_Policy(data.accountInfoResponse.state);
        this.setGracePeriod(data.accountInfoResponse.state);
        data.accountInfoResponse.zipCode = data.accountInfoResponse.zipCode.substring(0, 5);
        this.applyAccountInfo(data.accountInfoResponse);
        this.createForm();
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  isCA_Policy(_state)
  {
    if(_state==='CA')
    {
      this.isCAPolicy=true;
    }
    else{
      this.isCAPolicy=false;
    }
  }
  isWithinGracePeriod() {
    const policyEffectiveDate = new Date(this.policy.effectiveDate);
    const today = new Date();
    const Difference_In_Time = today.getTime() - policyEffectiveDate.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days < this.gracePeriod;
  }

  showPendingClaimsDisclaimerForCAPolicy() {
    if (!this.policy.paidClaims && this.policy.pendingClaims) {
      this.isDenyClaimOption = true; // show all options for inside State Specific Free Look Period (pending claims)
      return true;
    }
    else {
      return false; //hide all options for inside State Specific Free Look Period (paid claims)
    }
  }

  showPendingClaimsDisclaimerForNonCAPolicy() {
    if (this.policy.pendingClaims) {
      this.isDenyClaimOption = false; //hide only 1st option for inside State Specific Free Look Period (pending claims - Non CA)
      return true;
    }
  }

  showPendingClaimsDisclaimer() {
    this.flatRateFlag = false;
    if (this.policy.freeLookAvailable) {
      if (this.isCAPolicy) {
        return this.showPendingClaimsDisclaimerForCAPolicy();
      }
      else {
        return this.showPendingClaimsDisclaimerForNonCAPolicy();
      }
    }
    else {
      return false; //hide all options for outside State Specific Free Look Period(Pro-rate Cancel) 
    }
  }

  cancelDateForCAPolicy(params: CancelDateParams): string {
    this.flatRateFlag = false;
    const pendingPolicyOption = this.addressForm.value.pendingPolicyOptions;
    if (this.policy.pendingClaims || this.policy.paidClaims) {
      if (this.policy.pendingClaims) {
        if(pendingPolicyOption === '1'){
          this.flatRateFlag = true;
          return params.policyEffectiveDate; //cancellation date for No Claims (Flat Cancel)
        } else{
          return params.currentDate; //cancellation date for paid claims or unpaid claims (Pro-rate Cancel)
        }
      }
      if (this.policy.paidClaims) {
        return params.currentDate; //cancellation date for paid claims or unpaid claims (Pro-rate Cancel)
      }
    }
    else {
      this.flatRateFlag = true;
      return params.policyEffectiveDate; //cancellation date for No Claims (Flat Cancel)
    }
  }

  cancelDateForNonCAPolicy(params: CancelDateParams): string {
    //check for open or closed claims
    this.flatRateFlag = false;
    if (this.policy.pendingClaims || this.policy.paidClaims) {
      return params.currentDate; //cancellation date for open or closed claims (Pro-rate Cancel)
    }
    else {
      this.flatRateFlag = true;
      return params.policyEffectiveDate; //cancellation date for No Claims (Flat Cancel)
    }
  }

  insideStateSpecificFreeLookPeriod(params: CancelDateParams): string {
    if (this.isCAPolicy) {
      return this.cancelDateForCAPolicy(params);
    }
    else {
      return this.cancelDateForNonCAPolicy(params);
    }
  }

  outsideStateSpecificFreeLookPeriod({ currentDate }: CancelDateParams): string {
    this.flatRateFlag = false;
    return currentDate; //cancellation date outside State Specific Free Look Period(Pro-rate Cancel)
  }

  getNewCancellationDate(isFront: boolean): string {
    const params = this.getCancelDateParams(isFront);
    if (this.policy.freeLookAvailable) {
      return this.insideStateSpecificFreeLookPeriod(params);
    }
    else {
      return this.outsideStateSpecificFreeLookPeriod(params);
    }  
  }

  getCancelDateParams(isFront: boolean): CancelDateParams {
    const currentDate = this.getTodaysDate();
    const chosenDate = this.addressForm.value.effectiveDate.toLocaleDateString('en-US');

    const policyEffectiveDate = new Date(this.policy.effectiveDate).toLocaleDateString('en-US');
    const isWithinGracePeriod = this.isWithinGracePeriod();

    const dayAfterChosenDate = new Date(chosenDate);
    dayAfterChosenDate.setDate(dayAfterChosenDate.getDate() + 1);
    const dayAfterChosen = dayAfterChosenDate.toLocaleDateString('en-US');

    return {
      isWithinGracePeriod,
      currentDate,
      chosenDate,
      dayAfterChosen,
      isFront,
      policyEffectiveDate
    };
  }

  cancelDateWithPendingClaims({isWithinGracePeriod, policyEffectiveDate, currentDate}: CancelDateParams): string {
    // there is at least one pending claim
    if (isWithinGracePeriod) {
      const pendingPolicyOption = this.addressForm.value.pendingPolicyOptions;
      if (pendingPolicyOption === '1') {
        return policyEffectiveDate;
      } else if (pendingPolicyOption === '2') {
        return currentDate;
      } else {
        return '';
      }
    } else {
      return currentDate;
    }
  }

  cancelDateWithClosedClaims({chosenDate, currentDate}: CancelDateParams): string {
    if (this.isPetPassed() || this.isLostPet()) {
      return chosenDate;
    } else {
      return currentDate;
    }
  }

  cancelDateWithNoClaims({isWithinGracePeriod, policyEffectiveDate, isFront, chosenDate, dayAfterChosen, currentDate}: CancelDateParams): string {
    if (isWithinGracePeriod) {
      return policyEffectiveDate;
    } else {
      if (this.isPetPassed()) {
        return isFront ? chosenDate : dayAfterChosen;
      } else if (this.isLostPet()) {
        return chosenDate;
      } else {
        return currentDate;
      }
    }
  }

  getCancelResponseObject() {
    return {
      effectiveDate: this.getNewCancellationDate(false),
      firstName: this.accountInfo.firstName,
      lastName: this.accountInfo.lastName,
      petName: this.petName,
      policyNumber: this.policyNumber,
      reason: this.getCancelReasonText(this.addressForm.value.reason)
    };
  }

  getPetName(): string {
    return (this.policy && this.policy.pet && this.policy.pet.name) || '';
  }

  getPolicy(policyNumber) {
    //this.displayProgressSpinner++;
    this.policyDetailsList = JSON.parse(sessionStorage.getItem('policyList'));
    this.policy = this.policyDetailsList.reduce((tot, myPet) => myPet.policyNumber === policyNumber ? myPet : tot, null);
    this.petName = this.getPetName();
    this.getClaims();
  //   this.billingPaymentService.getPolicyDetailsList().subscribe({
  //     next: response => {
  //       if (response && response.policyDetailsList) {
  //         this.policy = response.policyDetailsList.reduce((tot, myPet) => {
  //           return myPet.policyNumber === policyNumber ? myPet : tot;
  //         }, null);
  //         this.petName = this.getPetName();
  //         this.getClaims();
  //       }
  //     },
  //     complete: () => this.displayProgressSpinner--
  //   });
   }

  updateSessionStoragePolicy(policyNumber, renewalNumber) {
    this.billingPaymentService.getPolicyDetail(policyNumber).subscribe({
      next: response => {
        if (response) {
          const policyDetailsSSList = JSON.parse(sessionStorage.getItem('policyList'));
          if (policyDetailsSSList && policyDetailsSSList.length != 0) {
            policyDetailsSSList.filter(policy => {
              if (policy.policyNumber == response.policyNumber) {
                policy.policyStatus = response.policyStatus;
                policy.premiumInstallmentAmount = response.premiumInstallmentAmount;
              }
            });
            sessionStorage.setItem('policyList', JSON.stringify(policyDetailsSSList));
          }

          const dataServiceSSList = JSON.parse(sessionStorage.getItem('dataService'));
          if (dataServiceSSList && dataServiceSSList.myPets && dataServiceSSList.myPets.length != 0) {
            dataServiceSSList.myPets.filter(policy => {
              if (policy.policyNumber == response.policyNumber) {
                policy.policyStatus = response.policyStatus;
                policy.premiumInstallmentAmount = response.premiumInstallmentAmount;
              }
            });
            sessionStorage.setItem('dataService', JSON.stringify(dataServiceSSList));
          }
        }
      }
    });
  }

  getPolicyNumberFromRoutes() {
    this.route.params.subscribe(params => {
      this.policyNumber = this.encDcrService.get(params['policyNumber']);
    });
  }

  getRate(effectiveDate: Date, cancelEffectiveDate: Date): string {
    //return effectiveDate && cancelEffectiveDate && effectiveDate === cancelEffectiveDate ? 'F' : 'P';
    return this.flatRateFlag ? 'F' : 'P';
  }

  getTodaysDate(): string {
    const today = new Date();
    return today.toLocaleDateString('en-US');
  }

  goToCancelForm() {
    this.router.navigate([this.routingConstants.cancelPolicy, this.encDcrService.set(this.policyNumber), this.policy.renewalNumber]);
  }

  reasonChange() {
    this.reasonSelected = true;
    if (!this.isPetPassed() && !this.isLostPet()) {
      this.addressForm.get('effectiveDate').patchValue(new Date());
      this.addressForm.get('effectiveDate').markAsDirty();
    }
  }

  submit() {
    if (this.canSubmit()) {
      //this.updateAccountInfo();
      this.verifyAddress();
    }
}
  verifyAddress() {
    // build request from form values
    this.addressRequest.address1 =  this.addressForm.get('address').value;
    this.addressRequest.address2 =  this.addressForm.get('address1').value;
    this.addressRequest.city =  this.addressForm.get('city').value;
    this.addressRequest.state  = this.addressForm.get('state').value;
    this.addressRequest.zipCode =  this.addressForm.get('zipCode').value;
  
    this.commonService.verifyAddress(this.addressRequest).subscribe(
      (data) => {
        if(data) {
          this.addressResponse=data;
          this.addressResponse.address2=this.addressResponse.address2==null||this.addressResponse.address2=='null'||this.addressResponse.address2=='Null'?'':this.addressResponse.address2;
          this.addressResponse.zipCode=this.addressResponse.zipCode.toString();
          this.updateAccountInfo();
        } else {
          this.addressResponse={};
          this.openAddressModal();
        }
      }, error => {
        this.addressResponse={};
        this.openAddressModal();
      }
    );
}

openAddressModal() {
  this.commonService.setAddressValidationModal(this.addressRequest).subscribe(result => {
    if (result) {
      if (result === 'Edit') {
        setTimeout(() => {
          this.focusAddr1.nativeElement.focus();
        }, 0);
        window.scrollTo(0, 0);
      } else if (result === 'Choose') {
        this.updateAccountInfo();
      }
    }
  });
}

  getCancelReasons() {
    this.displayProgressSpinner++;
    this.myPetsService.cancelReasons(this.policyNumber).subscribe({
      next: data => {
        this.reasons = data.reasons;
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  updateAccountInfo() {
    const accountRequest = this.applyFormData();
    this.displayProgressSpinner++;   
    //this.accountService.updateAccountInfo(accountRequest, memberId, accountId).subscribe({
      this.accountService.updateMemberDetails(accountRequest.updateType,accountRequest).subscribe({
      next: () => {
        this.cancelPolicy(this.policyNumber, this.policy.renewalNumber || null);
      },
      error: () => {
        this.commonService.setDialogModal({
          title: 'Error',
          header: 'Error cancelling policy.',
          content: ''
        });
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  onDisclaimerGroupChange(){
    this.inputCancelationDate = this.getNewCancellationDate(true);
  }
}
