<div *ngIf="recentClaims" class="row">
  <ng-container *ngFor="let item of recentClaims; let idx = index">
    <div class="col-md-4">
      <div class="recent-claims-title">
        <mat-card appearance="outlined" class="custom-card">
          <mat-card-header>
            <img mat-card-avatar *ngIf="checkForPetType(item.policyNumber)" alt="dogInfo" id="avatar-blueDog-{{idx}}"
              src="{{ petSpeciesUrl }}" />
            <!-- <img mat-card-avatar *ngIf="!setCanineImage(item.policyNumber)" alt="dogInfo" id="avatar-blueCat-{{idx}}"
              src="{{ iconObj.felineIcon }}" /> -->
            <mat-card-title id="card-title-{{idx}}">{{ item?.petName | titlecase }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="claims--item_top">
              <p id="diagnosis-{{idx}}">
                {{ content.claimsPage.allClaims.diagnosis }}:
                <!--<span class="float-end"><b>{{ item?.claimDetails && item.claimDetails[0]?.diagnosisDescription ? (item.claimDetails[0].diagnosisDescription | titlecase) : 'Pending' }}</b></span>-->
                <span class="float-end"><b>{{ !isInProcess(item) && item?.diagnosisDesc ? (item.diagnosisDesc | titlecase) : 'Pending' }}</b></span>
              </p>
              <p id="treatmentDate-{{idx}}">
                {{ content.claimsPage.allClaims.treatmentDate }}:
                <span class="float-end"><b>{{ item.treatmentDate | date }}</b></span>
              </p>
              <p id="submittedDate-{{idx}}">
                {{ content.claimsPage.allClaims.submittedDate }}:
                <span class="float-end"><b>{{ item.reportedDate | date }}</b></span>
              </p>
              <p id="plan-{{idx}}" *ngIf="item?.planName">
                {{ content.claimsPage.allClaims.plan }}:
                <span class="float-end"><b>{{ item?.planName | titlecase }}</b></span>
              </p>
              <p id="claimNumber-{{idx}}">
                {{ content.claimsPage.allClaims.claimNumber }}:
                <span class="float-end"><b>{{ item?.claimNumber }}</b></span>
              </p>
              <p *ngIf="!item?.planName"></p>
            </div>
            <div class="claims--item_bottom mt-3">
              <div class="col-md-12 mx-auto">
                <div class="d-flex align-items-center">
                  <mat-icon color="primary">check_circle</mat-icon>
                  <mat-progress-bar class="my-color" id="progressBar-{{idx}}" mode="determinate"
                    value="{{ item.statusbar }}"></mat-progress-bar>
                  <mat-icon color="primary" *ngIf="item.claimStatus === 'Complete'">check_circle</mat-icon>
                  <mat-icon color="primary" *ngIf="item.claimStatus !== 'Complete'">panorama_fish_eye</mat-icon>
                </div>
              </div>
              <div class="d-flex justify-content-between recent-claims-status-code">
                <span>Received</span>
                <span>In Review</span>
                <span>Complete</span>
              </div>
              <div class="recent-claims-status mt-3" *ngIf="item.claimStatus !== 'Complete'">
                <mat-icon class="float-start mr-1">timer</mat-icon>
                <p id="claimStatus-{{idx}}" class="media-body text-left">
                  {{ content?.homePage?.recentClaims?.yourClaim }}
                  <strong>{{ item.claimStatus === 'In Process' ? 'In Progress' : '' }}</strong>
                </p>
              </div>
              <div class="mt-3" *ngIf="item.claimStatus === 'Complete'">
                <div class="media">
                  <div class="media-left media-middle mr-1">
                    <a id="complete-status-{{idx}}"><img alt="image_check" width="28px"
                        src="{{ imageConstants.check }}" /></a>
                  </div>
                  <div class="media-body text-left" style="margin: auto">
                    <p>
                      Your claim is complete. Thanks for taking such great care of your pet!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-3" class="see-details-button">
              <button id="seeDetails-{{idx}}" mat-raised-button type="button" color="primary"
                (click)="navigateToRegistration(item.claimNumber, item.policyNumber, item.policyRenewal)">{{ content.claimsPage.allClaims.seeDetails }}</button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="noRecentClaims" class="row justify-content-center pt-5">
  <div class="col-md-10 col-lg-10 col-sm-12 text-center">
    <img alt="empty_image" height="200px" src="{{ imageConstants.images.recentClaimImage }}" width="200px" />
    <br />
    <h5><strong>Don't see your claim?</strong></h5>
    <h5><strong>Check Claim History.</strong></h5>
    <br />
  </div>
</div>

<div *ngIf="recentClaims.length === 3 && claims.length !== 3" class="text-center link-text d-block d-md-none">
  <a href="javascript:void(0)" (click)="viewAllClaims()"
    id="view-all-claims">{{ content.claimsPage.historyClaims.viewRecentClaims }}</a>
</div>
