<div class="row" *ngIf="claimNumber">
  <div class="col-md-12">
    <div class="recent-claim--item">
      <div class="recent-claim-left">        
        <div class="content_left">
          <h3>
            <strong>{{content.homePage.recentClaims.mostRecent}} <br> For {{petName | titlecase}}</strong>
          </h3>
        </div>

        <div class="all_claims">
          <a href="javascript:void(0);" (click)="goToClaims()">See all Claims</a>
        </div>

      </div>
        
      <div class="recent-claim-left">
        <div class="col-md-12 pl-0">
          
          <p class="no_bottom_margin">{{content.homePage.recentClaims.submittedDate}}
            <strong>{{submittedDate | date}}</strong>
          </p>
            
          <p>{{content.homePage.recentClaims.claimNumber1}} 
            <strong>{{claimNumber}}</strong>
          </p>

        </div>
      </div>
      
      <div class="claims--item_bottom mt-3">
        <div class="col-md-12 mx-auto">
          <div class="d-flex align-items-center">
            <mat-icon class="check-circle">check_circle</mat-icon>
            <mat-progress-bar class="my-color" mode="determinate" value="{{statusBar}}" title="Claim status bar"></mat-progress-bar>
            <mat-icon class="check-circle" *ngIf="claimStatus === 'Complete'">check_circle</mat-icon>
            <mat-icon class="check-circle" *ngIf="claimStatus !== 'Complete'">panorama_fish_eye</mat-icon>
          </div>
        </div>
        
        <div class="d-flex justify-content-between recent-claims-status-code">
          <span>Received</span>
          <span>In Review</span>
          <span>Complete</span>
        </div>
        
        <br>
        
        <div class="mt-3" *ngIf="!checkClaimStatus()">
          <div class="media">
            <div class="media-left media-middle pr-3">
              <mat-icon>timer</mat-icon>
            </div>
            
            <div class="media-body" style="margin: auto">
              <p>{{content.homePage.recentClaims.yourClaim}} <strong>{{claimStatus}}</strong></p>
            </div>
          
          </div>
        </div>

        <div class="mt-3" *ngIf="checkClaimStatus()">
          <div class="media ml-3 mr-3">
            
            <div class="media-left media-middle pr-3">
              <mat-icon class="check-circle">check_circle</mat-icon>
            </div>

            <div class="media-body" style="margin: auto">
              <p>Your claim is complete. Thanks for taking such great care of your pet!</p>
            </div>

          </div>
        </div>

        <div class="text-center mt-3 ">
          <button (click)="navigateToSeeClaimDetails(claimNumber)"  id="see-details-{{slideIndex}}"
            mat-stroked-button color="primary" class="see-details-button" tabindex="{{currentSlideNumber===slideIndex ? 0 : -1}}"
            type="button">{{content.claimsPage.allClaims.seeDetails}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="no_claim" *ngIf="!claimNumber">
  <div class="media">
    <div class="media-left media-middle pr-3">
      <a href="#" tabindex="{{currentSlideNumber === slideIndex ? 0 : -1}}">
        <img alt="enrollPet" alt="claim_image" class="media-object" height="150" id="pet-book-1-{{slideIndex}}"
          src="{{imageConstants.images.newClaimImage}}" width="150">
      </a>
    </div>

    <div class="media-body">
      <br>
      <h6><strong>Need to file a new claim?</strong></h6>
      <p><strong>It's easy.</strong></p>
    </div>

  </div>
  
  <div class="text-center">
    <app-new-claim-button [focusable]="currentSlideNumber === slideIndex" [policyNumber]="policyNumber"
      [renewalNumber]="policyRenewal"></app-new-claim-button>
  </div>
</div>