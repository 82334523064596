import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ForgotPasswordRequest} from '../../../models/request/forgot-password-request';
import {GetSecurityQuestionResponse} from '../../../models/response/get-security-question-response';
import {GetSecurityQuestionRequest} from '../../../models/request/get-security-question-request';
import {StandardMessage} from '../../../models/standard-message';
import {ForgotPasswordResponse} from '../../../models/response/forgot-password-response';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  public passwordResetUrl = environment.baseUrl + 'members/v1/password-reset';
  public validatePasswordUrl = environment.baseUrl + 'members/v1/validate-password';
  public securityQuestionUrl = environment.baseUrl + 'members/v1/securityQuestion';


  constructor(private http: HttpClient) {
  }

  public sendTempPassword(request: ForgotPasswordRequest): Observable<ForgotPasswordResponse> {
    return this.http.post<ForgotPasswordRequest>(this.passwordResetUrl, request)
      .pipe(map((policy) => policy as ForgotPasswordResponse));
  }

  public validatePassword(request: ForgotPasswordRequest): Observable<StandardMessage> {
    return this.http.post<ForgotPasswordRequest>(this.validatePasswordUrl, request)
      .pipe(map((policy) => policy as StandardMessage));
  }


  public securityQuestion(request: GetSecurityQuestionRequest): Observable<GetSecurityQuestionResponse> {
    return this.http.post<GetSecurityQuestionRequest>(this.securityQuestionUrl, request)
      .pipe(map((response) => response as GetSecurityQuestionResponse));
  }
}
