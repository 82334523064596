import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncrDecrService {
  public keys = '3T6LQH9XKUqsBDsa';

  constructor() {
  }

  setEncryptedData(key) {
    return {
      keySize: 128 / 8,
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    };
  }

  set(value) {
    const key = CryptoJS.enc.Utf8.parse(this.keys);
    const data = this.setEncryptedData(key);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, data);
    return encrypted.toString();
  }

  get(value) {
    if (value) {
      const key = CryptoJS.enc.Utf8.parse(this.keys);
      const data = this.setEncryptedData(key);
      const decrypted = CryptoJS.AES.decrypt(value, key, data);
      return decrypted.toString(CryptoJS.enc.Utf8);
    }   
  }

  getTokenValue(value) {
    if (value) {
      const key = CryptoJS.enc.Utf8.parse('ERMN05OPLRvbTTaIQkqLNMI7cPbKguaR8Zyg7n54NBVjQmtBhz4SzYx4NBVCXi3KJHlTPXKPOoi2wbXr6CUYTR==');
      const data = this.setEncryptedData(key);
      const decrypted = CryptoJS.AES.decrypt(value, key, data);
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
  }
}
