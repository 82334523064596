import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PartnershipService {
  public partnershipUrl = environment.baseUrl + 'channel-distribution-partner-management/pet-partnership/v1/partnerships?';

  constructor(private http: HttpClient) {

  }

  getPartnershipInfo(partnerId: string){
    const contentTypeHeader = new HttpHeaders({
      'Authorization': `Bearer ${environment.partnershipAccessToken}`,
      'client_id': environment.partnershipClientId,
      'X-NW-Message-ID': environment.partnershipMessageId,
    });
    return this.http.get(this.partnershipUrl + 'groupId=' + partnerId, { headers: contentTypeHeader });
  }
}
