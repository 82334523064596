<div [id]="policyDetails.pet.name + '-id-card'" class="id-card" tabindex="-1">
  <div class="header" [ngStyle]="policyDetails.cobrandingStatus ? {'background-image': 'url(' + cobrandingInsuranceCardHeaderLogo + ')' }
   : {'background-image': 'url(' + nwInsuranceCardHeaderLogo + ')' }">
  </div>
  
  <div class="content">
    <div class="logo-background">
      <h2 class="title">Pet Insurance ID Card</h2>
      <hr class="mt-0">
      <p>
        Pet Name: <span class="fw-bold">{{policyDetails.pet.name}}</span>
      </p>
      
      <p>
        Policy #/Member ID: <span class="fw-bold">{{policyDetails.policyNumber}}</span>
      </p>
      
      <p>
        Coverage: <span class="fw-bold">{{policyDetails.coverage}}</span>
      </p>
     
      <p>
        Status: <span class="fw-bold">{{policyDetails.policyStatus}}</span>
      </p>
      
      <hr>
      <img alt="" src="{{petRxExpressLogoUrl}}" width="80%">
      <h3>Pharmacy ID Information</h3>
      
      <p>
        Member Name: <span class="fw-bold">{{memberName}}</span>
      </p>
      
      <p>
        Relationship: <span class="fw-bold">01</span>
      </p>
      
      <p>
        Insurance DOB*: <span class="fw-bold">{{policyDetails.pet.dateofbirth | date:'M/d/yyyy'}}</span>
      </p>
      
      <p>
        BIN: <span class="fw-bold">023120</span>
      </p>
      
      <p>
        PCN: <span class="fw-bold">MED1</span>
      </p>
      
      <p>
        GROUP: <span class="fw-bold">NWIDE01</span>
      </p>
    </div>

    <p class="mt-2">
      <strong>*This date is displayed for pharmacy record-keeping only and may not exactly reflect your pet's age.</strong>
    </p>
    
    <p><i>This card is valid only at participating pharmacies.</i></p>
    
    <p><i>Nationwide pet insurance benefits are available for use at any pharmacy and veterinary practice of your choice.</i></p>

  </div>
</div>

<div style="padding-top: .5cm;">

  <p style="font-size: .8em;"><i>Use your pet insurance ID card to save on pet meds at participating pharmacies.<sup>&#8224;</sup> <a [href]="hyperlinkConstants.learnMorePetRx"> Learn more</a></i></p>

</div>

<div class="download">
  
  <button aria-label="Download Pet Insurance ID Card" mat-stroked-button class="download-button" (click)="saveIdCard()">
     Download
  </button>
</div>