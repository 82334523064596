import {UntypedFormControl} from '@angular/forms';

export function PasswordValidator(confirmPasswordInput: string) {
  let confirmPasswordControl: UntypedFormControl;
  let passwordControl: UntypedFormControl;

  return (control: UntypedFormControl) => {
    if (!control.parent) {
      return null;
    }

    if (!confirmPasswordControl) {
      confirmPasswordControl = control;
      passwordControl = control.parent.get(confirmPasswordInput) as UntypedFormControl;
      passwordControl.valueChanges.subscribe(() => {
        confirmPasswordControl.updateValueAndValidity();
      });
    }

    if (
      passwordControl.value !==
      confirmPasswordControl.value
    ) {
      return {
        notMatch: true
      };
    }
    return null;
  };
}
