<div class="container pb-5">
  <div class="container-title">
    <app-navigation-back id="myPet-backbutton" (navigateBackEvent)="goToPetsPage()"
                         class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
    <h1><strong>Policy Cancellation</strong></h1>
  </div>
  <hr style="border: 1px solid grey">
  <mat-card appearance="outlined">
    <p>We're sorry to see you go. You will receive an email from vpiportal@petinsurance.com confirming your policy
      cancellation.</p>
    <mat-card-header>
      <p><b>Thank you for allowing us to be a part of your pet’s life.</b></p>
    </mat-card-header>
    <mat-card-content>
      <ul class="pet-details">
        <li>
          Pet Name:
          <b *ngIf="cancelResponse && cancelResponse.petName">
            {{cancelResponse.petName | titlecase }}
          </b>
        </li>
        <li>
          Policy No.:
          <b *ngIf="cancelResponse && cancelResponse.policyNumber">
            {{cancelResponse && cancelResponse.policyNumber}}
          </b>
        </li>
        <li>
          First Name:
          <b *ngIf="cancelResponse && cancelResponse.firstName">
            {{cancelResponse && cancelResponse.firstName | titlecase}}
          </b>
        </li>
        <li>
          Last Name:
          <b *ngIf="cancelResponse && cancelResponse.lastName">
            {{cancelResponse && cancelResponse.lastName | titlecase}}
          </b>
        </li>
        <li>
          Cancellation Reason:
          <b *ngIf="cancelResponse && cancelResponse.reason">
            {{cancelResponse && cancelResponse.reason | titlecase}}
          </b>
        </li>
        <li>
          Cancellation Effective Date:
          <b *ngIf="cancelResponse && cancelResponse.effectiveDate">
            {{cancelResponse && cancelResponse.effectiveDate}}
          </b>
        </li>
      </ul>
      <br>
      <p><b>Please note:</b> Claims for treatment dates on or after the cancellation effective date will not be eligible
        for coverage</p>
      <p>To ensure receipt of emails sent from Nationwide, please review the Spam Filter settings on your PC to ensure
        that you can successfully receive emails from
        <b><u>vpiportal@petinsurance.com</u></b>.
        If you do not receive an email from us, please contact our Member Care department at 888-803-4874. Our
        Member Care team is available <b>Monday-Friday 5am-7pm and Saturday
          7am-3:30pm (Pacific).</b></p>
      <br>
      <div class="row">
        <div class="col-md-6">
          <app-navigation-back id='backbutton' (navigateBackEvent)="goToPetsPage()"
                               class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
