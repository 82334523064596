import {Component, OnInit} from '@angular/core';
import {ROUTINGCONSTANTS} from '../../../constants/routing-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CONTENT} from '../../../../content-management/content';
import {UpdatePassword} from '../../../../models/update-password';
import {CommonService} from '../../../services/common.service';
import {ForgotPasswordService} from '../forgot-password.service';
import {PasswordValidator} from '../../../custom-form-validators/confirm-password-validator';
import {first} from 'rxjs/operators';
import {GetSecurityQuestionRequest} from '../../../../models/request/get-security-question-request';
import {PasswordResetRequest} from '../../../../models/request/password-reset-request';
import {DataService} from '../../../../core/services/data.service';
import {ForgotPasswordRequest} from '../../../../models/request/forgot-password-request';
import {AuthService} from '../../../../core/services/auth.service';
import {LoginRequest} from '../../../../models/request/login-request';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public routingConstants = ROUTINGCONSTANTS;
  public resetPasswordForm: UntypedFormGroup;
  public isSubmitted = false;
  public content = CONTENT;
  public securityQuestion: string;
  public userId: string;
  public getSecurityQtn: GetSecurityQuestionRequest = {};
  public hide = true;
  public sentEmail = false;
  public displayProgressSpinner = false;
  public forgotPasswordRequest: PasswordResetRequest = {};

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private forgotPasswordService: ForgotPasswordService,
              private commonService: CommonService,
              private authService: AuthService,
              private dataService: DataService) {
    this.getValidatePasswordDetails();
    this.checkForSecurityQuestion();
  }

  ngOnInit() {
    this.createForm();
  }

  getValidatePasswordDetails() {
    const validate = this.dataService.forgotPasswordRequest;
    if (!validate && validate.loginId) {
      this.router.navigate([this.routingConstants.forgotPassword]);
    }
  }

  createForm() {
    this.resetPasswordForm = this.fb.group({
      securityAnswer: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmNewPassword: ['', [Validators.required, PasswordValidator('newPassword')]]
    });
  }

  get formDetails() {
    return this.resetPasswordForm.controls;
  }

  canSubmit(): boolean {
    return this.resetPasswordForm.valid && this.resetPasswordForm.dirty;
  }

  populateSecurityQuestion() {
    this.userId = sessionStorage.getItem('userId');
    this.getSecurityQtn.userId = this.userId;
    return this.getSecurityQtn;
  }

  checkForSecurityQuestion() {
    this.forgotPasswordService.securityQuestion(this.populateSecurityQuestion())
      .pipe(first())
      .subscribe((response) => {
        if (response.securityQuestion) {
          this.securityQuestion = response.securityQuestion;
        } else {
          this.sendTmpPassword();
          this.router.navigate([this.routingConstants.passwordEmailSent]);
        }
      });
  }

  sendTmpPassword() {
    const request = this.dataService.forgotPasswordRequest;
    this.forgotPasswordService.sendTempPassword(request)
      .pipe(first())
      .subscribe((response) => {
        this.sentEmail = response.sentEmail;
      });
  }

  changePassword() {
    this.forgotPasswordRequest.newPassword = this.resetPasswordForm.controls['confirmNewPassword'].value;
    this.forgotPasswordRequest.securityAnswer = this.resetPasswordForm.controls['securityAnswer'].value;
    this.forgotPasswordRequest.loginId = this.dataService.forgotPasswordRequest.loginId;
    this.forgotPasswordRequest.isValid = 'Y';
    return this.forgotPasswordRequest;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.authService.updatePassword(this.changePassword()).subscribe((policy) => {
      if (policy.jwt) {
        localStorage.removeItem('currentUser');
        this.router.navigate([this.routingConstants.home]);
      } else {
        this.setResetPasswordErrors(policy.standardMessage.code);
      }
    });
  }

  setResetPasswordErrors(code) {
    if (code === '88') {
      this.commonService.setValidationErrorModal(this.content.validations.settings.changeLoginIdError);
    }
  }

  navigateToForgotPasswordPage() {
    this.router.navigate([this.routingConstants.forgotPassword]);
  }
}
