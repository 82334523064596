import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appShowLast4]'
})
export class ShowLast4Directive {
  number = '';

  constructor(private el: ElementRef) {
  }

  @HostListener('blur') onBlur() {
    this.number = this.el.nativeElement.value;
    if (this.el.nativeElement.value && this.el.nativeElement.value.length > 4) {
      this.el.nativeElement.value = this.el.nativeElement.value.slice(0, this.el.nativeElement.value.length - 4).replace(/\d/g, '*') + this.el.nativeElement.value.slice(this.el.nativeElement.value.length - 4);
    }
  }

  @HostListener('focus') onFocus() {
    this.el.nativeElement.value = this.number;
  }
}
