import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseSplit'
})
export class CamelCaseSplitPipe implements PipeTransform {

  transform(input:string): string {
    return input.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

}
