import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ach-mockup-modal',
  templateUrl: './ach-mockup-modal.component.html',
  styleUrls: ['./ach-mockup-modal.component.scss']
})
export class AchMockupModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  closeActiveModal() {
    this.activeModal.close();
  }
}
