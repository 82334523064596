<div class="container">
  <div class="row text-center"> 
    
    <div class="text-end pr-0 pl-0 text-nowrap" [ngClass]="{'col-9': isMPIPolicy, 'col-8': !isMPIPolicy }">
      <h3 class="font-weight-bold">{{content.accountInfo.payment.myPetHeader}}</h3>
    </div>

    <div class="text-start" [ngClass]="{'col-3': isMPIPolicy, 'col-4': !isMPIPolicy }">
      <span (click)="editDetails()" class="my-auto edit-text" id="edit-icon-{{slideIndex}}">
        <i class="far fa-edit fa-lg" style="color: #707070"></i> 
      </span>
    </div>
  </div>

  <br>
  
  <div class="row" *ngIf="paymentData && (paymentData.paymentMethod|uppercase)!=='PAYROLL'">

      <div class="col-6 pl-0 pr-0 text-left">
        {{content.accountInfo.paymentMethod + ': '}} 
      </div>
      <div class="col-6 text-right">
        {{' Direct bill' + ' - ' + paymentData.paymentType}}
      </div>
    
      <div class="col-6 pl-0 pr-0 text-left" *ngIf="!isCredit()">
        {{content.accountInfo.routingNumber + ': '}}
      </div>
      <div class="col-6 text-right" *ngIf="!isCredit()">
        {{handleMask(paymentData.routingNumber)}}
      </div> 

      <div class="col-6 pl-0 pr-0 text-left" *ngIf="!isCredit()">
        {{content.accountInfo.accountNumber + ': '}}
      </div>
      <div class="col-6 text-right" *ngIf="!isCredit()">
        {{handleMask(paymentData.accountNumber)}}
      </div> 

      <div class="col-6 pl-0 pr-0 text-left" *ngIf="isCredit() && !isCheck()">
        {{content.accountInfo.cardNumber + ': '}}
      </div>
      <div class="col-6 text-right" *ngIf="isCredit() && !isCheck()">
        XXXX{{handleMask(paymentData.cardNumber)}}
      </div>

      <div class="col-6 pl-0 pr-0 text-left" *ngIf="!isCredit()">
        {{content.accountInfo.accountType + ': '}}
      </div>
      <div class="col-6 text-right" *ngIf="!isCredit()">
        {{paymentData.accountType | titlecase}}
      </div>

      <div class="col-6 pl-0 pr-0 text-left" *ngIf="isCredit() && !isCheck()">
        {{content.accountInfo.expiration + ': '}}
      </div>
      <div class="col-6 text-right" *ngIf="isCredit() && !isCheck()">
        {{paymentData.expDate}}
      </div>

      <div class="col-6 pl-0 pr-0 text-left">
        Payment Plan:
      </div>
      <div class="col-6 text-right">
        {{myPet.numofPayTerms > '1' ? 'Monthly' : 'Annually'}}
      </div>

      <div class="col-6 pl-0 pr-0 text-left">
        {{myPet.numofPayTerms > '1' ? 'Monthly' : 'Annually'}} Premium: 
      </div>
      <div class="col-6 text-right">
        {{myPet.premiumInstallmentAmount | currency}}
      </div>
  </div>

  <div class="row payment-section" *ngIf="myPet.policyType === 'Group Payroll'">
    <div class="col-6 pl-0 pr-0 text-left" *ngIf="myPet.policyType === 'Group Payroll'">
      Payment Method:  
    </div>
    <div class="col-6 text-right" *ngIf="myPet.policyType === 'Group Payroll'">
      Payroll Deduction
    </div>
  
    <div class="col-6 pl-0 pr-0 text-left" *ngIf="myPet.policyType === 'Group Payroll'">
      Annual Premium:  
    </div>
    <div class="col-6 text-right" *ngIf="myPet.policyType === 'Group Payroll'">
      {{myPet.premiumInstallmentAmount | currency}}
    </div>
    
  </div>
</div>
