<div class="container">
  <div class="container-title">
    <h1 class="fw-bold" id="edit-account-header">Password Sent</h1>
  </div>
  <hr style="border: 1px solid grey"/>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-10 col-md-10 sol-sm-12">
          <p>Thank you. An email containing your temporary password has been sent to your email address on file. Once you have retrieved the temporary password, you can log back into
            the Nationwide Pet Account Access page and create a new password.</p>
          <p>Please do not attempt to log into your account until you have been received the temporary password.</p>
          <p>To ensure receipt of emails sent from Nationwide, please review the Spam Filter settings on your PC to ensure that you can successfully receive emails from
            <a>vpiportal@petinsurance.com.</a>If you do not receive an email from us, please contact our Member Care department at 844-397-8938. Our Customer
            Care team is available Monday-Friday 5am-7pm and Saturday 7am-3:30pm (Pacific)</p>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="row justify-content-center">
        <div class="col-lg-10 col-md-10 col-sm-12">
          <button mat-raised-button class="custom-password-mat-button float-end mobile-button" color="primary" id="forgot-id-submit-btn" (click)="navigateToLoginPage()">{{content.buttons.next}}</button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
