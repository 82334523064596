export const ROUTINGCONSTANTS = {
  account: '/account',
  cancelForm: '/cancellation-form',
  cancelPolicy: '/cancellation',
  claimDetails: '/claim-details',
  claims: '/claims',
  createAccount: '/create-account',
  editLogin: '/edit-login',
  editMyAccount: 'edit-my-account',
  editPassword: '/edit-password',
  editPayment: '/edit-payment',
  editSecQs: '/edit-security-questions',
  electronicClaims: '/electronic-payment-opt-in',
  fileNewClaim: '/file-new-claim',
  forgotId: '/forgot-id',
  forgotPassword: '/forgot-password',
  home: '/home',
  login: '/login',
  managePolicy: '/manage-policy',
  mostRecentClaims: '/most-recent-claim-details',
  notifications: '/notifications',
  pets: '/pets',
  registration: '/registration',
  makePayment: '/make-payment',
  renewalBilling: '/renewal-billing',
  renewalConfirmation: '/renewal-confirmation',
  renewPolicy: '/renewal',
  resetPassword: '/reset-password',
  passwordEmailSent: '/email-sent',
  settings: '/settings',
  mppQuestions: 'mpp-questions',
  missingPolicy: '/link-missing-policy',
  walmartUrl: '/walmart-rx',
  paymentHistory: '/payment-history',
  newClaimsSubmission: '/new-claims-submission'
};
