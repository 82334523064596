import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import _ from 'lodash';
import {CARDPATTERNS} from '../../../shared/constants/card-patterns';

export class EditPaymentValidation {
  static clearErrors = (formGroup: UntypedFormGroup, field: string) => {
    formGroup.get(field).setErrors(null);
  };

  static setError = (formGroup: UntypedFormGroup, field: string, error: string) => {
    const newErrors = formGroup.get(field).errors || {};
    newErrors[error] = true;
    formGroup.get(field).setErrors(newErrors);
  };

  static removeError = (formGroup: UntypedFormGroup, field: string, error: string) => {
    const errors = formGroup.get(field).errors;
    if (errors) {
      delete errors[error];
      const newErrors = _.isEmpty(errors) ? null : errors;
      formGroup.get(field).setErrors(newErrors);
    }
  };

  static cardTypeValidator = (control: AbstractControl) => {
    const visaPattern = new RegExp(CARDPATTERNS.visa);
    const amexPattern = new RegExp(CARDPATTERNS.americanExpress);
    const discoverPattern = new RegExp(CARDPATTERNS.discover);
    const masterCardPattern = new RegExp(CARDPATTERNS.masterCard);

    if (!control.value || control.value.match(visaPattern) || control.value.match(amexPattern) || control.value.match(discoverPattern) || control.value.match(masterCardPattern)) {
      return null;
    } else {
      return { invalidCardType: true };
    }
  };

  static isExpired = (month: string, year: string): boolean => {
    const today = new Date();
    const dateString = month + '/01/' + year;
    const expDate = new Date(dateString);
    return expDate < today;
  };

  static achValidation = (fg: UntypedFormGroup, errors: any) => {
    if (!fg.get('routingNumber').value) {
      EditPaymentValidation.setError(fg, 'routingNumber', 'required');
      errors['routingNumberRequired'] = true;
    }
    if (!fg.get('accountNumber').value) {
      EditPaymentValidation.setError(fg, 'accountNumber', 'required');
      errors['accountNumberRequired'] = true;
    }
    if (!fg.get('accountType').value) {
      EditPaymentValidation.setError(fg, 'accountType', 'required');
      errors['accountTypeRequired'] = true;
    }

    if (fg.get('isGroup')) {
      errors = EditPaymentValidation.groupValidation(fg, errors);
    }

    EditPaymentValidation.clearCCErrors(fg);
    return errors;
  };

  static creditValidation = (fg: UntypedFormGroup, errors: any) => {
    if (!fg.get('cardNumber').value) {
      EditPaymentValidation.setError(fg, 'cardNumber', 'required');
      errors['cardNumberRequired'] = true;
    }
    const expMonth = fg.get('expMonth').value;
    const expYear = fg.get('expYear').value;
    if (!expMonth) {
      EditPaymentValidation.setError(fg, 'expMonth', 'required');
      errors['expMonthRequired'] = true;
    }
    if (!expYear) {
      EditPaymentValidation.setError(fg, 'expYear', 'required');
      errors['expYearRequired'] = true;
    }
    if (expMonth && expYear && EditPaymentValidation.isExpired(fg.get('expMonth').value, fg.get('expYear').value)) {
      EditPaymentValidation.setError(fg, 'expMonth', 'expired');
      EditPaymentValidation.setError(fg, 'expYear', 'expired');
      errors['cardExpired'] = true;
    } else {
      EditPaymentValidation.removeError(fg, 'expMonth', 'expired');
      EditPaymentValidation.removeError(fg, 'expYear', 'expired');
    }

    if (fg.get('isGroup')) {
      errors = EditPaymentValidation.groupValidation(fg, errors);
    }

    EditPaymentValidation.clearACHErrors(fg);

    return errors;
  };

  static groupValidation = (fg: UntypedFormGroup, errors: any) => {
    if (!fg.get('frequency').value) {
      EditPaymentValidation.setError(fg, 'frequency', 'required');
      errors['frequencyRequired'] = true;
    }
    return errors;
  };

  static clearCCErrors(fg: UntypedFormGroup) {
    EditPaymentValidation.clearErrors(fg, 'expMonth');
    EditPaymentValidation.clearErrors(fg, 'expYear');
    EditPaymentValidation.clearErrors(fg, 'cardNumber');
  }

  static clearACHErrors(fg: UntypedFormGroup) {
    EditPaymentValidation.clearErrors(fg, 'routingNumber');
    EditPaymentValidation.clearErrors(fg, 'accountNumber');
    EditPaymentValidation.clearErrors(fg, 'accountType');
  }
}
