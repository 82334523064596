<div class="container pb-5">
  <div class="cancel-title">
    <app-navigation-back id="myPet-backbutton" (navigateBackEvent)="goToCancelForm()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
    <h5 class="fw-bold">Policy Cancellation</h5>
  </div>
  <hr style="border: 1px solid grey"/>
  <mat-card appearance="outlined">
    <strong>Please confirm your contact information to ensure that any future correspondence, including any applicable refunds, will be sent to the proper address.</strong>
    <mat-card-content>
      <br/>
      <ul class="pet-details">
        <li>
          Pet Name:<b> {{ petName | titlecase }}</b>
        </li>
        <li>
          Policy No.:<b> {{ policyNumber }}</b>
        </li>
        <li>
          First Name:<b> {{ accountInfo && accountInfo.firstName | titlecase }}</b>
        </li>
        <li>
          Last Name:<b> {{ accountInfo && accountInfo.lastName | titlecase }}</b>
        </li>
      </ul>
      <br/>
      <form [formGroup]="addressForm" *ngIf="addressForm">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-form-field class="material-input-full-width pt-1" appearance="outline">
              <mat-icon matPrefix>local_shipping</mat-icon>
              <input matInput formControlName="address" type="text" #focusAddr1 maxlength="{{ formValidation.lengthValidation.addressLine1Length }}" [placeholder]="placeHolders.addressLine1" required/>
              <mat-label
              ><b>{{ labels.addressLine1 }}</b></mat-label
              >
              <mat-error *ngIf="addressForm.controls.address.errors && addressForm.controls.address.errors.required">
                {{ errorMessages.addressLine1.required }}
              </mat-error>
              <mat-error *ngIf="addressForm.controls.address.errors && addressForm.controls.address.errors.pattern">
                {{ errorMessages.addressLine1.pattern }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-form-field class="material-input-full-width pt-1" appearance="outline">
              <mat-icon matPrefix>local_shipping</mat-icon>
              <input matInput formControlName="address1" type="text"/>
              <mat-label
              ><b>{{ labels.addressLine2 }}</b></mat-label
              >
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-form-field class="material-input-full-width pt-1" appearance="outline">
              <mat-icon matPrefix>location_city</mat-icon>
              <input matInput formControlName="city" type="text" (keypress)="alphabetsOnly($event)" [placeholder]="placeHolders.city" required/>
              <mat-label
              ><b>{{ labels.city }}</b></mat-label
              >
              <mat-error *ngIf="addressForm.controls.city.errors && addressForm.controls.city.errors.required">
                {{ errorMessages.city.required }}
              </mat-error>
              <mat-error *ngIf="addressForm.controls.city.errors && addressForm.controls.city.errors.pattern">
                {{ errorMessages.city.pattern }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-form-field class="material-input-full-width pt-1" appearance="outline">
              <mat-label>{{ labels.state }}</mat-label>
              <mat-icon matPrefix>local_shipping</mat-icon>
              <mat-select formControlName="state" required (valueChange)="setGracePeriod($event)">
                <mat-option value="">{{ defaults.state }}</mat-option>
                <mat-option *ngFor="let state of stateCodes" [value]="state">{{ state }}</mat-option>
              </mat-select>
              <mat-error *ngIf="addressForm.controls.state.errors && addressForm.controls.state.errors.pattern">
                {{ errorMessages.state.pattern }}
              </mat-error>
              <mat-error *ngIf="addressForm.controls.state.errors && addressForm.controls.state.errors.required">
                {{ errorMessages.state.required }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-form-field class="material-input-full-width pt-1" appearance="outline">
              <mat-icon matPrefix>local_shipping</mat-icon>
              <input matInput formControlName="zipCode" type="text" maxlength="{{ formValidation.lengthValidation.zipCodeMaxLength }}" (keypress)="numbersOnly($event)" mask="00000" [placeholder]="placeHolders.zipCode" required/>
              <mat-label><b>{{ labels.zipCode }}</b></mat-label>
              <mat-error *ngIf="addressForm.controls.zipCode.errors && addressForm.controls.zipCode.errors.pattern">{{ errorMessages.zipCode.pattern }}</mat-error>
              <mat-error *ngIf="addressForm.controls.zipCode.errors && addressForm.controls.zipCode.errors.required">{{ errorMessages.zipCode.required }}</mat-error>
              <mat-error *ngIf="addressForm.controls.zipCode.errors && addressForm.controls.zipCode.errors.maxlength">{{ errorMessages.zipCode.maxLength }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-form-field class="material-input-full-width pt-1" appearance="outline">
              <mat-icon matPrefix>call</mat-icon>
              <input matInput formControlName="phone" type="tel" mask="000-000-0000" [placeholder]="placeHolders.homePhone" required />
              <mat-label><b>{{ labels.homePhone }}</b></mat-label>
              <mat-error *ngIf="addressForm.controls.phone.errors && addressForm.controls.phone.errors.minlength">{{ errorMessages.phone.minLength }}</mat-error>
              <mat-error *ngIf="addressForm.controls.phone.errors && addressForm.controls.phone.errors.required">{{ errorMessages.phone.required }}</mat-error>
              <mat-error *ngIf="addressForm.controls.phone.errors && addressForm.controls.phone.errors.maxlength">{{ errorMessages.phone.maxLength }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <br/>
        <p>
          Once you have selected a reason for your cancellation request, please confirm the cancellation effective date. This is the date the policy no longer will be effective and coverage will no
          longer be extended for the insured pet. Remember, any claims submitted on or after the cancellation
          effective date will be ineligible for coverage.
        </p>
        
        <div *ngIf="showPendingClaimsDisclaimer() && !this.isPetPassed()">
          
          <p>* Our records indicate you have submitted a claim currently in process. Please select how you want to proceed...</p>

          <mat-radio-group aria-label="Select an option" formControlName="pendingPolicyOptions" (change)="onDisclaimerGroupChange()">
            <mat-radio-button *ngIf="isDenyClaimOption" value="1">Deny Claim and Cancel Policy for full refund</mat-radio-button>
            <mat-radio-button value="2">Process Claim and Cancel Policy for prorated refund<br/>(If this option is selected and your plan has a deductible that needs to be satisfied, the eligible
              amount may be applied to the deductible.)</mat-radio-button>

            <mat-radio-button value="3">Process Claim and Do Not Cancel Policy</mat-radio-button>

          </mat-radio-group>

          <p>If uncertain, please contact our Member Care department at 844-397-8938. Monday to Friday 5 a.m. to 7 p.m. (Pacific) and Saturday 7 a.m. to 3:30 p.m. (Pacific).</p>

        </div>
        
        <p *ngIf="addressForm.value.pendingPolicyOptions === '2'">Thank you for allowing us to continue to protect you and your pet. Please click
          <a [href]="routingConstants.home">Here</a> to return to the home page.
        </p>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-form-field class="material-input-full-width pt-1" appearance="outline">
              <mat-label><b>Cancellation Reason</b></mat-label>
              <mat-select formControlName="reason" (selectionChange)="reasonChange()">
                <mat-option *ngFor="let reason of reasons" [value]="reason.code">{{ reason.reason | titlecase }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <span *ngIf="showStaticCancelDateField()">
              <mat-form-field class="material-input-full-width mat-form-field-readonly mdc-text-field--disabled" appearance="outline">
                <mat-label><b>Cancellation effective date</b></mat-label>
                <input matInput readonly [value]="inputCancelationDate" type="text" class="readonly-input"/>
              </mat-form-field>
            </span>
            <span *ngIf="showDatePicker()">
              <mat-form-field class="material-input-full-width pt-1 effectiveDate" appearance="outline">
                <mat-label
                ><b>Date your pet {{ lostPassedVerb() }}</b>
                </mat-label>
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="effectiveDate"/>
                <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint>(Cancellation effective date)</mat-hint>
                <mat-error *ngIf="addressForm.controls.effectiveDate.errors && addressForm.controls.effectiveDate.errors.invalidDate">
                  Unable to process a cancellation for this date, please contact our Member Care department at 844-397-8938. Our Member Care team is available Monday-Friday 5am-7pm and Saturday 7am-3:30pm (Pacific).
                </mat-error>
              </mat-form-field>
            </span>
          </div>
        </div>
        <div *ngIf="isLostPet() || isPetPassed()">
          <p>
            We're sorry to hear that you have lost your pet.
            <span *ngIf="isPetPassed()">Being a company of pet lovers, we understand the emotions that go along with losing a pet and our thoughts are with you.<br/><br/></span>
            <span *ngIf="showDatePicker()">To cancel your policy, please enter the date your pet{{ lostPassedVerb() }}.</span>
          </p>
          <p *ngIf="showDatePicker()">If your pet {{ lostPassedVerb() }} more than 30 days prior to today's date, please call a Member Care representative at 844-397-8938 for further instructions.
            Our Member Care team is available Monday-Friday 5am-7pm and Saturday 7am-3:30pm (Pacific).</p>
        </div>
        <p>
          I understand and certify that by submitting this online cancellation request I am acting with proper authority to cancel the coverage provided by the policy noted herein. I also understand
          that should I desire to reacquire coverage in the future, my application will require application
          underwriting and eligibility review.
        </p>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12" data-toggle="collapse">
            <!--<label data-toggle="collapse">-->
              <mat-checkbox color="primary" formControlName="agree"><b>I agree to the terms listed above</b></mat-checkbox>
            <!--</label>-->
          </div>
        </div>
      </form>
      <br>
      <div class="row">
        <div class="col-md-6">
          <app-navigation-back id="backbutton" (navigateBackEvent)="goToCancelForm()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 text-end">
          <button mat-raised-button type="button" [disabled]="!canSubmit()" (click)="submit()" color="primary" class="custom-cancel-button mobile-button">Next</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #addressErrorModal id="addressErrorModal" let-modal>
  <div class="modal-header" class="p-1">
    <ngb-alert [dismissible]="false" type="primary" class="col-12 p-1">A match for your address could not be found in
      the USPS database. Please choose one of the options below to proceed.
    </ngb-alert>
  </div>
  <div class="modal-body">
    <p class="text-center"><strong>You entered:</strong></p>
    <p class="text-center">
      {{addressRequest.address1}}{{addressRequest.address2 ? ', ' + addressRequest.address2 : ''}}
    </p>
    <p class="text-center">
      {{addressRequest.city}}, {{addressRequest.state}}, {{addressRequest.zipCode}}
    </p>
  </div>
  <div class="modal-footer d-block text-center">
    <div class="mx-auto">
      <button type="button" class="btn-primary" (click)="modal.close('Edit')" id="editAddress"><span
          class="addressButton">Edit this address</span></button>
      <button type="button" class="btn-primary ml-1 ml-sm-3 adrsBtn" (click)="modal.close('Choose')" id="chooseAddress"><span
          class="addressButton">Choose this address</span></button>
    </div>

  </div>
</ng-template>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>
