<div class="header-space">
  <h5 mat-dialog-title class="fw-bold red-text">{{tosContent.header}}</h5>
  <button [mat-dialog-close]="data" color="secondary" class="close-button">
    <mat-icon class="red-text">close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div *ngFor="let term of tosContent.terms">
    <h6 class="fw-bold">{{term.header}}</h6>
    <p>
      {{term.body}}
    </p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true" color="primary">Accept</button>
  <button mat-raised-button [mat-dialog-close]="false" color="secondary">Decline</button>
</div>
