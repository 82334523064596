<div class="guage_content" *ngIf="!isMyPetsPage">
  
  <div *ngIf="policy?.pet?.name" class="text-center">
    <h3>{{policy?.pet?.name | titlecase}}'s plan</h3>
  </div>

  <br />

  <div class="mx-auto">
    <div class="text-center nopadding"><b>{{annualDeductible|currency}}</b></div>
  </div>

  <div class="mx-auto">
    <div class="text-center text-nowrap nopadding">Annual deductible</div>
  </div>

  <div class="d-flex align-items-center">
    <round-progress [radius]="100" class="mx-auto" [stroke]="20" [max]="annualDeductible" [current]="deductibleApplied"
      [semicircle]="true" color="#0067C5" background="#f0f0f0" aria-label="deductible guage"></round-progress>
  </div>
  
  <div class="m-0 p-0 d-none d-md-block">
    <div class="d-flex mx-auto">
      <div class="col-4 text-center nopadding"><b>{{deductibleApplied|currency}}</b></div>
      <div class="col-4"></div>
      <div class="col-4 text-center nopadding"><b>{{remainingDeductible|currency}}</b></div>
    </div>

    <div class="d-flex mx-auto">
      <div class="col-4 text-center text-nowrap nopadding">Applied</div>
      <div class="col-4"></div>
      <div class="col-4 text-center nopadding">Remaining</div>
    </div>

  </div>
  
  <div class="m-0 p-0 d-block d-md-none">
    <div class="d-flex mx-auto">
      <div class="col-6 text-center nopadding"><b>{{deductibleApplied|currency}}</b></div>
      <div class="col-6 text-center nopadding"><b>{{remainingDeductible|currency}}</b></div>
    </div>

    <div class="d-flex mx-auto">
      <div class="col-6 text-center text-nowrap nopadding">Applied</div>
      <div class="col-6 text-center nopadding">Remaining</div>
    </div>

  </div>

  <br />

  <!-- <div class="text-center" ngIf="isMPIPolicy"> -->
  <div class="text-center" *ngIf="isMPIPolicy">
    <button id="seeCoverageDetails-{{slideIndex}}" class="see-details-button" mat-stroked-button type="button" (click)="goToPets()">{{
      content.claimsPage.allClaims.viewCoverageDetails }}</button>
  </div>

</div>

<div *ngIf="isMyPetsPage">
  
  <div class="text-center">
    <h6>Benefits overview</h6>
  </div>

  <div class="guage_content pet-guage-container">
    <br />

    <div class="mx-auto text-center">
      <div class="text-center nopadding"><b>{{annualDeductible|currency}}</b></div>
    </div>

    <div class="mx-auto">
      <div class="text-center text-nowrap nopadding">Annual deductible</div>
    </div>

    <div class="d-flex align-items-center">
      <round-progress [radius]="100" class="mx-auto" [stroke]="20" [max]="annualDeductible" [current]="deductibleApplied"
      [semicircle]="true" color="#0067C5" background="#f0f0f0"></round-progress>
    </div>

    <div class="m-0 p-0 d-none d-md-block">
      
      <div class="d-flex mx-auto">     
        <div class="col-6 text-center nopadding"><b>{{deductibleApplied|currency}}</b></div>      
        <div class=""></div>
        <div class="col-6 text-center nopadding"><b>{{remainingDeductible|currency}}</b></div>    
      </div>

      <div class="d-flex mx-auto">
        <div class="col-6 text-center text-nowrap nopadding">Applied</div>
        <div class=""></div>
        <div class="col-6 text-center nopadding">Remaining</div>
      </div>

    </div>
    
    <div class="m-0 p-0 d-block d-md-none">
      
      <div class="d-flex mx-auto">     
        <div class="col-6 text-center nopadding"><b>{{deductibleApplied|currency}}</b></div>      
        <div class="col-6 text-center nopadding"><b>{{remainingDeductible|currency}}</b></div>    
      </div>

      <div class="d-flex mx-auto">
        <div class="col-6 text-center text-nowrap nopadding">Applied</div>
        <div class="col-6 text-center nopadding">Remaining</div>
      </div>

    </div>

    <br />

    <div class="text-center" *ngIf="isMPIPolicy">
      Total reimbursed: <b>{{totalReimbursed | currency}}</b>
      <br>
      Policy term: {{termPeriod}}
    </div>

    <div class="text-center" *ngIf="!isMPIPolicy">
      Policy term: {{termPeriod}}
    </div>
    
  </div>
</div>
