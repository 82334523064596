<div class="my-cancel-wrapper container pb-5">
  <div *ngIf="!isPendingRenewal">
    <div class="cancel-title">
      <app-navigation-back id="myPet-backbutton" (navigateBackEvent)="goToManagePolicy()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
      <h5 class="fw-bold">Policy Cancellation</h5>
    </div>
    <hr style="border: 1px solid grey" />
    <mat-card appearance="outlined">
      <p>Thank you for visiting my.petinsurance.com. You have chosen to cancel your policy using our online account management feature.</p>
      <mat-card-header>
        <p><b>Before submitting your cancellation request, please be aware of the following:</b></p>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>Claims for treatment dates on or after the cancellation effective date will not be eligible for coverage.</li>
          <li>Your policy cannot be reinstated once the cancellation is finalized.</li>
          <li>Should you desire to reacquire coverage in the future, your application will require underwriting and eligibility review. This may include submitting a physical exam and full medical and/or treatment records.</li>
          <li>All conditions that were manifested, contracted or incurred, whether covered or not, during your policy term may be considered pre-existing and therefore excluded from future coverage with a new policy.</li>
        </ul>
        <br />
        <p>Before you cancel your policy, be sure that you consider all the great reasons you enrolled your pet to begin with. Your Nationwide policy gives you the peace of mind to know that you won’t have to make the decision between the health of your pet and your pocketbook.</p>
        <br />
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 justify-content-start">
            <app-navigation-back id="backbutton" (navigateBackEvent)="goToManagePolicy()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-end">
            <button mat-raised-button type="button" color="primary" (click)="navigateToCancelForm()" class="custom-cancel-button mobile-button">Next</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="isPendingRenewal">
    <div class="container-title">
      <app-navigation-back id="upperBackButton" (navigateBackEvent)="goToManagePolicy()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
      <h1 class="fw-bold">Looks like there's been a faux paws</h1>
    </div>
    <hr style="border: 1px solid grey" />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <mat-card appearance="outlined">
          <mat-card-content>
            <p><strong>Our records indicate that your pet's policy is eligible for Renewal, therefore you are unable to cancel online at this time. </strong></p>
            <br />
            <p>
              Please submit your request for cancellation <a [href]="hyperlinksConstants.header.help.email">here</a>. Your cancellation will be effective as of the date you submit your request. Please allow 2-3 business days to process the cancellation. You will receive an email and a letter to
              confirm your cancellation. Any refund due to you will be credited to the account from which your payments have been withdrawn, or sent to you as a check.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <div class="row justify-content-center">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <app-navigation-back id="back-button" (navigateBackEvent)="goToManagePolicy()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
              </div>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"> </app-progress-spinner>

