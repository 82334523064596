const CONTENTFULIDS = {
  assets: {
    bannerDesktop: '1vjT2pAxPF8BFC8OG4yGBn',
    bannerMobile: '5Eb0v18ZRB1eZkNyVRlO1K',
    sweepstakesDesktop: '39RehhDp9clQPkuHWgUjg',
    sweepstakesMobile: '1XjAhsTYN4haAM6PGRLIOe',
    loginImage: '6neWaQKvBfqOVlHc3TPIej',
    officialRulesUrl: '5ReTXnglr92WvM6Vli4SEx',
    addressValidationText: '5a4A085LmupC918mA2nIA9',
    petRxExpressOneLineLogo: '1ql8Zc4aXFc4KqvHXSG4uI',
    petRxExpressStackedLogo: '5vpixcDqDy2iNzao9khWYb',
    nationWideHeaderLogo:'3alNVohuxUBeouPDPvfAub'
  },
  entries: {
    bannerText: '73Y9PR6HWRZam5HQbymmSL',
    bannerLink: '0ivHODWtR9vnGU99kwY8D',
    imageIcons: '57oEPVY0KgfH1uqeKnjR9A',
    bannerCarouselDesktop: '332CLoRhx1yhcOjNIiUyiP',
    bannerCarouselMobile: '5I0qEb1dOMwo5soiagIFZO',
    speciesIcons: '3IQuZ3Sca9eujK5cVmp6Kr',
    speciesNewIcons: '3IQuZ3Sca9eujK5cVmp6Kr',
  },
  contentName: {
    blueLeafIcon: "Blue Leaf Icon",
    peopleGroupIcon: "People Group Icon",
    blueLightBulbIcon: "Blue Lightbulb Icon",
    bluePawIcon: "Blue Paw Icon",
    blueMoneyIcon: "Blue Money Icon",
    blueHeartIcon: "Blue Heart Icon",
    customerServiceIcon: "Blue Customer Service Icon",
    bluePrescriptionIcon: "Blue Prescription Icon",
    wallmartLogo: "Walmart logo",
    vethelplineLogo: "Vet helpline logo",
    // Banner Carousel Images
    portalCarouselImgOne: "Portal Carousel 1 - img",
    portalCarouselImgTwo: "Portal Carousel 2 - img",
    portalCarouselImgThree: "Portal Carousel 3 - img",
    // Banner Carousel Text
    portalCarouselTextOne: "Portal Carousel 1-Text",
    portalCarouselTextTwo: "Portal Carousel 2 -Text",
    portalCarouselTextThree: "Portal Carousel 3-Text"
  }
};
export default CONTENTFULIDS;