import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {PolicyDetails} from '../../models/policy-details';
import { NewPetDetails } from '../../models/new-pet-details';
import { LinkMissingPolicy } from '../../models/link-missing-policy';
import { ClaimSummaryResponse } from '../../models/response/claim-summary-response';
import { RecentClaimsResponse } from '../../models/response/recent-claims-response';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {
  private claimUrl = environment.baseUrl + 'claims-management/pet-php-claims/v1/';
  private petClaimURL = environment.baseUrl + 'claims-management/pet-php-claims/v1/';
  private enrollNewPetURL = environment.baseUrl + 'policy-management/pet-php-policies/v1/enrollpet';
  private missingPolicyURL = environment.baseUrl + 'policy-management/pet-php-policies/v1/';
  
  constructor(private http: HttpClient) {}

  public getAllClaims(): Observable<ClaimSummaryResponse> {
    return this.http.get(this.claimUrl + 'recent').pipe(
      map(claims => claims as ClaimSummaryResponse)
    );
  }

  public getPetClaims(policyNumber: string, renewal: number): Observable<ClaimSummaryResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        activeMemberId: sessionStorage.getItem('activeMemberId')
      })
    };
    return this.http.get(`${this.petClaimURL}${policyNumber}/${renewal}`,httpOptions).pipe(
      map(claims => claims as ClaimSummaryResponse)
    );
  }

  public getFilterClaims(claimType: string, key: string, filterValue: string): Observable<RecentClaimsResponse> {
    return this.http.get(`${this.claimUrl}${claimType}/filter/${key}/${filterValue}`).pipe(
      map(claim => claim as RecentClaimsResponse)
    );
  }

  public getSortClaims(claimType: string, orderBy: string, order: string): Observable<RecentClaimsResponse> {
    return this.http.get(`${this.claimUrl}${claimType}/sort/${orderBy}/${order}`).pipe(
      map(claim => claim as RecentClaimsResponse)
    );
  }

  public getNewPetDetails( memberId: string): Observable<NewPetDetails> {
    const contentTypeHeader = new HttpHeaders({
      activeMemberId: memberId
    });
    return this.http.get(this.enrollNewPetURL, { headers: contentTypeHeader }).pipe(
      map(newPet => newPet as NewPetDetails)
    );
  }

  public linkMissingPolicy(missingPolicy): Observable<LinkMissingPolicy> {
    return this.http.post(this.missingPolicyURL + missingPolicy.policyNumber + '/linkpet', missingPolicy).pipe(
      map(policy => policy as LinkMissingPolicy)
    );
  }
}
