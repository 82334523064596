import { Component, Input, OnChanges, OnInit} from "@angular/core";
import {MatDialog} from '@angular/material/dialog';
import {MyPetsService} from '../my-pets.service';
import { Claim } from "../../../models/claim";
import { IMAGECONSTANTS } from "../../../shared/constants/image-constants";
import { GtmEventsService } from "../../../shared/services/gtm-events.service";
import { EncrDecrService } from "../../../core/services/encr-decr.service";
import { CoverageBenefitsResponse } from '../../../models/response/coverage-benefits-response';
import {CoverageBenefits} from '../../../models/coverage-benefits';
import {GroupBenefits} from '../../../models/group-benefits';

@Component({
  selector: "app-my-pet-coverage", 
  templateUrl: "./my-pet-coverage.component.html",
  styleUrls: ["./my-pet-coverage.component.scss"],
})

export class MyPetCoverageComponent implements OnInit, OnChanges 
{
  public imageConstants = IMAGECONSTANTS;
  @Input() public policyClaims: any; 
  @Input() public policy: any;
  @Input() public coverageData:any[];
  public totalBenefit: number = 0;
  public totalReimbursed: number = 0;
  public totalRemaining: number = 0;
  public wellnessData: any;
  public detailsCoverage:any[];
  public coverageBenefits= [
  {
    riskCoverageCd: "Accident",
    riskCoverageLimit: "Unlimited",
    reimbursedAmt: "0.00",
    remainingBenefit: "Unlimited",
    groupBenefits: null,
    progressValue: 0,
    showMore: true
  },
  {
    riskCoverageCd: "Illness",
    riskCoverageLimit: "10000.00",
    reimbursedAmt: "0.00",
    remainingBenefit: "10000.00",
    groupBenefits: null,
    progressValue: 0,
    showMore: true
  },
  {
    riskCoverageCd: "Wellness",
    riskCoverageLimit: "Level1",
    reimbursedAmt: null,
    remainingBenefit: "5000.00",
    progressValue: 0,
    showMore: true,
    groupBenefits: [
    {
      groupCd: "B",
      groupName: "Heartworm or FeLV/FIV test",
      groupInitialLimit: "35.0",
      groupReimbursedAmt: "0.00",
      groupRemainingBenefit: "35.00"
    },
    {
      groupCd: "C",
      groupName: "Vaccination or Titer",
      groupInitialLimit: "80.0",
      groupReimbursedAmt: "67.00",
      groupRemainingBenefit: "13.00"
    },
    {
      groupCd: "D",
      groupName: "Flea control or Heartworm prevention",
      groupInitialLimit: "100.0",
      groupReimbursedAmt: "43.09",
      groupRemainingBenefit: "56.91"
    },
    {
      groupCd: "121",
      groupName: "Physical Exam",
      groupInitialLimit: "80.00",
      groupReimbursedAmt: "80.00",
      groupRemainingBenefit: "0.00"
    },
    {
      groupCd: "183",
      groupName: "Fecal Test",
      groupInitialLimit: "30.00",
      groupReimbursedAmt: "30.00",
      groupRemainingBenefit: "0.00"
    },
    {
      groupCd: "118",
      groupName: "Microchip",
      groupInitialLimit: "50.00",
      groupReimbursedAmt: "0.00",
      groupRemainingBenefit: "50.00"
    },
    {
      groupCd: "4599",
      groupName: "Overpayment Correction",
      groupInitialLimit: "450.00",
      groupReimbursedAmt: "0.00",
      groupRemainingBenefit: "450.00"
    },
    {
      groupCd: "184",
      groupName: "Deworming",
      groupInitialLimit: "25.00",
      groupReimbursedAmt: "0.00",
      groupRemainingBenefit: "25.00"
    },
    {
      groupCd: "186",
      groupName: "Health Certificate",
      groupInitialLimit: "50.00",
      groupReimbursedAmt: "0.00",
      groupRemainingBenefit: "50.00"
    }]
  }]
 
  constructor(
    private encDcrService: EncrDecrService,
    private gtmService: GtmEventsService,
    private myPetsService: MyPetsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() 
  {    
  }

  ngOnChanges() 
  {
    this.coverageMockdata();
  }

  coverageMockdata() 
  {
    this.totalBenefit = this.totalReimbursed = this.totalRemaining = 0;
    
    this.detailsCoverage = this.coverageData;
    
    this.findWellness(this.coverageData);

    this.detailsCoverage.forEach((coverageObj) => 
    {
      if (coverageObj.riskCoverageCd == "Wellness") 
      {
        let coverageDtl: GroupBenefits[] = coverageObj.groupBenefits;
        coverageDtl.forEach((coverageDtlObj) => 
        {
          (this.totalBenefit = this.totalBenefit + Number(coverageDtlObj.groupInitialLimit)),
            (this.totalReimbursed = this.totalReimbursed + Number(coverageDtlObj.groupReimbursedAmt)),
            (this.totalRemaining = this.totalBenefit - this.totalReimbursed);
        });
      } 
      else
      {
        if(coverageObj.riskCoverageLimit!="Unlimited")
        {
          coverageObj.progressValue =
          (Number(coverageObj.reimbursedAmt) * 100) /
          Number(coverageObj.riskCoverageLimit);
        }
        else
        {
          if(coverageObj.riskCoverageLimit=="Unlimited" && coverageObj.reimbursedAmt>0)
          {
            coverageObj.progressValue=25;
          }
        }
      }
    });
  }
  
  findWellness(coverageData)
  { 
    this.wellnessData = coverageData.filter((e,i) => e.riskCoverageCd == "Wellness");

    if(this.wellnessData.length)
    {
      this.detailsCoverage = coverageData.filter((e,i) => e.riskCoverageCd != "Wellness");
      this.detailsCoverage.push(this.wellnessData[0]);
    }
  }
}