<div class="container pb-5">
  <div class="container-title">
    <app-navigation-back (navigateBackEvent)="onNavigateBack()" id="topBackButton" class=" pull-left d-inline pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">Missing Policy</h1>
  </div>
  <hr style="border: 1px solid grey"/>

  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <mat-card-content>
          <form [formGroup]="missingPolicyForm">
            <p>Please enter the policy number of your missing policy below.</p>
            <p>In order for the additional policies to be added to your Account Overview, the member name and address on each policy must <b>match exactly.</b></p>
            <p>
              If the information that appears on your policies is different, please contact us at <u>customercare@petinsurance.com</u>
              to correct the information and link your policies.
            </p>
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width pt-1" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput id="currentLoginID" type="text" maxlength="10" formControlName="policyNumber" class="material-input-form" placeholder="enter Policy Number" [dropSpecialCharacters]="false" [specialCharacters]="[ '-' ]" mask="S-0*"
                         required/>
                  <mat-label><b>Policy Number</b></mat-label>
                  <mat-hint align="end">{{ formControl.policyNumber.value?.length || 0 }}/10</mat-hint>
                  <mat-error *ngIf="formControl.policyNumber.errors && formControl.policyNumber.errors.required">Policy Number is required</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card-content>
        <br/>
        <mat-card-actions>
          <div class="row justify-content-center pb-5">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <button mat-raised-button class="custom-mat-button float-end mobile-button" color="primary" (click)="onSubmit()"
                      [disabled]="missingPolicyForm.invalid">{{ content.buttons.submit }}</button>
              <app-navigation-back (navigateBackEvent)="onNavigateBack()" id="bottomBackButton" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>

<span class="d-none">
  <div #missingPolicyErrorModal>
    <p>We're sorry. The following policy number {{ missingPolicyForm.value['policyNumber'] }} was not able to be added to your account.</p>
    <h6 class="fw-bold">Why can't I add my policy?</h6>
    <br/>
    <p>There are a few reasons why policies cannot be added. This includes:</p>
    <ul>
      <li>Name and address information does not match exactly on policies.</li>
      <li>There is a pending change request on one of the policies.</li>
    </ul>
  </div>
</span>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>
