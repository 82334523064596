import {AccountInfo} from '../../../models/account-info';
import {AccountRequest} from '../../../models/account-request';
import {AccountService} from '../../../shared/services/account.service';
import {CommonService} from '../../../shared/services/common.service';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidationConstants} from '../../../shared/constants/form-validation-constants';
import {PREFERENCES} from '../../../shared/constants/preferences';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {TitleCasePipe} from '@angular/common';
import STATECODES from '../../../shared/constants/state-codes';
import {HYPERLINKSCONSTANTS} from '../../../shared/constants/hyperlinks-constants';
import {MyPetsService} from '../../my-pets/my-pets.service';
import {AccountResponse} from '../../../models/response/account-response';
import {ErrorModalData} from '../../../models/error-modal-data';
import {BillingPaymentService} from '../../../shared/services/billing-payment.service';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import { JwtService } from '../../../core/services/jwt.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FullstoryService } from '../../../shared/services/fullstory.service';

@Component({
  selector: 'app-edit-my-account',
  templateUrl: './edit-my-account.component.html',
  styleUrls: ['./edit-my-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMyAccountComponent implements OnInit {
  public accountInfo: AccountInfo;
  public content = CONTENT;
  public defaults = CONTENT.accountInfo.myAccount.defaults;
  public editPersonalInfoForm: UntypedFormGroup;
  public errorMessages = CONTENT.accountInfo.myAccount.errorMessages;
  public formValidation = FormValidationConstants;
  public labels = CONTENT.accountInfo.myAccount.labels;
  public placeHolders = CONTENT.accountInfo.myAccount.placeHolders;
  public preferences = PREFERENCES;
  public routes = ROUTINGCONSTANTS;
  public stateCodes = STATECODES;
  public originalECP = false;
  public preferencesChanged = false;
  public hyperlinkConstants = HYPERLINKSCONSTANTS;
  public displayProgressSpinner = 0;
  public isPendingRenewal = false;
  public isPendingRenewal_GIN = false;
  public isPendingRenewal_REN = false;
  public policyDetailsList = [];
  public opened = false;
  public addressRequest = {address1: '', address2: '', city: '', state: '', zipCode: ''};
  public addressResponse: any = {address1: '', address2: '', city: '', state: '', zipCode: ''};
  @ViewChild('focusAddr1') focusAddr1: ElementRef;

  constructor(private fb: UntypedFormBuilder,
              private cdRef: ChangeDetectorRef,
              private router: Router,
              private gtmService: GtmEventsService,
              private modalService: NgbModal,
              private billingPaymentService: BillingPaymentService,
              private accountService: AccountService,
              private commonService: CommonService,
              private titleCasePipe: TitleCasePipe,
              private jwtService: JwtService,
              private fullstoryService: FullstoryService,
              private myPetsService: MyPetsService) {
  }

  ngOnInit() {
    this.getOptInInfo();
    this.getECP();
    this.getPendingRenewal();
    this.CheckPendingRenewalPolicyType();
  }

  navigateToPivot() {
    this.addSpinner();
    this.myPetsService.getPolicyDocumentsSso().subscribe({
      next: url => {
        if (url.ssoUrl) {
          if (window.innerWidth <= 768) {
            window.open(url.ssoUrl, '_parent');
          } else {
            window.open(url.ssoUrl, '_blank');
          }
        }
      },
      complete: () => this.subtractSpinner()
    });
  }

  alphabetsOnly(event: KeyboardEvent): boolean {
    return (event.key >= 'A' && event.key <= 'Z') || (event.key >= 'a' && event.key <= 'z') || event.key === ' ' || event.key === ' Backspace';
  }

  getECP() {
    this.addSpinner();
    const accountId = sessionStorage.getItem('activeAccountId'); 
    this.accountService.getECP(accountId).subscribe({
      next: response => {
        this.originalECP = response.status;
        this.getAccountInfo();
      },
      complete: () => this.subtractSpinner()
    });
  }

  applyAccountInfo(accountInfo: AccountInfo) {
    const newAccountInfo: AccountInfo = JSON.parse(JSON.stringify(accountInfo));
    newAccountInfo.homePhone = this.setPhoneNumber(accountInfo.homePhone);
    newAccountInfo.workPhone = this.setPhoneNumber(accountInfo.workPhone);
    newAccountInfo.homePhone = newAccountInfo.homePhone.replace(/[^a-zA-Z0-9]/g, '');
    newAccountInfo.workPhone = newAccountInfo.workPhone.replace(/[^a-zA-Z0-9]/g, '');
    this.accountInfo = newAccountInfo;
  }

  setPhoneNumber(phoneNumber) {
    let phone = '';
    if (phoneNumber) {
      phone = phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, phoneNumber.length) || '';
    }
    return phone;
  }

  applyFormData(): AccountRequest {
    const answers = this.editPersonalInfoForm.value;
    const accountRequest: AccountRequest = {
      accountInfo: {
        city: this.addressResponse.city?this.addressResponse.city:answers.city,
        email: answers.emailAddress,
        firstName: answers.firstName,
        homePhone: answers.homePhone,
        lastName: answers.lastName,
        state: this.addressResponse.state?this.addressResponse.state:answers.state,
        streetAddress1: this.addressResponse.address1?this.addressResponse.address1:answers.addressLine1,
        streetAddress2: this.addressResponse.address2?this.addressResponse.address2:answers.addressLine2,
        workPhone: answers.mobilePhone,
        zipCode: this.addressResponse.zipCode?this.addressResponse.zipCode:answers.zipCode,
        electronicPaymentPrefStatus: answers.electECP,
        homePhoneId: this.accountInfo.homePhoneId
      },
      updateType: null
    };
    return accountRequest;
  }

  cancel() {
    this.goToAccount();
  }

  canSubmit(): boolean {
    return this.editPersonalInfoForm.valid && (this.editPersonalInfoForm.dirty || this.preferencesChanged);
  }

  setFormValidators(pattern?, maxLength?) {
    return [Validators.required, Validators.pattern(pattern), Validators.maxLength(maxLength)];
  }

  createForm(): void {
    const patternValidation = this.formValidation.patternValidation;
    const lengthValidation = this.formValidation.lengthValidation;
    this.editPersonalInfoForm = this.fb.group({
      firstName: [this.titleCasePipe.transform(this.accountInfo.firstName), this.setFormValidators(patternValidation.firstName, lengthValidation.firstNameLength)],
      lastName: [this.titleCasePipe.transform(this.accountInfo.lastName), this.setFormValidators(patternValidation.lastName, lengthValidation.lastNameLength)],
      addressLine1: [this.titleCasePipe.transform(this.accountInfo.address1), this.setFormValidators(patternValidation.addressLine1, lengthValidation.addressLine1Length)],
      addressLine2: [this.titleCasePipe.transform(this.accountInfo.address2), [Validators.pattern(patternValidation.addressLine2)]],
      city: [this.titleCasePipe.transform(this.accountInfo.city), [Validators.required, Validators.pattern(patternValidation.city)]],
      state: [this.accountInfo.state, Validators.required],
      zipCode: [this.accountInfo.zipCode, this.setFormValidators(patternValidation.zipCode, lengthValidation.zipCodeMaxLength)],
      emailAddress: [this.accountInfo.email, [Validators.required, Validators.email, Validators.maxLength(lengthValidation.emailLength)]],
      homePhone: [this.accountInfo.homePhone, [Validators.required, Validators.maxLength(lengthValidation.phoneMaxLength), Validators.minLength(lengthValidation.phoneMinLength)]],
      electECP: this.originalECP
    });

    this.editPersonalInfoForm.valueChanges.subscribe({
      next: () => {
        this.cdRef.markForCheck();
      }
    });

    this.preferences = this.preferences.map(p => {
      p['hidden'] = true;
      return p;
    });
  }

  getPendingRenewal() {
    this.addSpinner();
    this.policyDetailsList = JSON.parse(sessionStorage.getItem('policyList'));
    this.isPendingRenewal = this.policyDetailsList.reduce((acc, curr) => curr.isPendingRenewal || acc, false);
    this.subtractSpinner();
    // this.billingPaymentService.getPolicyDetailsList().subscribe({
    //   next: response => {
    //     this.isPendingRenewal = response.policyDetailsList.reduce((acc, curr) => curr.isPendingRenewal || acc, false);
    //   },
    //   complete: () => this.subtractSpinner()
    // });
  }
  CheckPendingRenewalPolicyType()
  {
    this.addSpinner();
    var currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt = this.jwtService.DecodeToken(currentUser);
    const tokenPolicyList = decodeJwt["policyList"];
    this.policyDetailsList.forEach(element => {
      if(element.isPendingRenewal)
      {
        if(this.isPendingRenewal_REN == false)
        {
          this.isPendingRenewal_REN = tokenPolicyList.some(p => p.policyNumber === element.policyNumber && p.sourceSystem === "REN");
        }
         
        if(this.isPendingRenewal_GIN == false) 
        {
          this.isPendingRenewal_GIN = tokenPolicyList.some(p => p.policyNumber === element.policyNumber && p.sourceSystem === "GIN");
        }
      }
    });
    this.subtractSpinner();
  }
  getAccountInfo() {
    this.addSpinner();
    const memberId = sessionStorage.getItem('activeMemberId');
    this.accountService.getAccountInfo(memberId).subscribe({
      next: (data: AccountResponse) => {
        this.applyAccountInfo(data.accountInfoResponse);
        this.createForm();
      },
      error: () => {
        this.commonService.setDialogModal({
          header: 'Error retrieving contact information.'
        });
      },
      complete: () => this.subtractSpinner()
    });
  }

  getOptInInfo() {
    this.addSpinner();
    this.accountService.getOptIn().subscribe({
      next: data => {
        this.preferences[0].response = data.affiliateOptin !== 'N';
        this.preferences[1].response = data.vpiOptin !== 'N';
      },
      error: () => { this.subtractSpinner(); },
      complete: () => this.subtractSpinner()
    });
  }

  goToAccount() {
    this.router.navigateByUrl(this.routes.account);
  }

  numbersOnly(event: KeyboardEvent): boolean {
    return (event.key >= '0' && event.key <= '9') || event.key === ' ' || event.key === 'backspace';
  }

  setConfirmationModal(modalData: ErrorModalData) {
    this.commonService.setConfirmationModal(modalData).subscribe({next: () => this.goToAccount()});
  }

  updateAccount(updateType: string, accountRequest: AccountRequest, ecpChange: boolean) {
    accountRequest.updateType = updateType;
    this.addSpinner();    
   // this.accountService.updateAccountInfo(accountRequest, memberId, accountId).subscribe({
    this.accountService.updateMemberDetails(updateType,accountRequest).subscribe({
      next: response => {
        if (updateType === 'PERSONAL' && ecpChange) {
          this.updateAccount('ELECTRONICPAYMENTPREF', accountRequest, ecpChange);
        } else if (!response.some(x=>x['messageId'] !== 0)) {
          this.setConfirmationModal({
            header: 'You have successfully changed your Contact Information/Preferences.'
          });
        } else {          
          this.commonService.setValidationErrorModal(response.some(x => x['code'] === 400 || x['code'] === 402) ? 'Our records indicate that your pet’s policy has one or' +
            ' more changes still pending.' : 'Error updating contact information.');
        }
      },
      error: () => {
        this.commonService.setDialogModal({
          header: 'Error updating contact information.'
        });
        this.subtractSpinner();
      },
      complete: () => this.subtractSpinner()
    });
  }

  contactInfoDirty() {
    return Object.keys(this.editPersonalInfoForm.controls).reduce((acc, key) => {
      if (key !== 'electECP' && this.editPersonalInfoForm.controls[key].dirty) {
        return true;
      }
      return acc;
    }, false);
  }

  updateContactOrECP() {
    if (sessionStorage.getItem('policyList')) {
      const appliedFormData = this.applyFormData();
      const ecpChange = this.originalECP !== appliedFormData.accountInfo.electronicPaymentPrefStatus;
      if (this.contactInfoDirty()) {
        this.updateAccount('PERSONAL', appliedFormData, ecpChange);
      } else if (ecpChange) {
        this.updateAccount('ELECTRONICPAYMENTPREF', appliedFormData, ecpChange);
      } else {
        this.goToAccount();
      }
    }
    else {
      setTimeout(() => {
        this.updateContactOrECP();
      }, 250);
    }
  }

  save() {
    if (this.isPendingRenewal_REN && this.accountInfo.state !== this.editPersonalInfoForm.value.state) {
      this.commonService.setDialogModal({header: this.errorMessages.pendingRenewalState.modal});
      return;
    }
    if (this.isPendingRenewal_GIN && this.accountInfo.zipCode.substring(0,5) !== this.editPersonalInfoForm.value.zipCode.substring(0,5)) {
      this.commonService.setDialogModal({header: this.errorMessages.pendingRenewalState.modal});
      return;
    }
    if (this.editPersonalInfoForm.valid ) {
      this.verifyAddress();
    }
    // if (this.preferencesChanged) {
    //   this.addSpinner();
    //   this.accountService.updateOptIn({
    //       InfoOptIn: this.preferences[1].response ? 'Y' : 'N',
    //       PromoOptIn: this.preferences[0].response ? 'Y' : 'N'
    //     })
    //     .subscribe({
    //       next: data => {
    //         this.updateContactOrECP();
    //       },
    //       error: () => {
    //         this.subtractSpinner();
    //         this.commonService.setDialogModal({
    //           title: 'Error',
    //           header: 'Error updating contact information.',
    //           content: ''
    //         });
    //       }
    //     });
    // } else {
    //   this.updateContactOrECP();
    // }
    // this.setGTMEvents();
  }

  verifyAddress() {
    // build request from form values
    this.addressRequest.address1 =  this.editPersonalInfoForm.get('addressLine1').value;
    this.addressRequest.address2 =  this.editPersonalInfoForm.get('addressLine2').value;
    this.addressRequest.city =  this.editPersonalInfoForm.get('city').value;
    this.addressRequest.state  = this.editPersonalInfoForm.get('state').value;
    this.addressRequest.zipCode =  this.editPersonalInfoForm.get('zipCode').value;
  
    this.commonService.verifyAddress(this.addressRequest).subscribe(
      (data) => {
        if(data) {
          this.addressResponse=data;
          this.addressResponse.address2=this.addressResponse.address2==null||this.addressResponse.address2=='null'||this.addressResponse.address2=='Null'?'':this.addressResponse.address2;
          this.addressResponse.zipCode=this.addressResponse.zipCode.toString();
          this.updateOptIn();
        } else {
          this.addressResponse={};
          this.openAddressModal();
        }
      }, error => {
        this.addressResponse={};
        this.openAddressModal();
      }
    );
}

openAddressModal() {
  this.commonService.setAddressValidationModal(this.addressRequest).subscribe(result => {
    if (result) {
      if (result === 'Edit') {
        setTimeout(() => {
          this.focusAddr1.nativeElement.focus();
        }, 0);
        window.scrollTo(0, 0);
      } else if (result === 'Choose') {
        this.updateOptIn();
      }
    }
  });
}

  updateOptIn(){    
    if (this.preferencesChanged) {
      this.addSpinner();
      this.accountService.updateOptIn({
          InfoOptIn: this.preferences[1].response ? 'Y' : 'N',
          PromoOptIn: this.preferences[0].response ? 'Y' : 'N'
        })
        .subscribe({
          next: data => {
            this.updateContactOrECP();
          },
          error: () => {
            this.subtractSpinner();
            this.commonService.setDialogModal({
              title: 'Error',
              header: 'Error updating contact information.',
              content: ''
            });
          }
        });
    } else {
      this.updateContactOrECP();
    }
    this.setGTMEvents();
}

  setGTMEvents() {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Edit Personal Information Save',
      eventLabel: 'Edit Personal Information Save Button',
      eventValue: 'Accounts: Edit Personal Information Save',
      userId: sessionStorage.getItem('userId'),
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  setPreference(prefText: string): void {
    this.preferencesChanged = true;
    this.preferences = this.preferences.map(pref => {
      if (pref.question === prefText) {
        pref.response = !pref.response;
      }
      return pref;
    });
  }

  addSpinner() {
    this.displayProgressSpinner++;
    this.cdRef.markForCheck();
  }

  subtractSpinner() {
    this.displayProgressSpinner--;
    this.cdRef.markForCheck();
  }
}