<div *ngIf="claimsHistory" class="row">
  <ng-container *ngFor="let item of claimsHistory; let idx = index;">
    <div class="col-md-4">
      <div class="claim--item">
        <mat-card appearance="outlined" class="custom-card">
          <mat-card-header>
            <img mat-card-avatar *ngIf="checkForPetType(item.policyNumber)" alt="dogInfo" id="avatar-blueDog-{{idx}}"
              src="{{ petSpeciesUrl }}" />
            <!-- <img mat-card-avatar *ngIf="!setCanineImage(item.policyNumber)" alt="dogInfo" id="avatar-blueCat-{{idx}}"
              src="{{ iconObj.felineIcon }}" /> -->
            <mat-card-title id="card-title-{{idx}}"><b>{{ item?.petName | titlecase }}</b></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="claims--item_top">
              <div class="row">
                <div class="col">
                  <p id="diagnosis-{{idx}}">
                    {{ content.claimsPage.allClaims.diagnosis }}:
                    <span class="float-end">
                      <!--<strong>{{ item?.claimDetails && item.claimDetails[0]?.diagnosisDescription ? ((item?.claimDetails)[0].diagnosisDescription | titlecase) : 'Pending' }}</strong>-->
                      <strong>{{ !isInProcess(item) && item.diagnosisDesc ? (item?.diagnosisDesc | titlecase) : 'Pending' }}</strong>
                    </span>
                  </p>
                  <p id="date-{{idx}}">{{ content.claimsPage.allClaims.date }}:<span
                      class="float-end"><strong>{{ item.treatmentDate | date }}</strong></span>
                  </p>
                  <p id="submittedAmount-{{idx}}">
                    {{ content.claimsPage.allClaims.submittedAmount }}:
                    <span class="float-end">
                      <strong>{{ getSubmittedAmount(item) && !isInProcess(item) ? (getSubmittedAmount(item) | currency) : 'Pending' }}</strong>
                    </span>
                  </p>
                  <p id="claimNumber-{{idx}}">
                    {{ content.claimsPage.allClaims.claimNumber }}:
                    <span class="float-end">
                      <strong>{{ item?.claimNumber }}</strong>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div class="col-md-12 mx-auto">
              <div class="text-center mt-3">
                <button mat-raised-button type="button" color="primary" id="see-details-{{idx}}"
                  (click)="navigateToClaimDetails(item.claimNumber, item.policyNumber, item.policyRenewal)">{{ content.claimsPage.allClaims.seeDetails }}</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="!claimsHistory?.length" class="row justify-content-center pt-5">
  <div class="col-md-10 col-lg-10 col-sm-12 text-center">
    <img src="{{ imageConstants.images.historyClaimImage }}" alt="empty_image" height="200px" width="200px" />
    <br />
    <h5><strong>All's quiet over here.</strong></h5>
    <br />
  </div>
</div>

<div *ngIf="claimsHistory.length === 3 && claims.length !== 3" class="text-center link-text d-block d-md-none">
  <a href="javascript:void(0)" (click)="viewAllClaims()"
    id="view-all-claims">{{ content.claimsPage.historyClaims.viewPastClaims }}</a>
</div>
