import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OTEDocumentService {
  //public submitNewClaimURL = environment.renbaseUrl + 'claims/v1/';
  public oteDocumentURL = environment.baseUrl + 'documentation-management/distributed-documents/v1/documents';
  public requestJsonObject: any;
  public policyNumberList: any[];

  constructor(private http: HttpClient) {
  }
  getOteDocument(): Observable<any> {
    const httpOptions = {
        headers: new HttpHeaders({
            TargetEnvironment: 'IterationTest',
            'X-NW-Message-Id': sessionStorage.getItem('XNWMessageId'),
            client_id: environment.client_id 
        })
      };
    return this.http.post(this.oteDocumentURL,this.requestJsonObject,httpOptions)
      .pipe(map(response => response)
      );
  }
}
