import {Injectable} from '@angular/core';
import {CreateUserRequest} from '../../models/request/create-user-request';
import {ValidatePolicyRequest} from '../../models/request/validate-policy-request';
import {Claim} from '../../models/claim';
import {MyPets} from '../../models/my-pets';
import {PolicyRenewalData} from '../../models/policy-renewal-payments';
import {StandardMessage} from '../../models/standard-message';
import {CancelResponseView} from '../../models/cancel-response-view';
import {ForgotPasswordRequest} from '../../models/request/forgot-password-request';
import {MakeAPayment} from '../../models/make-a-payment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public createUser: CreateUserRequest = {};
  public policyInfo: ValidatePolicyRequest = {};
  public claimsData: Claim[];
  public recentClaims: Claim[];
  public historyClaims: Claim[];
  public mostRecentClaims: Claim[];
  public myPets: MyPets[];
  public userId: string;
  public policyRenewalData: PolicyRenewalData;
  public cancelResponseView: CancelResponseView;
  public standardMessage: StandardMessage;
  public securityQuestion: string;
  public forgotPasswordRequest: ForgotPasswordRequest = {};
  public pastDueAmount: MakeAPayment;

  constructor() {
  }
}
