<div class="container">
  <mat-dialog-content>
    <img alt="" src="./../../../../assets/images/landing-page/nationwide-logo.png" width="200">
    <div class="address">
      One Nationwide Plaza
      <br>
      Columbus, OH 43215 USA
    </div>
    <br><br>
    <p><b>{{data.transaction.transactionDate}}</b></p>
    <p>Thank you for your payment!</p>
    <hr>
    <div>
      Pet name: <span class="fw-bold">{{data.petName}}</span>
    </div>
    <div>
      Policy number: <span class="fw-bold">{{data.policyNumber}}</span>
    </div>
    <br><br>
    <div>
      Payment account number: <span class="fw-bold">{{data.transaction.accountNumber}}</span>
    </div>
    <div>
      Payment type: <span class="fw-bold">{{data.transaction.paymentMethod | titlecase}}</span>
    </div>
    <div>
      Account type: <span class="fw-bold">{{data.transaction.paymentType}}</span>
    </div>
    <div>
      Description: <span class="fw-bold">{{data.transaction.transactionType | titlecase}}</span>
    </div>
    <div>
      {{(data.transaction.transactionType | uppercase) === 'PREMIUM PAYMENT' ? 'Payment' : 'Reversal/Refund'}}: <span class="fw-bold">{{(data.transaction.amount|currency) || (data.transaction.refund|currency)}}</span>
    </div>
    <br><br>
    <div class="text-center information">
      Please contact us with inquiries at 800-540-2016 during
      <br>
      normal business hours Monday through Saturday
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="row col-12 actions">
      <div class="col-6 text-end">
        <a (click)="generateReceipt()" href="javascript:void(0);">Print statement</a>
      </div>

      <div class="col-6 text-start">
        <a href="javascript:void(0);" (click)="closeMe()">Close window</a>
      </div>
    </div>
  </mat-dialog-actions>
</div>