import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IMAGECONSTANTS} from '../../constants/image-constants';

@Component({
  selector: 'app-navigation-back',
  templateUrl: './navigation-back.component.html',
  styleUrls: ['./navigation-back.component.scss']
})
export class NavigationBackComponent implements OnInit {

  public imageConstants = IMAGECONSTANTS;
  @Output() public navigateBackEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  navigateBack(event) {
    this.navigateBackEvent.emit(event);
  }
}
