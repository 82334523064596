import {allSortOptions, filterOptionsHistory, filterOptionsRecent, FILTERTYPES} from './../../../shared/constants/claims';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {CONTENT} from '../../../content-management/content';
import {PolicyDetails} from '../../../models/policy-details';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {CommonService} from '../../../shared/services/common.service';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import {HomepageService} from '../../homepage/homepage.service';
import {ClaimsService} from './claims.service';
import {TYPES} from '../../../shared/constants/claims';
import {RecentClaimsResponse} from '../../../models/response/recent-claims-response';
import {RecentClaim} from '../../../models/recent-claim';
import {TitleCasePipe} from '@angular/common';
import {EncrDecrService} from '../../../core/services/encr-decr.service';
import {JwtService} from '../../../core/services/jwt.service';

@Component({
  selector: 'app-all-claims',
  templateUrl: './all-claims.component.html',
  styleUrls: ['./all-claims.component.scss']
})
export class AllClaimsComponent implements OnInit {
  public content = CONTENT;
  public routingConstants = ROUTINGCONSTANTS;
  public recentClaims: RecentClaim[] = [];
  public claimsHistory: RecentClaim[] = [];
  public filterRecentClaims: RecentClaim[] = [];
  public resultRecentClaims: RecentClaim[] = [];
  public sortedRecentClaims: RecentClaim[] = [];
  public historyRecentClaimsList: RecentClaim[] = [];
  public recentRecentClaimsList: RecentClaim[] = [];
  public filterOptions = [];
  public sortOptions = [];
  public selectedFilter: any;
  public selectedSorting: any;
  public claimType: any = 'recent';
  public displayProgressSpinner = 0;
  public showFilter = false;
  public recentPets = [];
  public historyPets = [];
  public policyDetailsList: PolicyDetails[] = [];
  public filterOptionsRecent = filterOptionsRecent;
  public allSortOptions = allSortOptions;
  public filterOptionsHistory = filterOptionsHistory;
  public selectedTabIndex: number;
  public policyNumber: string;
  public petName: string;
  public tokenPolicyList: any = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private titleCasePipe: TitleCasePipe,
              private homepageService: HomepageService,
              private commonService: CommonService,
              private claimService: ClaimsService,
              private encDcrService: EncrDecrService,
              private jwtService: JwtService,
              private gtmService: GtmEventsService) {
  }

  ngOnInit() {
    this.getInitialLoad();
  }

getInitialLoad(){
  if(sessionStorage.getItem('policyList')){
    if(this.displayProgressSpinner>0){
      this.displayProgressSpinner--;
    }
    //this.getNewRecentClaims(false);
    this.filterOptions = this.filterOptionsRecent;
    this.getPetInfoFromParams();
  }else{
    if(this.displayProgressSpinner==0){
      this.displayProgressSpinner++;
    }
    setTimeout(() => { 
    this.getInitialLoad();
    },250);
  }

}

  getPetInfoFromParams() {
    this.selectedSorting = '';
    this.selectedFilter = '';
    this.route.params.subscribe((params) => {
      const policyNumber = this.encDcrService.get(params.policyNumber);
      if (params.petName && policyNumber) {
        this.displayProgressSpinner++;
        this.getViewPastClaims(policyNumber);
        
       // this.getNewRecentClaims(true);
        //setTimeout(() => { 
        this.selectedTabIndex = 1;
        this.selectedFilter = FILTERTYPES.POLICY_NUMBER;
        this.petName = params.petName;
        this.policyNumber = policyNumber;
        this.sortOptions = [{
          value: this.policyNumber,
          label: this.petName
        }];
        this.selectedSorting = policyNumber;
        this.claimType = 'history';
        //this.sortChange(policyNumber);
        this.displayProgressSpinner--;
        // }, 000);
      }else{
        this.getNewRecentClaims(false);
      }
    });
  }

  filterChange(change) {
    this.selectedSorting = '';
    this.selectedFilter = change;
    if (this.selectedFilter !== FILTERTYPES.POLICY_NUMBER) {
      this.sortOptions = this.allSortOptions.filter(sortOption => sortOption.filter === this.selectedFilter);
    } else {
      this.sortOptions = this.claimType === TYPES.RECENT ? this.recentPets : this.historyPets;
    }
  }

  getFilteredData(claimSummaryResponse: RecentClaimsResponse) {
    if (claimSummaryResponse) {
      if (claimSummaryResponse.claims) {
        if (this.claimType === TYPES.RECENT) {
          this.recentClaims = claimSummaryResponse.claims;
          this.commonService.setClaimStatusBar(this.recentClaims);
          this.displayProgressSpinner--;
        } else {
          this.claimsHistory = claimSummaryResponse.claims;
          this.displayProgressSpinner--;
        }
      } else if (claimSummaryResponse.standardMessage) {
        this.commonService.setValidationErrorModal({
          title: 'Error',
          content: 'There was in issue retrieving filtered claims.'
        });
      }
    }
  }

  public getNewRecentClaims(isHistory: boolean) {
    this.displayProgressSpinner++;
    const pastThirtyDaysFromToday = this.addDays(30);
    const currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt = this.jwtService.DecodeToken(currentUser);
    this.tokenPolicyList = JSON.parse(sessionStorage.getItem('policyList'));
    this.claimService.getNewRecentClaims(this.tokenPolicyList).subscribe({
      next: (data: any) => {
        if (data) {
          this.recentRecentClaimsList=[];
          this.historyRecentClaimsList=[];
          data.forEach(claimSummaryList => {
            if(claimSummaryList!==null && claimSummaryList!==undefined)
            {claimSummaryList.filter(element => {              
              const recentClaim: any = {};
              recentClaim.petName = element.petName;
              recentClaim.planName = element.policyPlanType;
              recentClaim.treatmentDate = new Date(element.treatmentDate);
              recentClaim.reportedDate = new Date(element.reportedDate);
              recentClaim.policyNumber = element.policyNumber;
              recentClaim.policyRenewal = element.renewal;
              recentClaim.claimStatus = element.status;
              recentClaim.claimNumber = element.claimNumber;
              recentClaim.statusbar = element.statusbar;
              recentClaim.diagnosisCode = element.diagnosisCode;
              recentClaim.diagnosisDesc = element.diagnosisDesc;
              recentClaim.totalClaimAmount = element.totalClaimAmount;
              if (recentClaim.reportedDate >= pastThirtyDaysFromToday) {
                this.recentRecentClaimsList.push(recentClaim);
              } else {
                this.historyRecentClaimsList.push(recentClaim);
              }

            });}
          });
          if (isHistory) {
            
            //this.historyRecentClaimsList = this.historyRecentClaimsList.sort((curr,acc) =>acc.treatmentDate.toISOString().localeCompare(curr.treatmentDate.toISOString()));
            this.resultRecentClaims = this.historyRecentClaimsList;
            //this.setClaims(this.historyRecentClaimsList, isHistory);
          } else {
            //this.recentRecentClaimsList = this.recentRecentClaimsList.sort((curr,acc) =>acc.treatmentDate.toISOString().localeCompare(curr.treatmentDate.toISOString()));
            this.resultRecentClaims = this.recentRecentClaimsList;
           // this.setClaims(this.recentRecentClaimsList, isHistory);
          }
          const recentclaimsResponse: RecentClaimsResponse = new RecentClaimsResponse();
          recentclaimsResponse.claims = this.resultRecentClaims;
          this.recievedRecentClaims(recentclaimsResponse, isHistory);
        //   this.claimService.getAllClaimDetails(this.resultRecentClaims).subscribe({
        //       next: claimData => {
        //         if (claimData) {
        //           var recentclaimsResponse: RecentClaimsResponse = new RecentClaimsResponse;
        //           recentclaimsResponse.claims = claimData;
        //           this.recievedRecentClaims(recentclaimsResponse, isHistory)                 
        //         }
        //       },
        //       complete: () => this.displayProgressSpinner--
        //     });         
        // }    
      }},
      complete: () => this.displayProgressSpinner--
    });
  }


  public getViewPastClaims(policyNumber) {
    this.displayProgressSpinner++;
    this.tokenPolicyList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = this.tokenPolicyList.find(p=>p.policyNumber === policyNumber);
    const activeMemberId = selectedPolicy.memberId;
    this.claimService.getClaimsList(selectedPolicy,activeMemberId).subscribe({
      next: (data: any) => {
        if (data) {
          this.historyRecentClaimsList=[];
            data.filter(element => {              
              const recentClaim: any = {};
              recentClaim.petName = element.petName;
              recentClaim.planName = element.policyPlanType;
              recentClaim.treatmentDate = new Date(element.treatmentDate);
              recentClaim.reportedDate = new Date(element.reportedDate);
              recentClaim.policyNumber = element.policyNumber;
              recentClaim.policyRenewal = element.renewal;
              recentClaim.claimStatus = element.status;
              recentClaim.claimNumber = element.claimNumber;
              recentClaim.statusbar = element.statusbar;
              recentClaim.diagnosisCode = element.diagnosisCode;
              recentClaim.diagnosisDesc = element.diagnosisDesc;
              recentClaim.totalClaimAmount = element.totalClaimAmount;
              this.historyRecentClaimsList.push(recentClaim);
              
            });
          this.resultRecentClaims = this.historyRecentClaimsList;
          const recentclaimsResponse: RecentClaimsResponse = new RecentClaimsResponse();
          recentclaimsResponse.claims = this.resultRecentClaims;
          this.recievedRecentClaims(recentclaimsResponse, true);
          // this.claimService.getAllClaimDetails(this.resultRecentClaims).subscribe({
          //     next: claimData => {
          //       if (claimData) {
          //         var recentclaimsResponse: RecentClaimsResponse = new RecentClaimsResponse;
          //         recentclaimsResponse.claims = claimData;
          //         this.recievedRecentClaims(recentclaimsResponse, true)                 
          //       }
          //     },
          //     complete: () => this.displayProgressSpinner--
          //   });         
        }
      },
      complete: () => {
        this.displayProgressSpinner--;
      }
    });
  }

  goToHome() {
    this.router.navigate([this.routingConstants.home]);
  }

  setSorting(claims: RecentClaim[]) {
    const map = new Map();
    return claims.reduce((acc, curr) => {
      if (!map.has(curr.policyNumber)) {
        map.set(curr.policyNumber, true);
        acc.push({
          value: curr.policyNumber,
          label: curr.petName
        });
      }
      return acc;
    }, []);
  }

  setClaims(claims: RecentClaim[], isHistory: boolean) {
    let type = TYPES.RECENT;
    if (isHistory) {
      this.claimsHistory = claims;
      type = TYPES.HISTORY;
      this.historyPets = this.setSorting(this.claimsHistory);
    } else {
      this.recentClaims = claims;
      this.commonService.setClaimStatusBar(this.recentClaims);
      this.recentPets = this.setSorting(this.recentClaims);
    }
  }

  setShowFilter(claims: RecentClaim[]) {
    this.showFilter = !!(claims && claims.length);
  }

  recievedRecentClaims(recentClaimResponse: RecentClaimsResponse, isHistory: boolean) {
    if (recentClaimResponse) {
      if (recentClaimResponse.claims) {
        this.resultRecentClaims= recentClaimResponse.claims;
        recentClaimResponse.claims = this.resultRecentClaims.sort((curr,acc) =>acc.treatmentDate.toISOString().localeCompare(curr.treatmentDate.toISOString()));
        //recentClaimResponse.claims = this.getHistoryRecentClaims(isHistory);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isHistory ? (this.claimsHistory = this.resultRecentClaims) : (this.recentClaims = this.resultRecentClaims);
        this.setClaims(recentClaimResponse.claims, isHistory);        
      } else if (recentClaimResponse.standardMessage) {
        this.commonService.setValidationErrorModal({
          title: 'Error',
          content: `There was an issue retrieving ${isHistory ? 'claim history' : 'recent claims'}.`
        });
      }
      this.setShowFilter(recentClaimResponse.claims);
    }
  }

 getHistoryRecentClaims(ishistory: boolean){    
    //var pastThirtyDaysFromToday = this.addDays(30);
   if(ishistory){
      //this.historyRecentClaimsList = this.resultRecentClaims.filter(claim=>claim.reportedDate<pastThirtyDaysFromToday);      
       this.historyRecentClaimsList = this.historyRecentClaimsList.sort((curr,acc) =>acc.treatmentDate.toISOString().localeCompare(curr.treatmentDate.toISOString()));
      return this.historyRecentClaimsList;
    } else{
      //this.recentRecentClaimsList = this.resultRecentClaims.filter(claim=>claim.reportedDate>=pastThirtyDaysFromToday)
       this.recentRecentClaimsList = this.recentRecentClaimsList.sort((curr,acc) =>acc.treatmentDate.toISOString().localeCompare(curr.treatmentDate.toISOString()));
      return this.recentRecentClaimsList;
    }
  }

  addDays(days: number): Date{
    const today = new Date();
    today. setDate(today. getDate() - days);
    return today;
    }

  isSort() {
    return this.selectedFilter === FILTERTYPES.TREATMENT_DATE || this.selectedFilter === FILTERTYPES.REPORTED_DATE;
  }

  sortChange(value) {
    this.selectedSorting = value;
    this.displayProgressSpinner++;
    if (!this.isSort()) {
      this.getFilteredClaims();
    } else {
      this.getSortedClaims();
    }
  }

  getFilteredClaims() {
    if(this.selectedFilter!=null && this.selectedFilter=='CLAIM_STATUS'){
      this.filterRecentClaims = this.resultRecentClaims?.filter(claim=>claim?.claimStatus?.toUpperCase()?.trim() == this.selectedSorting?.toUpperCase()?.trim());
    }
    else if(this.selectedFilter!=null && this.selectedFilter=='POLICY_NUMBER'){
      this.filterRecentClaims = this.resultRecentClaims?.filter(claim=>claim?.policyNumber?.toUpperCase()?.trim() == this.selectedSorting?.toUpperCase()?.trim());
    }
    const claimSummaryResponse = new RecentClaimsResponse();
    claimSummaryResponse.claims = this.filterRecentClaims;
    setTimeout(() => { this.getFilteredData(claimSummaryResponse); }, 2000);
  }

  getSortedClaims() {
    if(this.selectedFilter!=null && this.selectedFilter=='TREATMENT_DATE'){
      if(this.selectedSorting!=null && this.selectedSorting=='DESC'){
        this.filterRecentClaims = this.resultRecentClaims.sort((curr,acc) =>acc.treatmentDate.toISOString().localeCompare(curr.treatmentDate.toISOString()));
      }else if(this.selectedSorting!=null && this.selectedSorting=='ASC'){
        this.filterRecentClaims = this.resultRecentClaims.sort((curr,acc) =>curr.treatmentDate.toISOString().localeCompare(acc.treatmentDate.toISOString()));
      }
    }
    else if(this.selectedFilter!=null && this.selectedFilter=='REPORTED_DATE'){
      if(this.selectedSorting!=null && this.selectedSorting=='DESC'){
        this.filterRecentClaims = this.resultRecentClaims.sort((curr,acc) =>acc.reportedDate.toISOString().localeCompare(curr.reportedDate.toISOString()));
      }else if(this.selectedSorting!=null && this.selectedSorting=='ASC'){
        this.filterRecentClaims = this.resultRecentClaims.sort((curr,acc) =>curr.reportedDate.toISOString().localeCompare(acc.reportedDate.toISOString()));
      }
    }
    const claimSummaryResponse = new RecentClaimsResponse();
    claimSummaryResponse.claims = this.filterRecentClaims;
    setTimeout(() => { this.getFilteredData(claimSummaryResponse); }, 2000);
  }

  tabChange(tab) {
    this.selectedSorting = '';
    this.selectedFilter = '';
    this.sortOptions = [];
    if (tab && tab.index === 1) {
      this.filterOptions = this.filterOptionsHistory;
      this.claimType = TYPES.HISTORY;
      this.getNewRecentClaims(true);
    } else {
      this.filterOptions = this.filterOptionsRecent;
      this.claimType = TYPES.RECENT;
      this.getNewRecentClaims(false);
    }
  }
}
