import {FormValidationConstants} from '../shared/constants/form-validation-constants';
import {HYPERLINKSCONSTANTS} from '../shared/constants/hyperlinks-constants';

export const CONTENT = {
  header: {
    contact: {
      phone: '844-397-8938',
      underwriter: 'Underwriter'
    },
    hamburgerMenu: {
      home: 'Home',
      pets: 'My pets',
      claims: 'My claims',
      info: 'My account',
      newClaim: 'Start a new claim',
      settings: 'Settings',
      help: 'Help',
      logout: 'Logout',
      notifications: 'Notifications',
      collapse: 'Collapse',
      expand: 'Expand',
      viewDocuments: 'View my documents'
    }
  },
  footer: {
    home: 'Home',
    claims: 'Claims',
    pets: 'Pets',
    accounts: 'Accounts'
  },
  loginPage: {
    heading: 'Nationwide Pet Account Access Page',
    login: 'Log in',
    forgotIdAndPassword: 'Reset password',
    forgotId: 'Forgot your ID?',
    forgotPassword: 'Forgot your Password?',
    needHelp: 'Need help logging in?',
    createAccount: 'Register an account',
    createAccountShort: 'Register',
    rememberMe: 'Remember me on this Computer',
    rememberMeInfo: 'This should not be used on public computers or where others might have access to your computer',
    alreadyUpdated: 'Log in',
    updatePassword: 'Update password',
    info: {
      part1: 'Update your contact and payment information',
      part2: 'File a claim',
      part3: 'Check claim status and history',
      part4: 'Download forms',
      part5: 'Renew your policy',
      part6: 'Read pet health stories, policy news, Member Perks and more in The Companion newsletter',
      termsAndConditions: 'Products underwritten by Veterinary Pet Insurance Company (CA), Columbus, OH, an A.M. Best A+ rated company (2020);' +
        ' National Casualty Company (all other states), Columbus, OH, an A.M. Best A+ rated company (2020). Agency of Record: DVM Insurance Agency.' +
        ' All are subsidiaries of Nationwide Mutual Insurance Company. Subject to underwriting guidelines, review and approval. Products and discounts not ' +
        'available to all persons in all states. Insurance terms, definitions and explanations are intended for informational purposes only and do not in any ' +
        'way replace or modify the definitions and information contained in individual insurance contracts, policies or declaration pages, which are controlling. ' +
        'Nationwide, the Nationwide N and Eagle, and Nationwide is on your side are service marks of Nationwide Mutual Insurance Company. ©2021 Nationwide.'
    }
  },
  electronicClaimPage: {
    headers: {
      main: 'Sign Up for Electronic Claim Payments',
      confirmed: 'Thanks for opting in to electronic claim payments.',
      faq: 'Frequently Asked Questions'
    },
    body: {
      confirmed: `Once your next pet insurance claim is processed, we'll let you know when your reimbursement is available.
      Just look for a text or email from us with instructions on how to accept payment.`,
      main: 'Get your claim payments faster. We’ve partnered with Chase to deposit your pet insurance reimbursements directly to any bank. <b> It’s quick, easy and free!</b>',
      moreInfo: `For more information click <a href="${HYPERLINKSCONSTANTS.customerHelp}" target="_blank">here</a> to learn more.`,
      list: ['No fees of any kind', 'Faster payment—no more waiting for paper checks in the mail', 'Get notified by text or email when your reimbursement is available', 'Opt out any time'],
      faqs: [
        {
          title: 'What happens after I sign up?',
          body: `When your next claim has been processed, you will receive an email within 24 hours with instructions on how to register for an electronic payment account through Chase Payments.
            You can link this Chase Payments account to your desired bank account(checking or savings).  You will only need to register for a Chase Payments account once.
             For future claims, you will be notified of available claim payments by email and you will only need to accept these payments via your Chase Payments account.`
        },
        {
          title: 'How long will it take to receive payment?',
          body: `Once you have registered for a Chase Payments account, you will be able to immediately accept the pending payment.
             After you have accepted the payment, it will be transferred to the bank account you have specified within one to two business days.`
        },
        {
          title: 'Can I use any bank to opt in to Electronic Claims Payments?',
          body: 'You can use any U.S. bank to opt in. Simply provide your routing number and bank account number.'
        },
        {
          title: 'Will I receive anything in the mail?',
          body: `No. When you have opted in for electronic payment of claims, you will also receive email notification of your explanation of benefits,
           which will be available for view from your Nationwide Pet Account View My Documents page.`
        },
        {
          title: 'Is there a fee for receiving electronic payment of claims?',
          body: 'No. There is no fee for receiving electronic claims payments.'
        },
        {
          title: 'Can I un-enroll from electronic payment of claims?',
          body: `Yes, if you are already enrolled to receive electronic payment of claims, you can un-enroll within your Nationwide Pet Account Overview
           page by visiting the "Edit My Profile" section and clicking to uncheck the box that reads: "Yes, I want to receive electronic claim payments."
            Once you have unchecked the box, click "Submit" to save your preferences.`
        }
      ]
    },
    form: {
      header: 'Yes, I’d like to receive electronic claim payments.',
      body: 'If you’d also like to receive a text message when your reimbursement is available, provide your mobile phone number below.Standard messaging rates apply.'
    },
    tos: {
      header: 'Terms of service.',
      terms: [
        {
          header: 'Electronic deposit authorization',
          body: `By accepting this agreement, you authorize Nationwide Mutual Insurance Company, or any of its subsidiary and affiliated companies, to send your
          claim payments electronically to be distributed by JP Morgan Chase.You also authorize your bank to accept these transactions and credit your account
          accordingly.`
        },
        {
          header: 'Authorization continuation',
          body: `Your authorization is not invalidated by a change in company name or policy number, and it applies to the policies identified(e.g., pet),
           as well as to any additional policies added to your account with us after the date this agreement is accepted, unless you expressly indicate otherwise.`
        },
        {
          header: 'Stopping electronic deposits',
          body: `You understand that your authorization allows us to make one or more deposits through JP Morgan Chase, unless you tell us you want us to stop.To withdraw your authorization,
           you must uncheck the preference box related to electronic claims payment in your Edit Contacts / Preferences tab.`
        },
        {
          header: 'Explanation of Benefits',
          body: 'By accepting this agreement, all Explanation of Benefits related to any submitted claims will be sent to you electronically.'
        }
      ]
    }
  },
  registerAccountPage: {
    heading: 'Create Your Account',
    info: 'Step 1: Enter the required information below For security purposes, the information entered below must exactly\n' + '       match the information that is currently on file. It will be matched against our records to confirm your identity for this account.'
  },
  createLogInPage: {
    heading: 'Create Your Account',
    infoStep1: 'Step 2: Please create your LoginID and password',
    infoStep2: 'Step 3: please create a security question and answer for verification',
    dropDwnPlaceHolder: 'Please Create a Question'
  },
  claimsPage: {
    pending: 'Pending',
    allClaims: {
      heading: 'My Claims',
      newClaim: 'File a new claim',
      viewRecentClaims: 'View all recent claims',
      seeDetails: 'See details',
      viewCoverageDetails: 'View Coverage Details',
      diagnosis: 'Diagnosis',
      treatmentDate: 'Treatment Date',
      submittedDate: 'Submitted Date',
      plan: 'Plan',
      claimNumber: 'Claim Number',
      claimStatus: 'Claim Status',
      submittedAmount: 'Submitted Amount',
      date: 'Treatment Date',
      deductibleApplied: 'Deductible Applied',
      deductibleRemaining: 'Deductible Remaining',
      paid: 'Paid',
      heading1: 'My claim details',
      heresARecap: `Here's a recap`,
      heresWhatsNext: `Here's what's next`,
      heresWhatYouveSentUs: `Here's what you've sent us`,
      navigationFileNewClaims: '/file-new-claim'
    },
    recentClaims: {
      claimInReview: 'Claim in review',
      recap: 'Here’s a recap',
      whatHappened: 'Tell us what happened',
      whatYouSent: 'What you sent',
      faqs: 'FAQs',
      seeAnswer: 'See Answer',
      faq1: 'How long will it take to review my claim?',
      faq2: 'Does Nationwide send notifications when they receive my claim?',
      faq3: 'What will I receive once my claim is complete?',
      details: 'Details',
      detailsInfo: 'We\'ve completed your claim and sent payment. Thanks for taking such great care of your pet!',
      benefits: 'Explanation of benefits',
      viewRecentClaims: 'View all recent claims',
      completeClaim: ` For claims requiring medical records, the status will not be updated until the medical record review is complete. Claims are typically processed within two weeks from the time we receive all necessary information.
      However, if the claim requires additional time for processing, we\'ll notify the member prior to the 30-day mark, as regulated by each state.`
    },
    historyClaims: {
      details: 'Details',
      infoDetails: `We've completed your claim and sent payment. Thanks for taking such great care of your pet!`,
      explanationOfBenefits: 'Explanation of benefits',
      navigationClaims: '/claims',
      viewPastClaims: 'View past claims',
      viewRecentClaims: 'View all recent claims',
    }
  },

  claimSubmisstionAuth: 'By use of this service, you authorize Walmart and its service providers to submit your pet prescription transactions to Nationwide Mutual Insurance Company (“Nationwide”) on your behalf for the purpose of ' +
    'determining and processing benefits under your pet insurance policy(ies). You understand that you are financially responsible for all information submitted and any amount of pharmacy billing not covered by your Nationwide pet insurance policy.',

  homePage: {
    welcome: 'Welcome,',
    recentClaims: {
      mostRecent: 'Most Recent Claim',
      submittedDate: 'Submitted',
      claimNumber1: 'Claim#',
      claimNumber: 'Claim Number',
      seeAllClaims: 'See all claims',
      yourClaim: 'Your claim is',
      fileNewClaim: 'Start a new claim',
      navigationFileNewClaims: '/file-new-claim'
    },
    disclaimer: `Changes to policy information are updated every 24 hours unless otherwise indicated. By accessing the policy information online,
                the member is responsible for the accuracy of the information provided. Any person who knowingly and with intent to defraud any
                insurance company or other person commits a fraudulent insurance act may be subject to criminal and civil penalties.`,
    homePageFooter: {
      enrollPet: 'Enroll a new pet',
      vetHelpLine: 'Vethelpline',
      VethelplineInfo: 'Get 24/7 help from a veterinary expert on any pet health question.',
      walmartInfo: 'Get Rx pet meds for less at Walmart and Sam\'s Club pharmacies with Nationwide Pet Rx Express',
      fastCash: 'Receive your claim payment electronically!',
      fastCashHome: 'Get fast, easy electronic reimbursement',
      ecpEnrolled: `You've enrolled to accept claim payments electronically!`,
      referAFriend: 'Refer-a-friend',
      petNewsLetter: 'Pet newsletter',
      latestStories: 'Get the latest stories online',
      viewCompanion: 'View Companion',
      infoGraphic: 'Infographic: Pets & Toxic Plants',
      protectPet: 'Protect your pets from dangerous foliage',
      download: 'Download',
      petBirthDay: 'Pet birthday Pictures',
      birthdayShout: 'Give your pet a birthday shout-out on Facebook',
      birthdayPage: 'Go to the birthday page',
      referFriend: 'Refer a friend',
      companion: 'View pet health tips, Member Perks and our latest giveaway',
      phz: 'See articles, infographics and videos on daily life with pets',
      // sweepsTitle: 'Enter ',
      // sweepsTitle1: 'The Comfort Zone sweepstakes.',
      // sweepsCopy1: `Build the snuggle station of your dreams with gift cards and cozy cushions for you and your pets.
      //               One grand prize winner will receive a Supersac Lovesac, Best Friends by Sheri luxury pet bed and a
      //               $500 Tango gift card. 3 monthly prize winners will receive a $100 Tango card and a Best Friends by Sheri pet bed.`,
      // sweepsCopy2: 'To enter ',
      // sweepsCopy3: ' for one of your own pets who currently isn\'t insured by Nationwide, or one that you\'d like to get in the future. Sweepstakes runs 8/1/20-10/31/20.',
      sweepsTitle: `Enter to win our Kick Off the New Year sweepstakes`,
      sweepsCopy1: `Whether you and your pets are slow-walking into 2021 or running full speed ahead, enter to win our Kick Off the New Year
       sweepstakes to get started on the right foot - and right paw. Three lucky winners will receive a FitBark 2, a $250 Tango gift card, a Fitbit
       Charge 4 and custom Nike shoes designed by you!`,
      sweepsCopy2: `petinsurance.com/referral`,
      sweepsCopy3: `to send one.`,
      sweepsCopy4: `Sweepstakes runs 1/1/21-3/31/21. See `,
      sweepsCopy5: `official rules.`,
      sweepsCopy6: `Tango is not a sponsor of this promotion. The merchants represented are not sponsors of the rewards or otherwise affiliated with Nationwide.
      The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates. Please visit each company's website for additional terms and conditions.`,
      sweepCopy7: `To enter, complete a quote for one of your own pets who currently isn't insured by Nationwide, or one that you'd like to get in the future.`,
      sweepCopy8: `Get a quote`,
      sweepsQuote: 'complete a quote',
      sweepsLink: 'See official rules.',
      giftCard: 'Refer a friend and get an Amazon gift card!',
      referralPage: 'Go to referral page',
      helpInfo: {
        heading: 'How can we help you today?',
        fileNewClaim: 'I\'d like to file a new claim.',
        claimStatus: 'I want to check the status of a claim.',
        coverageDetails: 'I want to view my coverage details.',
        billingInfo: 'I need to update my billing information.',
        contactInfo: 'I need to change my contact information.'
      }
    }
  },
  validations: {
    confirmationModal: {
      heading: 'Confirmation'
    },
    sessionoutError: 'Your session has timed out, Do you want to login again?',
    maintenanceError: 'We\'re sorry. Our system is undergoing routine maintenance. Please attempt your transaction at a later time. Thank you for your patience.',
    serviceError: {
      title: 'Error',
      heading: '500 - Internal Server Error',
      content: 'There is a problem with the resources you are looking for, and it cannot be displayed'
    },
    AccountInfoError: {
      AccountError: 'Error retrieving contact information.',
      OptInError: 'No Data Found'
    },

    AuthorizePayments: {
      Success: 'Successfully Authorized Payment.',
      AlreadyCompleted: 'Authorization for this Policy Number has been Already Completed.',
      declined: 'Payment Information Provided Was Not Authorized'
    },

    settings: {
      changePassword: {
        invalidOldPwd: 'Invalid Old Password'
      },
      login: {
        currentLogin: {
          required: 'Current Login ID is required.',
          max: 'Current Login ID should be no longer than _',
          pattern: 'Enter valid login ID'
        }
      },
      changeLoginIdError: 'Invalid Security Answer'
    },
    forgotPassword: {
      loginIdError: 'Login ID is not found',
      petNameOrZipError: 'Invalid zip or Pet Name',
      securityQuestionError: 'Failed to retrieve Security Question. Invalid User'
    },
    loginPage: {
      toolTip: {
        loginId: 'Your Login ID must be at least 6 characters. You can use any combination of letters, numbers, @ (at sign), _ (underscore) or . (period).',
        password: 'Must be 8-65 characters long and a combination of letters and numbers or characters. Valid characters ' + '`~!@#$:%^&*-_/\\|?,.',
        confirmPassword: 'Must match Password. Case sensitive',
        securityQuestion: 'Must be at least 6 characters long. including spaces, and cannot exceed 80 characters. Please use ' + 'numbers and letters only. No special characters accepted, including question marks.',
        securityAnswer: 'Answer cannot exceed 80 characters.'
      },
      loginError: 'Login ID is Required',
      loginMinError: 'Login ID must be 6 characters',
      loginMaxError: 'Login ID must be less than 60 characters',
      loginIdPatternError: 'No special characters other than, @ (at sign), _ (underscore) or . (period). are allowed',
      loginIdPattern: 'Your Login ID must be at least 6 characters and unique. You can use any combination of letters, numbers, @ (at sign), _ (underscore) or . (period).',
      password: 'Password is Required',
      passwordMinLength: 'Must be 6-65 characters long and a combination of letters and numbers or characters.',
      passwordPattern: 'Must be 8-65 characters long and a combination of letters and numbers or characters. Valid characters ' + '`~!@#$:%^&*-_/\\|?,.',
      passwordLength: '',
      confirmPassword: 'Make sure your passwords match'
    },
    forgotId: {
      emailReqError: 'E-mail is Required',
      emailPatternError: 'Email format is incorrect',
      confirmEmail: 'Make sure your emails match',
      errorModal: {
        header: `The Email Address you have entered does not match the information in our records. Please try a different email address or create an account here`
      },
      confirmModal: {}
    },
    registerAccount: {
      tooltip: {
        policyNumber: 'Your policy Number can be found on your declarations Page that came with your Policy Packet.',
        firstName: 'Please enter your name exactly as it appears on the Declarations Page of your policy, even if misspelled.',
        phoneNumber: 'This must match the current phone number on file.'
      },
      policyNumReqError: 'Policy Number is required',
      policyNumLength: 'Policy Number Should be less than 24 characters',
      firstNameReq: 'First Name is required',
      lastNameReq: 'Last Name is required',
      zipCodeReq: 'Zip code is required',
      zipCodeLengthError: 'must be 5 character',
      phoneNumReq: 'Phone number is required',
      phoneNumLengthErr: 'Phone Number is not valid',
      securityQuesReq: 'Security Question is Required',
      securityQueMinLength: 'Security Question must be 6 characters.',
      securityQuePattern: 'No special characters accepted, including question marks.',
      securityAnsReq: 'Security Answer is required',
      error: 'Error',
      errorTitle: 'The Information you have entered does not match the information in our records.',
      errorMessage: 'If you require additional assistance, contact us via email at customercare@petinsurance.com or give us ' + 'a call at ' + '844-397-8938. Our Member Care team is available Monday-Friday 5am-7pm and Saturday 7am-3:30pm (Pacific)',
      duplicateUserId: 'We\'re sorry, but the Login ID that you have chosen already exists on another account. Please choose a new Login ID.',
      duplicateEmailId: 'We\'re sorry, but the email address that you have chosen already exists on another account. Please choose a new email address.',
      policyExists: 'Our Records indicate this policy number is already associated with an existing Pet Account Access Page login ID.',
      accountLocked: 'Your Account is Locked for 30 minutes as a result of 4 unsuccessful password attempts.',
      invalidPassword: 'Invalid Password',
      authFail: 'LoginId or Password is Incorrect'
    }
  },
  forgotIdPage: {
    heading: 'Forgot Your Login ID?',
    info: `No problem. Enter the information associated with the online account you created. If it matches our records, we will email your login ID.Need to create an account?`,
    startHere: `Start here.`
  },
  forgotPasswordPage: {
    heading: 'Forgot Your Password?',
    info: 'Enter the information below to proceed.',
    securityInfo: 'Please enter the answer to your personalized security question below.',
    forgotId: 'Forgot your ID?',
    loginId: 'Login ID:',
    zipCode: 'Zip Code:',
    petName: 'Pet Name'
  },
  buttons: {
    back: 'Back',
    submit: 'Submit',
    next: 'Next',
    edit: 'Edit',
    cancel: 'Cancel',
    save: 'Save'
  },
  fileNewClaim: {
    heading: 'Start a New Claim',
    WhoIsThisClaimFor: 'Who is this claim for ?',
    tellUsWhatHappened: 'Tell us what happened',
    reasonForVisitTapAllThatApply: 'Please select the reason',
    pleaseEnterYourHospitalName: 'Please enter your hospital name',
    question: 'What injury or illness did your veterinarian diagnose?',
    invoicesTotal: 'Invoice(s) total',
    invoiceDescription: 'You must submit itemized invoices with your claim form. Do not send estimates.',
    uploadInvoice: 'Upload invoice(s)',
    submitClaim: 'Submit claim',
    imageRestriction: 'Only jpg, jpeg, png, tiff, pdf files are allowed',
    viewTerms: 'By checking "View and Accept Terms and Conditions", I confirm to the best of my knowledge the information' +
      ' I provided is both true and correct. I authorize the release of my pet\'s medical records to Nationwide.',

    errorMessages: {
      selectThePet: 'Please select the pet',
      reasonForVisit: 'Error: Reason for visit required',
      hospitalName: 'Clinic/Pharmacy required',
      diagnosisDetails: 'Please enter the diagnosis details',
      treatmentDateFrom: 'Valid treatment start date required',
      treatmentDateTo: 'Valid treatment end date required',
      invoicesTotal: 'Error: Please enter the valid amount',
    }

  },

  claimTermsAndConditions: {
    title: `<b>Terms and Conditions</b>`,
    attachedEverything: `<b>Have you attached everything needed to process your claim?</b><br/>
    Ask your veterinarian's office for copies of your pet's treatment records and submit them with your claim. Treatment records and lab results
     help us process your claim faster, especially if your pet was treated for more than one condition, stayed overnight at the hospital or
     did not have a definite diagnosis.`,
    trackStatus: `<b>Want to track status of your claim?</b><br/>
    Log on to the Nationwide Pet Account Access page at my.petinsurance.com.
    <b>Please allow up to 48 hours for online claims to appear on your Nationwide Pet Account Access Page.</b>
    Your claim will be available for tracking within 72 hours from the date it is received.`,
    haveAnyQuestion: `<b>Have any questions</b>?<br/>
         For further assistance, please contact Member Care at 844-397-8938.
     Our Member Care team is available Monday through Friday from 5 a.m. to 7 p.m. and Saturday from 7 a.m. to 3:30 p.m. (Pacific).
     <br>
     <br>

    <b>Important notice</b>
    <br>
     WARNING: Any person who knowingly or with intent to defraud, injure, or deceive an insurance company files an application for insurance or presents a claim containing any materially false information, or
     conceals information for the purpose of misleading, concerning any material fact, commits a fraudulent insurance act, which is a crime – possibly constituting a felony –  and may subject
     the person to criminal and civil penalties, which may include imprisonment, fines, or a denial of insurance benefits. Attention Alabama Residents: Any person who knowingly presents a false or fraudulent
      claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution fines or confinement in prison, or any combination thereof.
      Attention Alaska Residents: A person who knowingly and with intent to injure, defraud, or deceive an insurance company files a claim containing false, incomplete, or misleading information may be prosecuted under state law.
       Attention Arizona Residents: For your protection Arizona law requires the following statement to appear on this form.
       Any person who knowingly presents a false or fraudulent claim for payment of a loss is subject to criminal and civil penalties. Attention Arkansas Residents: Any person who knowingly presents a false or fraudulent claim for payment
       of a loss or benefit or knowingly presents false information in an application for
       insurance is guilty of a crime and may be subject to fines and confinement in prison. For your protection California law requires the following to appear on this form: Any person who knowingly presents false or fraudulent information
       to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison. Attention Colorado Residents: It is unlawful to
        knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance,
     and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or
     information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a
     settlement or award payable from insurance proceeds shall be reported to the Colorado division of insurance within the department of regulatory
     agencies. WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other
      person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related
       to a claim was provided by the applicant. Attention Delaware Residents: Any person who knowingly, and with intent to injure, defraud or deceive
        any insurer, files a statement of claim containing any false, incomplete or misleading information is guilty of a felony.
        Attention Florida Residents: Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of
        claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree. Attention Idaho Residents:
         Any person who knowingly, and with intent to defraud or deceive any insurance company, files a statement of claim containing any false,
         incomplete, or misleading information is guilty of a felony. Attention Indiana Residents: Any person who knowingly, and with intent to defraud
         or deceive any insurance company, files a statement of claim containing any false, incomplete, or misleading information is guilty of a felony.
          Attention Kentucky Residents: Any person who knowingly and with intent to defraud any insurance company or other person files a statement of
          claim containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto
          commits a fraudulent insurance act, which is a crime. Attention Louisiana Residents: Any person who knowingly presents a false or fraudulent
          claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be
           subject to fines and confinement in prison. Attention Maine Residents: It is a crime to knowingly provide false, incomplete or misleading
           information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of
           insurance benefits. Attention Maryland Residents: Any person who knowingly or willfully presents a false or fraudulent claim for payment of a
           loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be
           subject to fines and confinement in prison. Attention Minnesota Residents: A person who files a claim with intent to defraud or helps commit
            a fraud against an insurer is guilty of a crime. Attention New Hampshire Residents: Any person who, with a purpose to injure, defraud or
            deceive any insurance company, files a statement of claim containing any false, incomplete or misleading information is subject to prosecution
             and punishment for insurance fraud, as provided in RSA 638:20. Attention New Jersey Residents: Any person who knowingly files a statement of
             claim containing any false or misleading information is subject to criminal and civil penalties. ATTENTION NEW MEXICO RESIDENTS: ANY PERSON
             WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION
              FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO CIVIL FINES AND CRIMINAL PENALTIES. Attention New York Residents: Any person who
              knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim
               containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto,
               commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed $5,000 and the stated
               value of the claim for each such violation. Attention Ohio Residents: Any person who, with intent to defraud or knowing that he is
               facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of
                insurance fraud. WARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the
                proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony. Attention Pennsylvania
                Residents: Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or
                statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact
                material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.
                Attention Rhode Island Residents: Any person who knowingly presents a false or fraudulent claim for payment of a loss or
                 benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines
                 and confinement in prison. Attention Tennessee Residents: It is a crime to knowingly provide false, incomplete or misleading information
                  to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance
                   benefits. Attention Texas Residents: Any person who knowingly presents a false or fraudulent claim for the payment of a loss is
                   guilty of a crime and may be subject to fines and confinement in state prison. Attention Utah Residents: Any person who knowingly
                    presents false or fraudulent underwriting information, files or causes to be filed a false or fraudulent claim for disability
                    compensation or medical benefits, or submits a false or fraudulent report or billing for health care fees or other professional
                    services is guilty of a crime and may be subject to fines and confinement in state prison. Attention Virginia Residents: It is
                    a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding
                    the company. Penalties include imprisonment, fines and denial of insurance benefits. Attention Washington Residents: It is a
                    crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding
                    the company. Penalties include imprisonment, fines, and denial of insurance benefits. Attention West Virginia Residents:
                     Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false
                     information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.


    I authorize the release of my pet’s medical records to Nationwide.`,

    confirmation: `Thank you!
    Your claim was successfully submitted. Please allow up to 48 hours for the claim to appear in your Nationwide Pet Account Access Page.
     We’ll also call or email you with confirmation at that time.`,
    error: `There was an issue submitting your claim.`,
    corruptFiles: `For troubleshooting tips, please follow the below steps:<br/>
    <ol>
    <li>Check the file type. Only jpg, jpeg, png, pdf or tiff are accepted.</li>
    <li>Make sure the file name consists only of letters and numbers; no special characters.</li>
    <li>Ensure PDF files are not password protected.</li>
    <li>Check that the total file size does not exceed 140MB.</li>
    </ol>
    <b>Still no luck? Try...</b>
    <ul>
    <li>Taking a new picture of the file.</li>
    <li>Taking a screenshot or print screen of the file.</li>
    <li>From a different browsers or device.</li>
    </ul>
    If all else fails, consider fax or USPS.<br/>
  <b>Fax:</b><br/>
   &nbsp; &nbsp; &nbsp; 714-989-5600
  <br/>
  <b>Mail:</b><br>
  &nbsp; &nbsp; &nbsp;  Nationwide Claims Department<br/>
  &nbsp; &nbsp; &nbsp;  P.O. Box 2344<br/>
  &nbsp; &nbsp; &nbsp;  Brea, CA 92822`
  },


  accountInfo: {
    header: 'My account',
    mySettings: 'My settings',
    myAccount: {
      electECP: `I have elected to receive all claim payments and all Explanation of Benefits electronically.
        By unchecking the box, I understand I will no longer receive claim payments electronically but will
        still receive all Explanation of Benefits by electronic delivery. Please go to ‘Manage your paperless options’
        to update your preferences for electronic delivery of Explanation of Benefits.`,
      confirmed: {
        body: 'You have successfully changed your contact information.',
        header: 'Confirmation'
      },
      header: 'Edit Personal Information',
      defaults: {
        state: 'Select State'
      },
      placeHolders: {
        addressLine1: 'Enter your street address',
        city: 'Enter your city',
        emailAddress: 'Enter your email address',
        homePhone: 'Enter your primary phone',
        mobilePhone: 'Enter your mobile phone',
        zipCode: 'Enter your zip code'
      },
      errorMessages: {
        pendingRenewalState: {
          initial: `* Address changes that require a new state of residence cannot be made online during renewal. To complete your address update, please call a pet insurance specialist at ${HYPERLINKSCONSTANTS.petInsuranceSpecialist.text}.`,
          modal: `* One or more of your policies is up for renewal. As a result, we're unable to process your online request to update your state of residence. To complete your address update, please call a pet insurance specialist
          at ${HYPERLINKSCONSTANTS.petInsuranceSpecialist.text} between the hours of 5 a.m. to 7 p.m. Monday–Friday, or 7 a.m. to 3:30 p.m. Saturday (Pacific).`
        },
        addressLine1: {
          pattern: 'Not a valid address format...',
          required: 'You must enter an address...'
        },
        addressLine2: {
          pattern: 'Not a valid address format...'
        },
        city: {
          pattern: 'Not a valid city format...',
          required: 'You must enter a city...'
        },
        state: {
          pattern: 'Not a valid state format...',
          required: 'You must enter a state...'
        },
        zipCode: {
          pattern: 'Zip Code must be #####.',
          required: 'You must enter a Zip Code',
          maxLength: `Zip code should not be more than ${FormValidationConstants.lengthValidation.zipCodeMaxLength} characters`
        },
        emailAddress: {
          pattern: 'Not a valid email format...',
          required: 'You must enter an email...',
          maxLength: `Email should not be more than ${FormValidationConstants.lengthValidation.emailLength} characters`,
          emailMismatch: 'Emails do not match'
        },
        phone: {
          pattern: 'Invalid phone # format...',
          required: 'You must enter a phone #...',
          maxLength: `Phone # must be no more than ${FormValidationConstants.lengthValidation.phoneMaxLength} digits`,
          minLength: `Phone # must be no more than ${FormValidationConstants.lengthValidation.phoneMinLength} digits`
        }
      },
      labels: {
        addressLine1: 'Street address',
        addressLine2: 'Address Line 2',
        city: 'City',
        emailAddress: 'Email address',
        firstName: 'First Name',
        homePhone: 'Primary phone',
        lastName: 'Last Name',
        mobilePhone: 'Mobile phone',
        state: 'State',
        verifyEmailAddress: 'Verify email address',
        zipCode: 'Zip Code'
      }
    },
    payment: {
      placeholders: {
        accountNumber: 'Enter account number',
        cardNumber: 'Enter card number',
        routingNumber: 'Enter routing number'
      },
      confirmed: {
        header: 'Confirmation',
        body: 'You have successfully changed your payment information.'
      },
      header: 'Payment',
      editPayment: 'Edit Payment Information',
      myPetHeader: 'Payment Information',
      labels: {
        accountNumber: 'Account number',
        accountType: 'Account type',
        cardName: 'Card name',
        cardNumber: 'Card number',
        cardType: 'Card type',
        creditCard: 'Credit Card',
        debit: 'Debit/Checking account',
        electronicClaimPayment: 'Electronic claim payment',
        expDate: 'EXP Date',
        expiration: 'Expiration',
        goPaperless: 'Go paperless',
        managePreference: 'Manage preferences',
        payment: 'Payment',
        paymentMethod: 'Payment method',
        routingNumber: 'Routing ABA number',
        selectPet: 'Select a pet to update',
        status: 'Status',
        frequency: 'Please select your payment frequency',
        needToCollect: 'To update your payment method, we will need to collect the following amount: ',
        renewalText: `Your policy is currently in its renewal period. You will be required to renew your policy as part of transitioning to a new payment method.`,
        totalBalance: 'Total balance due today: '
      },
      tooltip: {
        updatePaymentYes: `Converting your policy from payroll deduction to a direct bill payment method requires an updated payment schedule. Payroll deductions are received from your employer and applied to your policy after the current coverage month,
         whereas direct bill requires monthly payments in advance of the current coverage month.  Your policy payments must be brought into alignment with the new direct bill payment schedule, so the past due, current and next month’s payments are due at conversion.
         After the policy is converted, any money received from your employer will be applied to future payments or refunded to you in the event that the policy is paid in full.`,
        updatePaymentNo: `Converting your policy from payroll deduction to a direct bill payment method requires an updated payment schedule. Payroll deductions are received from your employer and applied to your policy after the current coverage month,
         whereas direct bill requires monthly payments in advance of the current coverage month.  Your policy payments must be brought into alignment with the new direct bill payment schedule, so the past due, current and next month’s payments are due at conversion.
         After the policy is converted, any money received from your employer will be applied to future payments or refunded to you in the event that the policy is paid in full. ` +
          `\n\n` + ` Your premium will not change for the remainder of your policy term. However, your premium may change at your next renewal if you no longer qualify for group preferred pricing.`
      },
      errorMessages: {
        routingNumber: {
          invalidABA: 'Please enter valid routing number',
          pattern: 'Please enter valid routing number',
          maxLength: `Routing number should not be more than ${FormValidationConstants.lengthValidation.routingLength} characters`,
          minLength: `Routing number should not be less than ${FormValidationConstants.lengthValidation.routingLength} characters`,
          required: 'This field is required'
        },
        accountNumber: {
          pattern: 'Please enter valid account number',
          maxLength: `Account number should not be more than ${FormValidationConstants.lengthValidation.accountMaxLength} characters`,
          minLength: `Account number should not be less than ${FormValidationConstants.lengthValidation.accountMinLength} characters`,

          required: 'This field is required'
        },
        cardNumber: {
          pattern: 'Please enter valid card number',
          minLength: `Card number should not be less than ${FormValidationConstants.lengthValidation.ccMinLength} characters`,
          required: 'This field is required',
          invalidCardType: 'Invalid credit card type'
        },
        accountType: {
          required: 'This field is required'
        },
        expMonth: {
          required: 'This field is required'
        },
        expYear: {
          required: 'This field is required',
          expired: 'Card has expired'
        }
      },
      allPets: 'Update all pets'
    },
    accountType: 'Account type',
    accountNumber: 'Account number',
    routingNumber: 'Routing ABA number',
    // myAccount: 'My account',
    personal: 'Personal',
    edit: 'Edit',
    firstName: 'First Name',
    lastName: 'Last Name',
    streetAddress: 'Street address',
    line2: 'Line 2',
    city: 'City',
    state: 'State',
    zip: 'Zip Code',
    emailAddress: 'Email address',
    homePhone: 'Primary phone',
    mobilePhone: 'Mobile phone',
    save: 'Save',
    editPaymentInfo: 'Edit payment info',
    selectPaymentMethod: 'Select payment method',
    creditCard: 'Credit Card',
    debitCard: 'Debit/Checking account',
    accountNickname: 'Account nickname',
    cardNumber: 'Card number',
    nameOnCard: 'Name on card',
    expDate: 'EXP Date',
    cvvCode: 'CVV Code',
    // payment: 'Payment',
    paymentMethod: 'Payment method',
    status: 'Status',
    cardName: 'Card name',
    cardType: 'Card type',
    expiration: 'Expiration',
    managePreferences: 'Manage preferences',
    editManagePreference: 'Edit Manage preferences',
    goPaperless: 'Go paperless',
    electronicClaimPayment: 'Electronic claim payment',
    customerMessage: 'If you would like to change your payment method, please email your request or contact our Member Care\n' + '        department at 844-397-8938. Our Member Care team is available Monday-Friday 5am-7pm and Saturday 7am-3:30pm (Pacific).',
    privacy: {
      title: 'Your Privacy',
      body: 'We value you as a member and respect your right to privacy. We will not sell or share your information with any third party as governed by applicable state and federal law.'
    }
  },
  settings: {
    heading: 'My settings',
    subHeading: 'Making Changes?',
    info: 'If you need to make changes to your account information, please enter them here. All updates will be reflected immediately on your account.',
    info1: 'If you need to make changes to your account information, please click on edit. All updates will be reflected immediately on your account.',
    login: {
      confirmation: {
        header: 'Confirmation',
        body: 'You have successfully updated your login ID.'
      },
      editError: {
        text: 'The Information you have entered does not match the information in our records.',
        login: 'Current Login ID.',
        password: 'Password.'
      },
      heading: 'Change login ID',
      info: 'Your Login ID must be at least 6 characters. You can use any combination of letters, numbers, @ (at sign), _ (underscore) or . (period).',
      labels: {
        currentID: 'Current login ID',
        newID: 'New login ID',
        confirmNewID: 'Confirm new login ID',
        password: 'Password'
      },
      placeHolders: {
        currentLogin: 'Enter Current Login ID',
        newLogin: 'Enter New Login ID',
        confirmLogin: 'Confirm new login ID'
      },
      errorMessages: {
        currentLogin: {
          required: 'Current login ID is Required',
          maxLength: `Current login ID should be no more than ${FormValidationConstants.lengthValidation.loginIDMaxLength} characters`,
          minLength: `Current login ID should be no less than ${FormValidationConstants.lengthValidation.loginIDMinLength} characters`,
          pattern: 'Enter valid login ID'
        },
        newLogin: {
          required: 'New login ID is Required',
          maxLength: `New login ID should be no more than ${FormValidationConstants.lengthValidation.loginIDMaxLength} characters`,
          minLength: `New login ID should be no less than ${FormValidationConstants.lengthValidation.loginIDMinLength} characters`,
          pattern: 'Enter valid login ID'
        },
        confirmLogin: {
          required: 'Login ID confirmation is Required',
          maxLength: `Login ID confirmation should be no more than ${FormValidationConstants.lengthValidation.loginIDMaxLength} characters`,
          minLength: `Login ID confirmation should be no less than ${FormValidationConstants.lengthValidation.loginIDMinLength} characters`,
          pattern: 'Enter valid login ID',
          mismatch: 'Login IDs do not match'
        },
        password: {
          required: 'Password is required',
          minLength: `Password should be no less than ${FormValidationConstants.lengthValidation.passwordMinLength} characters`,
          maxLength: `Password should be no more than ${FormValidationConstants.lengthValidation.passwordMaxLength} characters`,
          pattern: 'Enter valid password'
        }
      }
    },
    password: {
      confirmation: {
        header: 'Confirmation',
        body: 'You have successfully updated your password.'
      },
      editError: {
        generic: 'There was a problem updating your Password. Please try again later.',
        currentPassword: 'The Current Password you have entered does not match the information in our records'
      },
      heading: 'Change password',
      resetPassword: 'Change Your password',
      info: 'Password must be between 8-65 characters in length and must include at least one number and one letter or special character.',
      caseWarning: 'Passwords are case sensitive.',
      requiredWarning: 'All fields are required.',
      labels: {
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password'
      },
      placeHolders: {
        currentPassword: 'Enter current password',
        newPassword: 'Enter new password',
        confirmNewPassword: 'Enter new password confirmation'
      },
      errorMessages: {
        currentPassword: {
          required: 'Current password is required',
          minLength: `Current password should be no less than ${FormValidationConstants.lengthValidation.passwordMinLength} characters`,
          maxLength: `Current password should be no more than ${FormValidationConstants.lengthValidation.passwordMaxLength} characters`,
          pattern: 'Enter valid password'
        },
        newPassword: {
          required: 'New password is required',
          minLength: `New password should be no less than ${FormValidationConstants.lengthValidation.passwordMinLength} characters`,
          maxLength: `New password should be no more than ${FormValidationConstants.lengthValidation.passwordMaxLength} characters`,
          pattern: 'Must be 8-65 characters long and a combination of letters and numbers or characters. Valid characters ' + '`~!@#$:%^&*-_/\\|?,.'
        },
        confirmNewPassword: {
          required: 'Password confirmation is required',
          minLength: `Password confirmation should be no less than ${FormValidationConstants.lengthValidation.passwordMinLength} characters`,
          maxLength: `Password confirmation should be no more than ${FormValidationConstants.lengthValidation.passwordMaxLength} characters`,
          pattern: 'Must be 8-65 characters long and a combination of letters and numbers or characters. Valid characters ' + '`~!@#$:%^&*-_/\\|?,.',
          passwordMismatch: 'Passwords do not match'
        }
      }
    },
    securityQuestions: {
      confirmation: {
        header: 'Confirmation',
        body: 'You have successfully updated your security Q&A.'
      },
      editError: {
        header: 'Error',
        body: 'There was a problem updating your Security Q&A. Please try again later.'
      },
      heading: 'Update security question',
      headingCreate: 'Create security question',
      info: 'Please create a security question and answer in case you forget your password.',
      requiredWarning: 'All fields are required.',
      labels: {
        question: 'Please create your personalized security question:',
        answer: 'Please enter the answer:'
      },
      placeHolders: {
        question: 'Enter security question',
        answer: 'Enter answer'
      },
      hints: {
        question: 'Security question must be at least 6 characters long, including spaces, and cannot exceed 80 characters. Please use numbers and letter only. No special characters accepted.',
        answer: 'Answer cannot exceed 80 characters.'
      },
      errorMessages: {
        question: {
          required: 'Security question is required',
          minLength: `Security question should be no less than ${FormValidationConstants.lengthValidation.securityQuestionMinLength} characters`,
          maxLength: `Security question should be no more than ${FormValidationConstants.lengthValidation.securityQuestionMaxLength} characters`,
          pattern: `No special characters are allowed including but not limited to the following: ? / . > , < : ; ' ! & -`
        },
        answer: {
          required: 'Answer is required',
          maxLength: `Answer should be no more than ${FormValidationConstants.lengthValidation.securityAnswerMaxLength} characters`,
          pattern: `No special characters are allowed including but not limited to the following: ? / . > , < : ; ' ! & -`
        }
      }
    }
  },
  pets: {
    managePolicy: {
      heading: 'Manage Policy',
      policy: 'Policy #',
      effective: 'Policy Effective:',
      expiry: 'Policy Expires:',
      deductible: 'Policy Deductible:',
      viewPolicies: 'View all policy documents',
      renewPolicy: 'Renew your policy',
      upgradePolicy: 'Upgrade your Policy',
      cancelPolicy: 'Cancel your policy',
      renewal: {
        renewalBilling: {
          heading: 'Policy Renewal'
        },
        renewalConfirmation: {
          heading: 'Policy Renewal',
          headingInfo: 'Please Confirm Your Information',
          paymentPlan: 'Payment Plan:',
          paymentAmount: 'Payment Amount:',
          paymentType: 'Payment Type:',
          policy: 'Policy: #',
          pet: 'Pet:'
        }
      }
    }
  }
};
