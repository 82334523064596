export function getCookieValue(name) {
    const regex = new RegExp(`(^| )${name}=([^;]+)`)
    const match = document.cookie.match(regex)
    if (match) {
        return match[2]
    }
}
export function setCookieValue(cookieValue) {
    document.cookie = cookieValue;
}
  
export const UNUM_COOKIE_INFO = `isUnumUser=true; max-age=${new Date(new Date().getTime()+(5*24*60*60*1000))}; path=/`
export const UNUM_COOKIE_KEY = 'isUnumUser';