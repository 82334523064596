import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../../../shared/constants/routing-constants';
import {DataService} from '../../../../../core/services/data.service';

@Component({
  selector: 'app-cancellation-response',
  templateUrl: './cancellation-response.component.html',
  styleUrls: ['./cancellation-response.component.scss']
})
export class CancellationResponseComponent implements OnInit {
  private routes = ROUTINGCONSTANTS;
  public cancelResponse: any;

  constructor(private router: Router, private dataService: DataService) {
  }

  ngOnInit() {
    this.cancelResponse = this.getCancellationResponse();
    if (!this.cancelResponse) {
      this.goToPetsPage();
    }
  }

  getCancellationResponse() {
    return this.dataService.cancelResponseView;
  }

  goToPetsPage() {
    this.router.navigate([this.routes.pets]);
  }
}
